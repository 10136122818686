import React, { useEffect, useState, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15712@1x.png'
import logo4 from '../assets/images/path-15743-1@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15709-10@1x.png'
import logo8 from '../assets/images/path-15710-10@1x.png'
import mountain2 from '../assets/images/mountain2@1x.png'
import mountain3 from '../assets/images/mountain-3@1x.png'
import chess_brain from '../assets/images/chess-brain@1x.png'
import elephantlogo from '../assets/images/elephant-tree@1x.png'
import yoga from '../assets/images/yoga-2@1x.png'
import img3 from '../assets/images/img3.svg'
import { UpdateAssessments } from './Asses/Comm_Text'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import menu from '../assets/images/white dash.svg'
export default function CriticalThinking31(props) {
  const { dispatch, state } = useApp()
  let isMemoryLeak = useRef(true)
  const [open, toggleMenu] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.criticalthinking?.assesment[2]
      ? state?.user?.progress?.course?.criticalthinking?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const history = useHistory()

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    var stateNew = {
      ...state,
    }
    UpdateAssessments(
      stateNew.user.progress.course.criticalthinking,
      2,
      Number(localStorage.getItem('Pbarvalue3')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue3')), percent1),
    )

    await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        assessment:
          state?.user?.progress?.course?.criticalthinking?.assesment[0] +
          state?.user?.progress?.course?.criticalthinking?.assesment[1] +
          state?.user?.progress?.course?.criticalthinking?.assesment[2],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }

    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue3', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/critical_thinking3.2')
    } else {
      history.push('/critical_thinking3.1')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange upperr flex flex-row justify-between fixed w-full top-0'>
        <div>
          {open && (
            <div>
              <GlobalMenu openMenu={() => toggleMenu(false)} />
            </div>
          )}
          <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
            {!open ? (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' alt='logo' src={menu} />
                
              </div>
            ) : (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' alt='logo' src={logo3} />
                <img className='h-3 left-6  top-6 absolute' alt='logo' src={logo5} />
              </div>
            )}
          </div>
        </div>
        <h4 className='text-center  pt-5  percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>
      {!open && (
        <div className='w-screen mt-16'>
          <div className='crical_thinking_splash3  bg-no-repeat pl-5 pr-5 flex flex-col justify-evenly'>
            <div className='flex justify-center relative top-5'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs _bold font-semibold  pb-2 pt-10'
                style={{ color: '#ffffff', letterSpacing: '6px' }}>
                SOLVE
              </h4>
              <h4
                className='text-center text-2xl leading-6 mb-4 font-medium  _medium'
                style={{ color: '#ffffff' }}>
                Critical Thinking 3
              </h4>
              <h4
                className='_regular text-center text-xs mb-10 font-normal tracking-normal pb-20'
                style={{ color: '#ffffff' }}>
                Discover a proven process to harness the power of critical thinking and solve
                problems more successfully every day.
              </h4>
            </div>
            <h4
              className='_regular text-center text-xs mb-5 font-normal tracking-normal'
              style={{ color: '#ffffff', fontSize: '10px' }}>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </h4>
          </div>
          <div className=' overflow-auto'>
            <div className='mt-12'>
              <p
                className='_bold text-center  uppercase getFitSmallHeader'
                style={{ letterSpacing: '2px' }}>
                GET FIT
              </p>
              <h4
                className='text-center getFitMidHeading mb-[45px]  uppercase mt-2'
                style={{ lineHeight: '14px', letterSpacing: '2.80px' }}>
                CRITICAL THINKING 3
              </h4>
              <h4 className=' text-center _regular text-2xl leading-[29px] mb-6 text1 pl-10 pr-10'>
                Critical Thinking In <br></br> The Real World
              </h4>
            </div>
            <h4 className='text-left  mb-10 getFitBodySection ml-10 mr-10'>
              You should now have a clear understanding about what critical thinking is and why you
              need it. Now let’s explore how you will use it every day in the real world. As
              previously discussed, every job and virtually almost every professional and personal
              situation you find yourself in will give you an opportunity to apply critical thinking
              to make a better decision.
            </h4>
            <img alt='logo' src={mountain2} className='w-full mb-12' />
          </div>
          <div>
            <h4 className='text-left getFitBodySection  ml-10 mr-10'>
              Remember, these skills you are learning are just as applicable in your personal life
              as they are in your professional life.<br></br>
              <br></br>
              Next is an infographic that you can use as a template to harness the power of critical
              thinking every day. Take a moment to study the flow of information, important decision
              points, and potential outcomes.
            </h4>
            <div className='mt-7 _group-bg1 '>
              <img src={img3} alt='Fullbride_Image' className='cThinkingImg'></img>
            </div>

            <h4 className='text-center mt-9 mb-7 getFitHeadline pl-10 pr-10'>
              Think About Your Thinking
            </h4>
            <h4 className='text-left  getFitBodySection ml-10 mr-10'>
              Critical thinking is your ability to think about your thinking. It is also your
              ability to understand the logical connection between different ideas.<br></br>
              <br></br> Critical thinking often requires you to use your ability to reason. In
              short, you must question everything. Don’t take any information at face value. Always
              remove emotion, personal bias and/or confirmation bias, and take a systematic and
              logical approach.
              <br></br>
              <br></br>
            </h4>
            <img alt='logo' src={chess_brain} className='w-full mt-2' />

            <h4 className='text-center mt-12 mb-7 getFitHeadline pl-10 pr-10'>
              Five Key Skills You Must Use Every Day
            </h4>
            <h4 className=' text-left getFitBodySection ml-10 mr-10 mb-9'>
              To use critical thinking every day, there are five key skills you need to employ. You
              must be able to:
            </h4>
            <div className='flex justify-center mt-10 mb-5'>
              <div className='h-10 w-10 _grp6 rounded-full pt-1.5 text-center'>
                <p className=' _bold font-bold text-white'>1</p>
              </div>
            </div>
            <h4 className='getFitBold16px text-center  mb-10  ml-10 mr-10 '>
              Think about the subject objectively and critically.
            </h4>
            <div className='flex justify-center mt-10 mb-5'>
              <div className='h-10 w-10 _grp6 rounded-full pt-1.5 text-center'>
                <p className='_bold font-bold text-white'>2</p>
              </div>
            </div>
            <h4 className='getFitBold16px text-center  mb-10  ml-10 mr-10 '>
              Clearly identify the different arguments or points of view in any given situation.
            </h4>
            <div className='flex justify-center mt-10 mb-5'>
              <div className='h-10 w-10 _grp6 rounded-full pt-1.5 text-center'>
                <p className='_bold font-bold text-white'>3</p>
              </div>
            </div>
            <h4 className='getFitBold16px text-center mb-10 ml-10 mr-10 _bold'>
              Evaluate an argument’s strengths and weaknesses.
            </h4>

            <div className='flex justify-center mt-10 mb-5'>
              <div className='h-10 w-10 _grp6 rounded-full pt-1.5 text-center'>
                <p className='_bold font-bold text-white'>4</p>
              </div>
            </div>
            <h4 className='text-center  mb-10 getFitBold16px ml-10 mr-10 _bold'>
              Remove emotion, inference, and bias.
            </h4>

            <div className='flex justify-center mt-10 mb-5'>
              <div className='h-10 w-10 _grp6 rounded-full pt-1.5 text-center'>
                <p className='_bold font-bold text-white'>5</p>
              </div>
            </div>
            <h4 className='text-center mb-10 getFitBold16px ml-10 mr-10 '>
              Articulate your argument, which is supported by facts.
            </h4>

            <img alt='logo' src={yoga} className='w-full mt-10 mb-10' />

            <div className='mt-16  mb-10'>
              <h4 className='text-center getFitHeadline mt-12 mb-7  pl-10 pr-10'>
                Build Your Critical Thinking Muscles
              </h4>
              <div className='text-left getFitBodySection pl-10 pr-10'>
                Critical thinking is like any muscle; we may not use it all the time, but the more
                we do use it, the stronger it becomes. The first and most important idea to keep in
                mind is to be aware and conscious of your thinking. <br></br>
                <br></br>Next, when someone provides you with information, always ask the following
                “Quick 4” questions:
                <ol className='my-7 _regular ml-5 text-left text-base font-normal   _font_color_jacarta list'>
                  <li className='text-left getFitBold16px'>
                    <strong>What did they tell me?</strong>
                  </li>
                  <li className='text-left getFitBold16px'>
                    <strong>Why did they tell me?</strong>
                  </li>
                  <li className='text-left getFitBold16px'>
                    <strong>What is it compared to?</strong>
                  </li>
                  <li className='text-left getFitBold16px '>
                    <strong> What will be the result?</strong>
                  </li>
                </ol>
                For example, if a colleague tells you, “I don’t like working with Bob,” asking the
                above questions helps you immediately understand the ramifications of that
                statement.
                <h4 className='text-left getFitBold16px mt-6'>What did they tell me?</h4>
                Were they clear that they don’t like working with Bob or that they don’t like Bob’s
                work?
                <h4 className='text-left getFitBold16px mt-7'>Why did they tell me?</h4>
                Are they trying to get Bob in trouble? Is Bob’s work subpar? Are they unhappy with
                the project? Are they unhappy at work in general?
                <h4 className='text-left getFitBold16px mt-7  '>What is it compared to?</h4>
                If they don’t like working with Bob, who do they like working with? Do they prefer
                to work alone?
                <h4 className='text-left  mt-7  getFitBold16px'>What will be the result?</h4>
                Do they need Bob’s help to get the project done on time and correctly? Is there
                someone else who can help? Does this hurt the team’s ability to be effective?
              </div>
            </div>
            <img alt='logo' src={mountain3} className='w-full mt-3 mb-8' />
            <h4 className='text-center mt-12 mb-7 getFitHeadline pl-10 pr-10'>
              Opportunities Every Single Day
            </h4>
            <h4 className='text-left getFitBodySection pl-10 pr-10'>
              Even simple statements may have much bigger ramifications when not thought about
              critically. Good critical thinking immediately creates other questions that need to be
              asked in order to find the right solution.<br></br>
              <br></br> There are countless opportunities and examples of critical thinking in the
              real world. Below are just a few to think about:
            </h4>
            <h4 className='my-7  text-left getFitBodySection pl-10 pr-10'>
              ● A politician is faced with a pandemic. If the politician chooses to allow business
              as usual, it could put millions of lives at risk. If the politician chooses to close
              businesses to stem the pandemic, it could put the economy at risk. Which is the right
              choice?
            </h4>
            <h4 className='my-7 text-left getFitBodySection pl-10 pr-10'>
              ● An attorney has evidence in a case that may slightly favor their client. Should the
              attorney risk going to trial and winning a larger settlement or settle out of court
              for a potentially lower sum?
            </h4>
            <h4 className='my-7 text-left getFitBodySection pl-10 pr-10'>
              ● A first responder arrives at the scene of an accident. One person is bleeding, the
              other person is not breathing. Which one should the first responder treat first?
            </h4>
            <h4 className='my-7 getFitBodySection text-left pl-10 pr-10'>
              ● The stock market is falling to all-time lows. Should you sell your stock, or should
              you buy? If so, which stocks and when?
            </h4>
            <h4 className='my-7 text-left getFitBodySection pl-10 pr-10'>
              ● You want to get in better shape. There is a substantial amount of information in
              regard to diet and exercise online as well as from family and friends. What
              information should you believe, and from whom, to create a successful fitness plan?
            </h4>
            <img alt='logo' src={elephantlogo} className='w-full mt-10 mb-14' />
            <h4 className=' text-center getFitSmallHeader tracking-[2px]'>CRITICAL THINKING 3</h4>
            <h4 className='text-center mb-7 getFitHeadline  pl-10 pr-10'>
              Critical Thinking Summary And Final Wrap-Up
            </h4>
            <h4 className='mt-7 getFitBodySection text-left  pl-10 pr-10 '>
              Critical thinking is the foundation upon which every professional and personal
              decision you make should be built. You have the opportunity every day to use your
              critical thinking skills and to strengthen those skills for better results.<br></br>
              <br></br>
              Put simply, critical thinking is at the heart of problem solving, innovation, data
              analysis, good communication, business analysis, marketing and sales, and most of the
              other top in-demand business skills today.
              <br></br>
              <br></br>
              Critical thinking is your ability to analyze information objectively and then make a
              reasoned and sound judgment. Good critical thinkers can draw reasonable conclusions
              from given data and successfully discriminate between useful and less useful data to
              solve problems and make decisions. In short, critical thinking is the single most
              in-demand skill in the world and one of the most difficult skills to find. In a
              fast-changing, data-filled world, critical thinking is your key to assessing your
              surroundings and making smart and sound decisions.
              <br></br>
              <br></br>
              Critical thinking is at the heart of virtually every in-demand professional skill.
              Think about your thinking. Question everything. Don’t make assumptions or take
              statements at face value. Use critical thinking as your most powerful tool to win in a
              hyper-competitive world.
            </h4>
            {!open && (
              <div className='w-full mt-[30px]  pb-10 grid place-items-center'>
                <button className='relative' onClick={navigateNext}>
                  <img alt='logo' src={logo7} className='h-10 w-10' />
                  <img alt='logo' src={logo8} className='absolute h-3.5 ml-2.5 bottom-3 top-1/3' />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
