import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import leftArrow from '../assets/images/leftArrow.svg'
import dumble from '../assets/images/group-4546-1@1x.png'
import Home from '../assets/images/home icon.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import mail from '../assets/images/mail.svg'
import cross from '../assets/images/path-15765@1x.png'
export const Collabrate_tool1_6 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textInput_6, setTextInput_6] = useState({
    firstField: '',
    secondField: '',
    thirdField: '',
    fourthField: '',
  })
  const handlemail = () => {
    let data = {
      first: state?.user?.progress?.course?.eq_tool?.textArea,
      second: state?.user?.progress?.course?.eq_tool?.textBox,
      third: state?.user?.progress?.course?.eq_tool?.textInput,
      fourth: state?.user?.progress?.course?.eq_tool?.textInput_5,
      fifth: textInput_6,
    }
    localStorage.setItem('collMail', JSON.stringify(data))
    return history.push('/colmailSend')
  }

  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    var stateNew = { ...state }
    if (state?.user?.progress?.course?.eq_tool?.assessmentPercent < 100) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_communicate`,
          assessment: 100,
          complete: true,
        }),
      )
    }
    stateNew.user.progress.course.eq_tool = {
      ...stateNew.user.progress.course.eq_tool,
      textInput_6,
      assessmentPercent:
        state?.user?.progress?.course?.eq_tool?.assessmentPercent > 100
          ? state?.user?.progress?.course?.eq_tool?.assessmentPercent
          : 100,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (
      textInput_6?.firstField?.length >= 1 &&
      textInput_6?.secondField?.length >= 1 &&
      textInput_6?.thirdField?.length >= 1 &&
      textInput_6?.fourthField?.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }

      return history.push('/user_dashboard')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.eq_tool?.textInput_6) {
      setTextInput_6(state?.user?.progress?.course?.eq_tool?.textInput_6)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold'>COLLABORATE</h3>
              <strong>
                <h1 className='percent _regular'>100%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 6</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>EQ Check In</h1>
              <p className='ex_solve_para '>
                Mastering Emotional Intelligence (EQ) means regularly checking in on what we are
                learning from an experience and how we could continue to improve. Take a moment to
                reflect on how you’re doing and what you could do better.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={saveData}>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  How have I been managing my emotions well during this project?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput_6.firstField}
                  onChange={e =>
                    setTextInput_6({
                      ...textInput_6,
                      firstField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                />
                <p className='message ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    I’m able to stay calm even when I become frustrated.
                  </span>
                </p>
              </div>

              <div className='action_div '>
                <p className='ex_solve_para mb-[15px]'>What could I do better?</p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput_6.secondField}
                  onChange={e =>
                    setTextInput_6({
                      ...textInput_6,
                      secondField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area'
                />
                <p className='message'>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    I could make sure I speak kindly to everyone on my team.
                  </span>
                </p>
              </div>
              <div className='action_div'>
                <p className='ex_solve_para mb-[15px]'>
                  How have I been managing my team’s emotions well during this project?
                </p>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textInput_6.thirdField}
                  onChange={e =>
                    setTextInput_6({ ...textInput_6, thirdField: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />
                <p className='message'>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    I listening better to everyone’s concerns and challenges.
                  </span>
                </p>
              </div>

              <div className='action_div '>
                <p className='ex_solve_para mb-[15px]'>What could I do better?</p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput_6.fourthField}
                  onChange={e =>
                    setTextInput_6({
                      ...textInput_6,
                      fourthField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area '
                />

                <p className='message ' style={{ marginBottom: '48px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    I could be more patient with my team when I know they are feeling frustrated.
                  </span>
                </p>
              </div>

              {/* <p className='ex_solve_para'>Email the EQ Check In.</p> */}
              <div className='w-full flex flex-col items-center gap-8 mb-11 mt-5'>
                <div className='flex w-[90%] items-center justify-between mb-4'>
                  <button onClick={() => history.push('/collabrate_tool1.5')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <Link to={'/user_dashboard'}>
                    <button type='submit' onClick={saveData}>
                      <img src={Home} alt='image no present' className='w-[40px] h-[40px]'></img>
                    </button>
                  </Link>
                </div>
                {/* <button type='submit'>
                  <img src={mail} alt='image no present' className='w-[40px] h-[40px]'></img>
                </button> */}
                <div>
                  <p className='ex_solve_para mb-3'>Email the EQ Check-In.</p>
                  <button type='submit'>
                    <img
                      src={mail}
                      alt='image no present'
                      className='w-[40px] h-[40px]'
                      onClick={handlemail}
                    />
                  </button>
                </div>

                <div>
                  <p className='ex_solve_para' style={{ marginTop: 0 }}>
                    Delete all content.
                  </p>
                  <button
                    type='reset'
                    className='mt-4'
                    onClick={() => history.push('/collabrate_tool1.7')}>
                    <div className='w-full flex items-center '>
                      <div className='cross'>
                        <img src={cross} alt='Image not found' width={15} height={15} />
                      </div>
                    </div>
                  </button>
                  <p className='text-[#43296E] leading-none text-[11px] mt-10'>
                    ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
