import { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import Communicate from '../assets/images/Communicate.svg'
import { useHistory } from 'react-router-dom'
import logocircle2 from '../assets/images/group-3437-1@1x.png'
export const Persuasion1_6 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()

  const handleClick = () => {
    history.push('/persuasion1.7')
  }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2 '} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>

      <div className='flex flex-col items-center'>
        <div className='h-16 w-16 rounded-full mt-12 mb-3.5 '>
          <img src={Communicate} logo='logo' />
        </div>
        <h4 className='text-center text-sm leading-6 _bold mb-7 error_msg_color lineH2_8'>
          COMMUNICATE
        </h4>
        <p className=' _regular text-2xl  pt-1.5 _margin_px text-center _font_color_jacarta mb-3'>
          What You Can Do With Your New Skill
        </p>

        <div className='flex justify-center mb-5'>
          <div className='rounded-full cursor-pointer flex mt-2 h-10 w-10 justify-center avatarBg'>
            <div
              className='leading-7  my-2 text-center text-lg font-bold w-8 _bold'
              style={{ color: '#ffffff' }}>
              1
            </div>
          </div>
        </div>

        <h4 className='_semiBold text-center text-base  mb-10 text1 _margin_px'>
          Build more powerful and articulate arguments.
        </h4>

        <div className='flex justify-center  mb-5'>
          <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
            <div
              className='leading-7  my-2 text-center text-lg font-bold w-8 _bold'
              style={{ color: '#ffffff' }}>
              2
            </div>
          </div>
        </div>

        <h4 className='text-center text-base mb-10  text1  _semiBold _margin_px'>
          Sell your ideas to friends, colleagues, and clients.
        </h4>

        <div className='flex justify-center  mb-5'>
          <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
            <div
              className='leading-7  my-2 text-center text-lg font-bold w-8 _bold'
              style={{ color: '#ffffff' }}>
              3
            </div>
          </div>
        </div>

        <h4 className='text-center text-base mb-6  text1  _semiBold _margin_px'>
          Persuade those around you to say “yes” more often.
        </h4>

        <div className='mb-12 flex justify-center mt-24' onClick={handleClick}>
          <div>
            <img className=' w-10 h-10' alt='logo' src={logocircle2} />
          </div>
        </div>
      </div>
    </div>
  )
}
