import React, { useState, useEffect } from 'react'
import White from '../assets/images/whitemenu icon.svg'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import useApp from '../context/useApp'
import { useHistory } from 'react-router-dom'
import { saveScoring, saveProgress } from '../services/user'

export const Create_tool2_0 = () => {
  const [open, toggleMenu] = useState(false)
  const [data, setData] = useState()
  const { state } = useApp()
  const history = useHistory()
  const deleteAll = async () => {
    const uri = '/course/course'
    const textArea = ''
    var stateNew = { ...state }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      textArea,
    }
    await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    await saveScoring(
      JSON.stringify({
        course: `exercise_create`,
        assessment: 0,
      }),
    )
    const stateData = state.user.progress.course
    delete stateData.create_tool

    const localData = JSON.parse(localStorage.getItem('auth'))
    let lData = localData.user.progress.course
    delete lData.create_tool

    setData(localData)
    alert('Create Exercise data has been deleted successfully.')
  }
  useEffect(() => {
    if (data) {
      localStorage.setItem('auth', JSON.stringify(data))
      return history.push('/exercise')
    }
    return () => {
      if (history.action === 'POP') {
        history.replace('/exercise')
      }
    }
  }, [data, history])
  return (
    <div className='_group-bg1 fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top  '>
            <div className='mt-3 ml-5 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt '>
              <h3 className='exercise_solve_1_3_heading _bold CT_2_Heading'>CREATE</h3>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      <div className='flex flex-col items-center'>
        <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-24 mb-4 '>
          <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
        </div>
        <p className='text-sm text1 pt-1.5 px-10 text-center mb-4'>
          Are you sure you want to delete all content in your Create Exercise? Once deleted, it
          cannot be retrieved.
        </p>

        <div className='grid grid-rows-1 mt-4 ml-2'>
          <div className='grid grid-cols-2'>
            <div>
              <button className='Lbtn mr-28' onClick={() => history.push('/exercise')}>
                <span>NO</span>
              </button>
            </div>
            <div className='flex justify-end mr-3'>
              <button
                className='Ybtn'
                style={{ backgroundColor: 'var(--windsor)' }}
                onClick={deleteAll}>
                <span className='_bold tracking-[2px] indent-0.5'>YES</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
