import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import ct5 from '../assets/images/CT-5.jpg'
import ct6 from '../assets/images/CT-6.jpg'
import ct7 from '../assets/images/CT-7.jpg'
import ct8 from '../assets/images/CT-8.jpg'
import ct9 from '../assets/images/CT-9.jpg'
import ct10 from '../assets/images/CT-10.jpg'
import ct11 from '../assets/images/CT-11.jpg'
import CT_Flow from '../assets/images/CT_Flow2.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export const Creative_Thinking1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  let isMemoryLeak = useRef(true)
  const { state, dispatch } = useApp()
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.creative?.assesment[1]
      ? state?.user?.progress?.course?.creative?.assesment[1]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'
  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.creative,
      1,
      Number(localStorage.getItem('Pbarvalue14')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue14')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_create`,
        assessment: AddCourses(state?.user?.progress?.course?.creative?.assesment, 2),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue14', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])
  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/creative_thinking1.4')
    } else {
      history.push('/creative_thinking1.3')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='percentage pt-5 text-white pl-10 pr-5'>{percent1 > 97 ? 100 : percent1}%</h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='creative_thinking_splash pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                CREATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4 '>
                Creative Thinking 2
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover why you need creative thinking and when to use it to find new solutions for
                challenging problems.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center _mt-63 '>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>CREATIVE THINKING 2</h4>
            <p className='_regular text-2xl work_out  mb-7 text-center  _margin_px'>
              Why Do I Need Creative Thinking?
            </p>
            <p className='_regular work_out text-base mb-5 _margin_px'>
              As discussed, creative thinking is about looking at problems in new ways. Today, we
              are facing entirely new problems, and only new and creative thinking can solve them.
              We can’t solve new problems with old thinking. Some studies indicate that as many as
              65% of us will work in jobs that don’t exist today. We will use technologies that
              haven’t been invented to solve all new problems. To be successful, we must learn how
              to be more effective at creative thinking. (LinkedIn recently rated creativity as one
              of the top five most in-demand skills in the world.)
            </p>
            <img src={ct5} className='w-full mb-6' alt='logo' />
            <p className='_regular text-2xl work_out  mb-7 text-center  _margin_px'>
              Creativity Compounds Other Skill Sets
            </p>
            <div className=' work_out _regular text-base _margin_px mb-8'>
              <p>
                Creative thinking has become more important than ever before. In a world where we
                are faced with more data and never before seen challenges, creative thinking is the
                only tool that will help us look at these problems from new perspectives to find
                unorthodox solutions.
              </p>
              <br />
              <p>
                Let’s explore several additional skills where creative thinking can be used to help
                find the best solution or open additional paths to ideas that will lead to
                solutions. You may notice that some of these skills are similar or even the same as
                discussed in the critical thinking section. It’s important to remember that multiple
                skills being harnessed at the same time will give you a dramatic competitive
                advantage and ultimately help you find a better solution—or the right solution. All
                of the seven skills are symbiotic and work in harmony with each other. They are also
                additive. In other words, the more skills you bring to bear on a problem, the
                greater your chances of success.
              </p>
              <p className='my-4'>
                Review the following infographic to discover when to use creative thinking.
              </p>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={CT_Flow} className='w-full mb-10' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
              Five Skills That Creativity Will Help Jump Start
            </p>
            <img src={ct6} className='w-full ' alt='flow' />
            <p className=' work_out _bold text-2xl text-center mt-7'>1.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Communication
            </p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              Creative thinking is an important part of better communication. Good communication
              requires you to be able to articulate your ideas clearly and succinctly to those
              around you. Even the best creative ideas, if not articulated well, can be confusing at
              best and un-actionable at worst. Use creative thinking to explore new words, phrases,
              or even infographics and pictures to help better communicate your ideas. Ask, “How can
              I say this better?” You’ll notice that the seven skills often use infographics to help
              explain or clarify the flow of information, a process, or a methodology.
            </p>
            <img src={ct7} className='w-full ' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>2.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Innovation
            </p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              Critical thinking involves asking the right questions. It is the single most important
              part of problem solving and, thus, innovation. Once you understand what the problem
              is, creative thinking is often the best way to solve it—thinking unconventionally,
              looking at the problem from different perspectives, and even ideating on existing
              solutions. Brainstorming is the art of generating new ideas, and creative thinking is
              the fuel. We’ll explore real-world methods for brainstorming, problem solving, and
              applying innovation in the next section.
            </p>
            <img src={ct9} className='w-full ' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>3.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Organization
            </p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              We rarely think of highly creative people as being organized, yet organization is one
              of the most important skills a creative thinker must learn to master. Good creative
              thinkers must be able to organize and prioritize their ideas. At the end of any
              productive brainstorming session, creative thinkers must also be able to organize
              their ideas and articulate a strategy to their team.
            </p>
            <img src={ct10} className='w-full' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>4.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Open-Mindedness
            </p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              Our personal biases, perceptions, and habits can dramatically inhibit our creative
              thinking. Remember, we must be able to look at problems in new ways. We must put aside
              our traditional thinking and let our minds be open to any and all ideas and possible
              solutions. Before thinking about a problem, take a few minutes to listen to music,
              doodle on paper, breathe deeply, or simply clear your mind. Often, being open-minded
              is as easy as looking at the problem from your competitor’s perspective. What’s
              important to them? Being open-minded also means that we are open to any and all input
              from our team or others that will solve the problem best.
            </p>
            <img src={ct11} className='w-full' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>5.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>Analysis</p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              Analysis seems like the antithesis of creative thinking. Yet, being able to analyze
              the world around us is crucial to the process. Critical thinking and analysis play a
              pivotal role in the creative thinking process. All good creative thinking starts and
              ends with proper analysis of the problem and potential solutions. Just make sure you
              don’t get caught in analysis paralysis—the greatest inhibitor of creativity.
            </p>
            <img src={ct8} className='w-full ' alt='logo' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-10'>
              <h4 className='_bold font work_out text-center lineH2'>CREATIVE THINKING 2</h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Creative Thinking Summary
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Creative thinking is more important today than ever before. Put simply, we are
                facing new problems that require new thinking, new ideas, new perspectives, and new
                solutions. Old thinking can’t and won’t solve new problems.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
