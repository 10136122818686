export default function LoginHeader1() {
  return (
    <>
      <p className='text-center text-2xl leading-[29px] mt-[21px] antialiased _font_color_white _regular font-normal tracking-normal px-20'>
        Welcome to Business Fitness
      </p>
      <p
        className='mx-auto text-center text-sm font-normal mt-3 antialiased  _font_color_white _regular'
        style={{ lineHeight: '18px' }}>
        Powered by Fullbridge
      </p>
    </>
  )
}
