import React, { useState } from 'react'
import GlobalMenu from '../../components/userComponent/GlobalMenu'
import logo3 from '../../assets/images/path-15712@1x.png'
import logo4 from '../../assets/images/path-15743-1@1x.png'
import logo5 from '../../assets/images/path-15768@1x.png'
import assessLogo from '../../assets/images/Group 4551.svg'
import nextArrow from '../../assets/images/group-3439-10@1x.png'
import { useHistory } from 'react-router-dom'
export const A_EQ_0 = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)

  return (
    <div className='fixed top-0 bottom-0 right-0 left-0 '>
      <div className='h-16 bg_blue  flex flex-row justify-between fixed w-full top-0'>
        <div>
          {open && (
            <div>
              <GlobalMenu openMenu={() => toggleMenu(false)} />
            </div>
          )}

          <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
            {!open ? (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
                <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
                <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
              </div>
            ) : (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='emotional_intelligence_splash h-full'>
        <div className=' flex flex-col items-center mt-14 calc_height'>
          <div className='h-[66px] w-[54px] rounded-full mt-10 mb-5 '>
            <img src={assessLogo} logo='logo' />
          </div>
          <h4 className=' _bold text-xs _font_color_white ' style={{ letterSpacing: '3px' }}>
            ASSESSMENT
          </h4>
          <h4 className=' _medium text-2xl _font_color_white pb-3 pt-1.5 font_size'>
            Emotional Intelligence
          </h4>
          <p
            className=' _regular font11 _font_color_white px-10 text-center mb-10'
            style={{ lineHeight: '1.5' }}>
            Assess your ability to understand and manage your and other’s emotions. Answer every
            question as honestly as you can—no one but you will see your personal score.
          </p>

          {!open && (
            <div className='fixed bottom-0 text-center' style={{ marginBottom: '-13px' }}>
              <button className=' rounded-full ' onClick={() => history.push('/assess_eq_1')}>
                <img src={nextArrow} logo='logo ' className='h-10 w-10' />
              </button>
              <h4 className=' _regular font  _font_color_white pb-3 pt-1.5 mb-1'>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
