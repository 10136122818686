import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import Next_image from '../assets/images/group-3437-1@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import leftArrow from '../assets/images/leftArrow.svg'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export default function ExerciseSolves_1_7() {
  const { dispatch, state } = useApp()
  const [open, toggleMenu] = useState(false)
  const [count, setCount] = useState(0)

  const history = useHistory()
  const [textInput1_7, setTextInput1_7] = useState({
    objective: '',
    stakeholder: '',
    another: '',
    another2: '',
    another3: '',
  })
  useEffect(() => {
    if (state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.objective) {
      setTextInput1_7({
        ...textInput1_7,
        objective: state?.user?.progress?.course?.solve?.Exercise?.textInput1_7.objective,
        stakeholder: state?.user?.progress?.course?.solve?.Exercise?.textInput1_7.stakeholder,
        another: state?.user?.progress?.course?.solve?.Exercise?.textInput1_7.another,
        another2: state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another2,
        another3: state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another3,
      })
    }
  }, [])

  let uri = '/course/course'
  const handleSubmit = async event => {
    event.preventDefault()
    var count = 0
    const select = Object.values(textInput1_7).reduce((acc, cu) => {
      if (cu.trim().length > 0) {
        count++
      }
      if (count >= 3) {
        return (acc = true)
      }
    }, Boolean)

    var stateNew = { ...state }

    stateNew.user.progress.course.solve.Exercise = {
      ...stateNew.user.progress.course.solve.Exercise,
      textInput1_7,
      assessmentPercent:
        state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent > 78
          ? state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent
          : 78,
    }

    if (state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent < 78) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_solve`,
          assessment: 78,
        }),
      )
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (select) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
        return history.push('/exercise_solve_1.8')
      }
    }
    alert('Answers can not be blank')
  }

  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading nav_title '>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent'>78%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='step'>STEP 7</h3>
              <h1 className='leading-none mt-2 mainHeading '>Implementation</h1>
              <p className='ex_solve_para'>
                Every great idea or solution needs to be both viable and have clear steps to
                implementation. Add a <span className='_semiBold'> minimum of 3 </span>and no more
                than 5 steps to simply implement your solution.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <h3 className='step'>action</h3>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Step 1 to implementing my solution.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  value={textInput1_7.objective}
                  onChange={e => setTextInput1_7({ ...textInput1_7, objective: e.target.value })}
                  className='user_text_area forcheck'
                />

                <p className='message'>
                  <span className='_semiBold'> Example: </span>
                  <span className='italic'>
                    Identify key markets that could benefit from nuclear power.
                  </span>
                </p>
              </div>
              <div className='action_div'>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Step 2 to implementing my solution.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area forcheck w-full'
                  value={textInput1_7.stakeholder}
                  onChange={e => setTextInput1_7({ ...textInput1_7, stakeholder: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Step 3 to implementing my solution.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area forcheck w-full'
                  value={textInput1_7.another}
                  onChange={e => setTextInput1_7({ ...textInput1_7, another: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Step 4 to implementing my solution.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area forcheck w-full'
                  value={textInput1_7.another2}
                  onChange={e => setTextInput1_7({ ...textInput1_7, another2: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Step 5 to implementing my solution.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area forcheck w-full'
                  value={textInput1_7.another3}
                  onChange={e => setTextInput1_7({ ...textInput1_7, another3: e.target.value })}
                />
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/exercise_solve_1.6')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button type='submit'>
                    <img
                      src={Next_image}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
