import React, { useState, useEffect } from 'react'
import GlobalMenu from '../../components/userComponent/GlobalMenu'
import logo3 from '../../assets/images/path-15712@1x.png'
import logo4 from '../../assets/images/path-15743-1@1x.png'
import logo5 from '../../assets/images/path-15768@1x.png'
import logo7 from '../../assets/images/group-3439-10@1x.png'
import logo8 from '../../assets/images/Component 37 – 1.svg'
import backarrow from '../../assets/images/backrow.svg'
import check from '../../assets/images/Group 9485.svg'
import RightB from '../../assets/images/whitetick.svg'
import { saveProgress, saveScoring } from '../../services/user'
import { AddScore } from './Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../../reducer/types'
import { useHistory } from 'react-router-dom'
import useApp from '../../context/useApp'
export default function A_Creative_Thinking_1_9() {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [CheckValues, setCheckValues] = useState({
    value_1: 0,
    value_2: 1,
    value_3: 2, //ans
    value_4: 3,
    value_5: 4,
  })
  const [initial, stateinital] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  })
  const handleSubmit = async event => {
    event.preventDefault()
    let CheckNine =
      initial.one === true
        ? CheckValues.value_1
        : initial.two
        ? CheckValues.value_2
        : initial.three
        ? CheckValues.value_3
        : initial.four
        ? CheckValues.value_4
        : initial.five
        ? CheckValues.value_5
        : alert('Answers can not be blank')
    const mainCheck = CheckNine
    let uri = '/course/course'
    var stateNew = { ...state }
    CheckNine = CheckNine === 2 ? 4 : 0
    stateNew.user.progress.course.creative_thinking_assess = {
      ...stateNew.user.progress.course.creative_thinking_assess,
      CheckNine,
      check1: mainCheck,
    }
    let res = await saveScoring(
      JSON.stringify({
        course: `assess_creative_thinking`,
        assessment: AddScore(state?.user?.progress?.course?.creative_thinking_assess, 9),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (initial.one || initial.two || initial.three || initial.four || initial.five == true) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      history.push('/assess_creative_thinking_2_0')
    }
  }
  useEffect(() => {
    switch (
      Object.values(CheckValues).indexOf(
        state?.user?.progress?.course?.creative_thinking_assess?.check1,
      )
    ) {
      case 0:
        {
          stateinital({ ...initial, one: true })
        }
        break
      case 1:
        {
          stateinital({ ...initial, two: true })
        }
        break
      case 2:
        {
          stateinital({ ...initial, three: true })
        }
        break
      case 3:
        {
          stateinital({ ...initial, four: true })
        }
        break
      case 4: {
        stateinital({ ...initial, five: true })
      }

      default:
        break
    }
  }, [])

  return (
    <>
      <div className='-bg min-h-screen'>
        <div className='h-16 bg_blue upperr flex flex-row justify-between fixed w-full top-0'>
          <div>
            {open && (
              <div>
                <GlobalMenu openMenu={() => toggleMenu(false)} />
              </div>
            )}

            <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
              {!open ? (
                <div className='mt-3 ml-3'>
                  <img className=' w-9 h-9' src={logo3} alt='logo' />
                  <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
                  <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
                  <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
                </div>
              ) : (
                <div className='mt-3 ml-3'>
                  <img className=' w-9 h-9' src={logo3} alt='logo' />
                  <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
                </div>
              )}
            </div>
          </div>
        </div>
        {!open && (
          <>
            <div className='w-full relative'>
              <div
                className='grid place-items-center  fixed top-10 w-full z-40'
                style={{ zIndex: '1000' }}>
                <div>
                  <div
                    className='text-5xl text-center relative top-2 text-white _bold font-black '
                    style={{ lineHeight: '48px' }}>
                    <img src={logo8} alt='logo' className='h-16 w-16' />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-screen pt-16 calc_height calc_height2'>
              <div className='-creative_thinking_splash full-h -ctBg bg-no-repeat pl-5 pr-5 flex flex-col justify-between'>
                <div className='relative '>
                  <div className='mx-3 pt-5'>
                    <h4 className='text-center text-sm assesHeading font-medium _bold uppercase mt-10 _font_color_white'>
                      CREATIVE THINKING
                    </h4>
                    <h4 className='text-center font assesSubheading mt-6 _bold  mb-3 _font_color_white'>
                      ASSESSMENT
                    </h4>

                    <p
                      className='text-center font11 mx-auto  font-normal mb-3  _regular  _font_color_white'
                      style={{ lineHeight: '20px', width: '85%' }}>
                      Based on the information provided, which answer is most valid.
                    </p>

                    <h4
                      className='text-center text-base  font-normal  _semiBold assesPara pb-5 _font_color_white'
                      style={{ fontWeight: 600 }}>
                      A father and his son are in a car accident and the father dies. The son is
                      rushed to Emergency and the surgeon says, “I can’t operate on him, he’s my
                      son.” Explain.
                    </h4>
                  </div>
                  <div className='mx-3'>
                    <div
                      className='flex flex-row w-full mb-2.5'
                      style={{
                        height: '50px',
                      }}>
                      <div className='flex flex-col justify-center  '>
                        <div className='h-10 w-10 mr-2.5'>
                          <img
                            className=''
                            src={initial.one ? RightB : check}
                            onClick={() =>
                              stateinital({
                                ...initial,
                                one: initial.one === false || true,
                                two: false,
                                three: false,
                                four: false,
                                five: false,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <h4
                          className='_semiBold text-xs _font_color_white '
                          style={{ lineHeight: '14px' }}>
                          It’s his father in-law.
                        </h4>
                      </div>
                    </div>

                    <div
                      className='flex flex-row w-full mb-2.5'
                      style={{
                        height: '50px',
                      }}>
                      <div className='flex flex-col justify-center'>
                        <div className='h-10 w-10 mr-2.5'>
                          <img
                            className=''
                            src={initial.two ? RightB : check}
                            onClick={() =>
                              stateinital({
                                ...initial,
                                two: initial.two === false || true,
                                one: false,
                                three: false,
                                four: false,
                                five: false,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <h4
                          className='_semiBold text-xs _font_color_white'
                          style={{ lineHeight: '14px' }}>
                          It’s his Priest.
                        </h4>
                      </div>
                    </div>

                    <div
                      className='flex flex-row w-full mb-2.5'
                      style={{
                        height: '50px',
                      }}>
                      <div className='flex flex-col justify-center'>
                        <div className='h-10 w-10 mr-2.5'>
                          <img
                            className=''
                            src={initial.three ? RightB : check}
                            onClick={() =>
                              stateinital({
                                ...initial,
                                three: initial.three === false || true,
                                two: false,
                                one: false,
                                four: false,
                                five: false,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <h4
                          className='_semiBold text-xs _font_color_white'
                          style={{ lineHeight: '14px' }}>
                          It’s his mom.
                        </h4>
                      </div>
                    </div>

                    <div
                      className='flex flex-row w-full mb-2.5'
                      style={{
                        height: '50px',
                      }}>
                      <div className='flex flex-col justify-center'>
                        <div className='h-10 w-10 mr-2.5'>
                          <img
                            className=''
                            src={initial.four ? RightB : check}
                            onClick={() =>
                              stateinital({
                                ...initial,
                                four: initial.four === false || true,
                                two: false,
                                three: false,
                                five: false,
                                one: false,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <h4
                          className='_semiBold text-xs _font_color_white'
                          style={{ lineHeight: '14px' }}>
                          It’s impossible.
                        </h4>
                      </div>
                    </div>

                    <div
                      className='flex flex-row w-full mb-2.5'
                      style={{
                        height: '50px',
                      }}>
                      <div className='flex flex-col justify-center'>
                        <div className='h-10 w-10 mr-2.5'>
                          <img
                            className=''
                            src={initial.five ? RightB : check}
                            onClick={() =>
                              stateinital({
                                ...initial,
                                five: initial.five === false || true,
                                two: false,
                                three: false,
                                four: false,
                                one: false,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <h4
                          className='_semiBold text-xs _font_color_white'
                          style={{ lineHeight: '14px' }}>
                          More information is needed.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {!open && (
                  <div
                    className='w-full h-[80px] mt-[30px] pb-[40px]  button_bottom  mx-auto assessBottom'
                    style={{ width: '93%', marginBottom: '0px' }}>
                    <button
                      className='relative  mb-5'
                      onClick={() => history.push('/assess_creative_thinking_8')}>
                      <img alt='logo' src={backarrow} className='h-10 w-10' />
                    </button>
                    <button className='relative  mb-5' onClick={handleSubmit}>
                      <img alt='logo' src={logo7} className='h-10 w-10' />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className='mBg1'></div>
    </>
  )
}
