import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import solvelogo from '../assets/images/solve.svg'
import nextArrow from '../assets/images/whiteArrow.svg'
import { useHistory } from 'react-router-dom'
export const Solve1_0 = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)

  return (
    <div className='fixed top-0 bottom-0 right-0 left-0 '>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={
          'cursor-pointer pt-1 pl-2 pb-2.5 fixed w-full  ' + (open ? 'none' : '_solve1_bg')
        }
        style={{ pointerEvents: 'none' }}
        onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-5 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className='mt-5 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9 ' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>
      <div className='crical_thinking_splash'>
        <div
          className=' flex flex-col items-center mt-14 h-screen'
          style={{ background: 'rgba(67, 41, 110, .6)' }}>
          <div className='h-16 w-16 rounded-full mt-10 mb-5 '>
            <img src={solvelogo} logo='logo' />
          </div>
          <h4
            className=' exerciseReadheading _font_color_white uppercase'
            style={{ color: 'white' }}>
            Exercise
          </h4>
          <h4 className=' exerciseMainHeading pb-3 pt-1.5' style={{ color: 'white' }}>
            Solve
          </h4>
          <p className='whiteExtext px-10 text-center mb-10' style={{ lineHeight: '1.8' }}>
            Use a proven critical thinking methodology to help solve real-world problems. Solving
            problems successfully is a process, make sure you take time to carefully think through
            your answers and keep them short, clear and simple. You will be provided an Argument
            Summary at the end that you can use as an outline for a PowerPoint deck or simply to
            make a stronger case for your solution during a meeting.
          </p>

          {!open && (
            <div className='fixed bottom-0 text-center' style={{ marginBottom: '-13px' }}>
              <button className=' rounded-full ' onClick={() => history.push('/solve1.1')}>
                <img src={nextArrow} logo='logo' />
              </button>
              <h4
                className=' _regular  pb-3 pt-1.5 mb-1'
                style={{ fontSize: '10px', color: 'white', lineHeight: '24px' }}>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
