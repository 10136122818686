import React, { useEffect, useState } from 'react'
import GlobalMenu from '../../components/userComponent/GlobalMenu'
import logo3 from '../../assets/images/path-15712@1x.png'
import logo4 from '../../assets/images/path-15743-1@1x.png'
import logo5 from '../../assets/images/path-15768@1x.png'
import logo8 from '../../assets/images/Component 37 – 1.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../../context/useApp'
import { AddScore } from './Comm_Text'
import { saveScoring } from '../../services/user'
export default function A_Critical_Thinking_2_1() {
  const [open, toggleMenu] = useState(false)
  const [score, setScore] = useState(0)
  const history = useHistory()
  const { state } = useApp()

  useEffect(() => {
    let val = state.user.progress.course.Assess
    let sum =
      val.CheckOne +
      val.CheckTwo +
      val.CheckThree +
      val.CheckFour +
      val.CheckFive +
      val.CheckSix +
      val.CheckSeven +
      val.CheckEight +
      val.CheckNine +
      val.CheckTen
    setScore((sum / 10) * 25)
  }, [])
  const handleSubmit = async event => {
    event.preventDefault()
    let res = await saveScoring(
      JSON.stringify({
        course: `assess_critical_thinking`,
        assessment: AddScore(state?.user?.progress?.course?.Assess, 10),
        complete: true,
      }),
    )
    history.push('/user_dashboard')
  }
  return (
    <div className='bg min-h-screen'>
      <div className='absolute'>
        <div className='h-[71px] bg_blue upperr flex flex-row justify-between fixed w-full top-0'>
          <div>
            {open && (
              <div>
                <GlobalMenu openMenu={() => toggleMenu(false)} />
              </div>
            )}

            <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
              {!open ? (
                <div className='mt-3 ml-3'>
                  <img className=' w-[39px] h-[39px]' src={logo3} alt='logo' />
                  <img className='top-[22px] left-[22px] h-1   absolute' src={logo4} alt='logo' />
                  <img className=' h-1 top-[30px] left-[22px] absolute' src={logo4} alt='logo' />
                  <img className='top-[38px] left-[22px] h-1 absolute' src={logo4} alt='logo' />
                </div>
              ) : (
                <div className='mt-3 ml-3'>
                  <img className=' w-9 h-9' src={logo3} alt='logo' />
                  <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
                </div>
              )}
            </div>
          </div>
        </div>
        {!open && (
          <>
            <div className='w-full relative'>
              <div
                className='grid place-items-center  fixed top-10 w-full z-40'
                style={{ zIndex: '1000' }}>
                <div>
                  <div
                    className='text-5xl text-center relative top-2 text-white _bold font-black '
                    style={{ lineHeight: '48px' }}>
                    <img src={logo8} alt='logo' className='h-16 w-16' />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-screen pt-6 calc_height calc_height2'>
              <div className='crical_thinking_splash3 full-h2 ctBg bg-no-repeat pl-5 pr-5 flex flex-col justify-between'>
                <div className='flex justify-center relative ASSICON'>
                  {/* <div
                    className='text-5xl text-center relative  text-white _bold font-black '
                    style={{ lineHeight: '48px' }}>
                    <img src={logo8} alt='logo' className='h-16 w-16' />
                  </div> */}
                </div>
                <div className='relative'>
                  <div className='mx-3 pt-20'>
                    <h4 className='text-center text-sm leading-[18px] _bold leading-[18px] _bold uppercase mt-10 _font_color_white assesHeading'>
                      Critical Thinking
                    </h4>
                    <h4
                      className='text-center font leading-[13x]  mt-8 _bold  mb-[70px] _font_color_white'
                      style={{ color: '#ffffff', letterSpacing: '2px' }}>
                      ASSESSMENT
                    </h4>

                    <h4 className='text-center text-base text-lg leading-[18px]  font-normal mb-8  _semiBold  _font_color_white'>
                      Congratulations!
                    </h4>
                    <h4 className='text-center text-sm leading-[18px]  font-normal mb-5  _semiBold _font_color_white '>
                      You’ve completed the Critical Thinking assessment. Your score is posted below.
                    </h4>

                    <h4 className='text-center font-normal mb-1 color-white  _semiBold  _font_color_white font_90'>
                      {Math.round(score)}
                    </h4>

                    <p className='text-center text-sm font-normal  _regular  _font_color_white'>
                      Your Critical Thinking level is:
                    </p>
                    <p className='text-center text-lg mb-5 font-normal  _semiBold _font_color_white'>
                      <span className='_bold'>
                        {Math.round(score) < 41
                          ? 'Beginner'
                          : Math.round(score) >= 41 && Math.round(score) < 71
                          ? 'Intermediate'
                          : Math.round(score) >= 71 && Math.round(score) < 95
                          ? 'Advanced'
                          : Math.round(score) >= 95
                          ? 'Expert'
                          : ''}
                      </span>
                    </p>
                    <h4 className='text-center text-sm font-normal  _regular  _font_color_white'>
                      Keep it up by revisiting your workouts, engaging with live events and
                      exploring new sessions. You can always come back and take this assessment
                      again to try and improve your score.
                    </h4>
                  </div>
                </div>
                {!open && (
                  <div
                    className='flex justify-center text-xl _medium text-color'
                    style={{ marginTop: '120px', paddingBottom: '30px' }}>
                    <div
                      className=' mt-1 ml-5 marginright rounded-full py-2 pl-2 '
                      style={{ background: '#FFFFFF', height: '39px', width: '39px' }}
                      onClick={handleSubmit}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='#3c0774'
                        width='22'
                        height='22'
                        viewBox='0 0 576 512'>
                        <path d='M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z' />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
