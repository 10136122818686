import { APP_INITIALIZED, APP_USER_AUTH_SUCCESS, APP_USER_LOGOUT } from './types'

export const initialState = {
  loading: true,
  ...(JSON.parse(localStorage.getItem('auth')) || null),
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_INITIALIZED:
      return { ...state, loading: false }
    case APP_USER_AUTH_SUCCESS:
      localStorage.setItem('auth', JSON.stringify({ ...action.value }))
      return { ...state, ...action.value }
    case APP_USER_LOGOUT:
      localStorage.removeItem('auth')
      return {}
    default:
      return state
  }
}
