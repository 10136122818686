import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import Next_image from '../assets/images/group-3437-1@1x.png'
import { useHistory } from 'react-router-dom'
import { saveScoring } from '../services/user'
import White from '../assets/images/whitemenu icon.svg'
import { saveProgress } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import leftArrow from '../assets/images/leftArrow.svg'
import useApp from '../context/useApp'
export default function ExerciseSolve_1_8() {
  const [open, toggleMenu] = useState(false)
  const { dispatch, state } = useApp()
  const history = useHistory()

  const handleSubmit = async event => {
    let uri = '/course/course'
    var stateNew = { ...state }

    stateNew.user.progress.course.solve.Exercise = {
      ...stateNew.user.progress.course.solve.Exercise,

      assessmentPercent:
        state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent > 88
          ? state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent
          : 88,
    }
    if (state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent < 88) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_solve`,
          assessment: 88,
        }),
      )
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
    history.push('/exercise_solve_1.9')
  }

  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading nav_title'>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent'>88%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='step'>STEP 8</h3>
              <h1 className='leading-none mt-2 mainHeading '>Personal Bias</h1>
              <p className='ex_solve_para'>
                Have you been able to remove your personal bias? Are you looking at both sides of
                the argument? You’ve provided data to support your idea, but did you also look at
                data against your idea?
              </p>
              <p className='ex_solve_para'>
                Ask yourself the following three questions—if you can answer YES you’re finished. If
                NO, you may need to revisit the data and your solutions.
              </p>
            </div>
            <div className='solve_form_1_3'>
              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>1.</h1>
                </strong>
                <p className=' mb-[15px] _semiBold' style={{ marginTop: '0px', color: '#43296E' }}>
                  Did I remove emotion from <br /> my decision making?
                </p>
              </div>
              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>2.</h1>
                </strong>
                <p className=' mb-[15px] _semiBold' style={{ marginTop: '0px', color: '#43296E' }}>
                  Did I look at both sides <br /> of the argument?
                </p>
              </div>
              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>3.</h1>
                </strong>
                <p className=' mb-[15px] _semiBold' style={{ marginTop: '0px', color: '#43296E' }}>
                  Am I relying on the data, <br /> not my opinion?
                </p>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-8 mt-4'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/exercise_solve_1.7')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button type='submit' onClick={handleSubmit}>
                    <img
                      src={Next_image}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
