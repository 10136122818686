import React, { useEffect } from 'react'
import whiteImg from '../../assets/images/w-acr.png'
import blueArc from '../../assets/images/blue-arc.png'
const Semicircleprogressbar = props => {
  useEffect(() => {
    function setGaugeValue(value) {
      if (value < 0 || value > 1) {
        return
      }
      const media = document.querySelector('.track_main_fill')
      if (media) {
        document.querySelector('.track_main_fill').style.transform = `rotate(${value / 2}turn)`
      }
    }
    setGaugeValue(props.progrss / 100)
  }, [props])
  return (
    <div className='App'>
      <div className='gauge'>
        <div className='mmmm_div'>
          <div className='mm'>
            <img className='img1' src={whiteImg} alt='track_not_found' />
            <img className='img2 track_main_fill mt-0' src={blueArc} alt='track_not_found' />
          </div>
        </div>
        <div className='w-100' style={{ height: '5px' }}>
          <div className='flex w-100 absBorder'></div>
          <div className='w-100 flex justify-center'>
            <div className='flex' style={{ marginTop: '-50px' }}>
              {/* <h4
                className=' _regular text-sm self-center mb-4 pr-4'
                style={{ color: '#a93020', zIndex: '1000' }}>
                -5
              </h4> */}
              <h4
                className='text-center text-5xl progress  _light progress'
                style={{
                  color: '#3C0B74',
                  width: '80px',
                  maxWidth: '80px',
                  zIndex: '1000',
                  marginBottom: '10px',
                  paddingBottom: '10px',
                }}>
                {Math.round(Number(props?.progrss))}
              </h4>

              {/* <h4
                className=' _regular text-sm self-center mb-4 pl-4'
                style={{
                  color: '#4daa86',

                  zIndex: '1000',
                }}>
                +5
              </h4> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Semicircleprogressbar
