import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo6 from '../assets/images/path-15710-10@1x.png'
import { InputBox } from './reusable_code/ReusableComp'
import { EditClientData } from '../services/user'
const EditClient = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  var clientData = JSON.parse(localStorage.getItem('clientInfo'))
  const Fullname = !clientData ? '' : clientData.name.split(' ')
  const fname = !Fullname[0] ? 'Name not Provided' : Fullname[0]
  const Lname = !Fullname[1] ? 'Name not Provided' : Fullname[1]
  const [firstName, setFirstName] = useState(fname)
  const [lastName, setLastName] = useState(Lname)
  const [email, setEmail] = useState(!clientData ? null : clientData.username)
  const [groupCode, setGroupCode] = useState(!clientData ? null : clientData.g_code)
  const [userCount, setUserCount] = useState(!clientData ? null : clientData.user_count)

  const handleSubmit = async () => {
    let res = await EditClientData(
      JSON.stringify({
        name: `${firstName} ${lastName}`,
        username: email,
        delete: false,
        user_count: userCount,
      }),
    )
    let data = res.ok ? await res.text() : null

    if (data) {
      data = JSON.parse(data)
      history.replace('/edit-success')
      if (data?.data?.user?.is_admin) {
        localStorage.removeItem('clientInfo')
      }
    }
  }
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      <h4
        className='text-center _medium mt-10 text-2xl not-italic font-medium'
        style={{ color: 'var(--windsor)' }}>
        Edit
      </h4>

      <InputBox
        htmlFor='#first_name'
        label='Edit Client First Name'
        id='firstName'
        type='text'
        value={firstName}
        onChange={e => {
          setFirstName(e.target.value)
        }}
      />
      <InputBox
        htmlFor='#last_name'
        label='Edit Client Last Name'
        id='lastName'
        type='text'
        value={lastName}
        onChange={e => {
          setLastName(e.target.value)
        }}
      />

      <InputBox
        htmlFor='#gCode'
        label='Client Corporate Serial Number'
        id='gCode'
        type='text'
        value={groupCode}
        disabled
      />

      <InputBox
        htmlFor='#userCount'
        label='Edit Client User Count'
        id='userCount'
        type='number'
        value={userCount}
        onChange={e => {
          setUserCount(e.target.value)
        }}
      />
      {!open && (
        <div className='px-16  mt-32 mb-10 flex justify-between relative'>
          <button className='' onClick={() => history.push('/admin-client')}>
            <img src={logo1} className='h-10 w-10' alt='logo' />
            <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>

          <button onClick={() => handleSubmit()}>
            <img src={logo3} className='h-10 w-10' alt='logo' />
            <img src={logo6} className='absolute h-3.5 ml-2.5 bottom-3' alt='logo' />
          </button>
        </div>
      )}
    </div>
  )
}

export default EditClient
