import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per1 from '../assets/images/PER-1.jpg'
import per2 from '../assets/images/PER-2.jpg'
import per3 from '../assets/images/PER-3.jpg'
import per4 from '../assets/images/PER-4.jpg'
import cog_flex from '../assets/images/Persuasion1.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { UpdateAssessments } from './Asses/Comm_Text'
export const Persuasion1_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.communicate?.assesment[0]
      ? state?.user?.progress?.course?.communicate?.assesment[0]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }

    if (!stateNew.user.progress.course['communicate']) {
      stateNew.user.progress.course['communicate'] = {
        ...stateNew.user.progress.course.communicate,
        assesment: [
          Number(localStorage.getItem('Pbarvalue10')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue10')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course.communicate,
        0,
        Number(localStorage.getItem('Pbarvalue10')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue10')), percent1),
      )
    }
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        assessment: state?.user?.progress?.course?.communicate?.assesment[0],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue10', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/persuasion1.2')
    } else {
      history.push('/persuasion1.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='per_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  1
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                COMMUNICATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Persuasion 1
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover what persuasion is and its role in successful and effective communication.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px]'>
            <p className='text-center leading-3 text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 tracking-[2.8px]'>PERSUASION 1</h4>
            <p className='_regular text-2xl work_out  text-center mb-7 _margin_px'>
              What Is Persuasion?
            </p>
            <div className='_regular work_out text-base mb-5 _margin_px'>
              <p>
                Persuasion is your ability to convince someone of the merits of your ideas,
                solutions, or even you as a person.
              </p>
              <br />
              <p>
                The skill of persuasion has been around since the beginning of time. You can imagine
                cavemen bartering for simple tools or trying to persuade other members of their
                group to migrate to more promising lands.
              </p>
              <br />
              <p>
                At its core, persuasion is rooted in human desire—mostly by needs and wants. Put
                into economic terms, every market is driven by supply and demand. Typically, the
                greater the demand and the lower the supply, the higher the cost.
              </p>
              <br />
              <p>
                Being able to successfully sell a product or service or persuade someone to your
                point of view is tied to something they want or need. As obvious as that sounds, we
                rarely take the time to really think about what another person may want or really
                need before we start a meeting or begin negotiating.
              </p>
              <br />
              <p>
                Always start every encounter by asking, “What does the other person want or need?”
              </p>
            </div>
            <img src={per1} className='w-full mb-6' alt='logo' />
            <div className=' work_out _regular text-base _margin_px mb-6'>
              <p>
                As we explore persuasion, we need to keep in mind that not only are we often trying
                to persuade someone—they are also often trying to persuade us.
              </p>
              <br />
              <p>
                Understanding the strategies behind Persuasion will give us a competitive advantage
                in both situations.
              </p>
              <br />
              <p>
                So, when should we use persuasion strategies? Start by having a clear definition of
                what is at stake; in other words, “How will the outcome affect me and my world?”
                There are five key areas where we can be affected most, and depending on the degree
                of what’s at stake with the outcome, we can apply the right strategy for success.
              </p>
              <br />
              <p>Study the following infographic to better understand when to use persuasion.</p>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={cog_flex} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                The History Of Persuasion
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  To better understand what persuasion is, let’s take a brief look at the history of
                  sales. We know that early humans often traded goods or services for other goods or
                  services; this is commonly known as bartering. Early markets were often run by
                  trading livestock for grain or other food. One of the biggest challenges of the
                  barter and open-market system was simply moving goods long distances. Thus, a new
                  system had to be invented where value could be exchanged—and money was born.
                </p>
                <br />
                <p>
                  Whether you credit the Aztecs, Egyptians, Chinese, or Greeks, it was really the
                  Roman Empire that developed a worldwide currency and sophisticated monetary
                  system.
                </p>
                <br />
                <p>
                  Money changed everything. It changed how goods and services could be bought and
                  sold and opened the world up to trade. Salesmen were born, and sales began to
                  boom.
                </p>
              </div>
              <img src={per2} className='w-full mb-10' alt='logo' />
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Although persuasion is a skill that each one of us uses every single day,
                salespeople are often looked at negatively. As a matter of fact, the least trusted
                and least respected professions are: #1 politicians, #2 lobbyists, and #3
                salespeople, just in front of TV reporters. Why is it that so many of us don’t trust
                salespeople? The answer lies in history.
              </p>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                Persuasion: Snake Oil And The Future
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  During the Industrial Revolution of the 1700s and 1800s, we saw the rise of the
                  modern-day salesperson—more commonly known as the traveling salesman.
                </p>
                <br />
                <p>
                  During the industrial boom, snake oil was often used by railroad workers, who
                  suffered from the daily aches and pains of their jobs, as a cure-all potion that
                  promised rejuvenation. Snake oil salesmen were as much showmen as they were
                  salesmen, making big exaggerated claims, selling their potions, and then quickly
                  leaving town before the buyers realized that it was all a sham—the potions never
                  really worked.
                </p>
                <br />
                <p>
                  As the ruse was uncovered, snake oil salesmen were branded as charlatans,
                  hacks, and frauds. If you don’t believe branding works, keep in mind that
                  it’s been hundreds of years since anyone has sold snake oil, yet salespeople
                  continue to struggle with trust.
                </p>
              </div>
              <img src={per3} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  Sales have always been rooted in trust, and throughout the early and mid-1900s,
                  sales were focused on developing a relationship with the buyer. Having a positive
                  relationship between the salesperson and the buyer will always be important, but
                  in the 1990s, “solution selling” went mainstream. Solution selling was all about
                  the solution, showing a measurable benefit of buying a product or service.
                </p>
                <br />
                <p>
                  The information age turned persuasion on its head. The Internet made it harder
                  than ever before to develop a close relationship with the buyer, and it was no
                  longer up to the salesperson to provide information—it was assumed that the buyer
                  already had all the information they needed from the Internet.
                </p>
              </div>
              <img src={per4} className='w-full mb-10' alt='logo' />
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Today, the market is flooded with choice and overwhelmed with competition, and
                consumers struggle to make decisions. Often, they turn to a professional salesperson
                to help them better understand their choices.
              </p>
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>PERSUASION 1</h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Persuasion Summary
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  Although technology has changed how the market functions, persuasion is still
                  rooted in needs and wants as well as supply and demand.
                </p>
                <br />
                <p>
                  To be successful, you must seek to truly understand what the other person wants or
                  needs and then quickly and effectively articulate your solution—your value
                  proposition. Then, you must build trust to close the sale or persuade the other
                  person to your side of the argument.
                </p>
              </div>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
