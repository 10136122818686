import { Link } from 'react-router-dom'

export default function GlobalMenu(props) {
  const { openMenu } = props
  const global = {
    fontSize: '24px',
    lineHeight: '29px',
    color: 'white',
  }

  return (
    <div className='w-full menu-content h-full fixed top-0 bottom-0 right-0 left-0 _bg__windsor fixPopupView admin_signup_in text-center'>
      <div className=' _regular flex flex-col mb-48 gap-[31px]'>
        <p className='h-31'>
          <Link to='/user_dashboard' style={global} onClick={openMenu}>
            Dashboard
          </Link>
        </p>

        <p className='h-31'>
          <Link to='/assess' style={global} onClick={openMenu}>
            Assess
          </Link>
        </p>
        <p className='h-31'>
          <Link to='/get_fit' style={global} onClick={openMenu}>
            Get Fit
          </Link>
        </p>

        <p className='h-31'>
          <Link to='/exercise' style={global} onClick={openMenu}>
            Exercise
          </Link>
        </p>
        <p className='h-31'>
          <Link to='/user_dashboard_track1' style={global} onClick={openMenu}>
            Track
          </Link>
        </p>

        <p className='h-31'>
          <Link to='/logout' style={global} onClick={openMenu}>
            Log Out
          </Link>
        </p>
      </div>
    </div>
  )
}
