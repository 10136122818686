import React, { useEffect, useState, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15712@1x.png'
// import logo4 from '../assets/images/path-15743-1@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15709-10@1x.png'
import logo8 from '../assets/images/path-15710-10@1x.png'
import brainLogo from '../assets/images/looking-at-brain@1x.png'
import elephantLogo from '../assets/images/elephant-in-tree@1x.png'
import fishLogo from '../assets/images/fish-jump@1x.png'
import manBalanceLogo from '../assets/images/man-balance@1x.png'
import lightBulbLogo from '../assets/images/looking-at-light-bulb@1x.png'
import img1 from '../assets/images/img1.svg'
// import frameImg from '../assets/images/CT1.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { UpdateAssessments } from './Asses/Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
// import { A_EQ_0 } from './Asses/EQ1.0'
import menu from '../assets/images/white dash.svg'


export default function CriticalThinking11(props) {
  const { dispatch, state } = useApp()
  let isMemoryLeak = useRef(true)
  const [open, toggleMenu] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.criticalthinking?.assesment[0]
      ? state?.user?.progress?.course?.criticalthinking?.assesment[0]
      : 0,
  )

  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)
  const history = useHistory()
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    var stateNew = {
      ...state,
    }
    if (!stateNew.user.progress.course['criticalthinking']) {
      stateNew.user.progress.course['criticalthinking'] = {
        ...stateNew.user.progress.course.criticalthinking,
        assesment: [
          Number(localStorage.getItem('Pbarvalue')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course['criticalthinking'],
        0,
        Number(localStorage.getItem('Pbarvalue')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue')), percent1),
      )
    }

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        assessment: state?.user?.progress?.course?.criticalthinking?.assesment[0],
      }),
    )

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/critical_thinking1.2')
    } else {
      history.push('/critical_thinking1.1')
    }
  }
  return (
    <div className='bg min-h-screen'>
      <div className='h-[64px] bg_orange upperr flex flex-row justify-between fixed w-full top-0'>
        <div>
          {open && (
            <div>
              <GlobalMenu openMenu={() => toggleMenu(false)} />
            </div>
          )}

          <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
            {!open ? (
              <div className='mt-3 ml-3'>
                <div className=''>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
              </div>
            ) : (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
              </div>
            )}
          </div>
        </div>
        <h4 className=' text-center  pt-5  percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>
      {!open && (
        <div className='w-screen mt-16 min-h-screen'>
          <div className='crical_thinking_splash3 bg-no-repeat pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-[30px]'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div className='text-5xl text-center relative top-2 text-white _black  '>1</div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <p className='text-center getFitTopName  mb-2 mt-12  '>SOLVE</p>
              <h4
                className='text-center text-2xl  mb-3 _medium leading-[29px]'
                style={{ color: 'white' }}>
                Critical Thinking 1
              </h4>
              <h4
                className='text-center  mb-10 topPara pb-20'
                style={{ color: '#FFFFFF', fontSize: '11px' }}>
                Discover what critical thinking is and its role in <br /> helping you solve problems
                more effectively.
              </h4>
            </div>
            <p
              className='text-center mb-5 font-normal  leading-6 _regular'
              style={{ color: '#ffffff', fontSize: '10px' }}>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className=' overflow-auto'>
            <div className='mt-14'>
              <p className=' text-center mb-2 getFitSmallHeader ' style={{ letterSpacing: '2px' }}>
                GET FIT
              </p>
              <p
                className=' text-center  mb-8 getFitMidHeading uppercase'
                style={{ lineHeight: '14px', letterSpacing: '2.80px' }}>
                Critical Thinking 1
              </p>

              <p className=' text-center  mb-8 getFitHeadline pl-10 pr-10'>
                What Is Critical Thinking?
              </p>
            </div>
            <h4 className=' text-left getFitBodySection mb-10 text1 ml-10 mr-10'>
              Critical thinking is your ability to analyze information objectively and then make a
              reasoned (or sound) and proven judgment. Good critical thinkers can draw reasonable
              conclusions from given data and successfully discriminate between useful and less
              useful information to solve problems and make decisions.
            </h4>
            <img src={brainLogo} className='w-full mb-10' alt='logo' />
          </div>

          <h4 className='_regular text-left pb-14  text-base leading-6 text1 ml-10 mr-10'>
            Next, let’s explore how the flow of information and key decision points can affect
            critical thinking.
          </h4>
          <img className='w-full mb-16' src={img1} alt='logo' />

          <h4 className='  text-center mb-4 getFitHeadline pl-10 pr-10'>
            The History Of Critical Thinking
          </h4>
          <p className='  text-left getFitBodySection ml-10 mr-10'>
            The concept of critical thinking was first discussed over 2,500 years ago by Socrates,
            who discovered that when he asked probing questions, individuals were unable to
            rationally justify choices or claims to specific knowledge. His method of questioning is
            now known as Socratic questioning and remains the foundation of most critical thinking
            strategies.
          </p>
          <img src={elephantLogo} className='w-full mt-10 mb-10' alt='logo' />

          <p className='  text-left getFitBodySection ml-10 mr-10'>
            Other great thinkers, including Plato and Aristotle, followed Socrates’ probing
            questions strategy. They believed that only a trained mind would be able to uncover the
            truth behind our surface perceptions in life. Put simply, only critical thinking can
            help us see clearly and discover reality rather than just perception.
          </p>
          <p className='text-left getFitBodySection ml-10 mr-10 mt-5 mb-10'>
            During the 15th and 16th centuries, Renaissance scholars began to think critically about
            almost every subject. Two of the first books on critical thinking were written at this
            time: one by Francis Bacon in England called “The Advancement of Learning” and the other
            later in France by René Descartes called “The Rules for the Direction of the Mind.” Both
            of these books argued that only through questioning and systematic thought can we find
            the right answer. The emergence of this critical thinking methodology during the
            Renaissance ushered in modern-day science and the process of testing and deductive
            reasoning. The scientific approach of disciplined thinking combined with serious
            analysis and critique is the foundation of critical thinking.
          </p>
          <img src={fishLogo} className='w-full mt-10 mb-10' alt='logo' />
          <h4 className=' text-center  mb-4  getFitHeadline pl-10 pr-10'>
            So, How Do You Define Critical Thinking?
          </h4>
          <p className=' text-left getFitBodySection ml-10 mr-10'>
            Scholars and academics have attempted to define critical thinking in multiple ways, but
            in the end, it boils down best to define critical thinking as
            <span className='_regular leading-6 tracking-normal text-base font-extrabold'>
              &nbsp;the way we think about our thinking.&nbsp;
            </span>
            Most of us don't think about how we think. We don't think very comprehensively or
            systematically, thus we are prone to distractions, perceptions, and self-deceptions.
            Most of us want to see the world the way we want it to be, not the way it actually is.
            Critical thinking cuts through perceptions and forces us to find the realities— or the
            signal in the noise. We have to rely on facts and analyses, not just feelings or
            desires.
          </p>

          <img src={manBalanceLogo} className='w-full mt-10 mb-10' alt='logo' />

          <h4 className='text-center mb-4 getFitHeadline  pl-10 pr-10'>
            A Combination Of Multiple Skills
          </h4>
          <h4 className='_regular leading-6 tracking-normal text-base text-left  text1 ml-10 mr-10'>
            Although we have designated critical thinking as a skill, it’s really a combination of
            several skills that result in a way of thinking. There are three components inside
            critical thinking, and they hinge on your ability to:
          </h4>

          <div className='flex justify-center mt-8 mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='tracking-normal leading-7  my-2 _bold text-center whitespace-nowrap text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                1
              </div>
            </div>
          </div>

          <h4 className='_bold text-center text-base mb-10 font-bold tracking-normal text1 ml-10 mr-10 _bold'>
            Properly gather the right data.
          </h4>

          <div className='flex justify-center mt-8 mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='tracking-normal leading-7  my-2 _bold text-center whitespace-nowrap text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                2
              </div>
            </div>
          </div>

          <h4 className='_bold text-center text-base mb-10 font-bold tracking-normal text1 ml-10 mr-10 _bold'>
            Sort that data into what is important and what is not.
          </h4>

          <div className='flex justify-center mt-8 mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='tracking-normal leading-7  my-2 _bold text-center whitespace-nowrap text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                3
              </div>
            </div>
          </div>

          <h4 className='_bold text-center text-base mb-10 font-bold tracking-normal text1 ml-10 mr-10 _bold'>
            Use analysis to make a reasoned and sound decision.
          </h4>
          <h4 className=' text-left  getFitBodySection ml-10 mr-10'>
            At the root of gathering data, sorting data, and analyzing data is your ability to ask
            questions. Critical thinking is not driven by answers — it's driven by questions. Almost
            every problem or challenge you have been asked to solve at work or home, or ever will be
            asked to solve, is driven by a question. Remember, at the core of critical thinking is
            Socratic questioning—it's only by starting with the right question that we can use
            critical thinking to think systematically, ask new questions, and ultimately find the
            right answers.
          </h4>
          <div className='mt-16  mb-10'>
            <h4
              className=' text-center getFitSmallHeader leading-[13px]'
              style={{ letterSpacing: '2.80px' }}>
              CRITICAL THINKING 1
            </h4>
            <h4 className=' mb-4 getFitHeadline text-center pl-10 pr-10'>
              Critical Thinking <br /> Summary
            </h4>
          </div>
          <h4 className='text-left getFitBodySection ml-10 mr-10'>
            Critical thinking is your ability to analyze information objectively and then make a
            reasoned and sound judgment. At the heart of critical thinking is your ability to ask
            questions.
          </h4>
          <img src={lightBulbLogo} className='w-full mt-10 mb-10' alt='logo' />
          <h4 className=' text-left getFitBodySection ml-10 mr-10'>
            Only by asking the right questions can we strip away perceptions, discover realities,
            and make the right decision based on facts.
          </h4>

          {!open && (
            <div className='w-full h-[80px] mt-[30px] pb-[40px] grid place-items-center'>
              <button onClick={navigateNext} className='relative '>
                <img alt='logo' src={logo7} className='h-10 w-10' />
                <img alt='logo' src={logo8} className='absolute h-3.5 ml-2.5 bottom-3 top-1/3' />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
