import React from 'react'

const Scalton = () => {
  return (
    <div className='w-full h-[55px] bg-[#e5e7eb] my-4'>
      <div className='main'></div>
    </div>
  )
}

export default Scalton
