import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import homeIcon from '../assets/images/home.svg'
import menu from '../assets/images/dash.svg'
import cross from '../assets/images/white cross.svg'
import logoexc from '../assets/images/group-4546-1@1x.png'
import logosolve from '../assets/images/group-4482-2@1x.png'
import logo2 from '../assets/images/tool1.svg'
import { useHistory } from 'react-router-dom'
import logo3 from '../assets/images/tool2.svg'
import useApp from '../context/useApp'

export default function Exercise() {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { dispatch, state } = useApp()
  const [solveIsTrue, setSolveIsTrue] = useState(false)
  const [createIsTrue, setCreateIsTrue] = useState(false)
  const [collabrateIsTrue, setCollaborateIsTrue] = useState(false)

  useEffect(() => {
    if (
      state?.user?.progress?.course?.criticalthinking?.assesment[0] === 100 &&
      state?.user?.progress?.course?.criticalthinking?.assesment[1] === 100 &&
      state?.user?.progress?.course?.criticalthinking?.assesment[2] === 100
    ) {
      setSolveIsTrue(true)
    } else {
      setSolveIsTrue(false)
    }
    if (
      state?.user?.progress?.course?.creative?.assesment[0] === 100 &&
      state?.user?.progress?.course?.creative?.assesment[1] === 100 &&
      state?.user?.progress?.course?.creative?.assesment[2] === 100
    ) {
      setCreateIsTrue(true)
    } else {
      setCreateIsTrue(false)
    }
    if (
      state?.user?.progress?.course?.collabrate?.assesment[0] === 100 &&
      state?.user?.progress?.course?.collabrate?.assesment[1] === 100 &&
      state?.user?.progress?.course?.collabrate?.assesment[2] === 100
    ) {
      setCollaborateIsTrue(true)
    } else {
      setCollaborateIsTrue(false)
    }
  })

  return (
    <div className='exercise_cover w-full min-h-screen overflow-y-scroll overflow-x-hidden '>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 ml-2'}
        style={{ pointerEvents: 'none' }}
        onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={menu} alt='logo_1' />
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={cross} alt='logo_2' />
          </div>
        )}
      </div>
      <div className='flex justify-center mt-10'>
        <img className='w-20 h-10' src={logoexc} alt='logo_4' />
      </div>
      <h4 className='text-center text-2xl pt-4 pb-10 _bold leading-6 text-white'>EXERCISE</h4>

      <div
        className='flex justify-center _bold text-base text-white mt-2'
        style={{ lineHeight: '18px' }}>
        How do I unlock exercises?
      </div>
      <div
        className='text-sm text-white px-7 text-center  mb-9 _regular'
        style={{ lineHeight: '18px' }}>
        Each time you complete a Get Fit section,
        <br /> the corresponding Exercise is unlocked.
      </div>

      <div className='_medium text-2xl leading-6 text-white text-center px-6 mt-3'>
        What do you want to accomplish?
      </div>
      {/* first row */}
      {!open && (
        <div className='w-full flex flex-col gap-4'>
          <div className='exmdiv mt-9 mb-2 '>
            <div className='flex flex-col w-24 h-24'>
              <div
                className='responsivediv  border border-excbor rounded-[10px]  mx-auto'
                style={{
                  backgroundColor: '#ffffff',
                  opacity: solveIsTrue ? 1 : 0.7,
                }}
                onClick={() => solveIsTrue && history.push('/solve1.0')}>
                <img className='h-16 w-12  responsiveImg' src={logosolve} alt='logo_8' />
              </div>
              <div
                className='text-white _bold text-center leading-3 pt-4'
                style={{ fontSize: '11px' }}>
                SOLVE <br /> A Problem
              </div>
            </div>

            <div className='flex flex-col w-24 h-24 '>
              <div
                className='responsivediv  border border-excbor rounded-[10px]  mx-auto'
                style={{
                  backgroundColor: '#ffffff',
                  opacity: createIsTrue ? 1 : 0.7,
                }}
                onClick={() => createIsTrue && history.push('/create_tool1.0')}>
                <img className='h-16 w-12  responsiveImg' src={logo2} alt='logo_8' />
              </div>
              <div
                className='text-white _bold text-center leading-3 pt-4'
                style={{ fontSize: '11px' }}>
                CREATE <br />
                New Ideas
              </div>
            </div>
            <div className='flex flex-col w-24 h-24'>
              <div
                className='responsivediv  border border-excbor rounded-[10px]  mx-auto'
                style={{
                  backgroundColor: '#ffffff',
                  opacity: collabrateIsTrue ? 1 : 0.7,
                }}
                onClick={() => collabrateIsTrue && history.push('/collabrate_tool1.0')}>
                <img className='h-16 w-12  responsiveImg' src={logo3} alt='logo_8' />
              </div>
              <div
                className='text-white _bold text-center leading-3 pt-4'
                style={{ fontSize: '11px' }}>
                COLLABORATE <br /> With Someone
              </div>
            </div>
          </div>
          {/* working div End  */}
          <div className='w-full mt-16 flex items-center text-[#fff]  gap-3 flex-col'>
            <p className='font-black '>COMING SOON </p>
            <p className='w-[80%] text-[14px] _regular leading-[18px] text-center'>
              More of the world’s most in-demand <br />
              Business Fitness Exercises will be
              <br /> available shortly.
            </p>
          </div>
        </div>
      )}

      {/* end */}

      {!open && (
        <div className='flex justify-center mt-8 mb-6 text-xl _medium text-color '>
          <img
            className='h-16 w-12 mx-auto my-4 '
            src={homeIcon}
            alt='home icon'
            onClick={() => history.push('/user_dashboard')}
          />
        </div>
      )}
    </div>
  )
}
