import React, { useEffect, useState } from 'react'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import useApp from '../context/useApp'
import { useHistory } from 'react-router-dom'
import { InputBox } from '../adminPages/reusable_code/ReusableComp'
import { sendEmailIndividual } from '../services/user'
const CreateMailScreen = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [email, setEmail] = useState('')
  const [emailData, setEmailData] = useState('')
  const [subject, setSub] = useState('')
  const mailSend = async event => {
    event.preventDefault()
    let body = {
      email: email,
      subject: subject,
      text: emailData,
      key: 'create',
    }
    body = JSON.stringify(body)
    const data = await sendEmailIndividual(body)
    if (data?.status === 200) {
      let confirmBox = window.confirm('Mail sent successfully')
      if (confirmBox) {
        history.push('/create_tool1.9')
      }
    } else {
      alert('Something went wrong, Please check all values are correctly filled')
    }
  }
  useEffect(() => {
    setEmailData(JSON.parse(localStorage.getItem('createMix')))
  }, [])

  return (
    <div className='_group-bg1 fixed top-0 bottom-0 right-0 left-0 overflow-x-hidden'>
      {open && (
        <div className='w-full flex justify-between z-20'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top absolute'>
            <div className='mt-3 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                alt='Not Load'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt ' style={{ marginRight: '12%' }}>
              <h3 className='exercise_solve_1_3_heading'>CREATE</h3>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      <div className='flex flex-col items-center' style={{ position: 'absolute', zIndex: '-1' }}>
        <div style={{ width: '100%', marginTop: '60px', marginBottom: '40px' }}>
          <form className='relative' style={{ marginBottom: '40px' }}>
            <InputBox
              htmlFor='#To'
              label='To'
              id='To'
              type='text'
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            <InputBox
              htmlFor='#subject'
              label='Subject'
              id='firstName'
              type='text'
              value={subject}
              onChange={event => {
                setSub(event.target.value)
              }}
            />
            <div className='px-9 mt-8'>
              <label
                className='not-italic font-medium inline-block font-style _medium font-extrabold'
                style={{ color: 'var(--jacarta)', fontSize: '10px', lineHeight: '10px' }}>
                Email Body
              </label>
              <div>
                <div>
                  <div>
                    Congratulations <br></br>You’ve used the art and science of Creative Thinking to
                    generate new ideas. Use your summary below as an outline for discussion with
                    your team and make sure to listen to everyone’s ideas — the more ideas the more
                    potential solutions.
                  </div>
                  <strong>My Problem</strong>
                  <div>
                    <div className='rap'>{emailData?.myProblem}</div>
                  </div>
                </div>
                <div>
                  <strong>My Ideas and Supporting Data</strong>
                  <div>
                    <strong>Idea 1</strong>
                    <div className='rap'>{emailData?.solution_1}</div>
                    <div className='rap'>{emailData?.idea_1}</div>
                    <strong>Idea 2</strong>
                    <div className='rap'>{emailData?.solution_2}</div>
                    <div className='rap'>{emailData?.idea_2}</div>
                    <strong>Idea 3</strong>
                    <div className='rap'>{emailData?.solution_3}</div>
                    <div className='rap'>{emailData?.idea_3}</div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className='absolute left-9 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6 '
              onClick={mailSend}>
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default CreateMailScreen
