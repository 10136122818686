import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import leftArrow from '../assets/images/leftArrow.svg'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
export const Create_tool1_1 = () => {
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [textArea, setTextArea] = useState('')
  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    var stateNew = { ...state }
    // here we check condtion assessment% on storage
    if (!state?.user?.progress?.course?.create_tool?.assessmentPercent) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 14,
        }),
      )
    }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      textArea,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 14
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 14,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (textArea.length >= 1) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
        return history.push('/create_tool1.2')
      }
    }
    return alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.create_tool?.textArea) {
      setTextArea(state?.user?.progress?.course?.create_tool?.textArea)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top ml-2.5'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3
                className='exercise_solve_1_3jjjjj _bold nav_title'
                style={{ letterSpacing: '5px' }}>
                CREATE
              </h3>
              <strong>
                <h1 className='percent _regular nav_percent'>14%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 1</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Problem Definition</h1>
              <p className='ex_solve_para '>
                Don’t try and get creative until you’ve clearly defined the problem. Don’t make
                assumptions. One of the biggest mistakes that companies and individuals make is not
                clearly defining the real problem they are trying to solve.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  Define the problem you are trying to solve. Keep it short and simple. You only get
                  150 characters.
                </p>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textArea}
                  maxLength={150}
                  onChange={e => setTextArea(e.target.value.trimStart())}
                  className='user_text_area'
                />
                <span className='absolute  nc  creat_tool text-xs' style={{ color: 'red' }}>
                  {textArea?.length}/150
                </span>
                <div className='relative mt-3.5'>
                  <p className='message '>
                    <span className='_bold'>Example: </span>
                    <span className='italic'>
                      The problem with solar panels is that they are expensive and
                      inefficient—typically as low as just 20% of sunlight is converted into usable
                      energy.
                    </span>
                  </p>
                </div>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/create_tool1.0')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit' onClick={saveData}>
                    <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
