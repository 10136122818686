import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15743-1@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logocircle from '../assets/images/path-15712@1x.png'
import logocircle2 from '../assets/images/group-3437-1@1x.png'
import logohead from '../assets/images/group-4480-4@1x.png'
import { useHistory } from 'react-router-dom'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
export default function CriticalThinking33() {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  return (
    <div className=' fixed top-0 bottom-0 right-0 left-0 overflow-auto p-3 bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute left-0 top-0'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-4 ml-5'>
            <img className=' w-9 h-9' alt='logo' src={menu} />
            
          </div>
        ) : (
          <div className='mt-4 ml-5'>
            <img className=' w-9 h-9' alt='logo' src={whiteCross} />
          </div>
        )}
      </div>

      <div className='flex justify-center mt-9'>
        <img className='h-16 w-16' alt='logo' src={logohead} />
      </div>
      <h4
        className='text-center text-sm _bold mt-4 error_msg_color'
        style={{ letterSpacing: '2.8px', fontSize: '14px' }}>
        SOLVE
      </h4>
      <div className='px-5'>
        <h4
          className='text-center text-[7px] _bold mt-7 work_out'
          style={{ letterSpacing: '2px', fontSize: '10px' }}>
          CRITICAL THINKING
        </h4>
        <h4 className='text-center text-2xl leading-6 _regular mt-2 work_out'>Congratulations!</h4>
        <p className='getFitBodySection mt-4 text-center' style={{ lineHeight: '24px' }}>
          You’ve completed Critical Thinking and have boosted your Business Fitness level with a
          foundational human-centric skill that will help you <strong> solve </strong>problems more
          successfully every day.
        </p>
        <div
          className='mb-10 flex justify-center relative'
          style={{ marginTop: '352px' }}
          onClick={() => history.push('/critical_thinking3.4')}>
          <div>
            <img className=' w-10 h-10' alt='logo' src={logocircle2} />
          </div>
        </div>
      </div>
    </div>
  )
}
