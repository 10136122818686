import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import RightW from '../assets/images/right.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import RightB from '../assets/images/whitetick.svg'
import logo5 from '../assets/images/path-15768@1x.png'
export const Create_tool1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textInput_5, setTextInput_5] = useState({
    first_idea: '',
    second_idea: '',
    third_idea: '',
  })
  const [saveIdeas, setSaveIdeas] = useState({
    prev_value_1: '',
    prev_value_2: '',
    prev_value_3: '',
  })

  const [initial, stateinital] = useState({
    one: false,
    two: false,
    three: false,
  })
  // let uri = '/course/course'
  const handleSubmit = async event => {
    event.preventDefault()
    const topOneIdea =
      initial.one === true
        ? saveIdeas.prev_value_1
        : initial.two
        ? saveIdeas.prev_value_2
        : initial.three
        ? saveIdeas.prev_value_3
        : alert('Select Any one idea')

    let uri = '/course/course'
    var stateNew = { ...state }
    if (state?.user?.progress?.course?.create_tool?.assessmentPercent < 71) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 71,
        }),
      )
    }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      textInput_5,
      topOneIdea,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 71
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 71,
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (
      textInput_5?.first_idea?.length >= 1 &&
      textInput_5?.second_idea?.length >= 1 &&
      textInput_5?.third_idea?.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      if (topOneIdea) return history.push('/create_tool1.8')
    } else {
      alert('Answer can not be blank')
    }
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.create_tool?.textInput_5) {
      setTextInput_5(state?.user?.progress?.course?.create_tool?.textInput_5)
    }
  }, [])

  useEffect(() => {
    switch (
      Object.values(saveIdeas).indexOf(state.user?.progress?.course?.create_tool?.topOneIdea)
    ) {
      case 0:
        {
          stateinital({ ...initial, one: true })
        }
        break
      case 1:
        {
          stateinital({ ...initial, two: true })
        }
        break
      case 2: {
        stateinital({ ...initial, three: true })
      }

      default:
        break
    }
  }, [])
  setTimeout(() => {
    setSaveIdeas({
      prev_value_1: state.user?.progress?.course?.create_tool?.toolDataArr[0],
      prev_value_2: state.user?.progress?.course?.create_tool?.toolDataArr[1],
      prev_value_3: state.user?.progress?.course?.create_tool?.toolDataArr[2],
    })
  }, 3000)

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top ml-2.5'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt ' style={{ marginRight: '1%' }}>
              <h3 className='exercise_solve_1_3_heading _bold nav_title'>CREATE</h3>
              <strong>
                <h1 className='percent _regular nav_percent'>71%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 5 </h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Gather Supporting Data</h1>
              <p className='ex_solve_para  '>
                Now gather the data that supports your Top 3 potential ideas. Gather at least one
                source per idea and consider gathering more to make your argument even stronger.
                Then, choose a final idea by clicking on a checkmark.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='action_div'>
                <h1
                  className='leading-none  mainHeading _semiBold mb-2'
                  style={{ color: '#D9182D', fontSize: '16px' }}>
                  My Ideas
                </h1>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Text from 1.4'
                  value={saveIdeas.prev_value_1}
                  disabled
                  onChange={e => setSaveIdeas({ ...saveIdeas, prev_value_1: e.target.value })}
                  className='user_text_area w-full border_0_bc_red'></textarea>
                <img
                  alt='not found'
                  className='WhiteTick1'
                  src={initial.one ? RightW : RightB}
                  onClick={() =>
                    stateinital({
                      ...initial,
                      one: initial.one === false ? true : false,
                      two: false,
                      three: false,
                    })
                  }
                />
              </div>
              <div className='action_div'>
                <h3 className='step mt-[21px] h-[22px] '>action</h3>
                <p className='ex_solve_para   mb-3 '>
                  Data source that supports your first potential Idea.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  value={textInput_5.first_idea}
                  onChange={e =>
                    setTextInput_5({ ...textInput_5, first_idea: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />
                <p className='message semiBold'>
                  <span className='_bold'> Example: </span>{' '}
                  <span className='italic'>
                    Studies show that the price of graphene is going down every year and will equal
                    silicon by 2025. (Add your source and hyperlink here).
                  </span>
                </p>
              </div>
              <div className='action_div'>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Text from 1.4'
                  value={saveIdeas.prev_value_2}
                  disabled
                  onChange={e => setSaveIdeas({ ...saveIdeas, prev_value_2: e.target.value })}
                  className='user_text_area w-full border_0_bc_red'></textarea>
                <img
                  src={initial.two ? RightW : RightB}
                  onClick={() =>
                    stateinital({
                      ...initial,
                      two: initial.two === false ? true : false,
                      one: false,
                      three: false,
                    })
                  }
                  alt='not found'
                  className='WhiteTick1'
                />
              </div>
              <div className='action_div'>
                <h3 className='step mt-[21px] h-[22px] '>action</h3>
                <p className='ex_solve_para   mb-3 '>
                  Data source that supports your second potential Idea.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  value={textInput_5.second_idea}
                  onChange={e =>
                    setTextInput_5({ ...textInput_5, second_idea: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />
              </div>
              <div className='action_div mt-[52px]'>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Text from 1.4'
                  value={saveIdeas.prev_value_3}
                  disabled
                  onChange={e => setSaveIdeas({ ...saveIdeas, prev_value_3: e.target.value })}
                  className='user_text_area w-full border_0_bc_red'></textarea>
                <img
                  src={initial.three ? RightW : RightB}
                  onClick={() =>
                    stateinital({
                      ...initial,
                      three: initial.three === false ? true : false,
                      one: false,
                      two: false,
                    })
                  }
                  alt='not found'
                  className='WhiteTick1'
                />
              </div>
              <div className='action_div mb-24'>
                <h3 className='step mt-[21px] h-[22px] '>action</h3>
                <p className='ex_solve_para   mb-3 '>
                  Data source that supports your third potential Idea.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  value={textInput_5.third_idea}
                  onChange={e =>
                    setTextInput_5({ ...textInput_5, third_idea: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />
              </div>

              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/create_tool1.4')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit' onClick={handleSubmit}>
                    <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
