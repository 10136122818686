import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import ProgressCard from '../components/userComponent/ProgressCard'
import logo2 from '../assets/images/path-15754@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import logo8 from '../assets/images/path-15712@1x.png'
import logo6 from '../assets/images/FB-Weight-Icon.png'
import logo7 from '../assets/images/path-15753@1x.png'
import { useHistory } from 'react-router-dom'

export default function UserStrategizeWorkout(props) {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const data = [
    {
      name: 'WORKOUT1:CRITICAL THINKING',
      description:
        'Discover what critical thinking is and how it applies to every other human-centric skill.',
      progress: 50,
      time: '10 min',
    },
    {
      name: 'WORKOUT2:CRITICAL THINKING',
      description:
        'Explore why you need critical thinking in both your personal and professional life.',
      progress: 50,
      time: '10 min',
    },
    {
      name: 'WORKOUT3:CRITICAL THINKING',
      description:
        'Learn how you will apply your critical Thinking skills everyday in the real-world.',
      progress: 50,
      time: '10 min',
    },
  ]

  return (
    <div className='user_get-fit fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo3} />
            <img className='top-5 left-5 h-1   absolute' alt='logo' src={logo4} />
            <img className=' h-1 top-7 left-5 absolute' alt='logo' src={logo4} />
            <img className='top-9 left-5 h-1 absolute' alt='logo' src={logo4} />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo8} />
            <img className='h-3 left-6  top-6 absolute' alt='logo' src={logo5} />
          </div>
        )}
      </div>
      <div className='flex justify-center mt-10'>
        <img className='h-13 w-12' alt='logo' src={logo6} />
      </div>
      <h4
        className='text-center text-xl font-semibold tracking-widest pb-2 font-style'
        style={{ color: '#fbebe5' }}>
        Strategize
      </h4>
      <h4
        className='text-center text-xs mb-10 font-semibold tracking-widest _bold font-style'
        style={{ color: '#fbebe5' }}>
        W O R K O U T S
      </h4>
      {data?.map((item, index) => {
        return (
          <ProgressCard
            key={index}
            time={item?.time}
            name={item?.name}
            description={item?.description}
            progress={item?.progress}
            cardContainerColor={'#5a2246'}
            nameColor={'#ffffff'}
            descriptionColor={'#ffffff'}
            progressContainerColor={'#844a75'}
            progressColor={'#ffffff'}
            progressTextColor={'#ffffff'}
          />
        )
      })}
      <div className='  mt-48 mb-10 flex justify-center relative'>
        <div onClick={() => history.push('/get_fit')}>
          <img className='h-10 w-10 absolute' alt='logo' src={logo7} style={{ zIndex: -1 }} />
          <div className='flex justify-center items-center' style={{ height: 41, width: 38 }}>
            <img className='h-4 w-4' alt='logo' src={logo2} />
          </div>
        </div>
      </div>
    </div>
  )
}
