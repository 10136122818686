import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import mail from '../assets/images/mail.svg'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import leftArrow from '../assets/images/leftArrow.svg'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
export const CollabrateTool1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textInput, setTextInput] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
  })
  const [textInput1_3, setTextInput1_3] = useState({
    objective: !state.user?.progress?.course.eq_tool?.textArea
      ? ''
      : state.user?.progress?.course.eq_tool?.textArea !== 0
      ? state.user?.progress?.course.eq_tool?.textArea
      : '',
    prev_value_1: !state.user?.progress?.course.eq_tool?.textBox?.firstInputText
      ? ''
      : state.user?.progress?.course.eq_tool?.textBox?.firstInputText.length !== 0
      ? state.user?.progress?.course.eq_tool?.textBox?.firstInputText
      : '',
    prev_value_2: !state.user?.progress?.course.eq_tool?.textBox?.secondInputText
      ? ''
      : state.user?.progress?.course.eq_tool?.textBox?.secondInputText.length !== 0
      ? state.user?.progress?.course.eq_tool?.textBox?.secondInputText
      : '',
    prev_value_3: !state.user?.progress?.course.eq_tool?.textBox?.thirdInputText
      ? ''
      : state.user?.progress?.course.eq_tool?.textBox?.thirdInputText.length !== 0
      ? state.user?.progress?.course.eq_tool?.textBox?.thirdInputText
      : '',
    prev_value_4: !state.user?.progress?.course.eq_tool?.textBox?.fourthInputText
      ? ''
      : state.user?.progress?.course.eq_tool?.textBox?.fourthInputText.length !== 0
      ? state.user?.progress?.course.eq_tool?.textBox?.fourthInputText
      : '',
    prev_value_5: !state.user?.progress?.course.eq_tool?.textBox?.fifthInputText
      ? ''
      : state.user?.progress?.course.eq_tool?.textBox?.fifthInputText.length !== 0
      ? state.user?.progress?.course.eq_tool?.textBox?.fifthInputText
      : '',
  })
  const handleSubmit = event => {
    event.preventDefault()
  }
  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()

    var stateNew = { ...state }
    if (state?.user?.progress?.course?.eq_tool?.assessmentPercent < 50) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_communicate`,
          assessment: 50,
        }),
      )
    }
    stateNew.user.progress.course.eq_tool = {
      ...stateNew.user.progress.course.eq_tool,
      textInput,
      assessmentPercent:
        state?.user?.progress?.course?.eq_tool?.assessmentPercent > 50
          ? state?.user?.progress?.course?.eq_tool?.assessmentPercent
          : 50,
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)

    const checkdata1 = Object.values(textInput1_3).slice(1, Object.values(textInput1_3).length)
    let flag = true
    for (let i = 0; i < checkdata1.length; i++) {
      if (checkdata1[i]?.length > 0) {
        if (
          (i === 0 && textInput?.first?.length < 1) ||
          (i === 1 && textInput?.second?.length < 1) ||
          (i === 2 && textInput?.third?.length < 1) ||
          (i === 3 && textInput?.fourth?.length < 1) ||
          (i === 4 && textInput?.fifth?.length < 1)
        ) {
          flag = false
          break
        }
      }
    }
    if (flag) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      return history.push('/collabrate_tool1.4')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.eq_tool?.textInput) {
      setTextInput(state?.user?.progress?.course?.eq_tool?.textInput)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold'>COLLABORATE</h3>
              <strong>
                <h1 className='percent _regular'>50%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 3</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>
                Define The Deliverables Of Each Person
              </h1>
              <p className='ex_solve_para'>
                Once you know the objective and the players, now you need to make sure you clearly
                define the deliverables that each team is to provide in order to achieve the defined
                objective. Make sure you’re being specific.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  Define the specific deliverable each of the below is to provide.
                </p>
                <h3 className='ex_solve_para mb-[15px] _semiBold' style={{ marginTop: '28px' }}>
                  Collaboration Outcome
                </h3>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_3.objective}
                  onChange={e => setTextInput1_3({ ...textInput1_3, objective: e.target.value })}
                  className='user_text_area w-full border_0_bc_red'
                  // style={{ fontSize: '14px',backgroundColor: '#D9182D', opacity:'5%'}}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_3.prev_value_1}
                  onChange={e => setTextInput1_3({ ...textInput1_3, prev_value_1: e.target.value })}
                  className='user_text_area Usertext_area_Box'
                />
              </div>
              <div className='action_div mt-[7px]'>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  disabled={textInput1_3?.prev_value_1?.trim()?.length < 1}
                  value={textInput.first}
                  onChange={e => setTextInput({ ...textInput, first: e.target.value.trimStart() })}
                  className='user_text_area Usertext_area_Box second_Box'
                />
                <p className='message ' style={{ marginBottom: '40px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Bob needs to provide the exact size specifications to the Creative team for the
                    updated laptop.
                  </span>
                </p>
              </div>

              <div className='action_div '>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_3.prev_value_2}
                  onChange={e => setTextInput1_3({ ...textInput1_3, prev_value_2: e.target.value })}
                  className='user_text_area Usertext_area_Box'
                />
              </div>
              <div className='action_div mt-[7px]'>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput.second}
                  disabled={textInput1_3?.prev_value_2?.trim().length < 1}
                  onChange={e => setTextInput({ ...textInput, second: e.target.value.trimStart() })}
                  className='user_text_area Usertext_area_Box'
                  style={{ fontSize: '14px', height: '110px', color: '#676767' }}
                />
                <p className='message' style={{ marginBottom: '40px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Lisa needs to provide the budget Engineering and Design need to work within for
                    the final product.
                  </span>
                </p>
              </div>

              <div className='action_div '>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_3.prev_value_3}
                  onChange={e => setTextInput1_3({ ...textInput1_3, prev_value_3: e.target.value })}
                  className='user_text_area Usertext_area_Box'
                />
              </div>
              <div className='action_div mt-[7px]'>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textInput.third}
                  disabled={textInput1_3?.prev_value_3?.trim()?.length < 1}
                  onChange={e => setTextInput({ ...textInput, third: e.target.value.trimStart() })}
                  className='user_text_area Usertext_area_Box second_Box'
                />
                <p className='message' style={{ marginBottom: '40px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Carol needs to provide the feedback from the customer survey on the new laptop
                    design.
                  </span>
                </p>
              </div>

              <div className='action_div'>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_3.prev_value_4}
                  onChange={e => setTextInput1_3({ ...textInput1_3, prev_value_4: e.target.value })}
                  className='user_text_area Usertext_area_Box'
                />
              </div>
              <div className='action_div mt-[7px]'>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textInput.fourth}
                  disabled={textInput1_3?.prev_value_4?.trim()?.length < 1}
                  onChange={e => setTextInput({ ...textInput, fourth: e.target.value.trimStart() })}
                  className='user_text_area Usertext_area_Box second_Box'
                />
                <p className='message ' style={{ marginBottom: '40px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Tom needs to provide new design ideas and solutions by June 5.
                  </span>
                </p>
              </div>
              <div className='action_div'>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_3.prev_value_5}
                  onChange={e => setTextInput1_3({ ...textInput1_3, prev_value_5: e.target.value })}
                  className='user_text_area Usertext_area_Box'
                />
              </div>
              <div className='action_div mt-[7px]'>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textInput.fifth}
                  disabled={textInput1_3?.prev_value_5?.trim()?.length < 1}
                  onChange={e => setTextInput({ ...textInput, fifth: e.target.value.trimStart() })}
                  className='user_text_area Usertext_area_Box second_Box '
                />
                <p className='message' style={{ marginBottom: '40px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Steve needs to provide feedback to the team on product launch deadlines no later
                    than June 10.
                  </span>
                </p>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <div>
                    <button onClick={() => history.push('/collabrate_tool1.2')}>
                      <img src={leftArrow} alt='Back Arrow'></img>
                    </button>
                  </div>
                  <div>
                    <button type='submit' onClick={saveData}>
                      <img
                        src={nextArrow}
                        alt='image no present'
                        className='w-[40px] h-[40px]'></img>
                    </button>
                  </div>
                </div>
                <div>
                  <p className='ex_solve_para mb-3'>Email these objectives.</p>
                  <button type='submit'>
                    <img
                      src={mail}
                      alt='image no present'
                      className='w-[40px] h-[40px]'
                      onClick={() => {
                        const mailSendData = { ...textInput, ...textInput1_3 }
                        localStorage.setItem('mailSendDataCol2', JSON.stringify(mailSendData))
                        return history.push('/collabratemail1')
                      }}
                    />
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
