import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { resetPass } from '../services/user'
import UserLoginHeader from '../components/userComponent/UserLoginHeader'
const initialState = {
  newPassword: { value: '', isError: false, msg: '' },
  confPassword: { value: '', isError: false, msg: '' },
  res: { isError: false, msg: '' },
}

export default function ResetPassowrd() {
  const { dispatch } = useApp()

  const [form, setForm] = useState(initialState)
  const [errMsg, setErrMsg] = useState()
  const history = useHistory()
  const pathname = window.location.pathname
  const token = pathname.slice(16, pathname.length)

  const newPass = async _ => {
    if (form.newPassword.value === '' || form.confPassword.value === '') {
      setForm({
        ...form,
        ...(form.newPassword.value === ''
          ? {
              newPassword: { ...form.newPassword, isError: true, msg: 'This field is required.' },
            }
          : { newPassword: { ...form.newPassword, isError: false, msg: '' } }),
        ...form,
        ...(form.confPassword.value === ''
          ? {
              confPassword: {
                ...form.confPassword,
                isError: true,
                msg: 'This field is required.',
              },
            }
          : { confPassword: { ...form.confPassword, isError: false, msg: '' } }),

        resp: {
          ...form.resp,
          isError: true,
          msg: 'Please fill all the required fields.',
        },
      })
      return
    } else if (
      !form.newPassword.value ||
      !form.confPassword.value ||
      form.newPassword.value !== form.confPassword.value
    ) {
      setErrMsg("Passwords don't match")
    } else if (form.newPassword.value.length < 8) {
      setErrMsg('Password should not be less than 8 characters')
    } else if (
      !/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(
        form.newPassword.value,
      )
    ) {
      setErrMsg(
        'The password must be at least 8 characters long contain a special character, a number, an uppercase letter, and a lower case letter ',
      )
    } else {
      let res = await resetPass(
        JSON.stringify({
          token: token,
          password: btoa(`${form.newPassword.value}`),
          ConfirmPassword: btoa(`${form.confPassword.value}`),
        }),
      )

      let data = res.ok ? await res.text() : null
      if (data) {
        data = JSON.parse(data)
        if (data.success) {
          dispatch({ type: APP_USER_AUTH_SUCCESS, value: data.data })
          history.replace('/admin_signin')
          alert(data.message)
        } else {
          setErrMsg(data.message)
          setTimeout(() => {
            history.replace('/admin_signin')
          }, 2000)
        }
      }

      setForm(initialState)
    }
  }

  return (
    <div className='admin_signup_in fixed overflow-auto top-0 right-0 left-0 bottom-0'>
      <UserLoginHeader />
      <div className='mt-14 mx-8'>
        {errMsg ? (
          <>
            <p className=' text-2xl  mt-11  _font_color_white _regular font-normal '>{errMsg}</p>
          </>
        ) : (
          <>
            <p className=' text-2xl  mt-11  _font_color_white _regular font-normal '>
              Reset Password
            </p>
          </>
        )}
        <div>
          <label htmlFor='#newPass' className='text-white font mt-4 inline-block _medium'>
            New Password
          </label>
          <input
            id='password'
            type='password'
            required
            className='bg-transparent border-b w-full text-white mt-4 mb-4'
            value={form.newPassword.value}
            onBlur={e =>
              e.target.value === ''
                ? setForm({
                    ...form,
                    newPassword: {
                      ...form.newPassword,
                      isError: true,
                      msg: 'This field is required.',
                    },
                  })
                : setForm({
                    ...form,
                    newPassword: { ...form.newPassword, isError: false, msg: '' },
                  })
            }
            onChange={e =>
              setForm({ ...form, newPassword: { ...form.newPassword, value: e.target.value } })
            }
          />
        </div>

        <div>
          <label htmlFor='#confPass' className='text-white font mt-4 inline-block _medium'>
            Confirm Password
          </label>
          <input
            id='password'
            type='password'
            required
            className='bg-transparent border-b w-full text-white mt-4 mb-4'
            value={form.confPassword.value}
            onBlur={e =>
              e.target.value === ''
                ? setForm({
                    ...form,
                    confPassword: {
                      ...form.confPassword,
                      isError: true,
                      msg: 'This field is required.',
                    },
                  })
                : setForm({
                    ...form,
                    confPassword: { ...form.confPassword, isError: false, msg: '' },
                  })
            }
            onChange={e =>
              setForm({ ...form, confPassword: { ...form.confPassword, value: e.target.value } })
            }
          />
        </div>

        <div className='flex justify-end'>
          <button
            disabled={!form.newPassword.value}
            className='right_Icons h-8 w-8 bg-no-repeat'
            onClick={() => {
              newPass()
            }}></button>
        </div>
      </div>
    </div>
  )
}
