import logohead from '../assets/images/group-4480-4@1x.png'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import useApp from '../context/useApp'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export default function CriticalThinking35() {
  const history = useHistory()
  const { dispatch, state } = useApp()
  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.solve.cg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        cg: state?.user?.progress?.course?.solve.cg,
         kg: state?.user?.progress?.course?.solve.kg,
        assessment:
          state?.user?.progress?.course?.criticalthinking?.assesment[0] +
          state?.user?.progress?.course?.criticalthinking?.assesment[1] +
          state?.user?.progress?.course?.criticalthinking?.assesment[2],
        complete: true,
      }),
    )

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
    return history.push('/user_dashboard')
  }
  const handleClickTwo = async uri => {
    var stateNew = { ...state }

    stateNew.user.progress.course.solve.cg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        cg: state?.user?.progress?.course?.solve.cg,
        kg: state?.user?.progress?.course?.solve.kg,
        assessment:
          state?.user?.progress?.course?.solve?.assessments[0]?.progress +
          state?.user?.progress?.course?.solve.assessments[1]?.progress +
          state?.user?.progress?.course?.solve.assessments[2]?.progress,
        complete: true,
      }),
    )

    return history.push('/user_dashboard')
  }

  return (
    <CommonConfirmation
      logo={logohead}
      title={'SOLVE'}
      trainingTitle={'CRITICAL THINKING'}
      question={'Do you feel more confident to use critical thinking every day?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
