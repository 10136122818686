import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import { saveScoring, saveProgress } from '../services/user'
import useApp from '../context/useApp'
export const Create_tool1_8 = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const handleSubmit = async () => {
    let uri = '/course/course'
    if (state?.user?.progress?.course?.create_tool?.assessmentPercent < 86) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 86,
        }),
      )
    }
    var stateNew = { ...state }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 86
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 86,
    }
    await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    history.push('/create_tool1.9')
  }
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top ml-2.5'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold nav_title_create'>CREATE</h3>
              <strong>
                <h1 className='percent _regular nav_percent'>86%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 6</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Meet and Iterate</h1>
              <p className='ex_solve_para'>
                You’ve brainstormed, developed, refined and filtered your ideas, and you’ve chosen
                your top idea. Now it’s time to meet and iterate with your team.
              </p>
              <p className='ex_solve_para  '>
                Bring all your ideas to the meeting but start the discussion with your top three
                data-supported ideas.
              </p>
              <p className='ex_solve_para  '>
                You might have the best idea, or you might be able to iterate on your idea or
                someone else’s, so keep and open mind, listen carefully and make sure to remove
                personal bias to find the best idea.
              </p>
              <p className='ex_solve_para  '>
                The next page will provide a summary of your creative journey. Once a final idea is
                chosen, you may want to visit the SOLVE Tool to better articulate your solution and
                steps for implementation.
              </p>
            </div>

            <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
              <button type='submit' onClick={handleSubmit}>
                <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
              </button>
              <p className='text-[#43296E] leading-none text-[11px]'>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
