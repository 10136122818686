import React, { useState, useEffect } from 'react'
import leftArrow from '../assets/images/leftArrow.svg'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import mail from '../assets/images/mail.svg'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import whitetick from '../assets/images/whitetick.svg'
import rightTick from '../assets/images/right.svg'
export const Create_tool1_4 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textInput1_4, setTextInput1_4] = useState({
    objective: !state.user?.progress?.course.create_tool?.textArea
      ? ''
      : state.user?.progress?.course.create_tool?.textArea !== 0
      ? state.user?.progress?.course.create_tool?.textArea
      : '',
    prev_value_1: !state.user?.progress?.course.create_tool?.inputField?.firstInputText
      ? ''
      : state.user?.progress?.course.create_tool?.inputField?.firstInputText.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField?.firstInputText
      : '',
    prev_value_2: !state.user?.progress?.course.create_tool?.inputField?.secondInputText
      ? ''
      : state.user?.progress?.course.create_tool?.inputField?.secondInputText.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField?.secondInputText
      : '',
    prev_value_3: !state.user?.progress?.course.create_tool?.inputField_3?.firstField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.firstField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.firstField
      : '',
    prev_value_4: !state.user?.progress?.course.create_tool?.inputField_3?.secondField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.secondField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.secondField
      : '',
    prev_value_5: !state.user?.progress?.course.create_tool?.inputField_3?.thirdField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.thirdField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.thirdField
      : '',
    prev_value_6: !state.user?.progress?.course.create_tool?.inputField_3?.fourthField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.fourthField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.fourthField
      : '',
    prev_value_7: !state.user?.progress?.course.create_tool?.inputField_3?.fifthField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.fifthField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.fifthField
      : '',
    prev_value_8: !state.user?.progress?.course.create_tool?.inputField_3?.sixField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.sixField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.sixField
      : '',
    prev_value_9: !state.user?.progress?.course.create_tool?.inputField_3?.sevenField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.sevenField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.sevenField
      : '',
    prev_value_10: !state.user?.progress?.course.create_tool?.inputField_3?.eightField
      ? ''
      : state.user?.progress?.course.create_tool?.inputField_3?.eightField.length !== 0
      ? state.user?.progress?.course.create_tool?.inputField_3?.eightField
      : '',
  })
  const [firstToggle, setFirstToggle] = useState(false)
  const [secondToggle, setSecondToggle] = useState(false)
  const [thirdToggle, setThirdToggle] = useState(false)
  const [fourthtoggle, setFourthtoggle] = useState(false)
  const [fifthToggle, setFifthToggle] = useState(false)
  const [sixToggle, setSixoggle] = useState(false)
  const [sevenToggle, setSevenToggle] = useState(false)
  const [eightToggle, setEightToggle] = useState(false)
  const [nineToggle, setNineToggle] = useState(false)
  const [tenToggle, setTenToggle] = useState(false)
  const [toolDataArr] = useState([])

  const handleSubmit = (event, toggle) => {
    const index = toolDataArr.indexOf(event)
    if (index > -1) {
      toolDataArr.splice(index, 1)
    } else {
      if (toolDataArr.length < 3 && toggle) {
        toolDataArr.push(event)
      } else if (toolDataArr.length === 3) {
        alert('only 3 Ideas can be selected')
      }
    }
    // event.preventDefault()
  }
  useEffect(() => {
    if (state?.user?.progress?.course?.create_tool?.toolDataArr) {
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_1)
      ) {
        setFirstToggle(true)
        toolDataArr.push(textInput1_4.prev_value_1)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_2)
      ) {
        setSecondToggle(true)
        toolDataArr.push(textInput1_4.prev_value_2)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_3)
      ) {
        setThirdToggle(true)
        toolDataArr.push(textInput1_4.prev_value_3)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_4)
      ) {
        setFourthtoggle(true)
        toolDataArr.push(textInput1_4.prev_value_4)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_5)
      ) {
        setFifthToggle(true)
        toolDataArr.push(textInput1_4.prev_value_5)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_6)
      ) {
        setSixoggle(true)
        toolDataArr.push(textInput1_4.prev_value_6)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_7)
      ) {
        setSevenToggle(true)
        toolDataArr.push(textInput1_4.prev_value_7)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_8)
      ) {
        setEightToggle(true)
        toolDataArr.push(textInput1_4.prev_value_8)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_9)
      ) {
        setNineToggle(true)
        toolDataArr.push(textInput1_4.prev_value_9)
      }
      if (
        state?.user?.progress?.course?.create_tool?.toolDataArr.includes(textInput1_4.prev_value_10)
      ) {
        setTenToggle(true)
        toolDataArr.push(textInput1_4.prev_value_10)
      }
    }
  }, [])

  const saveData = async e => {
    // e.preventDefault()

    let uri = '/course/course'
    var stateNew = { ...state }
    if (state?.user?.progress?.course?.create_tool?.assessmentPercent) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 57,
        }),
      )
    }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      toolDataArr,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 57
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 57,
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top ml-2.5'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt '>
              <h3
                className='exercise_solve_1_3_heading _bold uppercase nav_title'
                style={{ marginRight: '5%' }}>
                Create
              </h3>
              <div>
                <h1 className='percent _regular nav_percent'>57%</h1>
              </div>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='step _bold'>STEP 4</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Top 10 Solutions</h1>
              <p className='ex_solve_para '>
                Hopefully you were able to generate 40-50 ideas using the SCAMPER method. First,
                list your top 10, then select your top 3 by clicking the checkmark by the box. The
                best way to narrow down ideas is to ask yourself the following questions:
              </p>
            </div>

            <div className='action_div'>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  marginBottom: '20px',
                }}>
                <div>
                  <h1 className='leading-none mainHeading circle_count'>1</h1>
                </div>
              </div>
              <p className='ex_solve_para  mb-[15px]' style={{ marginTop: '0px' }}>
                <span className='_bold'> Is this idea realistic?</span>
                <br />
                (Do we have the materials, technology and/or know how to implement?)
              </p>
            </div>
            <div className='action_div'>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  marginBottom: '20px',
                }}>
                <div>
                  <h1 className='leading-none  mainHeading circle_count'>2</h1>
                </div>
              </div>
              <div className='ex_solve_para  mb-[15px]' style={{ marginTop: '0px' }}>
                <span className='_bold'> Is this solution financially viable? </span>
                <br />
                (Does the idea cost substantially more than the existing solution?)
              </div>
            </div>

            <div className='action_div'>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  marginBottom: '20px',
                }}>
                <div>
                  <h1 className='leading-none  mainHeading circle_count'>3</h1>
                </div>
              </div>
              <div className='ex_solve_para  mb-[15px]' style={{ marginTop: '0px' }}>
                <span className='_bold'> Does this idea really solve the problem? </span>
                <br />
                (Does this idea solve the problem as defined or solve something else?)
              </div>
            </div>

            <form className='solve_form_1_3' onSubmit={saveData}>
              <div className='action_div'>
                <h1
                  className='leading-none  mainHeading _semiBold '
                  style={{
                    color: '#D9182D',
                    fontSize: '16px',
                    marginBottom: '15px',
                  }}>
                  My Problem
                </h1>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  disabled
                  value={textInput1_4.objective}
                  onChange={e =>
                    setTextInput1_4({
                      ...textInput1_4,
                      objective: e.target.value,
                    })
                  }
                  className='user_text_area w-full border_0_bc_red'
                  style={{
                    fontSize: '14px',
                    backgroundColor: '#D9182D',
                    height: '181px',
                    color: '#fff',
                  }}
                />
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      1
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={firstToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_1) ||
                        toolDataArr.length < 3
                      ) {
                        setFirstToggle(v => {
                          if (
                            toolDataArr.length <= 3 &&
                            v === false
                            // state.user.progress.course.create_tool.toolDataArr.length <= 3
                          )
                            return true
                          return false
                        })
                      }
                      // setFirstToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_1, !firstToggle)
                    }}
                  />

                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_1}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_1: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      2
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={secondToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_2) ||
                        toolDataArr.length < 3
                      ) {
                        setSecondToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }

                      // setSecondToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_2, !secondToggle)
                    }}
                  />

                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_2}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_2: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      3
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  {/* question smjho-> 10 input field h unpr check ka image laga h */}
                  <img
                    src={thirdToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_3) ||
                        toolDataArr.length < 3
                      ) {
                        setThirdToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      handleSubmit(textInput1_4.prev_value_3, !thirdToggle)
                    }}
                  />

                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_3}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_3: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      4
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={fourthtoggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_4) ||
                        toolDataArr.length < 3
                      ) {
                        setFourthtoggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      // setFourthtoggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_4, !fourthtoggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_4}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_4: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      5
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={fifthToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_5) ||
                        toolDataArr.length < 3
                      ) {
                        setFifthToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      // setFifthToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_5, !fifthToggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_5}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_5: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      6
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={sixToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_6) ||
                        toolDataArr.length < 3
                      ) {
                        setSixoggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      // setSixoggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_6, !sixToggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    disabled
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_6}
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_6: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      7
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={sevenToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_7) ||
                        toolDataArr.length < 3
                      ) {
                        setSevenToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      // setSevenToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_7, !sevenToggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_7}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_7: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      8
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={eightToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_8) ||
                        toolDataArr.length < 3
                      ) {
                        // setEightToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                        setEightToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }

                      handleSubmit(textInput1_4.prev_value_8, !eightToggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='Type here.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_8}
                    disabled
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_8: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      9
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={nineToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_9) ||
                        toolDataArr.length < 3
                      ) {
                        setNineToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      // setNineToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_9, !nineToggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='User text.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_9}
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_9: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              <div className='action_div mt-9'>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '20px',
                  }}>
                  <div>
                    <h1
                      className='leading-none  mainHeading circle_count'
                      style={{ backgroundColor: '#D9182D' }}>
                      10
                    </h1>
                  </div>
                </div>
                <h3 className='step _bold'>Idea</h3>
              </div>

              <div className='action_div mt-5'>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <img
                    src={tenToggle ? rightTick : whitetick}
                    alt='logo'
                    style={{
                      position: 'absolute',
                      right: '-18px',
                      bottom: '50px',
                    }}
                    onClick={() => {
                      if (
                        toolDataArr.includes(textInput1_4.prev_value_10) ||
                        toolDataArr.length < 3
                      ) {
                        setTenToggle(v => {
                          if (toolDataArr.length <= 3 && v === false) return true
                          return false
                        })
                      }
                      // setTenToggle(v => (toolDataArr.length <= 3 && !v ? true : false))
                      handleSubmit(textInput1_4.prev_value_10, !tenToggle)
                    }}
                  />
                  <textarea
                    name='new_text_area'
                    placeholder='User text.'
                    className='user_text_area w-full'
                    value={textInput1_4.prev_value_10}
                    onChange={e =>
                      setTextInput1_4({
                        ...textInput1_4,
                        prev_value_10: e.target.value,
                      })
                    }
                    style={{ height: '140px', backgroundColor: '#FFF' }}
                  />
                </div>
              </div>

              {/* <p className='ex_solve_para' style={{ marginTop: '80px' }}>
                Email these ideas.
              </p> */}
              {/* <div className='w-full flex flex-col items-center gap-14 mb-11 mt-5'>
                <button type='submit'>
                  <img src={mail} alt='image no present' className='w-[40px] h-[40px]'></img>
                </button>
              </div> */}
              <div
                className='w-full flex flex-col items-center gap-6 mb-6 mt-5'
                style={{ marginTop: '80px' }}>
                <div className='flex w-[90%] items-center justify-between'>
                  <button
                    onClick={() => {
                      history.push('/create_tool1.3')
                    }}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button
                    type='submit'
                    onClick={event => {
                      if (toolDataArr.length !== 3) {
                        alert('Select exactly 3 ideas')
                        event.preventDefault()
                      } else {
                        saveData()
                        return history.push('/create_tool1.5')
                      }
                    }}>
                    <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <div>
                  <p className='ex_solve_para mb-3'>Email my final 10 ideas.</p>
                  <button
                    type='submit'
                    onClick={() => {
                      localStorage.setItem('create2', JSON.stringify(textInput1_4))
                      return history.push('/createemail2')
                    }}>
                    <img src={mail} alt='image no present' className='w-[40px] h-[40px]' />
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
