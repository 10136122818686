import { AppConstants } from '../appConstanst'

let controller
let signal
const getToken = () => {
  var auth = localStorage.getItem('auth')
  const token = auth && JSON.parse(auth).token
  return token
}
export const saveScoring = (body, token) => {
  if (controller) {
    controller.abort()
  }
  controller = new AbortController()
  signal = controller.signal
  return fetch(`${AppConstants.BASE_URL}/app/v2/course`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
    signal,
  }).catch(err => {
    console.log(err)
  })
}
