import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
// admin components
import admin_signin from './adminPages/admin_signin'
import AdminDashboard from './adminPages/AdminDashboard'
import EditUsers from './adminPages/EditUsers'
import AddUsers from './adminPages/AddUsers'
import EditUser from './adminPages/EditUser'
import EmailUser from './adminPages/email_user'
import view_users from './adminPages/view_users'
import DeleteUser from './adminPages/DeleteUser'
import privacy_policy from './adminPages/privacy_policy'
import UserSignUp from './userPages/user_signup'
import user_signin from './userPages/user_signin'
import UserDashboard from './userPages/user_dashboard'
import UserDashboardTrack1 from './userPages/user_dashboard_track1'
import Assess from './userPages/assess'
import GetFit from './userPages/get_fit'
import Exercise from './userPages/exercise'
import UserSolveWorkout from './userPages/user_solve_workout'
import { Solve1_1 } from './userPages/Solve1_1'
import { Solve1_2 } from './userPages/Solve1_2'
import { Solve1_0 } from './userPages/Solve1_0'
import { Solve2_0 } from './userPages/Solve2_0'
import UserAdaptWorkout from './userPages/user_adapt_workout'
import UserCommunicateWorkout from './userPages/user_communicate_workouts'
import UserInspireWorkout from './userPages/user_inspire_workout'
import UserCreateWorkout from './userPages/user_create_workout'
import UserCollaborateWorkout from './userPages/user_collaborate_workout'
import UserAnalyzeWorkout from './userPages/user_analyze_workouts'
import UserAchieveWorkout from './userPages/user_achieve_workouts'
import UserStrategizeWorkout from './userPages/user_strategize_workout'
import UserAccountEdit from './userPages/user_account_edit'
import user_logout from './userPages/user_logout'
import UserPersonalInfoSuccess from './userPages/user_personal_info_suc'
import CriticalThinking from './userPages/critical_thinking1.2'
import CriticalThinking32 from './userPages/critical_thinking3.2'
import CriticalThinking33 from './userPages/critical_thinking3.3'
import CriticalThinking22 from './userPages/critical_thinking2.2'
import CriticalThinking11 from './userPages/critical_thinking1.1'
import CriticalThinking21 from './userPages/critical_thinking2.1'
import CriticalThinking31 from './userPages/CriticalThinking3.1'
import CriticalThinking34 from './userPages/critical_thinking3.4'
import CriticalThinking35 from './userPages/critical_thinking3.5'
import useApp from './context/useApp'
import ReactGA from 'react-ga4'
import ExerciseSolve from './userPages/Exercise1.3'
import ExerciseSolves from './userPages/Exercise1.4'
import ExerciseSolves_1_5 from './userPages/Exercise1.5'
import ExerciseSolvess from './userPages/Exercise1.6'
import ExerciseSolves_1_7 from './userPages/Excercise1.7'
import ExerciseSolve_1_8 from './userPages/Exercise1.8'
import ExerciseSolves_1_9 from './userPages/Exercise1.9'
import { EmotionalIntelligence } from './userPages/EQ1.1'
import { EmotionalIntelligence1_2 } from './userPages/EQ1.2'
import { EmotionalIntelligence2_1 } from './userPages/EQ2.1'
import { EmotionalIntelligence2_2 } from './userPages/EQ2.2'
import { EmotionalIntelligence3_1 } from './userPages/EQ3.1'
import { EmotionalIntelligence3_2 } from './userPages/EQ3.2'
import { EmotionalIntelligence3_3 } from './userPages/EQ3.3'
import { EmotionalIntelligence3_4 } from './userPages/EQ3.4'
import { EmotionalIntelligence3_5 } from './userPages/EQ3.5'
import { CogFlex1_1 } from './userPages/CogFlex1.1'
import { CogFlex_1_2 } from './userPages/CogFlex1.2'
import { CogFlex1_3 } from './userPages/CogFlex1.3'
import { CogFlex1_4 } from './userPages/CogFlex1.4'
import { CogFlex1_5 } from './userPages/CogFlex1.5'
import { CogFlex1_6 } from './userPages/CogFlex1.6'
import { CogFlex1_7 } from './userPages/CogFlex1.7'
import { CogFlex1_8 } from './userPages/CogFlex1.8'
import { CogFlex1_9 } from './userPages/CogFlex1.9'
import { Persuasion1_1 } from './userPages/Persuasion1.1'
import { Persuasion1_3 } from './userPages/Persuasion1.3'
import { Persuasion1_5 } from './userPages/Persuasion1.5'
import { Creative_Thinking1_1 } from './userPages/CreativeThinking1.1'
import { Creative_Thinking1_3 } from './userPages/Creative_Thinking1.3'
import { Persuasion1_2 } from './userPages/Persuasion1.2'
import { Persuasion1_4 } from './userPages/Persuasion1.4'
import { Persuasion1_6 } from './userPages/Persuasion1.6'
import { Persuasion1_7 } from './userPages/Persuasion1.7'
import { Persuasion1_8 } from './userPages/Persuasion1.8'
import { Persuasion1_9 } from './userPages/Persuasion1.9'
import { Creative_Thinking1_5 } from './userPages/Creative_Thinking1.5'
import { Creative_Thinking1_2 } from './userPages/Creative_Thinking1.2'
import { Creative_Thinking1_4 } from './userPages/Creative_Thinking1.4'
import { Creative_Thinking1_6 } from './userPages/Creative_Thinking1.6'
import { Creative_thinking1_7 } from './userPages/Creative_Thinking1.7'
import { Creative_Thinking1_8 } from './userPages/Creative_Thinking1.8'
import { Creative_Thinking1_9 } from './userPages/Creative_Thinking1.9'
import { PersonalBranding1_1 } from './userPages/PersonalBranding1.1'
import { PersonalBranding1_2 } from './userPages/personalBranding1.2'
import { PersonalBranding1_3 } from './userPages/PersonalBranding1.3'
import { PersonalBranding1_4 } from './userPages/PersonalBranding1.4'
import { PersonalBranding1_5 } from './userPages/PersonalBranding1.5'
import { PersonalBranding1_6 } from './userPages/PersonalBranding1.6'
import { PersonalBranding1_7 } from './userPages/PersonalBranding1.7'
import { PersonalBranding1_8 } from './userPages/PersonalBranding1.8'
import { PersonalBranding1_9 } from './userPages/PersonalBranding1.9'
import { Hyper1_1 } from './userPages/HyperFocus1.1'
import { Hyperfocus1_2 } from './userPages/HyperFocus1.2'
import { Hyper1_3 } from './userPages/HyperFocus1.3'
import { Hyperfocus1_4 } from './userPages/HyperFocus1.4'
import { Hyper1_5 } from './userPages/HyperFocus1.5'
import { Hyper1_6 } from './userPages/HyperFocus1.6'
import { Hyper1_7 } from './userPages/HyperFocus1.7'
import { Hyper1_8 } from './userPages/HyperFocus1.8'
import { Hyper1_9 } from './userPages/HyperFocus1.9'
import { Landing } from './userPages/FrontPage'
import AdminClientDash from './adminPages/Admin_Client'
import AddClient from './adminPages/AddClient'
import { AddSuccess } from './adminPages/AddSuccess'
import EditClient from './adminPages/EditClient'
import { EditSuccess } from './adminPages/EditSuccess'
import ClientDashboard from './adminPages/ClientDashboard'
import { CollabrateTool1_0 } from './userPages/Collabrate_tool1.0'
import { CollabrateTool1_1 } from './userPages/Collabrate_tool1.1'
import { CollabrateTool1_2 } from './userPages/Collabrate_tool1.2'
import { CollabrateTool1_3 } from './userPages/Collabrate_tool1.3'
import { CreateTool1_0 } from './userPages/Create_tool1.0'
import { Create_tool1_1 } from './userPages/Create_tool1.1'
import { Create_tool1_2 } from './userPages/Create_tool1.2'
import { CollabrateTool1_4 } from './userPages/Collabrate_tool1.4'
import { Collabrate_tool1_5 } from './userPages/Collabrate_tool1.5'
import { Collabrate_tool1_6 } from './userPages/Collabrate_tool1.6'
import { Collabrate_tool1_7 } from './userPages/Collabrate_tool1.7'
import { Create_tool2_0 } from './userPages/Create_tool2.0'
import { Create_tool1_8 } from './userPages/Create_tool1.8'
import { Create_tool1_3 } from './userPages/Create_tool1.3'
import { Create_tool1_4 } from './userPages/Create_tool1.4'
import { Create_tool1_5 } from './userPages/CreateTool1.5'
import CreateTool1_9 from './userPages/CreateTool1.9'
import { A_Critical_Thinking_0 } from './userPages/Asses/Critical_Thinking1.0'
import A_Critical_Thinking_1_1 from './userPages/Asses/Critical_Thinking1.1'
import A_Critical_Thinking_1_2 from './userPages/Asses/Critical_Thinking1.2'
import A_Critical_Thinking_1_3 from './userPages/Asses/Critical_Thinking1.3'
import A_Critical_Thinking_1_4 from './userPages/Asses/Critical_Thinking1.4'
import A_Critical_Thinking_1_5 from './userPages/Asses/Critical_Thinking1.5'
import A_Critical_Thinking_1_6 from './userPages/Asses/Critical_thinking1.6'
import A_Critical_Thinking_1_7 from './userPages/Asses/Critical_Thinking1.7'
import A_Critical_Thinking_1_8 from './userPages/Asses/Critical_Thinking1.8'
import A_Critical_Thinking_1_9 from './userPages/Asses/Critical_Thinking1.9'
import A_Critical_Thinking_2_0 from './userPages/Asses/Critical_Thinking2.0'
import A_Critical_Thinking_2_1 from './userPages/Asses/Critical_Thinking2.1'
import { A_Creative_Thinking_0 } from './userPages/Asses/Creative_Thinking1.0'
import A_Creative_Thinking_1_1 from './userPages/Asses/Creative_Thinking1.1'
import A_Creative_Thinking_1_2 from './userPages/Asses/Creative_Thinking1.2'
import A_Creative_Thinking_1_3 from './userPages/Asses/Creative_Thinking1.3'
import A_Creative_Thinking_1_4 from './userPages/Asses/Creative_Thinking1.4'
import A_Creative_Thinking_1_5 from './userPages/Asses/Creative_Thinking1.5'
import A_Creative_Thinking_1_6 from './userPages/Asses/Creative_Thinking1.6'
import A_Creative_Thinking_1_7 from './userPages/Asses/Creative_Thinking1.7'
import A_Creative_Thinking_1_8 from './userPages/Asses/Creative_Thinking1.8'
import A_Creative_Thinking_1_9 from './userPages/Asses/Creative_Thinking1.9'
import A_Creative_Thinking_2_0 from './userPages/Asses/Creative_Thinking2.0'
import A_Creative_Thinking_2_1 from './userPages/Asses/Creative_Thinking2.1'
import { A_EQ_0 } from './userPages/Asses/EQ1.0'
import A_EQ_1_1 from './userPages/Asses/EQ1.1'
import A_EQ_1_2 from './userPages/Asses/EQ1.2'
import A_EQ_1_3 from './userPages/Asses/EQ1.3'
import A_EQ_1_4 from './userPages/Asses/EQ1.4'
import A_EQ_1_6 from './userPages/Asses/EQ1.6'

import A_EQ_1_5 from './userPages/Asses/EQ1.5'
import A_EQ_1_7 from './userPages/Asses/EQ1.7'
import A_EQ_1_8 from './userPages/Asses/EQ1.8'
import A_EQ_1_9 from './userPages/Asses/EQ1.9'
import A_EQ_2_0 from './userPages/Asses/EQ2.0'
import A_EQ_2_1 from './userPages/Asses/EQ2.1'
import ForgotPassword from './adminPages/Forget_Password'
import ResetPassowrd from './adminPages/Reset_Password'
import { AppConstants } from './appConstanst'
import { EmailSuccess } from './adminPages/EmailSuccess'
import MailSendScreen from './userPages/MailSendScreen'
import CollMilSend from './userPages/CollMilSend'
import CreateMailScreen from './userPages/CreateMailScreen'
import CreateMail2 from './userPages/CreateMail2'
import ColleborateMailsend1 from './userPages/ColleborateMailsend1'
import CollaborateSend2 from './userPages/CollaborateSend2'
import CreateMailIdeas from './userPages/createMailIdeas'
const MEASUREMENT_ID = AppConstants.MEASUREMENT_ID

function AuthMissing() {
  return (
    <>
      <Switch>
        <Route path='/admin_signin' component={admin_signin} />
        <Route path='/user_signin' component={user_signin} />
        <Route path='/corporate_signup' component={UserSignUp} />
        <Route path='/' component={Landing} />
      </Switch>
      {/* <Redirect to='/' /> */}
    </>
  )
}
export const App = () => {
  const { state } = useApp()
  const history = useHistory()
  if (state?.token) {
    ReactGA._appendCustomMap({ dimension1: 'custom_user_property' }, { dimension2: 'group_code' })

    ReactGA.initialize(MEASUREMENT_ID, {
      gaOptions: { debug_mode: true },
      gtagOptions: {
        debug_mode: true,
      },
    })
    ReactGA.send('pageview')

    ReactGA.set({ user_id: state?.user?.username })
    ReactGA.set({
      user_properties: {
        group_code: state?.clientGCode?.g_code || 'asd', // change asd to group_code
      },
    })
  }

  if (window.location.pathname !== '/critical_thinking1.1' && localStorage.getItem('Pbarvalue')) {
    localStorage.removeItem('Pbarvalue')
  }
  if (window.location.pathname !== '/critical_thinking2.1' && localStorage.getItem('Pbarvalue2')) {
    localStorage.removeItem('Pbarvalue2')
  }
  if (window.location.pathname !== '/critical_thinking3.1' && localStorage.getItem('Pbarvalue3')) {
    localStorage.removeItem('Pbarvalue3')
  }
  if (
    window.location.pathname !== '/emotional_intelligence1.1' &&
    localStorage.getItem('Pbarvalue4')
  ) {
    localStorage.removeItem('Pbarvalue4')
  }
  if (
    window.location.pathname !== '/emotional_intelligence2.1' &&
    localStorage.getItem('Pbarvalue5')
  ) {
    localStorage.removeItem('Pbarvalue5')
  }
  if (
    window.location.pathname !== '/emotional_intelligence3.1' &&
    localStorage.getItem('Pbarvalue6')
  ) {
    localStorage.removeItem('Pbarvalue6')
  }
  if (window.location.pathname !== '/cog_flex_1.1' && localStorage.getItem('Pbarvalue7')) {
    localStorage.removeItem('Pbarvalue7')
  }
  if (window.location.pathname !== '/cog_flex_1.3' && localStorage.getItem('Pbarvalue8')) {
    localStorage.removeItem('Pbarvalue8')
  }
  if (window.location.pathname !== '/cog_flex_1.5' && localStorage.getItem('Pbarvalue9')) {
    localStorage.removeItem('Pbarvalue9')
  }
  if (window.location.pathname !== '/persuasion1.1' && localStorage.getItem('Pbarvalue10')) {
    localStorage.removeItem('Pbarvalue10')
  }

  if (window.location.pathname !== '/persuasion1.3' && localStorage.getItem('Pbarvalue11')) {
    localStorage.removeItem('Pbarvalue11')
  }
  if (window.location.pathname !== '/persuasion1.5' && localStorage.getItem('Pbarvalue12')) {
    localStorage.removeItem('Pbarvalue12')
  }
  if (window.location.pathname !== '/creative_thinking1.1' && localStorage.getItem('Pbarvalue13')) {
    localStorage.removeItem('Pbarvalue13')
  }
  if (window.location.pathname !== '/creative_thinking1.3' && localStorage.getItem('Pbarvalue14')) {
    localStorage.removeItem('Pbarvalue14')
  }
  if (window.location.pathname !== '/creative_thinking1.5' && localStorage.getItem('Pbarvalue15')) {
    localStorage.removeItem('Pbarvalue15')
  }
  if (window.location.pathname !== '/personalbranding1.1' && localStorage.getItem('Pbarvalue16')) {
    localStorage.removeItem('Pbarvalue16')
  }
  if (window.location.pathname !== '/personalbranding1.3' && localStorage.getItem('Pbarvalue17')) {
    localStorage.removeItem('Pbarvalue17')
  }
  if (window.location.pathname !== '/personalbranding1.5' && localStorage.getItem('Pbarvalue18')) {
    localStorage.removeItem('Pbarvalue18')
  }
  if (window.location.pathname !== '/hyper_focus1.1' && localStorage.getItem('Pbarvalue19')) {
    localStorage.removeItem('Pbarvalue19')
  }
  if (window.location.pathname !== '/hyper_focus1.3' && localStorage.getItem('Pbarvalue20')) {
    localStorage.removeItem('Pbarvalue20')
  }
  if (window.location.pathname !== '/hyper_focus1.5' && localStorage.getItem('Pbarvalue21')) {
    localStorage.removeItem('Pbarvalue21')
  }

  return (
    <>
      <Switch>
        <Route path='/admin_signin' component={admin_signin} />
        <Route path='/privacy_policy' component={privacy_policy} />
        <Route path='/success' component={EmailSuccess} />
        <Route path='/forget-password' component={ForgotPassword} />
        <Route path='/reset-password/:id' component={ResetPassowrd} />
        <Route path='/corporate_signup' component={UserSignUp} />
        <Route path='/' component={Landing} />
      </Switch>
      {!state.token ? (
        <>
          <AuthMissing />
        </>
      ) : state.user.is_admin ? (
        <>
          <Switch>
            <Route path='/admin-dashboard' component={AdminDashboard} />
            <Route path='/admin-edit-users' component={EditUsers} />
            <Route path='/admin-add-users' component={AddUsers} />
            <Route path='/admin_view_users' component={view_users} />
            <Route path='/admin-email_user' component={EmailUser} />
            <Route path='/admin-edit-user' component={EditUser} />
            <Route path='/admin-delete-user' component={DeleteUser} />
            <Route path='/admin-client' component={AdminClientDash} />
            <Route path='/add-client' component={AddClient} />
            <Route path='/add-success' component={AddSuccess} />
            <Route path='/edit-client' component={EditClient} />
            <Route path='/edit-success' component={EditSuccess} />
          </Switch>
        </>
      ) : state.client ? (
        <>
          <Route path='/client-dashboard' component={ClientDashboard} />
          <Route exact path='/logout' component={user_logout} />
        </>
      ) : (
        <>
          <Switch>
            <Route path='/user_dashboard' component={UserDashboard} />
            <Route path='/user_dashboard_track1' component={UserDashboardTrack1} />
            <Route path='/assess' component={Assess} />
            <Route path='/get_fit' component={GetFit} />
            <Route path='/exercise' component={Exercise} />
            <Route path='/exercise_solve_1.7' component={ExerciseSolves_1_7} />
            <Route path='/exercise_solve_1.3' component={ExerciseSolve} />
            <Route path='/exercise_solve_1.4' component={ExerciseSolves} />
            <Route path='/exercise_solve_1.5' component={ExerciseSolves_1_5} />
            <Route path='/exercise_solve_1.6' component={ExerciseSolvess} />
            <Route path='/exercise_solve_1.8' component={ExerciseSolve_1_8} />
            <Route path='/exercise_solve_1.9' component={ExerciseSolves_1_9} />
            <Route path='/user_track' component={UserDashboardTrack1} />
            <Route exact path='/logout' component={user_logout} />
            <Route path='/user_solve_workout' component={UserSolveWorkout} />
            <Route path='/user_adapt' component={UserAdaptWorkout} />
            <Route path='/user_communicate_workout' component={UserCommunicateWorkout} />
            <Route path='/user_inspire_workout' component={UserInspireWorkout} />
            <Route path='/user_create_workout' component={UserCreateWorkout} />
            <Route path='/user_collaborate_workout' component={UserCollaborateWorkout} />
            <Route path='/user_analyze_workout' component={UserAnalyzeWorkout} />
            <Route path='/user_achieve_workout' component={UserAchieveWorkout} />
            <Route path='/user_strategize_workout' component={UserStrategizeWorkout} />
            <Route path='/user_account_edit' component={UserAccountEdit} />
            <Route path='/user_personal_info_suc' component={UserPersonalInfoSuccess} />
            <Route path='/critical_thinking1.2' component={CriticalThinking} />
            <Route path='/critical_thinking3.2' component={CriticalThinking32} />
            <Route path='/critical_thinking2.2' component={CriticalThinking22} />
            <Route path='/critical_thinking3.3' component={CriticalThinking33} />
            <Route path='/critical_thinking1.1' component={CriticalThinking11} />
            <Route path='/critical_thinking2.1' component={CriticalThinking21} />
            <Route path='/critical_thinking3.1' component={CriticalThinking31} />
            <Route path='/critical_thinking3.4' component={CriticalThinking34} />
            <Route path='/critical_thinking3.5' component={CriticalThinking35} />
            <Route path='/hyper_focus1.1' component={Hyper1_1} />
            <Route path='/hyper_focus1.2' component={Hyperfocus1_2} />
            <Route path='/hyper_focus1.3' component={Hyper1_3} />
            <Route path='/hyper_focus1.4' component={Hyperfocus1_4} />
            <Route path='/hyper_focus1.5' component={Hyper1_5} />
            <Route path='/hyper_focus1.6' component={Hyper1_6} />
            <Route path='/hyper_focus1.7' component={Hyper1_7} />
            <Route path='/hyper_focus1.8' component={Hyper1_8} />
            <Route path='/hyper_focus1.9' component={Hyper1_9} />
            <Route path='/solve1.0' component={Solve1_0} />
            <Route path='/solve1.1' component={Solve1_1} />
            <Route path='/solve1.2' component={Solve1_2} />
            <Route path='/solve2.0' component={Solve2_0} />
            <Route path='/emotional_intelligence1.1' component={EmotionalIntelligence} />
            <Route path='/emotional_intelligence1.2' component={EmotionalIntelligence1_2} />
            <Route path='/emotional_intelligence2.1' component={EmotionalIntelligence2_1} />
            <Route path='/emotional_intelligence2.2' component={EmotionalIntelligence2_2} />
            <Route path='/emotional_intelligence3.1' component={EmotionalIntelligence3_1} />
            <Route path='/emotional_intelligence3.2' component={EmotionalIntelligence3_2} />
            <Route path='/emotional_intelligence3.3' component={EmotionalIntelligence3_3} />
            <Route path='/emotional_intelligence3.4' component={EmotionalIntelligence3_4} />
            <Route path='/emotional_intelligence3.5' component={EmotionalIntelligence3_5} />
            <Route path='/cog_flex_1.1' component={CogFlex1_1} />
            <Route path='/cog_flex_1.2' component={CogFlex_1_2} />
            <Route path='/cog_flex_1.3' component={CogFlex1_3} />
            <Route path='/cog_flex_1.4' component={CogFlex1_4} />
            <Route path='/cog_flex_1.5' component={CogFlex1_5} />
            <Route path='/cog_flex_1.6' component={CogFlex1_6} />
            <Route path='/cog_flex_1.7' component={CogFlex1_7} />
            <Route path='/cog_flex_1.8' component={CogFlex1_8} />
            <Route path='/cog_flex_1.9' component={CogFlex1_9} />
            <Route path='/persuasion1.1' component={Persuasion1_1} />
            <Route path='/persuasion1.2' component={Persuasion1_2} />
            <Route path='/persuasion1.3' component={Persuasion1_3} />
            <Route path='/persuasion1.4' component={Persuasion1_4} />
            <Route path='/persuasion1.5' component={Persuasion1_5} />
            <Route path='/persuasion1.6' component={Persuasion1_6} />
            <Route path='/persuasion1.7' component={Persuasion1_7} />
            <Route path='/persuasion1.8' component={Persuasion1_8} />
            <Route path='/persuasion1.9' component={Persuasion1_9} />
            <Route path='/personalbranding1.1' component={PersonalBranding1_1} />
            <Route path='/personalbranding1.2' component={PersonalBranding1_2} />
            <Route path='/personalbranding1.3' component={PersonalBranding1_3} />
            <Route path='/personalbranding1.4' component={PersonalBranding1_4} />
            <Route path='/personalbranding1.5' component={PersonalBranding1_5} />
            <Route path='/personalbranding1.6' component={PersonalBranding1_6} />
            <Route path='/personalbranding1.7' component={PersonalBranding1_7} />
            <Route path='/personalBranding1.2' component={PersonalBranding1_2} />
            <Route path='/personalbranding1.8' component={PersonalBranding1_8} />
            <Route path='/personalbranding1.9' component={PersonalBranding1_9} />
            <Route path='/creative_thinking1.1' component={Creative_Thinking1_1} />
            <Route path='/creative_thinking1.2' component={Creative_Thinking1_2} />
            <Route path='/creative_thinking1.3' component={Creative_Thinking1_3} />
            <Route path='/creative_thinking1.4' component={Creative_Thinking1_4} />
            <Route path='/creative_thinking1.5' component={Creative_Thinking1_5} />
            <Route path='/creative_thinking1.6' component={Creative_Thinking1_6} />
            <Route path='/creative_thinking1.7' component={Creative_thinking1_7} />
            <Route path='/creative_thinking1.8' component={Creative_Thinking1_8} />
            <Route path='/creative_thinking1.9' component={Creative_Thinking1_9} />
            <Route path='/collabrate_tool1.0' component={CollabrateTool1_0} />
            <Route path='/collabratemail1' component={ColleborateMailsend1} />
            <Route path='/collabrate_tool1.1' component={CollabrateTool1_1} />
            <Route path='/collabrate_tool1.2' component={CollabrateTool1_2} />
            <Route path='/collabrate_tool1.3' component={CollabrateTool1_3} />
            <Route path='/collabrate_tool1.4' component={CollabrateTool1_4} />
            <Route path='/collabrate_tool1.5' component={Collabrate_tool1_5} />
            <Route path='/collabrate_tool1.6' component={Collabrate_tool1_6} />
            <Route path='/collabrate_tool1.7' component={Collabrate_tool1_7} />
            <Route path='/create_tool1.0' component={CreateTool1_0} />
            <Route path='/create_tool1.1' component={Create_tool1_1} />
            <Route path='/create_tool1.2' component={Create_tool1_2} />
            <Route path='/colmailSend' component={CollMilSend} />
            <Route path='/createemail2' component={CreateMail2} />
            <Route path='/createmail' component={CreateMailScreen} />
            <Route path='/create_tool1.3' component={Create_tool1_3} />
            <Route path='/createMailIdeas' component={CreateMailIdeas} />
            <Route path='/create_tool1.4' component={Create_tool1_4} />
            <Route path='/create_tool1.5' component={Create_tool1_5} />
            <Route path='/create_tool1.8' component={Create_tool1_8} />
            <Route path='/create_tool1.9' component={CreateTool1_9} />
            <Route path='/collaborate2' component={CollaborateSend2} />
            <Route path='/create_tool2.0' component={Create_tool2_0} />
            <Route path='/assess_critical_thinking_0' component={A_Critical_Thinking_0} />
            <Route path='/assess_critical_thinking_1' component={A_Critical_Thinking_1_1} />
            <Route path='/assess_critical_thinking_2' component={A_Critical_Thinking_1_2} />
            <Route path='/assess_critical_thinking_3' component={A_Critical_Thinking_1_3} />
            <Route path='/assess_critical_thinking_4' component={A_Critical_Thinking_1_4} />
            <Route path='/assess_critical_thinking_5' component={A_Critical_Thinking_1_5} />
            <Route path='/assess_critical_thinking_6' component={A_Critical_Thinking_1_6} />
            <Route path='/assess_critical_thinking_7' component={A_Critical_Thinking_1_7} />
            <Route path='/assess_critical_thinking_8' component={A_Critical_Thinking_1_8} />
            <Route path='/assess_critical_thinking_9' component={A_Critical_Thinking_1_9} />
            <Route path='/assess_critical_thinking_2_0' component={A_Critical_Thinking_2_0} />
            <Route path='/assess_critical_thinking_2_1' component={A_Critical_Thinking_2_1} />
            <Route path='/assess_creative_thinking_0' component={A_Creative_Thinking_0} />
            <Route path='/assess_creative_thinking_1' component={A_Creative_Thinking_1_1} />
            <Route path='/assess_creative_thinking_2' component={A_Creative_Thinking_1_2} />
            <Route path='/assess_creative_thinking_3' component={A_Creative_Thinking_1_3} />
            <Route path='/assess_creative_thinking_4' component={A_Creative_Thinking_1_4} />
            <Route path='/assess_creative_thinking_5' component={A_Creative_Thinking_1_5} />
            <Route path='/assess_creative_thinking_6' component={A_Creative_Thinking_1_6} />
            <Route path='/assess_creative_thinking_7' component={A_Creative_Thinking_1_7} />
            <Route path='/assess_creative_thinking_8' component={A_Creative_Thinking_1_8} />
            <Route path='/assess_creative_thinking_9' component={A_Creative_Thinking_1_9} />
            <Route path='/assess_creative_thinking_2_0' component={A_Creative_Thinking_2_0} />
            <Route path='/assess_creative_thinking_2_1' component={A_Creative_Thinking_2_1} />
            <Route path='/mailsend' component={MailSendScreen} />
            <Route path='/assess_eq_0' component={A_EQ_0} />
            <Route path='/assess_eq_1' component={A_EQ_1_1} />
            <Route path='/assess_eq_2' component={A_EQ_1_2} />
            <Route path='/assess_eq_3' component={A_EQ_1_3} />
            <Route path='/assess_eq_4' component={A_EQ_1_4} />
            <Route path='/assess_eq_5' component={A_EQ_1_5} />
            <Route path='/assess_eq_6' component={A_EQ_1_6} />
            <Route path='/assess_eq_7' component={A_EQ_1_7} />
            <Route path='/assess_eq_8' component={A_EQ_1_8} />
            <Route path='/assess_eq_9' component={A_EQ_1_9} />
            <Route path='/assess_eq_2_0' component={A_EQ_2_0} />
            <Route path='/assess_eq_2_1' component={A_EQ_2_1} />
          </Switch>
        </>
      )}
    </>
  )
}
