import Inspire from '../assets/images/Inspire.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress, saveScoring } from '../services/user'
import { AddCourses } from './Asses/Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export const PersonalBranding1_9 = () => {
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.personalBranding.cg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        cg: state?.user?.progress?.course?.personalBranding?.cg,
        kg: state?.user?.progress?.course?.personalBranding?.kg,
        assessment: AddCourses(state?.user?.progress?.course?.personalBranding?.assesment, 3),
        complete: true,
      }),
    )
    try {
      let data = await saveProgress(
        uri,
        JSON.stringify(stateNew.user.progress.course),
        state?.token,
      )
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      return history.push('/user_dashboard')
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickTwo = async uri => {
    var stateNew = { ...state }
    stateNew.user.progress.course.personalBranding.cg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        cg: state?.user?.progress?.course?.personalBranding?.cg,
        kg: state?.user?.progress?.course?.personalBranding?.kg,
        assessment: AddCourses(state?.user?.progress?.course?.personalBranding?.assesment, 3),
        complete: true,
      }),
    )
    return history.push('/user_dashboard')
  }

  return (
    <CommonConfirmation
      logo={Inspire}
      title={'INSPIRE'}
      trainingTitle={'PERSONAL BRANDING'}
      question={'Do you feel more confident to build your own brand?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
