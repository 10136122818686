import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo6 from '../assets/images/group-4544-1@1x.png'
import logo7 from '../assets/images/group-4482-1@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logoadapt from '../assets/images/group-4515@1x.png'
import logocmn from '../assets/images/group-4484@1x.png'
import logoinp from '../assets/images/group-4519@1x.png'
import logocreate from '../assets/images/group-4513@1x.png'
import logocollaborate from '../assets/images/group-4507@1x.png'
import logoanalyze from '../assets/images/group-4511@1x.png'
import logoachieve from '../assets/images/group-4517@1x.png'
import logostratz from '../assets/images/group-4509@1x.png'
import whtcrc from '../assets/images/path-15712@1x.png'

import { useHistory } from 'react-router-dom'

export const GetFitCard = props => {
  return (
    <div
      className='flex flex-row pt-2 pb-2 mt-3 '
      style={{ backgroundColor: '#ffffff', height: '80px', opacity: '.9' }}
      onClick={props.onClick}>
      <div
        className=' flex flex-col justify-around items-center    responsive-card'
        style={{ paddingLeft: '18px', paddingRight: '15px', width: '20%' }}>
        <img className='h-[41.23px] w-[31.91px]' alt='logo' src={props.img} />
        <h4
          className='_bold items-center leading-none '
          style={{ color: '#e28d3d', fontSize: '10px', display: 'flex', flexDirection: 'column' }}>
          30 min
        </h4>
      </div>
      <div style={{ width: '80%' }}>
        <div className=' text-sm mt-2 _bold' style={{ color: '#e28d3d', lineHeight: '14px' }}>
          {props.title}
        </div>
        <div
          className=' _regular  text-left leading-[14px] mt-1 pr-3 '
          style={{ color: '#e28d3d', fontSize: '11px' }}>
          {props.description}
        </div>
      </div>
    </div>
  )
}

export default function GetFit() {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()

  let arr = [
    {
      name: 'ANALYZE',
      description:
        'Our world is filled with information. Learn how to analyze data more efficiently and effectively.',
      logo: logoanalyze,
    },

    {
      name: 'STRATEGIZE',
      description:
        'Every goal and KPI needs an effective strategy. Explore how to strategize for better results.',
      logo: logostratz,
    },
  ]

  return (
    <div className='user_solve_cover w-full min-h-screen overflow-y-scroll '>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo3} />
            <img className='top-5 left-5 h-1   absolute' alt='logo' src={logo4} />
            <img className=' h-1 top-7 left-5 absolute' alt='logo' src={logo4} />
            <img className='top-9 left-5 h-1 absolute' alt='logo' src={logo4} />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo3} />
            <img className='h-3 left-6  top-6 absolute' alt='logo' src={logo5} />
          </div>
        )}
      </div>
      {!open && (
        <div>
          <div className='flex justify-center mt-10'>
            <img alt='logo' src={logo6} style={{ height: '62.43px', width: '66.48px' }} />
          </div>
          <h4
            className='text-center text-2xl pt-4 pb-[18px] _bold leading-6'
            style={{ color: '#FFFFFF' }}>
            GET FIT
          </h4>
          <GetFitCard
            onClick={() => history.push('/user_collaborate_workout')}
            img={logocollaborate}
            title='COLLABORATE'
            description='Explore the skills that will help you build better relationships for more effective collaboration.'
          />
          <GetFitCard
            onClick={() => history.push('/user_create_workout')}
            img={logocreate}
            title='CREATE'
            description='New problems require new thinking. Learn the skills that will help you be more creative.'
          />
          <GetFitCard
            onClick={() => history.push('/user_solve_workout')}
            img={logo7}
            title='SOLVE'
            description='Discover the most in-demand skill in the world and how you can solve problems more effectively.'
          />
          <GetFitCard
            onClick={() => history.push('/user_adapt')}
            img={logoadapt}
            title='ADAPT'
            description='Learn the skills that will help you adapt to change quicker and move between subjects with ease.'
          />
          <GetFitCard
            onClick={() => history.push('/user_achieve_workout')}
            img={logoachieve}
            title='ACHIEVE'
            description='Discover the skills that will help you achieve more with a higher quality of output.'
          />

          <GetFitCard
            onClick={() => history.push('/user_inspire_workout')}
            img={logoinp}
            title='INSPIRE'
            description='Master the skills that will help you be more inspirational in every aspect of your life.'
          />
          <GetFitCard
            onClick={() => history.push('/user_communicate_workout')}
            img={logocmn}
            title='COMMUNICATE'
            description='Good communication is foundational to success. Discover the skills that will help you excel.'
          />

          <div
            className='flex justify-center _bold text-lg text-white mt-12'
            style={{ lineHeight: '18px' }}>
            COMING SOON
          </div>
          <div
            className='text-sm text-white px-[35px] text-center mt-5 mb-[32px]'
            style={{ lineHeight: '18px' }}>
            Get Business Fit with more of the world’s most in-demand skills which will be available
            shortly.
          </div>

          {arr.map((item, idx) => {
            return (
              <div
                key={idx}
                className='flex flex-row pt-2 mt-3.5'
                style={{ backgroundColor: '#ffffff', opacity: '80%' }}>
                <div className='w-1/4  flex flex-col pb-2 mx-3 justify-around items-center responsive-card'>
                  <img className='h-11 w-8' alt='logo' src={item?.logo} />
                  <h4
                    className=' font-bold _bold mt-[5px]'
                    style={{ color: '#e28d3d', fontSize: '10px' }}>
                    30 min
                  </h4>
                </div>
                <div className='flex flex-col'>
                  <h4
                    className=' text-sm mt-2 _bold'
                    style={{ color: '#e28d3d', lineHeight: '14px' }}>
                    {item?.name}
                  </h4>
                  <h4
                    className='pr-5 _regular pb-2 text-left leading-4 mt-1'
                    style={{ color: '#e28d3d', fontSize: '11px' }}>
                    {item?.description}
                  </h4>
                </div>
              </div>
            )
          })}

          <div className='flex justify-center mt-[49px] mb-[68px] text-xl _medium text-color mr-3'>
            <div>
              <img src={whtcrc} className='absolute h-[39px] w-[39px' alt='logo' />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#3c0774'
                width='23'
                height='23'
                viewBox='0 0 576 512'
                className='relative left-2'
                style={{ top: '6px' }}
                onClick={() => {
                  history.push('/user_dashboard')
                }}>
                <path d='M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z' />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
