import React from 'react'
import rightCheck from '../../assets/images/whitetick.svg'

export const Text = props => {
  return (
    <div className='flex flex-col mx-1 justify-center text-center'>
      <div className='mx-2'>
        <img className='' src={rightCheck} />
      </div>
      <div className='_semiBold _font_color_white font_9'>{props.text}</div>
    </div>
  )
}

export const Coll_Text = props => {
  return (
    <div className='flex flex-row my-2 text-center'>
      <div className='mr-3'>
        <div
          className='h-10 w-10 rounded-full bg_sky grid place-items-center'
          style={{ border: '1px solid #fff' }}></div>
      </div>
      <div className='_semiBold _font_color_white font_9'>{props.text}</div>
    </div>
  )
}

export const AddScore = function (state, c) {
  var sum = 0
  const st = Object.entries(state)
  let count = 0
  st.forEach(data => {
    if (count < c) {
      if (data[0] != 'check1' && data[0] != 'check2') {
        sum += (data[1] / 10) * 25
        count++
      }
    }
  })
  var res = sum
  return res
}

export const AddCourses = function (state, c) {
  var sum = 0
  const st = state
  let count = 0
  for (let val of Object.values(st)) {
    if (count < c) {
      sum += val
    }
    count++
  }
  var res = sum
  return res
}

export const UpdateAssessments = function (state, position, c) {
  state.assesment.splice(position)
  state.assesment[position] = c
}
