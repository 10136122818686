import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15743-1@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logocircle from '../assets/images/path-15712@1x.png'
import logohead from '../assets/images/group-4480-4@1x.png'
import homeIcon from '../assets/images/Component 20 – 15@2x.png'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import { useHistory } from 'react-router-dom'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import useApp from '../context/useApp'
export default function CriticalThinking22() {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.solve.cg = true
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
    return history.push('/critical_thinking3.1')
  }
  return (
    <div className=' fixed top-0 bottom-0 right-0 left-0 overflow-auto p-3 bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
         {!open ? (
          <div className=''>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className=''>
            <img className=' w-9 h-9' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>
      <div className='flex justify-center mt-9'>
        <img className='h-16 w-16' alt='logo' src={logohead} />
      </div>
      <h4
        className='text-center text-sm _bold mt-4 error_msg_color'
        style={{ letterSpacing: '2.8px', fontSize: '10px' }}>
        SOLVE
      </h4>
      <div className='px-5'>
        <h4
          className='text-center  _bold mt-7 work_out'
          style={{ letterSpacing: '2px', fontSize: '10px' }}>
          CRITICAL THINKING 2
        </h4>
        <h4 className='text-center text-2xl leading-6 _regular work_out   _bold'>Great work!</h4>
        <p
          className='text-center text-base leading-4 _regular mt-6 ml-2 work_out'
          style={{ lineHeight: '24px', color: '#43296E' }}>
          You’ve completed Critical Thinking 2 and have discovered why you need critical thinking,
          when to use it and how it compliments other skills to solve problems more effectively.
        </p>
        <p
          className='text-center text-base leading-4 _semiBold mt-16  work_out'
          style={{ lineHeight: '24px' }}>
          Are you ready to learn about using critical thinking real world?
        </p>

        <div className='flex justify-center mt-4'>
          <div className='flex flex-row justify-between mt-4'>
            <button className='Ybtn' style={{ backgroundColor: '#3C0774' }} onClick={handleClick}>
              <p className='_bold' style={{ fontSize: '10px', letterSpacing: '2px',textIndent:"2px" }}>
                YES
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className='flex justify-center text-xl _medium text-color mt-32 mb-7'>
        <button
          onClick={() => history.push('user_dashboard')}
          className='mt-1 rounded-full h-[39px] w-[39px]'
          style={{ backgroundColor: '#3C0774' }}>
          <img className=' w-[39px] h-[39px]' src={homeIcon} alt='logo' />
        </button>
      </div>
    </div>
  )
}
