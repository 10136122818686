import logohead from '../assets/images/group-4480-4@1x.png'
import { useHistory } from 'react-router-dom'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress, saveScoring } from '../services/user'
import useApp from '../context/useApp'

import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export default function CriticalThinking34() {
  const history = useHistory()
  const { dispatch, state } = useApp()
  const handleClick = async () => {
    var stateNew = { ...state }
    let uri = '/course/course'
    stateNew.user.progress.course.solve.kg = true
   
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        kg: stateNew?.user?.progress?.course?.solve.kg,
        
        
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
    return history.push('/critical_thinking3.5')
  }
  const handleClickTwo = async uri => {
    var stateNew = { ...state }
    stateNew.user.progress.course.solve.kg = false
  
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        kg: stateNew?.user?.progress?.course?.solve.kg,
        
      }),
    )

    return history.push('/critical_thinking3.5')
  }

  return (
    <CommonConfirmation
      logo={logohead}
      title={'SOLVE'}
      trainingTitle={'CRITICAL THINKING'}
      question={'Did you learn new information about critical thinking?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
