import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo6 from '../assets/images/path-15710-10@1x.png'
import logo9 from '../assets/images/group-4466-1@1x.png'
import { AppConstants } from '../appConstanst'

import { InputBox } from './reusable_code/ReusableComp'
const EditUser = () => {
  //  Previous user Data
  const [btnState, setbtnState] = useState(false)
  var userData = JSON.parse(localStorage.getItem('userInfo'))
  const Fullname = !userData ? '' : userData.name.split(' ')
  const fname = !Fullname[0] ? 'Name not Provided' : Fullname[0]
  const Lname = !Fullname[1] ? 'Name not Provided' : Fullname[1]
  const history = useHistory()
  const [responseState, setResponsestate] = useState(false)
  const [open, toggleMenu] = useState(false)
  const [firstName, setFirstName] = useState(fname)
  const [lastName, setLastName] = useState(Lname)
  const [email, setEmail] = useState(!userData ? null : userData.username)
  const [checkSubscriber, setCheckSubscriber] = useState(!userData ? null : userData.subscriber)
  const [checKBlock, setCheckBlock] = useState(false)
  const [checkDelete, setCheckDelete] = useState(false)

  const token =
    localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).token
      ? JSON.parse(localStorage.getItem('auth')).token
      : null

  const editProfile = body =>
    fetch(`${AppConstants.BASE_URL}/user/edit-profile-by-admin`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body,
    })

  const handleSubmit = async () => {
    let res = await editProfile(
      JSON.stringify({
        name: `${firstName} ${lastName}`,
        username: email,
        subscriber: checkSubscriber,
        blocked: checKBlock,
        deleted: checkDelete,
      }),
    )
    let data = res.ok ? await res.text() : null
    if (res.ok) {
      localStorage.removeItem('userInfo')
      setResponsestate(true)
    }

    if (data) {
      data = JSON.parse(data)
      if (data.success) {
        // dispatch({ type: APP_USER_AUTH_SUCCESS, value: data.data })
        if (data?.data?.user?.is_admin) {
          alert('User edit successfully')
          localStorage.removeItem('userInfo')
        }
      } else {
        const error = await res.json()
        alert(error.message)
      }
    }
  }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      <h4
        className='text-center _medium mt-10 text-2xl not-italic font-medium'
        style={{ color: 'var(--windsor)' }}>
        Edit User
      </h4>
      <InputBox
        htmlFor='#first_name'
        label='Edit User First Name'
        id='firstName'
        type='text'
        value={firstName}
        onChange={e => {
          setFirstName(e.target.value)
        }}
      />

      <InputBox
        htmlFor='#last_name'
        label='Edit User Last Name'
        id='lastName'
        type='text'
        value={lastName}
        onChange={e => {
          setLastName(e.target.value)
        }}
      />
      <div className='px-9 '>
        <div className='flex justify-end'>
          {!open && (
            <div className='absolute mt-10 '>
              <img src={logo3} className='h-10 w-10' alt='logo' />
              <img
                src={logo9}
                className='absolute h-9 w-9'
                style={{ bottom: '2px', marginLeft: '2px' }}
                alt='logo'
              />
            </div>
          )}
        </div>
      </div>
      <InputBox
        htmlFor='#email'
        label='Edit User Email'
        id='email'
        type='email'
        value={email}
        disabled
        // onChange={e => {
        //   setEmail(e.target.value)
        // }}
      />

      <div className='px-9 flex' style={{ marginTop: '52px' }}>
        <input
          id='checkbox'
          type='checkbox'
          checked={checkSubscriber === true ? true : false}
          className=' mt-4 mb-8 h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setCheckSubscriber(checkSubscriber === false ? true : false)}
        />
        <div
          className=' text-xs mt-6 not-italic font-medium inline-block _medium ml-3'
          style={{ color: ' var(--windsor)' }}>
          Subscriber
        </div>
      </div>
      <div className='px-9 flex'>
        <input
          id='checkbox'
          type='checkbox'
          className=' mt-4 mb-8  h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setCheckBlock(checKBlock === false ? true : false)}
        />
        <div
          className=' text-xs mt-6 not-italic font-medium inline-block _medium ml-3'
          style={{ color: ' var(--windsor)' }}>
          Block User
        </div>
      </div>
      <div className='px-9 flex'>
        <input
          id='checkbox'
          type='checkbox'
          // onChange={handleTick}
          className=' mt-4 mb-8  h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setCheckDelete(checkDelete === false ? true : false)}
        />
        <div
          className=' text-xs mt-6 not-italic font-medium inline-block _medium ml-3'
          style={{ color: ' var(--windsor)' }}>
          Delete User
        </div>
      </div>

      {!open && (
        <div className='px-16 flex justify-between mt-16 mb-11 relative'>
          <button className='' onClick={() => history.push('/admin-edit-users')}>
            <img src={logo1} className='h-10 w-10' alt='logo' />
            <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>
          <button onClick={handleSubmit}>
            <img src={logo3} className='h-10 w-10' alt='logo' />
            <img src={logo6} className='absolute h-3.5 ml-2.5 bottom-3' alt='logo' />
          </button>
        </div>
      )}
      <div
        className='popUp'
        style={{
          display: responseState === true && btnState === false ? 'block' : 'none',
        }}>
        <div className='PopUpWrapper'>
          <h5 className='font-semibold text-green-500 p-2 text-center'>
            User Info Successfully Edited.
          </h5>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full
          '
            onClick={() => {
              setbtnState(true)
              history.push('/admin-edit-users')
            }}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditUser
