import Communicate from '../assets/images/Communicate.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { AddCourses } from './Asses/Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export const Persuasion1_9 = () => {
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.communicate.cg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        cg: state?.user?.progress?.course?.communicate.cg,
        kg: state?.user?.progress?.course?.communicate.kg,
        assessment: AddCourses(state?.user?.progress?.course?.communicate?.assesment, 3),
        complete: true,
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }

    return history.push('/user_dashboard')
  }

  const handleClickTwo = async () => {
    var stateNew = { ...state }
    stateNew.user.progress.course.communicate.cg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        cg: state?.user?.progress?.course?.communicate.cg,
        kg: state?.user?.progress?.course?.communicate.kg,
        assessment: AddCourses(state?.user?.progress?.course?.communicate?.assesment, 3),
        complete: true,
      }),
    )

    return history.push('/user_dashboard')
  }

  return (
    <CommonConfirmation
      logo={Communicate}
      title={'COMMUNICATE'}
      trainingTitle={'PERSUASION'}
      question={'Do you feel more confident to use persuasion every day?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
