import React, { useReducer } from 'react'
import { reducer, initialState } from '../reducer'
// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

export const AppContext = React.createContext()

export default function AppProvier(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AppContext.Provider {...props} value={{ state, dispatch }} />
  )
}
