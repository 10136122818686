import { AppConstants } from '../appConstanst'

let controller
let signal

const getToken = () => {
  var auth = localStorage.getItem('auth')
  const token = auth && JSON.parse(auth).token
  return token
}

export const login = body =>
  fetch(`${AppConstants.BASE_URL}/signin`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
export const forgotPass = body =>
  fetch(`${AppConstants.BASE_URL}/forgot-password`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
export const resetPass = body =>
  fetch(`${AppConstants.BASE_URL}/reset-password`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
export const signup = body =>
  fetch(`${AppConstants.BASE_URL}/signup`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body,
  })

export const userSignup = body =>
  fetch(`${AppConstants.BASE_URL}/v2/signup`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
export const editProfile = body =>
  fetch(`${AppConstants.BASE_URL}/user/edit-profile-by-admin`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })

export const getContent = (uri, token) =>
  fetch(`${AppConstants.BASE_URL}/app${uri}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    credentials: 'same-origin',
  })

export const getUserProfile = token =>
  fetch(`${AppConstants.BASE_URL}/app/user/profile`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    credentials: 'same-origin',
  })

export const saveProgress = (uri, body, token) => {
  if (controller) {
    controller.abort()
  }
  controller = new AbortController()
  signal = controller.signal
  return fetch(`${AppConstants.BASE_URL}/app${uri}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
    signal,
  }).catch(err => {
    console.log(err)
  })
}
export const sendEmailIndividual = async body => {
  var data
  await fetch(`${AppConstants.BASE_URL}/user/sendmail`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })
    .then(res => {
      return res.json()
    })
    .then(res => {
      data = res
    })
  return data
}
export const searchUser = (body, token) =>
  fetch(`${AppConstants.BASE_URL}/user/search`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })

export const userList = async (body, token) => {
  var New
  await fetch(`${AppConstants.BASE_URL}/user/list`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })
    .then(res => res.json())
    .then(data => {
      New = data
    })
  return New
}

// Admin Dashbord Get Data
export const adminDashbordData = async token => {
  var New
  await fetch(`${AppConstants.BASE_URL}/admin/dashboard`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
  })
    .then(res => res.json())
    .then(data => {
      New = data
    })
  return New
}

export const sendEmail = body =>
  fetch(`${AppConstants.BASE_URL}/admin/sendmail`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })

export const addClientData = (body, token) =>
  fetch(`${AppConstants.BASE_URL}/admin/createclient`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })

export const totalClients = async (body, token) => {
  var New
  await fetch(`${AppConstants.BASE_URL}/admin/totalClient`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })
    .then(res => res.json())
    .then(data => {
      New = data
    })
  return New
}

export const EditClientData = body =>
  fetch(`${AppConstants.BASE_URL}/admin/updateadminclient`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
  })
let controller1
let signal1
export const saveScoring = (body, token) => {
  if (controller1) {
    console.log(controller1)
    controller1.abort()
  }
  controller1 = new AbortController()
  signal1 = controller1.signal
  return fetch(`${AppConstants.BASE_URL}/app/v2/course`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body,
    signal1,
  }).catch(err => {
    console.log(err)
  })
}
