import React, { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import mail from '../assets/images/mail.svg'
import Home from '../assets/images/home icon.svg'
import cross from '../assets/images/path-15765@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import useApp from '../context/useApp'
import { saveScoring, saveProgress } from '../services/user'
import { Link, useHistory } from 'react-router-dom'
export default function CreateTool1_9() {
  const { state } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const stopApiDoublecall = useRef(true)
  const [textInput1_9, setTextInput1_9] = useState({
    myProblem: !state?.user?.progress?.course?.create_tool?.textArea
      ? ''
      : state?.user?.progress?.course?.create_tool?.textArea !== 0
      ? state?.user?.progress?.course?.create_tool?.textArea
      : '',
    idea_1: !state?.user?.progress?.course?.create_tool?.textInput_5?.first_idea
      ? ''
      : state?.user?.progress?.course?.create_tool?.textInput_5?.first_idea.length !== 0
      ? state?.user?.progress?.course?.create_tool?.textInput_5?.first_idea
      : '',
    solution_1: !state?.user?.progress?.course?.create_tool?.toolDataArr[0]
      ? ''
      : state?.user?.progress?.course?.create_tool?.toolDataArr[0].length !== 0
      ? state?.user?.progress?.course?.create_tool?.toolDataArr[0]
      : '',
    idea_2: !state?.user?.progress?.course?.create_tool?.textInput_5?.second_idea
      ? ''
      : state?.user?.progress?.course?.create_tool?.textInput_5?.second_idea.length !== 0
      ? state?.user?.progress?.course?.create_tool?.textInput_5?.second_idea
      : '',
    solution_2: !state?.user?.progress?.course?.create_tool?.toolDataArr[1]
      ? ''
      : state?.user?.progress?.course?.create_tool?.toolDataArr[1]?.length !== 0
      ? state?.user?.progress?.course?.create_tool?.toolDataArr[1]
      : '',
    idea_3: !state?.user?.progress?.course?.create_tool?.textInput_5?.third_idea
      ? ''
      : state?.user?.progress?.course?.create_tool?.textInput_5?.third_idea.length !== 0
      ? state?.user.progress?.course?.create_tool?.textInput_5?.third_idea
      : '',
    solution_3: !state?.user.progress?.course?.create_tool?.toolDataArr[2]
      ? ''
      : state?.user.progress?.course?.create_tool?.toolDataArr[2]?.length !== 0
      ? state?.user.progress?.course?.create_tool?.toolDataArr[2]
      : '',
  })
  useEffect(() => {
    if (stopApiDoublecall.current) {
      SaveApiProgress()
    }
    return () => {
      stopApiDoublecall.current = false
    }
  }, [textInput1_9])
  const SaveApiProgress = async () => {
    const uri = '/course/course'
    if (state?.user?.progress?.course?.create_tool?.assessmentPercent < 100) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 100,
          complete: true,
        }),
      )
    }
    var stateNew = { ...state }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 100
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 100,
    }
    await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
  }
  const handleSubmit = async event => {
    event.preventDefault()

    history.push('/user_dashboard')
  }
  const handlemail = event => {
    localStorage.setItem('createMix', JSON.stringify(textInput1_9))
    return history.push('/createmail')
  }
  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top '>
            <div className='mt-3 ml-5 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt top_m' style={{ marginLeft: '48px' }}>
              <h3 className='exercise_solve_1_3_heading _bold'>CREATE</h3>
              <strong>
                <h1 className='percent _regular'>100%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3  _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='ExerciseHeading'>EXERCISE</h3>
              <h1 className='leading-none mt-2 mainHeading '>Congratulations!</h1>
              <p className='ex_solve_para'>
                You’ve used the art and science of Creative Thinking to generate new ideas. Use your
                summary below as an outline for discussion with your team and make sure to listen to
                everyone’s ideas — the more ideas the more potential solutions.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <strong>
                  <h1 className='same_style' style={{ fontWeight: 'bolder', color: '#43296E' }}>
                    My Problem
                  </h1>
                </strong>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.1'
                  style={{ height: '208px' }}
                  className='user_text_area tx_1_9  '
                  value={textInput1_9.myProblem}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, myProblem: e.target.value })}
                />
              </div>
              <div className='action_div'>
                <p className='ex_solve_para same_style' style={{ color: '#43296E' }}>
                  My Ideas and Supporting Data
                </p>
                <div className='flex items-center w-full justify-center mt-8 mb-3 '>
                  <div className='cscs1'>1</div>
                </div>
                <p className='ex_solve_para same_style exerciseReadheading'>IDEA</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 a'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.solution_1}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, solution_1: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 b'
                  className='user_text_area w-full border_0_bc_red'
                  value={textInput1_9.idea_1}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, idea_1: e.target.value })}
                />
              </div>
              <div className='flex items-center w-full justify-center mt-8'>
                <div className='cscs1'>2</div>
              </div>
              <p className='ex_solve_para same_style exerciseReadheading'>IDEA</p>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 a'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.solution_2}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, solution_2: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 b'
                  className='user_text_area w-full border_0_bc_red'
                  value={textInput1_9.idea_2}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, idea_2: e.target.value })}
                />
              </div>
              <div className='flex items-center w-full justify-center mt-8'>
                <div className='cscs1'>3</div>
              </div>
              <p className='ex_solve_para same_style exerciseReadheading'>IDEA</p>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 a'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.solution_3}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, solution_3: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 b'
                  className='user_text_area w-full border_0_bc_red'
                  value={textInput1_9.idea_3}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, idea_3: e.target.value })}
                />
              </div>

              {/* <p className='ex_solve_para'>Email these ideas and supporting data.</p> */}
              <div className='w-full flex flex-col items-center gap-14 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/exercise_solve_1.5')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <Link to={'/user_dashboard'}>
                    <button type='submit' onClick={handleSubmit}>
                      <img src={Home} alt='image no present' className='w-[40px] h-[40px]'></img>
                    </button>
                  </Link>
                </div>
                <div>
                  <p className='ex_solve_para mb-3'>Email these ideas and supporting data.</p>

                  <button type='submit'>
                    <img
                      src={mail}
                      alt='image no present'
                      className='w-[40px] h-[40px]'
                      onClick={handlemail}
                    />
                  </button>
                </div>

                <div>
                  <p className='ex_solve_para'>Delete all content.</p>
                  <button
                    type='reset'
                    className='mt-4'
                    onClick={() => history.push('/create_tool2.0')}>
                    <div className='w-full flex items-center '>
                      <div className='cross'>
                        <img src={cross} alt='Image not found' width={15} height={15} />
                      </div>
                    </div>
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
