import { APP_USER_LOGOUT } from '../../reducer/types'
import useApp from '../../context/useApp'
import { useHistory } from 'react-router-dom'
export default function ClientMenu(props) {
  const { dispatch } = useApp()
  const history = useHistory()
  const Logout = () => {
    dispatch({ type: APP_USER_LOGOUT })
    history.push('/')
  }
  return (
    <div className='w-full menu-content h-full fixed top-0 bottom-0 right-0 left-0 _bg__windsor fixPopupView admin_signup_in text-center'>
      <div className=' _regular text-2xl'>
        <p className='pb-3'>
          <button onClick={Logout} className='text-white text-2xl'>
            Log Out
          </button>
        </p>
      </div>
    </div>
  )
}
