import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import Next_image from '../assets/images/group-3437-1@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export default function ExerciseSolve() {
  const { dispatch, state } = useApp()
  let uri = '/course/course'
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const [textInput1_3, setTextInput1_3] = useState({
    objective: '',
    stakeholder: '',
  })
  useEffect(() => {
    if (state?.user?.progress?.course?.solve?.Exercise?.textInput1_3?.objective) {
      setTextInput1_3({
        ...textInput1_3,
        objective: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_3.objective
          ? ''
          : state?.user?.progress?.course?.solve?.Exercise?.textInput1_3.objective,
        stakeholder: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_3.stakeholder
          ? ''
          : state?.user?.progress?.course?.solve?.Exercise?.textInput1_3.stakeholder,
      })
    }
  }, [])
  const handleSubmit = async event => {
    event.preventDefault()
    var stateNew = { ...state }

    stateNew.user.progress.course.solve.Exercise = {
      ...stateNew.user.progress.course.solve.Exercise,
      textInput1_3,
      assessmentPercent:
        state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent > 33
          ? state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent
          : 33,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)

    if (textInput1_3?.objective?.length >= 1 && textInput1_3?.stakeholder?.length >= 1) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      if (state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent < 33) {
        let res = await saveScoring(
          JSON.stringify({
            course: `exercise_solve`,
            assessment: 33,
          }),
        )
      }
      return history.push('/exercise_solve_1.4')
    }
    alert('Answer can not be blank')
  }

  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold nav_title'>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent _regular'>33%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 3</h3>
              <h1 className='leading-none mt-2 mainHeading _medium'>
                Define Objective And Stakeholders
              </h1>
              <p className='ex_solve_para '>
                Defining the objective is focused on clearly outlining what you want the potential
                solutions to accomplish. Second, you need to define the stakeholders, which is who
                the objective is going to affect the most.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Define the objective. Again, keep it short you only get 150 characters.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  value={textInput1_3.objective}
                  maxLength={150}
                  onChange={e =>
                    setTextInput1_3({ ...textInput1_3, objective: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />
                <span
                  className='text-right text-xs ml-auto mt-2 '
                  style={{
                    color: 'red',
                  }}>
                  {textInput1_3.objective.length}/150
                </span>
                <p className='message '>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    The objective is to find clean energy solutions to charge electric cars.
                  </span>
                </p>
              </div>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Define the stakeholders in 150 characters or less.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area w-full'
                  value={textInput1_3?.stakeholder}
                  maxLength={150}
                  onChange={e =>
                    setTextInput1_3({ ...textInput1_3, stakeholder: e.target.value.trimStart() })
                  }
                />
                <span className='text-right text-xs ml-auto mt-2 ' style={{ color: 'red' }}>
                  {textInput1_3?.stakeholder?.length}/150
                </span>
                <p className='message semiBold'>
                  <span className='_bold'> Example: </span>
                  <span className='italic'>
                    The stakeholders are early adopters of electric cars, city, state and the
                    Federal Governments.
                  </span>
                </p>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/solve1.2')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button type='submit'>
                    <img
                      src={Next_image}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
