import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo6 from '../assets/images/path-15710-10@1x.png'
const DeleteUser = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      <h4
        className='text-center font-style mt-10 text-2xl not-italic font-medium _medium leading-6 tracking-normal'
        style={{ color: 'var(--windsor)' }}>
        Delete User
      </h4>
      <div className='px-14'>
        <h4
          className=' text-sm mt-14 mb-3 font-bold tracking-normal _bold text-center'
          style={{ color: 'var(--jacarta)', lineHeight: '14px' }}>
          Are you sure you want to delete this user? All user information will be deleted from the
          database
        </h4>
      </div>
      {!open && (
        <div className='px-16 flex justify-between top-4 relative '>
          <button className='' onClick={() => history.push('/admin-edit-user')}>
            <img src={logo1} className='h-10 w-10' alt='logo' />
            <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>
          <button>
            <img src={logo3} className='h-10 w-10' alt='logo' />
            <img src={logo6} className='absolute h-3.5 ml-2.5 bottom-3' alt='logo' />
          </button>
        </div>
      )}
    </div>
  )
}

export default DeleteUser
