import React, { useState, useEffect } from 'react'
import GlobalMenu from '../../components/userComponent/GlobalMenu'
import logo3 from '../../assets/images/path-15712@1x.png'
import logo4 from '../../assets/images/path-15743-1@1x.png'
import logo5 from '../../assets/images/path-15768@1x.png'
import logo7 from '../../assets/images/group-3439-10@1x.png'
import logo8 from '../../assets/images/Component 37 – 1.svg'
import check from '../../assets/images/Group 9485.svg'
import backarrow from '../../assets/images/backrow.svg'
import RightB from '../../assets/images/whitetick.svg'
import { saveProgress, saveScoring } from '../../services/user'
import { APP_USER_AUTH_SUCCESS } from '../../reducer/types'
import { useHistory } from 'react-router-dom'
import useApp from '../../context/useApp'
import { AddScore } from './Comm_Text'
export default function A_EQ_2_0() {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [CheckValues, setCheckValues] = useState({
    value_1: 0,
    value_2: 1, //ans
    value_3: 2,
    value_4: 3,
    value_5: 4,
  })
  const [initial, stateinital] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  })
  const handleSubmit = async event => {
    event.preventDefault()
    let SlectedTen =
      initial.one === true
        ? CheckValues.value_1
        : initial.two
        ? CheckValues.value_2
        : initial.three
        ? CheckValues.value_3
        : initial.four
        ? CheckValues.value_4
        : initial.five
        ? CheckValues.value_5
        : alert('Answers can not be blank')
    const mainCheck = SlectedTen
    let uri = '/course/course'
    var stateNew = { ...state }
    SlectedTen = SlectedTen === 1 ? 4 : 0
    stateNew.user.progress.course.emotional_intelligence_assess = {
      ...stateNew.user.progress.course.emotional_intelligence_assess,
      SlectedTen,
      check2: mainCheck,
    }
    let res = await saveScoring(
      JSON.stringify({
        course: `assess_emotional_intelligence`,
        assessment: AddScore(state?.user?.progress?.course?.emotional_intelligence_assess, 10),
        complete: true,
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (initial.one || initial.two || initial.three || initial.four || initial.five == true) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      history.push('/assess_eq_2_1')
    }
  }
  useEffect(() => {
    switch (
      Object.values(CheckValues).indexOf(
        state?.user?.progress?.course?.emotional_intelligence_assess?.check2,
      )
    ) {
      case 0:
        {
          stateinital({ ...initial, one: true })
        }
        break
      case 1:
        {
          stateinital({ ...initial, two: true })
        }
        break
      case 2:
        {
          stateinital({ ...initial, three: true })
        }
        break
      case 3:
        {
          stateinital({ ...initial, four: true })
        }
        break
      case 4: {
        stateinital({ ...initial, five: true })
      }

      default:
        break
    }
  }, [])

  return (
    <div className='bg min-h-screen'>
      <div className='h-16 bg_blue upperr flex flex-row justify-between fixed w-full top-0'>
        <div>
          {open && (
            <div>
              <GlobalMenu openMenu={() => toggleMenu(false)} />
            </div>
          )}

          <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
            {!open ? (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
                <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
                <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
              </div>
            ) : (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
              </div>
            )}
          </div>
        </div>
      </div>
      {!open && (
        <div className='w-screen pt-16 calc_height calc_height2'>
          <div className='emotional_intelligence_splash  ctBg bg-no-repeat pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center absolute  emIcon'>
              <div
                className='text-5xl text-center relative top-2 text-white _bold font-black '
                style={{ lineHeight: '48px' }}>
                <img src={logo8} alt='logo' className='h-16 w-16' />
              </div>
            </div>
            <div className='relative'>
              <div className='mx-3'>
                <h4 className='text-center text-sm assesHeading font-medium _bold uppercase pt-20 _font_color_white'>
                  EMOTIONAL INTELLIGENCE
                </h4>
                <h4 className='text-center font assesSubheading mt-6 _bold  mb-3 _font_color_white'>
                  ASSESSMENT
                </h4>

                <p
                  className='text-center font11  font-normal mb-3  _regular  _font_color_white w-[90%]'
                  style={{ lineHeight: '20px' }}>
                  Based on the information provided, which answer is most valid.
                </p>

                <h4 className='text-center assesFontBody pb-5 _font_color_white'>
                  You’re in a meeting and a colleague takes credit for work that you have done. What
                  do you do?
                </h4>
              </div>
              <div className=' mx-3 '>
                <div
                  className='flex flex-row w-full mb-2.5'
                  style={{
                    height: '50px',
                  }}>
                  <div className='flex flex-col justify-center  '>
                    <div className='h-10 w-10 mr-2.5'>
                      <img
                        className=''
                        src={initial.one ? RightB : check}
                        onClick={() =>
                          stateinital({
                            ...initial,
                            one: initial.one === false || true,
                            two: false,
                            three: false,
                            four: false,
                            five: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <h4
                      className=' _semiBold text-xs _font_color_white'
                      style={{ lineHeight: '14px' }}>
                      Immediately and publicly confront them over the ownership of your work.
                    </h4>
                  </div>
                </div>

                <div
                  className='flex flex-row w-full mb-2.5'
                  style={{
                    height: '50px',
                  }}>
                  <div className='flex flex-col justify-center  '>
                    <div className='h-10 w-10 mr-2.5'>
                      <img
                        className=''
                        src={initial.two ? RightB : check}
                        onClick={() =>
                          stateinital({
                            ...initial,
                            two: initial.two === false ? true : false,
                            one: false,
                            three: false,
                            four: false,
                            five: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <h4
                      className=' _semiBold text-xs _font_color_white'
                      style={{ lineHeight: '14px' }}>
                      After the meeting, privately tell them that you would appreciate in the future
                      that they give you credit for your work.
                    </h4>
                  </div>
                </div>

                <div
                  className='flex flex-row w-full mb-2.5'
                  style={{
                    height: '50px',
                  }}>
                  <div className='flex flex-col justify-center  '>
                    <div className='h-10 w-10 mr-2.5'>
                      <img
                        className=''
                        src={initial.three ? RightB : check}
                        onClick={() =>
                          stateinital({
                            ...initial,
                            three: initial.three === false || true,
                            two: false,
                            one: false,
                            four: false,
                            five: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <h4
                      className=' _semiBold text-xs _font_color_white'
                      style={{ lineHeight: '14px' }}>
                      Go directly to your superior and calmly discuss who really did the work.
                    </h4>
                  </div>
                </div>

                <div
                  className='flex flex-row w-full mb-2.5'
                  style={{
                    height: '50px',
                  }}>
                  <div className='flex flex-col justify-center  '>
                    <div className='h-10 w-10 mr-2.5'>
                      <img
                        className=''
                        src={initial.four ? RightB : check}
                        onClick={() =>
                          stateinital({
                            ...initial,
                            four: initial.four === false || true,
                            two: false,
                            three: false,
                            one: false,
                            five: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <h4
                      className=' _semiBold text-xs _font_color_white'
                      style={{ lineHeight: '14px' }}>
                      Nothing. It’s not a good idea to embarrass colleagues in public.
                    </h4>
                  </div>
                </div>

                <div
                  className='flex flex-row w-full mb-2.5'
                  style={{
                    height: '50px',
                  }}>
                  <div className='flex flex-col justify-center  '>
                    <div className='h-10 w-10 mr-2.5'>
                      <img
                        className=''
                        src={initial.five ? RightB : check}
                        onClick={() =>
                          stateinital({
                            ...initial,
                            five: initial.five === false || true,
                            two: false,
                            three: false,
                            four: false,
                            one: false,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <h4
                      className=' _semiBold text-xs _font_color_white'
                      style={{ lineHeight: '14px' }}>
                      After your colleague speaks, thank them for referencing your work and then
                      give the group more details..
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            {!open && (
              <div
                className='w-full h-[80px] mt-[30px] pb-[40px]  button_bottom mt-28 mx-auto assessBottom'
                style={{ width: '93%', marginBottom: '0px' }}>
                <button className='relative  mb-5' onClick={() => history.push('/assess_eq_9')}>
                  <img alt='logo' src={backarrow} className='h-10 w-10' />
                </button>
                <button className='relative  mb-5' onClick={handleSubmit}>
                  <img alt='logo' src={logo7} className='h-10 w-10' />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
