import logo from '../../assets/images/Group 2323@2x.png'
import headingLogo from '../../assets/images/Fullbridge Logo White@2x.png'
export default function AdminLoginHeader() {
  return (
    <>
      <div className='w-full inline-grid mt-[52px] justify-center'>
        <img className='w-[30px] h-[40px] ' src={logo} alt='logo' />
      </div>
      <div className='w-full inline-grid mt-6 justify-center'>
        <img className='w-[205px] h-[18px] ' src={headingLogo} alt='headingLogo' />
        <p className='text-xl text-white _regular text-center mt-3'>
          Business Fitness<sup style={{ fontSize: '10px' }}>&reg;</sup>
        </p>
      </div>
    </>
  )
}
