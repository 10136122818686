import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per5 from '../assets/images/PER-5.jpg'
import per6 from '../assets/images/PER-6.jpg'
import per7 from '../assets/images/PER-7.jpg'
import per8 from '../assets/images/PER-8.jpg'
import per9 from '../assets/images/PER-9.jpg'
import per10 from '../assets/images/PER-10.jpg'
import per18 from '../assets/images/PER-18.jpg'
import persuasion from '../assets/images/Persuasion2.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export const Persuasion1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.communicate?.assesment[1]
      ? state?.user?.progress?.course?.communicate?.assesment[1]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.communicate,
      1,
      Number(localStorage.getItem('Pbarvalue11')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue11')), percent1),
    )
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        assessment: AddCourses(state?.user?.progress?.course?.communicate?.assesment, 2),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue11', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/persuasion1.4')
    } else {
      history.push('/persuasion1.3')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl percentage pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='per_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                COMMUNICATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Persuasion 2
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover why you need persuasion and when to use it to drive more successful
                communication.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center _mt-63 '>
            <p className='text-center mb-1 text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 tracking-[2.8px]'>PERSUASION 2</h4>
            <p className='_regular text-2xl work_out  mb-7 text-center  _margin_px'>
              Why Do I Need To Be Persuasive?
            </p>
            <div className='_regular work_out text-base mb-5 _margin_px'>
              <p>
                Although Persuasion has been around since the beginning of time, it’s more important
                today than ever before. As a matter of fact, LinkedIn rates Persuasion as the #2
                most in-demand soft skill in the market.
              </p>
              <br />
              <p>
                No matter how smart you are, no matter how talented you are, and no matter how good
                you are at your job, you must be able to persuade others to your point of view.
              </p>
              <br />

              <p>
                It’s also vital to recognize that not only are we selling every day—we are also
                being sold. The more we understand the strategies and tactics behind persuasion, the
                less likely these strategies and tactics will work on us.
              </p>
              <br />
              <p>
                A deep understanding of persuasion gives us a competitive advantage when trying to
                persuade others and a competitive advantage when someone is trying to exercise
                influence over us.
              </p>
            </div>
            <img src={per18} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-base _margin_px mb-8'>
              The good news is that there is a substantial amount of science that supports the art
              of persuasion, and like almost every important task, there are two critical steps:
            </p>

            <div className='flex justify-center mb-5'>
              <div className='rounded-full cursor-pointer flex mt-2 h-10 w-10 justify-center avatarBg'>
                <div
                  className='leading-7  my-2 _bold text-center text-lg font-bold w-8'
                  style={{ color: '#ffffff' }}>
                  1
                </div>
              </div>
            </div>

            <h4 className='_bold text-center text-base  mb-6 text1 _margin_px'>Preparation</h4>

            <div className='flex justify-center mb-5'>
              <div className='rounded-full cursor-pointer flex mt-2 h-10 w-10 justify-center avatarBg'>
                <div
                  className='leading-7  my-2 _bold text-center text-lg font-bold w-8'
                  style={{ color: '#ffffff' }}>
                  2
                </div>
              </div>
            </div>

            <h4 className='_bold text-center text-base  mb-6 text1 _margin_px'>Execution</h4>

            <div className='_regular work_out text-base mb-5 _margin_px'>
              <p>
                In this section we’re going to explore the five pillars of preparation, and in the
                next section, we’re going to explore the six laws of persuasion. Once you’ve learned
                to master both preparation and execution, you will be able to build powerful
                arguments, sway opinions, and persuade others to say “Yes!”
              </p>
              <br />
              <p>
                <span className='_bold'>NOTE:</span> We recognize that we have used the term
                “argument” throughout, but it’s important to understand that we’re not talking about
                being argumentative or confrontational. We are using the term argument as it is
                truly defined: a reason, or set of reasons, given with the aim of persuading others
                that an action or idea is right or wrong.
              </p>
              <br />

              <p>
                The following infographic will help you better understand the 5 Pillars of Preparing
                to Make a Persuasive Argument.
              </p>
            </div>
          </div>

          <div className='flex'>
            <img src={persuasion} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                Five Pillars Of A Persuasive Argument
              </p>

              <p className='_regular work_out text-base  _margin_px mb-6'>
                Remember, the five pillars of a persuasive argument are all about preparing for your
                encounter. The more prepared you are, the more successful you will be. Let’s explore
                each one of these pillars in more depth.
              </p>
              <img src={per5} className='w-full ' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <p className=' work_out _bold text-2xl text-center mt-7'>1.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Stay In Learning Mode
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                A central theme of the seven skills is change. Everything continues to change at an
                unprecedented pace, and learning has become a lifelong endeavor.
              </p>
              <br />
              <p>
                Make sure that you continue to learn and stay curious. Also remember to exercise
                your cognitive flexibility muscles by connecting the dots between abstract ideas and
                new information.
              </p>
              <br />
              <p>
                Make sure to scan the world news every morning to stay on top of what’s happening
                and where there might be new opportunities. The more you learn, the more you’ll be
                able to use this knowledge when building a powerfully persuasive argument.
              </p>
            </div>
            <img src={per6} className='w-full' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>2.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Begin With The End In Mind
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                No matter what you’re working on, always begin with the end in mind. Ask yourself,
                “What do I want the outcome to be?” Every persuasive argument begins with a very
                clear outcome in mind.
              </p>
              <br />
              <p>
                As with every argument and negotiation, there could be multiple potential outcomes.
                Make sure that you explore multiple outcomes and that you prioritize which of these
                outcomes you prefer—and which of these outcomes you’re willing to accept.
              </p>
              <br />
              <p>
                Your persuasive argument should be focused on and built around the outcomes you want
                most. The information and data that you gather should support your argument and
                outcomes.
              </p>
            </div>
            <img src={per7} className='w-full ' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>3.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Understand The Other Side
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                Before you can start to gather data and information to support your argument, it’s
                critical to understand what the other side’s point of view (POV) or desired outcome
                might be.
              </p>
              <br />
              <p>
                Every successful negotiation and argument is rooted first in finding common ground.
                This is followed by moving the other person’s POV toward your argument. Always
                research the other side’s background, interests, passions, and previous wins and
                losses.
              </p>
              <br />
              <p>
                Most importantly, try to understand what they want and what they would consider a
                win—remember that it might be something rational, like a specific dollar amount, or
                something emotional, like recognition in front of the company.
              </p>
              <br />
              <p>
                Only by understanding the other side’s POV and goals can you carefully craft a
                persuasive argument.
              </p>
            </div>
            <img src={per8} className='w-full' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>4.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Gather Data
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                Once you have a clear understanding of what you want the outcome to be—and a good
                idea of what you believe the other side wants the outcome to be—you can start to
                gather the critical data you need to support your argument.
              </p>
              <br />
              <p>
                Remember to begin with the end in mind and to gather the data that supports that
                end. As you gather data, it’s important to make sure you provide context. In other
                words, try to apply the data to your argument as much as possible. Make sure it’s
                both relevant and timely.
              </p>
              <br />
              <p>
                One of the biggest mistakes made during arguments is using old information and
                out-of-date data, or data that is not relevant to the current situation. Your data
                is not the argument—rather, it supports your argument. Don’t get lost in the data.
                Keep the end in mind, and use data only as a support tool.
              </p>
            </div>
            <img src={per9} className='w-full ' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>5.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Choose A Clear Argument Statement
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                It’s imperative that you choose a clear argument statement. As obvious as this
                sounds, many of us make the mistake of failing to clearly articulate what we want or
                what we want the outcome to be.
              </p>
              <br />
              <p>
                How often have you heard an executive say, “What are you asking for?” or “What are
                you asking me to do?” Always start with your argument statement—your one-line
                statement that clearly articulates your desired outcome.
              </p>
              <br />
              <p>
                For example, “I would like to hire two additional staff members.” This is a clear
                desired outcome. What comes next is your argument—which includes information and
                data to support why you feel you need two additional staff members, and of course,
                what’s in it for the other side.
              </p>
            </div>
            <img src={per10} className='w-full ' alt='logo' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-10'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>PERSUASION 2</h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Persuasion Summary
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Your ability to persuade others to your point of view is a crucial component of
                successful communication and will often determine your value as a leader or
                influential team member. You’ll use persuasion every day, and the more you practice
                the five pillars, the more powerful your communication will become.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
