import React, { useState, useRef, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import mail from '../assets/images/mail.svg'
import Home from '../assets/images/home icon.svg'
import cross from '../assets/images/path-15765@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import useApp from '../context/useApp'
import { Link, useHistory } from 'react-router-dom'
export default function ExerciseSolves_1_9() {
  const { dispatch, state } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const stopApiForDoubleCall = useRef(true)
  const [textInput1_9, setTextInput1_9] = useState({
    objective: !state.user?.progress?.course.exercise?.textArea
      ? ''
      : state.user?.progress?.course.exercise?.textArea !== 0
      ? state.user?.progress?.course.exercise?.textArea
      : '',
    stakeholder: !state.user?.progress?.course.exercise?.textBox?.firstInputText
      ? ''
      : state.user?.progress?.course.exercise?.textBox?.firstInputText.length !== 0
      ? state.user?.progress?.course.exercise?.textBox?.firstInputText
      : '',
    another: !state.user?.progress?.course.exercise?.textBox?.secondInputText
      ? ''
      : state.user?.progress?.course.exercise?.textBox?.secondInputText.length !== 0
      ? state.user?.progress?.course.exercise?.textBox?.secondInputText
      : '',
    another2: !state.user?.progress?.course.exercise?.textBox?.thirdInputText
      ? ''
      : state.user?.progress?.course.exercise?.textBox?.thirdInputText.length !== 0
      ? state.user?.progress?.course.exercise?.textBox?.thirdInputText
      : '',
    another3: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_3?.objective
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_3?.objective?.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_3?.objective
      : '',
    another4: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_3?.stakeholder
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise.textInput1_3?.stakeholder.length !== 0
      ? state.user.progress.course.solve.Exercise.textInput1_3.stakeholder
      : '',
    another5: !state?.user.progress?.course?.solve?.Exercise?.textSend
      ? ''
      : state?.user.progress?.course?.solve?.Exercise?.textSend.length !== 0
      ? state?.user.progress?.course?.solve?.Exercise?.textSend
      : '',
    another6: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.objective
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.objective !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.objective
      : '',
    another7: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.stakeholder
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.stakeholder.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.stakeholder
      : '',
    another8: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.another
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.another.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_5?.another
      : '',
    another9: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.objective
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.objective.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.objective
      : '',
    another10: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.stakeholder
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.stakeholder.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.stakeholder
      : '',
    another11: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another
      : '',
    another12: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another2
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another2.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another2
      : '',
    another13: !state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another3
      ? ''
      : state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another3.length !== 0
      ? state?.user?.progress?.course?.solve?.Exercise?.textInput1_7?.another3
      : '',
  })
  function handlemail(event) {
    event.preventDefault()
    let mail_data = {
      mailpara: `Congratulations You’ve used the art and science of Critical Thinking to articulate a better solution
                based on data. Use your summary below as an outline for a PowerPoint or simply to
                make a better argument for your solution in your personal or professional life.`,
      textInput1_9,
    }
    localStorage.setItem('mailData', JSON.stringify(mail_data))
    history.push('/mailsend')
  }
  useEffect(() => {
    if (stopApiForDoubleCall.current) {
      SaveScore()
    }

    return () => {
      stopApiForDoubleCall.current = false
    }
  }, [textInput1_9])
  // Api call
  const SaveScore = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }

    stateNew.user.progress.course.solve.Exercise = {
      ...stateNew.user.progress.course.solve.Exercise,
      assessmentPercent: 100,
    }

    let res = await saveScoring(
      JSON.stringify({
        course: `exercise_solve`,
        assessment: 100,
        complete: true,
      }),
    )

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }
  const handleSubmit = async event => {
    event.preventDefault()

    history.push('/user_dashboard')
  }

  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading nav_title'>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent'>100%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3  _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='ExerciseHeading'>EXERCISE</h3>
              <h1 className='leading-none mt-2 mainHeading '>Congratulations!</h1>
              <p className='ex_solve_para' id='mainsendpara'>
                You’ve used the art and science of Critical Thinking to articulate a better solution
                based on data. Use your summary below as an outline for a PowerPoint or simply to
                make a better argument for your solution in your personal or professional life.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <strong>
                  <h1 className='same_style'>My Problem</h1>
                </strong>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.1'
                  value={textInput1_9.objective}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, objective: e.target.value })}
                  className='user_text_area tx_1_9'
                />
              </div>
              <div className='action_div'>
                <p className='ex_solve_para same_style'>Supporting Data</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 a'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.stakeholder}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, stakeholder: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 b'
                  className='user_text_area w-full border_0_bc_red'
                  value={textInput1_9.another}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.2 c'
                  className='user_text_area w-full border_0_bc_red'
                  value={textInput1_9.another2}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another2: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] same_style'>Objective</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.3 a'
                  className='user_text_area w-full'
                  value={textInput1_9.another3}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another3: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] same_style'>Stakeholder(s)</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.3 b'
                  className='user_text_area w-full'
                  value={textInput1_9.another4}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another4: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] same_style'>Potential Solution</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.6 (checked box)'
                  className='user_text_area w-full tx_1_9 '
                  value={textInput1_9.another5}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another5: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] same_style'>Supporting Data</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.5 a'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.another6}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another6: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.5 b'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.another7}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another7: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder='Text from 1.5 c'
                  className='user_text_area w-full border_0_bc_red '
                  value={textInput1_9.another8}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another8: e.target.value })}
                />
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para mb-[15px] same_style'>Steps for Implementation</p>
                <textarea
                  name='new_text_area'
                  placeholder=''
                  className='user_text_area w-full'
                  value={textInput1_9.another9}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another9: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder=''
                  className='user_text_area w-full'
                  value={textInput1_9.another10}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another10: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder=''
                  className='user_text_area w-full'
                  value={textInput1_9.another11}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another11: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder=''
                  className='user_text_area w-full'
                  value={textInput1_9.another12}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another12: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className='action_div mt-5'>
                <textarea
                  name='new_text_area'
                  placeholder=''
                  className='user_text_area w-full'
                  value={textInput1_9.another13}
                  disabled
                  onChange={e => setTextInput1_9({ ...textInput1_9, another13: e.target.value })}
                  style={{ backgroundColor: '#fff' }}
                />
              </div>
              {/* <p className='ex_solve_para'>Email this solution to someone.</p> */}
              <div className=' w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex items-center justify-between w-[90%]'>
                  <button onClick={() => history.push('/exercise_solve_1.8')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <Link to={'/user_dashboard'}>
                    <button type='submit' onClick={handleSubmit}>
                      <img src={Home} alt='image no present' className='w-[40px] h-[40px]' />
                    </button>
                  </Link>
                </div>
                <div>
                  <p className='ex_solve_para mb-3'>Email this solution to someone.</p>
                  <button type='submit'>
                    <img
                      src={mail}
                      alt='image no present'
                      className='w-[40px] h-[40px]'
                      onClick={handlemail}
                    />
                  </button>
                </div>

                <div>
                  <p className='ex_solve_para'>Delete all content.</p>
                  <button type='reset' className='mt-4' onClick={() => history.push('/solve2.0')}>
                    <div className='w-full flex items-center '>
                      <div className='cross'>
                        <img src={cross} alt='Image not found' width={15} height={15} />
                      </div>
                    </div>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
