import { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import Inspire from '../assets/images/Inspire.svg'
import { useHistory } from 'react-router-dom'
import logocircle2 from '../assets/images/group-3437-1@1x.png'
export const PersonalBranding1_7 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2 '} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>

      <div className='flex flex-col items-center'>
        <div className='h-16 w-16 rounded-full mt-12 mb-3.5 '>
          <img src={Inspire} logo='logo' />
        </div>
        <h4 className='text-center text-sm leading-6 _bold mb-7 error_msg_color lineH2_8'>
          INSPIRE
        </h4>
        <h4 className='_bold font work_out text-center _font_color_jacarta lineH2 '>
          PERSONAL BRANDING
        </h4>
        <p className='text-2xl _regular _font_color_jacarta'>Congratulations!</p>
        <p
          className=' _regular text-base  pt-5 _margin_px text-center _font_color_jacarta'
          style={{ marginBottom: '71px' }}>
          You’ve completed Personal <br></br> Branding and have boosted your <br></br>Business
          Fitness level with a <br></br>foundational human-centric skill <br></br>that will help you
          <span className='_bold'> inspire </span>
          others <br></br> more successfully every day.
        </p>

        <div
          className='mb-12 flex justify-center mt-24'
          onClick={() => history.push('/personalbranding1.8')}>
          <div>
            <img className=' w-10 h-10' alt='logo' src={logocircle2} />
          </div>
        </div>
      </div>
    </div>
  )
}
