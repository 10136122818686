import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per1 from '../assets/images/hyper2.png'
import per2 from '../assets/images/hyper1.svg'
import per3 from '../assets/images/hyper5.png'
import per4 from '../assets/images/hyper4.png'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { UpdateAssessments } from './Asses/Comm_Text'
export const Hyper1_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.hyper?.assesment[0]
      ? state?.user?.progress?.course?.hyper?.assesment[0]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }

    if (!stateNew?.user?.progress?.course['hyper']) {
      stateNew.user.progress.course['hyper'] = {
        ...stateNew.user.progress.course.hyper,
        assesment: [
          Number(localStorage.getItem('Pbarvalue19')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue19')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course.hyper,
        0,
        Number(localStorage.getItem('Pbarvalue19')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue19')), percent1),
      )
    }
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_achieve`,
        assessment: state?.user?.progress?.course?.hyper?.assesment[0],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue19', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])
  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/hyper_focus1.2')
    } else {
      return history.push('/hyper_focus1.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between  fixed z-10 top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className=' pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-full mt-16 flex flex-col'>
          <div className='personal1 w-full flex flex-col  items-center '>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange grid place-items-center'>
                <div
                  className='text-5xl text-center  text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  1
                </div>
              </div>
            </div>
            <div className='mt-auto flex flex-col sskss justify-between'>
              <div>
                <h4
                  className='text-center text-xs  pb-2 _bold text-white lspacing'
                  style={{ letterSpacing: '6px' }}>
                  ACHIEVE
                </h4>
                <h4 className='text-[26px]  text-center  leading-6 _medium text-white pb-4 '>
                  Hyper-Focus 1
                </h4>
                <p
                  className='text-center font11 z-0 _regular text-white _margin_px '
                  style={{ lineHeight: '21px', opacity: '.8' }}>
                  Discover what hyper-focus is and its role in helping you achieve more with better
                  outcomes.
                </p>
              </div>
              <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white opacity-80'>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>

          <div className='flex flex-col items-center mt-[48px] '>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-10 lineH2_8'>HYPER-FOCUS 1</h4>
            <p className='_regular text-2xl work_out mb-4 text-center  _margin_px'>
              What Is Hyper-Focus?
            </p>
            <div className='_regular work_out1 text-base mb-5'>
              <p className='self'>
                In a fast-changing and hyper-competitive world, it’s easy to lose focus. But what
                really is focus? And what’s the difference between focus and hyper-focus? One of the
                single biggest challenges we all face today is competition for our attention. Our
                attention is limited and, thus, in high demand. Some argue that we’re living in the
                attention economy, an economy based on the money that companies make when they can
                get us to focus on their content or message. But the war for our attention is hard
                to win. The competition is fierce, and the tactics are diverse. And, while everyone
                around us is vying for our attention, what matters most to us is what we choose to
                focus on.
              </p>
            </div>
            <img src={per1} className='w-full mb-6' alt='logo' />
            <div className='_regular work_out1 text-base mb-5'>
              <p
                style={{
                  alignSelf: 'baseline',
                }}>
                Steve Jobs said, “People think focus means saying yes to the thing you’ve got to
                focus on. But that’s not what it means at all. It means saying no to the hundred
                other good ideas that are there. You have to pick carefully.”
              </p>

              <div className='self'>
                And herein lies the difference between focus and hyper-focus. Focus can be defined
                as “paying particular attention to,” but hyper-focus includes not only what we’re
                going to focus on but also clearly defining what we are not going to focus on. Thus,
                when faced with potential distractions, we are able to maintain our focus because we
                have predetermined what we’re already going to say “no” to. Only by creating this
                clear distinction are we able to attain hyper-focus and, thus, measurably increase
                productivity and attain better results.
                <p className='self' style={{ marginTop: '30px' }}>
                  Hyper-focus is your ability to center your attention and create a state or
                  condition that permits clear perception and understanding about not only what you
                  are going to focus on but also what you will not pay attention to.
                </p>
                <p className='self' style={{ marginTop: '30px' }}>
                  Study the following infographic to better understand the choice flow required to
                  reach a state of hyper-focus.
                </p>
              </div>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={per2} className=' mb-5' style={{ width: '100%' }} alt='img not found'></img>
          </div>

          <div className='flex flex-col items-center  '>
            <div className=' w-full'>
              <p className='_regular work_out text-2xl text-center mt-[50px] bold mb-7 _margin_px'>
                Our Brain And Focus
              </p>
              <div className='_regular work_out1 text-base mb-5 gap-4'>
                <p className='self'>
                  How the brain focuses has been studied for decades, but only recently, due to
                  advancements in technology, have there been substantial breakthroughs in
                  understanding how the brain really chooses what deserves our attention and what
                  should be treated as background noise.
                </p>

                <p className='self'>
                  There’s a big difference between visual focus (not to be confused with blurry
                  focus) and mental focus. In short, how do we choose what to look at and for how
                  long? New studies at the McGovern Institute for Brain Research have found that
                  when we pay attention to something specific, neurons in the visual cortex respond
                  to the object we’re focusing on and fire in synchrony, whereas those responding to
                  irrelevant information become suppressed. Put simply, it’s as if this synchrony
                  dramatically increases the volume so that the responding neurons rise above the
                  general noise.
                </p>
              </div>
              <img src={per3} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='self'>
                  These studies seem to suggest that what we choose to focus on, or not focus on,
                  dramatically affects our brain waves, and the frequency of those waves
                  dramatically affects our ability to focus. With every small glance away from what
                  we’re working on, we lose focus—and productivity. For example, while typing an
                  email, if we glance away to look at a text message, a notification on our
                  calendar, or even the cup of coffee on our desk, our brain waves change—and we
                  lose focus.
                </p>
                <p className='self'>
                  Have you ever been so involved in a project, so deeply engaged, that you’ve lost
                  sense of time? This is hyper-focus. This is a state of mind where you’re no longer
                  distracted by anything around you. You’re completely engaged and committed to a
                  singular objective. It’s during these times that we most often experience personal
                  or professional breakthroughs, insights, and epiphanies. And certainly, it’s
                  during these times that we experience the highest levels of productivity.
                </p>
              </div>

              <div className='_regular work_out1 text-base mb-5 self'></div>

              <img src={per4} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <div
                  className='_regular  text-2xl work_out mb-4 text-center  _margin_px'
                  style={{
                    fontWeight: '500',
                  }}>
                  <h4
                    className='text-center text-xs _bold mb-1'
                    style={{ letterSpacing: '2px', fontSize: '10px' }}>
                    HYPER-FOCUS 1
                  </h4>
                  Hyper-Focus Summary
                </div>
                <p className='self'>
                  Hyper-focus is that state of mind when we lose track of time and are no longer
                  distracted by anything or anyone around us. Hyper-focus allows us to successfully
                  prioritize the most important tasks first, reduce errors, and increase successful
                  output. In a busy world, hyper-focus has become more important than ever before.
                </p>
              </div>
            </div>
          </div>

          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
