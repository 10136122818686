import React, { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo15 from '../assets/images/path-15712@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import { AppConstants } from '../appConstanst'
import logo10 from '../assets/images/group-4548@1x.png'
import logo11 from '../assets/images/group-4550-1@1x.png'
import logo12 from '../assets/images/group-4546@1x.png'
import logo17 from '../assets/images/group-4544@1x.png'
import Semicircleprogressbar from '../adminPages/common/Semicircleprogressbar'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'

export default function UserDashboard() {
  const { state } = useApp()
  const [open, toggleMenu] = useState(false)
  const [value, setValue] = useState(0)
  const history = useHistory()
  var isRef = useRef(true)
  const body = { username: state?.user?.username }
  //  component Style
  const para = { color: '#2B1F5C', lineHeight: '18px' }
  useEffect(() => {
    if (isRef.current) {
      fetch(`${AppConstants.BASE_URL}/fbf_score`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then(res => res.json())
        .then(response => {
          const myPromise = new Promise((resolve, reject) => {
            resolve(response)
          })
          myPromise.then(res => {
            setValue(res?.data?.['isClientuserData']?.fbf_score_per)
          })
        })
    }

    return () => {
      isRef.current = false
    }
  }, [value])
  const mDate = state?.user.created_at
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const memberYear = new Date(mDate).getFullYear()
  const m = new Date(mDate)
  const memberMonth = month[m.getMonth()]
  const memberDate = new Date(mDate).getDate()
  const ProgressArray = Array()

  // Critcal thinking Add data

  // const CriticalArray = Object.values(state?.user?.progress?.course)
  //   .map(items => {
  //     return items?.assessments
  //       ? items?.assessments?.map(item => {
  //         return item?.progress
  //       })
  //       : items?.assesment
  //   })
  //   .flat()

  // var TotalPercentage = 0
  // for (let i = 0; i < CriticalArray.length; i++) {
  //   TotalPercentage += CriticalArray[i] ? CriticalArray[i] : 0
  // }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0  mx-auto left-0 overflow-auto'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo15} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      <h4
        className='text-center mt-10 mb-[33px] text-2xl _medium'
        style={{ color: '#3C0B74', lineHeight: '29px' }}>
        Dashboard
      </h4>
      <h4
        className='text-center _medium  text-sm'
        style={{ color: '#3C0B74', lineHeight: '18px', height: '25px' }}>
        {state?.user.name}
      </h4>
      <h4
        className='text-center _bold'
        style={{ color: '#3C0B74', lineHeight: '13px', fontSize: '10px' }}>
        Member Since: {memberMonth} {memberDate}, {memberYear}
      </h4>
      <div className='flex flex-row justify-center' style={{ marginTop: '60px' }}>
        <p
          className='text-center text-2xl _medium'
          style={{ color: '#3C0B74', lineHeight: '13px', letterSpacing: '0px' }}>
          Business Fitness
          <span style={{ fontSize: '10px' }}>&reg;</span>
        </p>
      </div>
      {!open && (
        <div className='relative mb-5 mt-5' style={{ marginTop: '23px', padding: '0 10%' }}>
          <Semicircleprogressbar progrss={value} />
        </div>
      )}
      <h4 className='text-center text-sm _regular' style={para}>
        Your Business Fitness level is:
      </h4>
      <h4
        className='text-center text-lg _bold'
        style={{ color: '#3C0B74', lineHeight: '22px', textTransform: 'uppercase' }}>
        {Number(value) < 41
          ? 'Beginner'
          : Number(value) >= 41 && Number(value) < 71
          ? 'Intermediate'
          : Number(value) >= 71 && Number(value) < 95
          ? 'Advanced'
          : Number(value) >= 95
          ? 'Expert'
          : ''}
      </h4>
      <h4 className='text-center text-sm _regular mt-5 px-[20px]' style={para}>
        Increase your Business Fitness level by <br /> taking assessments in Assess, getting
        <br />
        business fit in Get Fit and applying what <br /> you’ve learned in the Exercise section.
        <br />
        Plus, get personal Business Fitness
        <br /> insights in the Track section.
      </h4>
      {!open && (
        <div className='flex flex-row justify-around w-full mt-10  gap-[19px]'>
          <div className='flex flex-row justify-around w-[87%] mt-10'>
            <div onClick={() => history.push('/assess')}>
              <div className='access_cls'>
                <img className='bg-cover rsi1' src={logo11} alt='logo' />
              </div>
              <h4
                className='font-bold leading-3 text-xs _bold text-center mt-2'
                style={{ color: '#2B1F5C', textShadow: '.1px .1px #2b1f5c' }}>
                ASSESS
              </h4>
            </div>
            <div onClick={() => history.push('/get_fit')}>
              <div className='git_fit_img'>
                {/* <img className=' w-90 h-20 bg-cover ' src={logo9} alt='logo' /> */}
                <img className=' bg-cover rsi3' src={logo17} alt='logo' />
              </div>
              <h4
                className='font-bold leading-3 text-xs _bold text-center mt-2'
                style={{ color: '#e28d3d', textShadow: '.1px .1px #e28d3d' }}>
                GET FIT
              </h4>
            </div>
            <div>
              <div className='exe_cls' onClick={() => history.push('/exercise')}>
                <img className='  bg-cover rsi4' src={logo12} alt='logo' />
              </div>
              <h4
                className='font-bold leading-3 text-xs _bold text-center mt-2'
                style={{ color: '#D9182D', textShadow: '.1px .1px #D9182D' }}>
                EXERCISE
              </h4>
            </div>
            <div onClick={() => history.push('/user_track')}>
              <div className='track_cls'>
                <img className='bg-cover rsi2' src={logo10} alt='logo' />
              </div>
              <h4
                className='font-bold leading-3 text-xs _bold text-center mt-2'
                style={{ color: '#45825e', textShadow: '.1px .1px #45825e' }}>
                TRACK
              </h4>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
