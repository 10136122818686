import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import cf1 from '../assets/images/CF-1.jpg'
import cf2 from '../assets/images/CF-2.jpg'
import cf3 from '../assets/images/CF-3.jpg'
import cf4 from '../assets/images/CF-4.jpg'
import cf5 from '../assets/images/CF-5.jpg'
import cf6 from '../assets/images/CF-6.jpg'
import cf7 from '../assets/images/CF-7.jpg'
import cog_flex from '../assets/images/Cognitive1.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { UpdateAssessments } from './Asses/Comm_Text'
export const CogFlex1_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.adapt?.assesment[0]
      ? state?.user?.progress?.course?.adapt?.assesment[0]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }
    let uri = '/course/course'
    if (!stateNew.user.progress.course['adapt']) {
      stateNew.user.progress.course['adapt'] = {
        ...stateNew.user.progress.course.adapt,
        assesment: [
          Number(localStorage.getItem('Pbarvalue7')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue7')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course.adapt,
        0,
        Number(localStorage.getItem('Pbarvalue7')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue7')), percent1),
      )
    }

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_adapt`,
        assessment: state?.user?.progress?.course?.adapt?.assesment[0],
      }),
    )

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }
  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue7', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/cog_flex_1.2')
    } else {
      history.push('/cog_flex_1.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='cog_flex_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  1
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                ADAPT
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Cognitive Flexibility 1
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover what cognitive flexibility is and its role in adapting successfully to
                change.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center _mt-58 '>
            <p className='text-center mb-1 text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>

            <h4 className='_bold text-sm work_out mb-5 tracking-[2.8px]'>
              COGNITIVE FLEXIBILITY 1
            </h4>

            <p className='_regular text-2xl work_out  text-center mb-7 _margin_px'>
              What Is Cognitive Flexibility?
            </p>
            <p className='_regular work_out text-base mb-5 _margin_px'>
              In a world constantly in flux, cognitive flexibility is your ability to switch between
              two different concepts or multiple concepts at the same time to find the right
              solution. In other words, this is your ability to be mentally flexible in thinking
              about different ideas quickly in regard to how they may be applicable in any given
              situation.
            </p>
            <img src={cf1} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-base _margin_px mb-6'>
              <section className='my-4'>
                In short, cognitive flexibility is our ability to adapt flexibly to a constantly
                changing environment. As humans, we tend to be very good at adapting to our
                surroundings. But, in a noisy world, one of the biggest challenges we face is trying
                to determine which changes are relevant and which changes are not.{' '}
              </section>
              <section className='my-4'>
                Although our cognitive flexibility can decline with age, we can strengthen our
                cognitive flexibility muscles by being aware of change and consciously adapting to
                new situations and environments.
              </section>
              <section className='my-4'>
                Study the following infographic to better understand the flow and effects of using
                cognitive flexibility.
              </section>
            </p>
          </div>

          <div className='flex items-center  '>
            <img src={cog_flex} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                The Cognitive Flexibility Theory
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                <section className='my-4'>
                  At the core of cognitive flexibility is our ability to change what we are thinking
                  about, how we are thinking about it, and even what we think about it. In short,
                  cognitive flexibility is the ability to change our minds.{' '}
                </section>
                <section className='my-4'>
                  The cognitive flexibility theory was first introduced in 1988. It strives to
                  determine how our minds obtain and manage knowledge and, more importantly, how we
                  restructure our existing knowledge based on new information that we receive.
                </section>
              </p>
              <img src={cf2} className='w-full mb-10' alt='logo' />
              <p className='_regular work_out text-2xl text-center mb-6 _margin_px  '>
                Four Main Principles Of The Cognitive Flexibility Theory
              </p>
            </div>
          </div>

          <div className='flex justify-center mb-5'>
            <div className='rounded-full cursor-pointer flex mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                1
              </div>
            </div>
          </div>

          <h4 className='_bold text-center text-base  mb-6 text1 _margin_px'>
            Knowledge is context dependent.
          </h4>

          <div className='flex justify-center  mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                2
              </div>
            </div>
          </div>

          <h4 className='text-center text-base mb-6  text1  _bold _margin_px'>
            Knowledge cannot be oversimplified.
          </h4>

          <div className='flex justify-center  mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                3
              </div>
            </div>
          </div>

          <h4 className='text-center text-base mb-6  text1  _bold _margin_px'>
            Knowledge is constructed.
          </h4>

          <div className='flex justify-center mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg font-bold w-8'
                style={{ color: '#ffffff' }}>
                4
              </div>
            </div>
          </div>

          <h4 className='_bold text-center text-base  text1 _margin_px '>
            Knowledge is interconnected.
          </h4>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                Let’s explore each one of these a bit more so we can gain a better understanding of
                cognitive flexibility.
                <p className='_bold my-4'>Knowledge Is Context Dependent </p>
                First, it’s critical to understand that knowledge cannot be perceived out of
                context. In other words, it’s the context that allows us to understand an idea and
                then properly categorize that information. In order for us to learn new information,
                we have to understand three important components:
              </div>
              <img src={cf3} className='w-full mb-8' alt='logo' />
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <ol style={{ listStyle: 'decimal', padding: '15px' }}>
                  <li>What is the new information?</li>
                  <li> Why do I need this new information? </li>
                  <li>
                    How can I apply this information in real-life settings? Providing different
                    examples of the same idea helps us develop context that increases our ability to
                    learn.
                  </li>
                </ol>
              </div>
              <p className='_bold  work_out _margin_px mb-6'>Knowledge Cannot Be Oversimplified</p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                While it is vital to always try to simplify ideas as much as possible, in order for
                us to learn, our brains must be challenged enough to be engaged. Oversimplification
                of ideas and concepts lures us into complacency, where we feel like we may already
                know or understand the information. When our brains are challenged with new and
                difficult ideas or concepts, cognitive flexibility allows us to make our own
                connections, thus driving learning and true knowledge gain.
              </p>
              <img src={cf4} className='w-full mb-9' alt='logo' />
              <p className='_bold work_out _margin_px mb-6'>Knowledge Is Constructed</p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Simply transmitting information does not result in knowledge. Knowledge is
                constructed through a process that involves cognitive flexibility—our ability to
                explore new ideas and concepts, categorize them, and thus understand how an idea
                could be applied in different real-world settings.
              </p>
              <img src={cf5} className='w-full mb-9' alt='logo' />
              <p className='_bold work_out _margin_px mb-6'> Knowledge Is Interconnected </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Our ability to successfully adapt to change is equal to our cognitive
                flexibility—our ability to piece together our existing knowledge to provide usable
                solutions. In short, we must utilize the information we already have and apply it to
                newly acquired information. Cognitive flexibility is our ability to connect a new
                piece of information with information we already have, place it in context, and be
                able to use it to solve real-world problems.
              </p>
              <img src={cf6} className='w-full mb-6' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-5'>
              <p className='_regular work_out text-2xl  text-center _margin_px mb-5'>
                Task And Cognitive Switching
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Finally, it’s important to note two important behaviors regarding cognitive
                flexibility: task switching and cognitive shifting. Task switching is our ability to
                unconsciously shift attention between one task and another, whereas cognitive
                shifting is our ability to consciously shift attention between one task and another.
              </p>
              <img src={cf7} className='w-full mb-6' alt='logo' />
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Our cognitive flexibility, or ability to adapt, can be dramatically affected by both
                task switching and cognitive shifting. Because task switching is unconscious, it
                allows us to quickly and efficiently adapt to different situations. For example, it
                is critical for a martial artist to be good at task switching. Because cognitive
                shifting is a conscious redirecting of our attention, it’s critical in helping us to
                be productive, including with our time management skills.
              </p>
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                COGNITIVE FLEXIBILITY 1
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Cognitive Flexibility Summary
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                In summary, cognitive flexibility is your ability to connect the dots between
                existing knowledge and new knowledge so that you can adapt to change successfully.
                It is your ability to smoothly switch between subjects and adapt to new situations
                as well as your ability to let go of old thinking when you have new data or
                information. Cognitive flexibility is a skill that must be constantly sharpened;
                otherwise, you will find yourself stuck and falling behind.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
