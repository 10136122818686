import { useHistory } from 'react-router-dom'
export default function ProgressCard(props) {
  const history = useHistory()
  return (
    <div
      className='flex flex-row pt-2 pb-1 mt-2 active nonactive'
      style={{ backgroundColor: props.cardContainerColor, opacity: props.cardContainerOpacity }}
      onClick={() => {
        history.push(props.route)
      }}>
      <div className='w-20 flex flex-col justify-between pl-5 mt-2 '>
        {props.leftLogo && (
          <img
            className='ml-1.5'
            style={{ height: '32.6PX', width: '25.3px' }}
            src={props.leftLogo}
            alt='logo'
          />
        )}
        <h4
          className=' _bold'
          style={{ color: props.timeColor, fontSize: '10px', lineHeight: '13px' }}>
          {props.time}
        </h4>
      </div>
      <div className='w-11/12'>
        <h4 className=' text-sm mt-2 _bold leading-l14' style={{ color: props.nameColor }}>
          {props.name}
        </h4>
        <h4
          className='_regular mt-1'
          style={{
            color: props.descriptionColor,
            fontSize: '11px',
            lineHeight: '14px',
            width: '96%',
          }}>
          {props.description}
        </h4>
        <div className='flex flex-row'>
          <div
            style={{
              height: '4px',
              width: '85%',
              backgroundColor: `${props.progressContainerColor}`,
              borderRadius: 40,
              marginTop: 5,
            }}>
            <div
              style={{
                height: '100%',
                width: `${props.progress}%`,
                backgroundColor: `${props.progressColor}`,
                borderRadius: 40,
                textAlign: 'right',
              }}></div>
          </div>
          <span
            className='_bold'
            style={{
              color: `${props.progressTextColor}`,
              fontWeight: 900,
              marginLeft: 10,
              fontSize: '10px',
              lineHeight: '13px',
            }}>{`${props?.progress}%`}</span>
        </div>
      </div>
    </div>
  )
}
