import React, { useEffect, useState } from 'react'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import useApp from '../context/useApp'
import { useHistory } from 'react-router-dom'
import { InputBox } from '../adminPages/reusable_code/ReusableComp'
import { sendEmailIndividual } from '../services/user'
const CreateMail2 = () => {
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [email, setEmail] = useState('')
  const [emailData, setEmailData] = useState('')
  const [subject, setSub] = useState('')
  const mailSend = async event => {
    event.preventDefault()
    let body = {
      email: email,
      subject: subject,
      text: emailData,
      key: 'create2',
    }
    body = JSON.stringify(body)
    const data = await sendEmailIndividual(body)
    if (data?.status === 200) {
      let confirmBox = window.confirm('Mail sent successfully')
      if (confirmBox) {
        history.push('/create_tool1.4')
      }
    } else {
      alert('Something went wrong, Please check all values are correctly filled')
    }
  }

  useEffect(() => {
    setEmailData(JSON.parse(localStorage.getItem('create2')))
  }, [])
  return (
    <div className='_group-bg1 fixed w-full top-0 bottom-0 right-0 left-0 overflow-x-hidden'>
      {open && (
        <div className='w-full flex justify-between z-20'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top absolute'>
            <div className='mt-3 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                alt='Not Load'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt ' style={{ marginRight: '12%' }}>
              <h3 className='exercise_solve_1_3_heading'>CREATE</h3>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      <div
        className='flex flex-col items-center w-full'
        style={{ position: 'absolute', zIndex: '-1' }}>
        <div style={{ width: '100%', marginTop: '60px', marginBottom: '40px' }}>
          <form className='relative' style={{ marginBottom: '40px' }}>
            <InputBox
              htmlFor='#To'
              label='To'
              id='To'
              type='text'
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            <InputBox
              htmlFor='#subject'
              label='Subject'
              id='firstName'
              type='text'
              value={subject}
              onChange={event => {
                setSub(event.target.value)
              }}
            />
            <div className='px-9 mt-8S'>
              <label
                className='not-italic font-medium inline-block font-style _medium font-extrabold'
                style={{ color: 'var(--jacarta)', fontSize: '10px', lineHeight: '10px' }}>
                Email Body
              </label>
              <div>
                <div>
                  <p className='_bold'>Top 10 Solutions</p>
                  <p>
                    Hopefully you were able to generate 40-50 ideas using the SCAMPER method. First,
                    list your top 10, then select your top 3 by clicking the checkmark by the box.
                    The best way to narrow down ideas is to ask yourself the following questions:
                    <br /> 1. Is this idea realistic? (Do we have the materials, technology and/or
                    know how to implement?) <br />
                    2. Is this solution financially viable? (Does the idea cost substantially more
                    than the existing solution?)
                    <br></br> 3. Does this idea really solve the problem? (Does this idea solve the
                    problem as defined or solve something else?)
                  </p>
                  <strong>My Problem</strong>
                  <div>
                    <div>{emailData?.objective}</div>
                  </div>
                </div>
                <div>
                  <strong>Ideas</strong>
                  <div>
                    <div>{emailData?.prev_value_1}</div>
                    <div>{emailData?.prev_value_2}</div>
                    <div>{emailData?.prev_value_3}</div>
                    <div>{emailData?.prev_value_4}</div>
                    <div>{emailData?.prev_value_5}</div>
                    <div>{emailData?.prev_value_6}</div>
                    <div>{emailData?.prev_value_7}</div>
                    <div>{emailData?.prev_value_8}</div>
                    <div>{emailData?.prev_value_9}</div>
                    <div>{emailData?.prev_value_10}</div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className='absolute left-9 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6 '
              onClick={mailSend}>
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default CreateMail2
