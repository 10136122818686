import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import leftArrow from '../assets/images/leftArrow.svg'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
export const CollabrateTool1_2 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textBox, setTextBox] = useState({
    firstInputText: '',
    secondInputText: '',
    thirdInputText: '',
    fourthInputText: '',
    fifthInputText: '',
  })

  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    var stateNew = { ...state }
    if (state?.user?.progress?.course?.eq_tool?.assessmentPercent < 33) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_communicate`,
          assessment: 33,
        }),
      )
    }
    stateNew.user.progress.course.eq_tool = {
      ...stateNew.user.progress.course.eq_tool,
      textBox,
      assessmentPercent:
        state?.user?.progress?.course?.eq_tool?.assessmentPercent > 33
          ? state?.user?.progress?.course?.eq_tool?.assessmentPercent
          : 33,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (
      textBox?.firstInputText?.length >= 1 ||
      textBox?.secondInputText?.length >= 1 ||
      textBox?.thirdInputText?.length >= 1 ||
      textBox?.fourthInputText?.length >= 1 ||
      textBox?.fifthInputText?.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      return history.push('/collabrate_tool1.3')
    }

    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.eq_tool?.textBox) {
      setTextBox(state?.user?.progress?.course?.eq_tool?.textBox)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold ml-3'>COLLABORATE</h3>
              <strong>
                <h1 className='percent _regular'>33%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 2</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Define The Players</h1>
              <p className='ex_solve_para  '>
                Now, clearly define exactly who needs to collaborate to achieve this outcome. Make
                sure to add specific names. We recommend up to 5 individuals or groups total to
                maximize successful collaboration.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='mb-[17px]'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]' style={{ fontSize: '13px' }}>
                  List the name of the individual(s) and their department.
                  <span className='font-black'> (You must list at least one.)</span>
                </p>
              </div>
              <div className='action_div ' style={{ marginTop: '-12px', alignItems: 'flex-start' }}>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textBox.firstInputText}
                  onChange={e => setTextBox({ ...textBox, firstInputText: e.target.value })}
                  className='user_text_area'
                  style={{ fontSize: '14px', height: '80px' }}
                />
                <p className='message ' style={{ marginBottom: '45px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'> Bob Smith from Engineering</span>
                </p>
              </div>
              <div className='action_div ' style={{ marginTop: '-12px', alignItems: 'flex-start' }}>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textBox.secondInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, secondInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px', height: '80px' }}
                />
                <p className='message ' style={{ marginBottom: '45px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'> Lisa Roberts from Accounting.</span>
                </p>
              </div>
              <div className='action_div ' style={{ marginTop: '-12px', alignItems: 'flex-start' }}>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textBox.thirdInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, thirdInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px', height: '80px' }}
                />
                <p className='message' style={{ marginBottom: '45px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'> Carol Spellman from Marketing.</span>
                </p>
              </div>

              <div className='action_div ' style={{ marginTop: '-12px', alignItems: 'flex-start' }}>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textBox.fourthInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, fourthInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px', height: '80px' }}
                />
                <p className='message' style={{ marginBottom: '45px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'> Tom McCabe from Design.</span>
                </p>
              </div>

              <div className='action_div ' style={{ marginTop: '-12px', alignItems: 'flex-start' }}>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textBox.fifthInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, fifthInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px', height: '80px' }}
                />
                <p className='message' style={{ marginBottom: '45px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'> Steve Keller from Admin.</span>
                </p>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/collabrate_tool1.1')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit'>
                    <img
                      src={nextArrow}
                      onClick={saveData}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
