import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { login } from '../services/user'
import AdminLoginHeader from '../components/AdminComponent/AdminLoginHeader'
const initialState = {
  username: { value: '', isError: false, msg: '' },
  password: { value: '', isError: false, msg: '' },
  res: { isError: false, msg: '' },
}

export default function Admin_signin() {
  const { dispatch } = useApp()

  useEffect(() => {
    var isMounted = true
    var myHistory = { page_id: 1, user_id: 5 }
    if (isMounted) window.history.pushState(myHistory, '/corporate_signup')
    return () => {
      isMounted = false
    }
  }, [])
  const [form, setForm] = useState(initialState)
  const [isPasswordEnter, setIsPasswordEnter] = useState('')
  const [isError, setError] = useState(false)
  const history = useHistory()
  const signin = async _ => {
    if (form.username.value === '' || form.password.value === '') {
      setForm({
        ...form,
        ...(form.username.value === ''
          ? { username: { ...form.username, isError: true, msg: 'This field is required.' } }
          : { username: { ...form.username, isError: false, msg: '' } }),
        ...(form.pass.value === ''
          ? { password: { ...form.password, isError: true, msg: 'This field is required.' } }
          : { password: { ...form.password, isError: false, msg: '' } }),
        resp: {
          ...form.resp,
          isError: true,
          msg: 'Please fill all the required fields.',
        },
      })
      return
    }
    if (!form.username.isError && !form.password.isError) {
      let res = await login(
        JSON.stringify({
          username: form.username.value.toLowerCase(),
          password: btoa(`${form.password.value}`),
          is_admin: true,
        }),
      )

      let data = res.ok ? await res.text() : null
      if (data) {
        data = JSON.parse(data)
        if (data.success) {
          dispatch({ type: APP_USER_AUTH_SUCCESS, value: data.data })
          if (data?.data?.user?.is_admin) {
            history.replace('/admin-dashboard')
          } else if (data?.data?.client) {
            history.replace('/client-dashboard')
          } else {
            history.replace('/user_dashboard')
          }
        }
      } else {
        const error = await res.json()
        setError(!isError)
        setIsPasswordEnter('')
      }
    }
    setForm({
      ...form,
      password: {
        value: '',
        isError: false,
        msg: '',
      },
    })
  }

  return (
    <div className='admin_signup_in fixed overflow-auto top-0 right-0 left-0 bottom-0'>
      <AdminLoginHeader />
      {isError ? (
        <h4 className='errorMessage'>Invalid credentials. Please enter valid credentials.</h4>
      ) : null}
      <div
        className='mt-[54px] mx-9'
        style={{
          marginBottom: isError ? '54px' : '0',
        }}>
        <h4 className='text-white _regular text-2xl '>Sign in</h4>

        <div>
          <label htmlFor='#email' className='text-white font mt-4 inline-block _medium'>
            Email address
          </label>
          <input
            id='email'
            className='bg-transparent border-b w-full text-white mt-2 mb-4 outline-none'
            value={form.username.value}
            autoComplete='new-username'
            onBlur={e =>
              e.target.value.trim() === ''
                ? setForm({
                    ...form,
                    username: {
                      ...form.username,
                      isError: true,
                      msg: 'This field is required.',
                    },
                  })
                : setForm({ ...form, username: { ...form.username, isError: false, msg: '' } })
            }
            onChange={e => {
              if (isError) {
                setError(false)
              }
              return setForm({
                ...form,
                username: { ...form.username, value: e.target.value },
              })
            }}
          />
        </div>
        <div>
          <label htmlFor='#password' className='text-white font mt-4 inline-block _medium'>
            Password
          </label>
          <input
            id='password'
            type='password'
            className='bg-transparent border-b w-full text-white mt-3 mb-4 outline-none'
            autoComplete='new-password'
            value={form.password.value}
            onBlur={e =>
              e.target.value === ''
                ? setForm({
                    ...form,
                    password: {
                      ...form.password,
                      isError: true,
                      msg: 'This field is required.',
                    },
                  })
                : setForm({ ...form, password: { ...form.password, isError: false, msg: '' } })
            }
            onChange={e => {
              if (isError) {
                setError(false)
              }
              return setForm({ ...form, password: { ...form.password, value: e.target.value } })
            }}
          />
        </div>

        <div className={`${isPasswordEnter ? 'flex items-end justify-between' : 'flex'}`}>
          <p
            style={{ fontSize: '10px' }}
            className=' _semiBold _font_color_white divide-transparent'
            onClick={() => {
              history.push('/forget-password')
            }}>
            Forgot Password
          </p>
          <div className='flex-grow'></div>
          <button
            disabled={!form.username.value}
            className='next_Icons h-[39px] w-[39px] bg-no-repeat'
            onClick={() => {
              signin()
            }}></button>
        </div>
        <div className='mt-[60px] mb-56'>
          <p className='text-white _regular text-2xl'>Not a member?</p>
          <p
            className='text-white _regular text-sm mt-4'
            onClick={() => {
              history.replace('/corporate_signup')
            }}>
            Click here to create your account.
          </p>
        </div>
      </div>
    </div>
  )
}
