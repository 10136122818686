import { useState } from 'react'
import GlobalMenu from '../../components/userComponent/GlobalMenu'
import menu from '../../assets/images/white dash.svg'
import whiteCross from '../../assets/images/white cross.svg'

export const CommonConfirmation = props => {
  const [open, toggleMenu] = useState(false)

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2 '} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>

      <div className='flex flex-col items-center'>
        <div className='h-16 w-16 rounded-full mt-12 mb-3.5 '>
          <img src={props.logo} logo='logo' />
        </div>
        <h4
          className='text-center text-sm leading-6 _bold mb-7 error_msg_color lineH2_8'
          style={{ fontSize: '14px' }}>
          {props.title}
        </h4>
        <h4
          className='_bold font work_out text-center _font_color_jacarta '
          style={{ marginBottom: '60px', color: '#43296E', letterSpacing: '2px' }}>
          {props.trainingTitle}
        </h4>
        <p className=' _semiBold text-base  pt-1.5 _margin_px text-center _font_color_jacarta mb-5'>
          {props.question}
        </p>

        <div className='grid grid-rows-1 mt-4 ml-2'>
          <div className='grid px-[41px] grid-cols-2'>
            <div>
              <button
                className='Lbtn mr-56 _bold'
                onClick={props.handleClickTwo}
                style={{fontSize: '10px', letterSpacing: '2px',textIndent:"2px" }}>
                <span>NO</span>
              </button>
            </div>
            <div className='flex justify-end mr-3'>
              <button
                className='Ybtn'
                style={{ backgroundColor: '#3C0774' }}
                onClick={props.handleClick}>
                <p className='_bold' style={{ fontSize: '10px', letterSpacing: '2px',textIndent:"2px" }}>
                  YES
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
