import React, { useEffect, useState } from 'react'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import { saveProgress } from '../services/user'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import useApp from '../context/useApp'
import { useHistory } from 'react-router-dom'
import { InputBox } from '../adminPages/reusable_code/ReusableComp'
import { sendEmailIndividual } from '../services/user'
const CreateMailIdeas = () => {
  const { state } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [email, setEmail] = useState('')
  const [emailData, setEmailData] = useState('')
  const [subject, setSub] = useState('')
  const mailSend = async event => {
    event.preventDefault()
    let uri = '/course/course'

    let body = {
      email: email,
      subject: subject,
      text: emailData,
      key: 'createMailIdeas',
    }
    let inputField_3 = emailData
    var stateNew = { ...state }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      inputField_3,
    }

    await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    body = JSON.stringify(body)
    const data = await sendEmailIndividual(body)
    if (data?.status === 200) {
      let confirmBox = window.confirm('Mail sent successfully')
      if (confirmBox) {
        history.push('/create_tool1.3')
      }
    } else {
      alert('Something went wrong, Please check all values are correctly filled')
    }
  }

  useEffect(() => {
    setEmailData(JSON.parse(localStorage.getItem('createMailIdeas')))
  }, [])
  return (
    <div className='_group-bg1 fixed w-full top-0 bottom-0 right-0 left-0 overflow-x-hidden'>
      {open && (
        <div className='w-full flex justify-between z-20'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top absolute'>
            <div className='mt-3 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                alt='Not Load'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt ' style={{ marginRight: '12%' }}>
              <h3 className='exercise_solve_1_3_heading'>CREATE</h3>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      <div
        className='flex flex-col items-center w-full'
        style={{ position: 'absolute', zIndex: '-1' }}>
        <div style={{ width: '100%', marginTop: '60px', marginBottom: '40px' }}>
          <form className='relative' style={{ marginBottom: '40px' }}>
            <InputBox
              htmlFor='#To'
              label='To'
              id='To'
              type='text'
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            <InputBox
              htmlFor='#subject'
              label='Subject'
              id='firstName'
              type='text'
              value={subject}
              onChange={event => {
                setSub(event.target.value)
              }}
            />
            <div className='px-9 mt-8S'>
              <label
                className='not-italic font-medium inline-block font-style _medium font-extrabold'
                style={{ color: 'var(--jacarta)', fontSize: '10px', lineHeight: '10px' }}>
                Email Body
              </label>
              <div>
                <h1 className='_bold my-3'>Generate Ideas</h1>
                <div>
                  <p>
                    Thomas Edison discovered that generating lots of ideas was his key to success.
                    He suggests developing between 40-50 ideas at a minimum. Why? Because most
                    often, your last 10 ideas will be the best.
                  </p>
                  <p>
                    We are going to use this section to jumpstart ideas and guide you through the
                    creative process. Remember, rarely do you need to come up with a completely new
                    idea, rather you can use part of an idea you’ve already had or make incremental
                    changes. Follow the SCAMPER steps below and add any new ideas. Try and generate
                    40-50 total ideas.
                  </p>
                </div>
                <div>
                  <div>
                    <div>
                      <div className='font-semibold'>
                        Can you SUBSTITUTE something with the current idea to solve the problem?
                      </div>
                      <div>{emailData?.firstField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        Can you COMBINE something with the current idea to solve the problem?
                      </div>
                      <div>{emailData?.secondField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        Can you ADAPT the current idea to solve the problem?
                      </div>
                      <div>{emailData?.thirdField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        Can you MODIFY the current idea to solve the problem?
                      </div>
                      <div>{emailData?.fourthField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        Can you put the current idea to ANOTHER USE?
                      </div>
                      <div>{emailData?.fifthField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        Can you ELIMINATE something to solve the current problem?
                      </div>
                      <div>{emailData?.sixField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        Can you REARRANGE something to solve the current problem?
                      </div>
                      <div>{emailData?.sevenField}</div>
                    </div>
                    <div>
                      <div className='font-semibold'>
                        What completely new ideas could solve the current problem?
                      </div>
                      <div>{emailData?.eightField}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className='absolute left-9 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6 '
              onClick={mailSend}>
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default CreateMailIdeas
