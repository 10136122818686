import React, { useState } from 'react'
import AdminMenu from '../components/AdminComponent/AdminMenu'

import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'

export default function UserPersonalInfoSuccess(props) {
  const [open, toggleMenu] = useState(false)

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      <h4
        className='text-center  mt-10 text-2xl leading-6 not-italic _medium'
        style={{ color: 'var(--windsor)' }}>
        Account
      </h4>
      <div className='px-5'>
        <p className='_medium text-lg text-center mt-11 text-txtwin' style={{ lineHeight: '18px' }}>
          Success!
        </p>
        <p className='_regular text-sm text-center mt-4 text-jac leading-l14'>
          Your Personal Information has been successfully updated.
        </p>
      </div>
      <div
        className='flex justify-center text-xl _medium text-color '
        style={{ marginTop: '410px' }}>
        <div className=' mt-1 ml-5  rounded-full py-1.5 pl-2 h-10 w-10 bg-[#3C0774]'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='#FFFFFF'
            width='23'
            height='23'
            viewBox='0 0 576 512'>
            <path d='M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z' />
          </svg>
        </div>
      </div>
    </div>
  )
}
