import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import useApp from '../context/useApp'
import { AppConstants } from '../appConstanst'
import Scalton from './common/Scalton'
const Cards = props => {
  return (
    <div className='flex flex-col gap-5 mt-3 cursor-pointer relative' onClick={props.click}>
      <div
        className='flex opacity-10'
        style={{ backgroundColor: ' var(--catalina-blue)', height: '64px' }}></div>
      <div className='min-h-full min-w-full overflow-hidden absolute top-3  '>
        <div className='flex flex-grow justify-between px-5'>
          <button
            className='text-sm tracking-normal font-bold uppercase _bold'
            style={{ color: 'var(--jacarta)', lineHeight: '14px' }}
            onClick={props.click}>
            {props.title}
          </button>
          <div
            className='text-5xl tracking-normal font-light uppercase _light'
            style={{ color: 'var(--jacarta)', lineHeight: '48px' }}>
            {props.number}
          </div>
        </div>
      </div>
    </div>
  )
}

const AdminDashboard = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  // AdminDashboardmin data
  const [AdminDashboardData, setAdminDashbordData] = useState({})
  const [clientCount, setClientCount] = useState()
  const { state } = useApp()

  const [liveUsers, setLiveUsers] = useState('')
  const [pastWeekUsers, setPastWeekUsers] = useState('')
  const [pastMonthUsers, setPastMonthUsers] = useState('')
  const [aveFrequency, setAverFrequency] = useState('')
  const [timePerVisit, setTimePerVisit] = useState('')
  const [scoringData, setScoringData] = useState(0)

  useEffect(() => {
    var isMemoryLeak = true

    const CallingAdminData = async () => {
      await fetch(`${AppConstants.BASE_URL}/admin/dashboard`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${state.token}` },
      })
        .then(res => res.json())
        .then(data => {
          const Wholedata = data
          setAdminDashbordData(Wholedata)
          setClientCount(Wholedata.TotalAdminMadeBySuperAdmin)
          setLiveUsers(Wholedata.data?.google_analytics.rows[0].metricValues[0].value)
          setPastWeekUsers(Wholedata.data?.google_analytics.rows[0].metricValues[1].value)
          setPastMonthUsers(Wholedata.data?.google_analytics.rows[0].metricValues[2].value)
          setAverFrequency(Wholedata.data?.google_analytics.rows[0].metricValues[5].value)
          setTimePerVisit(Wholedata.data?.google_analytics.rows[0].metricValues[4].value)
          setScoringData(Wholedata.data?.admin_scoring_data)
        })
    }

    if (isMemoryLeak) {
      CallingAdminData()
    }

    return () => {
      isMemoryLeak = false
    }
  }, [])
  const mDate = state?.user.created_at
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const memberYear = new Date(mDate).getFullYear()
  const m = new Date(mDate)
  const memberMonth = month[m.getMonth()]
  const memberDate = new Date(mDate).getDate()
  localStorage.setItem('totalUser', AdminDashboardData?.data?.total_users_count)

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      {!open ? (
        <>
          <h2
            className='text-center _medium mt-10 text-2xl not-italic font-medium'
            style={{ color: 'var(--windsor)' }}>
            Admin
          </h2>
          <p
            className='text-center mt-11 text-sm px-2 not-italic font-medium truncate _medium'
            style={{ color: 'var(--windsor)' }}>
            {state.user.name}
          </p>
          <p
            className='text-center _medium not-italic mt-1 font-bold text-xs '
            style={{ color: 'var(--windsor)' }}>
            Admin Since {memberMonth} {memberDate}, {memberYear}
          </p>

          <div
            className='text-center mt-16 mb-5 text-2xl _medium not-italic font-medium'
            style={{ color: 'var(--windsor)' }}>
            Users and Admin
          </div>
          {AdminDashboardData?.data?.total_users_count?.length > 0 ? (
            <Cards
              click={() => {
                history.push('/admin-edit-users')
              }}
              title='Total Users'
              number={scoringData?.total_user ? scoringData?.total_user : 0}
            />
          ) : (
            <Scalton />
          )}
          {AdminDashboardData?.data?.total_subscribers_count?.length > 0 ? (
            <Cards
              click={() => {
                history.push('/admin-dashboard')
              }}
              title='  Total Subscribers'
              number={`${AdminDashboardData.data?.total_subscribers_count}`}
            />
          ) : (
            <Scalton />
          )}
          {AdminDashboardData?.data?.total_subscribers_count?.length > 0 ? (
            <Cards
              click={() => {
                history.push('/admin-client')
              }}
              title='Total Client'
              number={`${clientCount}`}
            />
          ) : (
            <Scalton />
          )}
          <div
            className='text-center my-6 text-2xl _medium not-italic font-medium'
            style={{ color: 'var(--windsor)' }}>
            Activity
          </div>

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title=' Live Now'
            number={liveUsers}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title=' Live in past week'
            id='displayUsers'
            number={pastWeekUsers}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='Live in past month'
            number={pastMonthUsers}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='Ave frequency'
            number={aveFrequency}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='Ave time per visit'
            number={Math.floor(timePerVisit / 60) + 'M'}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='AVE BUSINESS FITNESS'
            number={scoringData?.fbf_score_per ? scoringData?.fbf_score_per : 0}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='STARTS'
            number={scoringData.total_start}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='COMPLETIONS'
            number={scoringData.completion}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='% OF COMPLETIONS'
            number={scoringData.completion_per}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='AVE KNOWLEDGE GAIN'
            number={scoringData.avg_kg}
          />

          <Cards
            click={() => {
              history.push('/admin-dashboard')
            }}
            title='AVE CONFIDENCE GAIN'
            number={scoringData.avg_cf}
          />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default AdminDashboard
