import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import BR9 from '../assets/images/BR-9.jpg'
import BR8 from '../assets/images/BR-8.jpg'
import BR10 from '../assets/images/BR-10.jpg'
import BR11 from '../assets/images/BR-11.jpg'
import BR12 from '../assets/images/BR-12.jpg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
export const PersonalBranding1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  let isMemoryLeak = useRef(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.personalBranding?.assesment[1]
      ? state?.user?.progress?.course?.personalBranding?.assesment[1]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.personalBranding,
      1,
      Number(localStorage.getItem('Pbarvalue17')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue17')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        assessment: AddCourses(state?.user?.progress?.course?.personalBranding?.assesment, 2),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue17', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/personalbranding1.4')
    } else {
      history.push('/personalbranding1.3')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between  fixed z-10 top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}
        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className=' pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-full mt-16 flex flex-col  '>
          <div className='personal  pl-5 pr-5 flex flex-col items-center  justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange grid place-items-center'>
                <div
                  className='text-5xl text-center  text-white font-black _black'
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                INSPIRE
              </h4>
              <h4 className='text-[26px]  text-center  leading-6 _medium text-white pb-4 '>
                Personal Branding 2
              </h4>
              <p
                className='text-center font11 z-0 _regular text-white _margin_px'
                style={{ lineHeight: '21px', opacity: '.8' }}>
                Explore why you need personal branding and when to use it to stand out and inspire
                others.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white opacity-80'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px] '>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>PERSONAL BRANDING 2</h4>

            <p className='_regular text-2xl work_out  text-center mb-7 _margin_px'>
              Why Do I Need Personal Branding?
            </p>
            <div className='_regular work_out1 text-base mb-7'>
              <p className='self'>
                Each one of us is constantly being branded by everyone around us. All of our actions
                and choices affect what people believe to be true about us. One statistic that gives
                pause for thought is that 64% of consumers around the world expressed that they
                would buy from a brand or boycott it solely because of its position on a social or
                political issue. What we believe to be true about brands is powerful.
              </p>

              <p className='self'>
                <span className='_bold'>
                  How long does it take for someone to form an opinion about you?{' '}
                </span>
                It takes about 50 milliseconds, or significantly less than half of one second. We
                are all busy today, and as mentioned, we often judge “the book” by its cover. In
                other words, if you pull up in a Rolls Royce, I might make the assumption that
                you’re rich. Maybe you are, and maybe you aren’t. It doesn’t matter. My perceptions
                of what I believe to be true determine how or what I think about you.
              </p>
            </div>
            <img src={BR9} className='w-full mb-11' alt='logo' />
            <div className=' work_out _regular text-base _margin_px mb-11'>
              <p>
                Do you really need personal branding? Absolutely. Nowadays, the difference between
                being successful or not is often determined by the perceptions that those around us
                have of who we are and, thus, whether or not they believe we are valuable or that
                they can trust us. Each one of us must take control of this process as much as
                possible. This is the art and science of building a powerful personal brand.
              </p>
            </div>
            <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
              Your Personal Brand Is Critical To Success
            </p>
            <p className='work_out _regular text-base _margin_px mb-11'>
              Let’s explore additional situations where personal branding can become vital to your
              success.
            </p>
            <img src={BR8} className='w-full mb-8 ' alt='logo' />
            <p className='work_out _regular text-base _margin_px mb-9'>
              <div className='_bold mb-1'>Securing A New Job</div>
              <br />
              What makes the difference between winning a new job or not? It all comes down to
              trust. Does the person interviewing you believe that you can best solve their problem,
              and if so, do they trust you? Trust hinges on your personal brand or what the other
              person believes to be true about you, so you must make sure that your personal brand
              is driving home the attributes that are most important to the interviewer and giving
              them reasons to believe what you are saying.
            </p>
            <img src={BR11} className='w-full mb-8' alt='logo' />
            <p className='work_out _regular text-base _margin_px mb-9'>
              <div className='_bold'>Getting A Promotion</div>
              <br />
              Getting a promotion is very similar to winning a job. Your personal brand will be on
              trial, and the person considering giving you that promotion wants to know that they
              can trust you and that you have the attributes that matter most for the new position.
              The difference between getting a promotion or winning a job is that your boss will
              already know more about you, your history, and your performance. You will already have
              built a personal brand reputation. When it comes time for a new opportunity, it’s your
              chance to capitalize on that reputation.
            </p>
            <img src={BR12} className='w-full mb-8' alt='logo' />
            <p className='work_out _regular text-base _margin_px mb-9'>
              <div className='_bold'>Presenting A New Idea</div>
              <br />
              Presentation and communication skills are in high demand today. But even if you are a
              good speaker and presenter, believability and trust comes down to your personal brand.
              Whenever you are presenting a new idea, make sure that you are dressed appropriately,
              that you are at your best, and that you are using the power of your personal brand to
              persuade those around you.
            </p>
            <img src={BR10} className='w-full mb-8' alt='logo' />
            <p className='work_out _regular text-base _margin_px mb-9'>
              <div className='_bold'> Networking </div>
              <br />
              Networking is all about creating connections and a network of people you can trust to
              get things done. We often think that networking is just about getting a new job, but
              more often, in the real world, networking is about finding resources. When it comes to
              networking, your personal brand is paramount. You must be known to be an expert in one
              in-demand thing, and you need to be driving home the attributes that matter to your
              network.
            </p>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                PERSONAL BRANDING 2
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Personal Branding Summary
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  Put simply, the main reason you need personal branding is because you can’t stop
                  other people from thinking about you—but you can dramatically influence what they
                  think about you.
                </p>
                <br />
                <p>
                  Don’t leave your personal brand up to chance. Make sure you take control of every
                  aspect possible to ensure that you not only influence but also inspire those
                  around you.
                </p>
              </div>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
