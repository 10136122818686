import { useEffect, useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import Collabrate from '../assets/images/col.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
export const EmotionalIntelligence1_2 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()

  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.collabrate = {
      ...stateNew.user.progress.course.collabrate,
      kg: true,
    }
    stateNew.user.progress.course.collabrate = {
      ...stateNew.user.progress.course.collabrate,
      cg: true,
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
    return history.push('/emotional_intelligence2.1')
  }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2 '} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className='mt-5 ml-3'>
            <img className=' w-9 h-9' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>

      <div className='flex flex-col items-center'>
        <div className='h-16 w-16 rounded-full mt-12 mb-3.5 '>
          <img src={Collabrate} logo='logo' />
        </div>
        <h4 className='text-center text-sm leading-6 _bold mb-7 error_msg_color lineH2_8'>
          COLLABORATE
        </h4>
        <h4 className='getFitSmallHeader text-center tracking-[2px]'>EMOTIONAL INTELLIGENCE 1</h4>
        <p className='getFitHeadline'>Great work!</p>
        <p
          className=' getFitBodySection pt-1.5 _margin_px text-center _font_color_jacarta'
          style={{ marginBottom: '71px' }}>
          You’ve completed Emotional Intelligence 1 and have discovered what it is and its role in
          effective and successful collaboration.
        </p>

        <p className='getFitBold16px _margin_px text-center mb-4 _font_color_jacarta'>
          Are you ready to learn the ‘why’ behind emotional intelligence?
        </p>
        <div className='flex flex-row justify-between mt-4'>
          <button className='Ybtn' style={{ backgroundColor: '#43296E' }} onClick={handleClick}>
            <span className='_bold tracking-[2px] indent-0.5'>YES</span>
          </button>
        </div>
        <div
          className='flex justify-center text-xl _medium text-color mt-32 mb-7'
          onClick={() => history.push('user_dashboard')}>
          <div className=' mt-1 rounded-full py-1.5 pl-2 h-10 w-10 bg-[#3C0774]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='#FFFFFF'
              width='23'
              height='23'
              viewBox='0 0 576 512'>
              <path d='M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z' />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
