import React, { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo from '../assets/images/group-4548@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo15 from '../assets/images/path-15712@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import whiteImg from '../assets/images/white_arc.png'
import greenImg from '../assets/images/green_arc.png'
import logo1 from '../assets/images/path-15792@1x.png'
import logo2 from '../assets/images/white_home.svg'
import { useHistory } from 'react-router-dom'
import { AppConstants } from '../appConstanst'
import useApp from '../context/useApp'
export default function UserDashboardTrack1() {
  const { state } = useApp()
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const [value1, setValue] = useState(0)
  const isRef = useRef(true)
  const [Percent1, setPercent] = useState(0)
  const [userAssesmentInfo, setuserAssesmentInfo] = useState()
  const mDate = state?.user.created_at
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const memberYear = new Date(mDate).getFullYear()
  const m = new Date(mDate)
  const memberMonth = month[m.getMonth()]
  const memberDate = new Date(mDate).getDate()

  const body = { username: state?.user?.username }
  useEffect(() => {
    if (isRef.current) {
      fetch(`${AppConstants.BASE_URL}/fbf_score`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then(res => res.json())
        .then(response => {
          setValue(response?.data?.['isClientuserData']?.fbf_score_per)
          setPercent(response?.data?.['isClientuserData']?.fbf_score_per)
          setuserAssesmentInfo(response?.data?.['indivaiuserData'])
        })
    }

    return () => {
      isRef.current = false
    }
  }, [])
  // Critcal thinking Add data
  const CriticalArray = Object.values(state?.user?.progress?.course)
    .map(items => {
      return items?.assessments
        ? items?.assessments?.map(item => {
            return item?.progress
          })
        : items?.assesment
    })
    .flat()

  // const KNOWLEDGEGAINCONFIDENCEGAIN = Object.values(state?.user?.progress?.course)
  //   .map(items => {
  //     return [items?.kg, items?.cg]
  //   })
  //   .flat()
  var knowcount = userAssesmentInfo?.kg
  var concount = userAssesmentInfo?.cg

  var TotalPercentage = 0
  for (let i = 0; i < CriticalArray.length; i++) {
    TotalPercentage += CriticalArray[i] ? CriticalArray[i] : 0
  }

  const Percent = (TotalPercentage * 100) / 2100
  const value = Percent
  var Percent2 = Number(userAssesmentInfo?.complete_per)
  var AssesmentTaken = 0
  useEffect(() => {
    const gaugeElement = document.querySelector('.gauge')

    function setGaugeValue(gauge, value) {
      if (value < 0 || value > 1) {
        return
      }
      const media = document.querySelector('.track_main_fill')
      if (media) {
        document.querySelector('.track_main_fill').style.transform = `rotate(${value / 2}turn)`
      }
    }
    setGaugeValue(gaugeElement, value1 / 100)
  }, [value1, open])
  CriticalArray.forEach(item => {
    if (item > 0) {
      AssesmentTaken++
    }
  })
  const arr = [
    {
      name: '% COMPLETE',
      value: `${Percent2 ? Math.round(Percent2) : 0}`,
    },
    {
      name: 'COURSE COMPLETED',
      value: `${Math.round(
        userAssesmentInfo?.complete_total ? userAssesmentInfo?.complete_total : 0,
      )}`,
    },
    {
      name: 'WORKOUT MINUTES',
      value: `${Math.round(
        userAssesmentInfo?.complete_total * 30 ? userAssesmentInfo?.complete_total * 30 : 0,
      )}`,
    },
    {
      name: 'ASSESSMENTS TAKEN',
      value: `${Math.round(userAssesmentInfo?.total ? userAssesmentInfo?.total : 0)}`,
    },
    {
      name: 'AVE ASSESSMENT SCORE',
      value: `${Math.round(
        userAssesmentInfo?.assessment_avg ? userAssesmentInfo?.assessment_avg : 0,
      )}`,
    },
    {
      name: '% KNOWLEDGE GAIN',
      value: `${Math.round(knowcount) ? Math.round(knowcount) : 0}`,
    },
    {
      name: '% CONFIDENCE GAIN',
      value: `${Math.round(concount) ? Math.round(concount) : 0}`,
    },
  ]

  return (
    <div className='user_track_cover w-full min-h-screen overflow-y-scroll'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo15} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      {!open && (
        <div>
          <div className='flex justify-center mt-10'>
            <img style={{ height: '52.62px', widht: '67.95px' }} src={logo} alt='logo' />
          </div>

          <h4
            className='text-center text-2xl _bold mt-5'
            style={{ color: '#FFFFFF', lineHeight: '29px' }}>
            TRACK
          </h4>

          <h4
            className='text-center _medium text-sm mt-10'
            style={{ color: '#FFFFFF', lineHeight: '18px' }}>
            {state?.user.name}
          </h4>

          <h4
            className='text-center mt-2  _bold '
            style={{ color: '#FFFFFF', lineHeight: '13px', fontSize: '10px' }}>
            Member since: {memberMonth} {memberDate}, {memberYear}
          </h4>
          <p
            className='text-center text-2xl mt-5 _medium'
            style={{ color: '#ffffff', lineHeight: '13px', letterSpacing: '0px' }}>
            Business Fitness
            <span style={{ fontSize: '10px' }}>&reg;</span>
          </p>
          <div className='mmmm_div'>
            <div className='mm'>
              <img className='img1' src={whiteImg} alt='track_not_found' />
              <img className='img2 track_main_fill' src={greenImg} alt='track_not_found' />
            </div>
            <div className='flex z-10 gap-5 text-white' style={{ marginTop: '-50px' }}>
              {/* <h6 className='_regular text-sm pt-4'>-5</h6> */}
              <h4
                className='text-center _light tracking-widest text-5xl'
                style={{ color: '#FFFFFF', fontSize: '3rem' }}>
                {Math.round(value1)}
              </h4>
              {/* <h6 className='_regular text-sm pt-4'>+5</h6> */}
            </div>
          </div>

          <h4
            className='text-center text-sm _regular'
            style={{ color: '#FFFFFF', lineHeight: '18px' }}>
            Your Business Fitness level is:
          </h4>
          <h4
            className='text-center _bold text-lg mb-10 uppercase'
            style={{ color: '#FFFFFF', lineHeight: '22px' }}>
            {Number(value1) < 41
              ? 'Beginner'
              : Number(value1) >= 41 && Number(value1) < 71
              ? 'Intermediate'
              : Number(value1) >= 71 && Number(value1) < 95
              ? 'Advanced'
              : Number(value1) >= 95
              ? 'Expert'
              : ''}
          </h4>

          {arr.map((item, idx) => {
            return (
              <div
                key={idx}
                className='flex justify-between pl-8 pr-5 mt-3'
                style={{ backgroundColor: '#ffffff', height: '57px', opacity: '90%' }}>
                <button className='text-sm _bold' style={{ color: '#458059', lineHeight: '18px' }}>
                  {item?.name}
                </button>
                <div className='text-5xl _light' style={{ color: '#458059', lineHeight: '58px' }}>
                  {item?.value}
                </div>
              </div>
            )
          })}

          {/* <div className='text-2xl leading-6 text-center font-medium text-white mt-10'>AWARDS</div>
          <div className='flex flex-row justify-between pl-5 pr-5 mt-10 mb-20 gap-2'>
            <div>
              <div
                className='relative h-24 w-24 border-2 border-bor_clr_track1 rounded-[10px]'
                style={{ backgroundColor: '#ffffff' }}></div>
            </div>
            <div>
              <div
                className='relative h-24 w-24 border-2 border-bor_clr_track1 rounded-[10px]'
                style={{ backgroundColor: '#ffffff' }}></div>
            </div>
            <div>
              <div
                className='relative h-24 w-24 border-2 border-bor_clr_track1 rounded-[10px]'
                style={{ backgroundColor: '#ffffff' }}></div>
            </div>
          </div> */}
          <div className='flex justify-center  _medium text-color relative mt-10 mb-10'>
            <img
              src={logo2}
              className='bottom-3'
              onClick={() => history.push('/user_dashboard')}
              alt='logo'
            />
          </div>
        </div>
      )}
    </div>
  )
}
