import Inspire from '../assets/images/Inspire.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export const PersonalBranding1_8 = () => {
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.personalBranding.kg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        kg: state?.user?.progress?.course?.personalBranding?.kg,
      
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }

    return history.push('/personalbranding1.9')
  }

  const handleClickTwo = async uri => {
    var stateNew = { ...state }
    stateNew.user.progress.course.personalBranding.kg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        kg: state?.user?.progress?.course?.personalBranding?.kg,
       
      }),
    )

    return history.push('/personalbranding1.9')
  }

  return (
    <CommonConfirmation
      logo={Inspire}
      title={'INSPIRE'}
      trainingTitle={'PERSONAL BRANDING'}
      question={'Did you learn new information about personal branding?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
