import React, { useState, useEffect } from 'react'
import logo5 from '../assets/images/path-15768@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo7 from '../assets/images/path-15712@1x.png'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import nextArrow from '../assets/images/next arrow.svg'
import dumble from '../assets/images/group-4546-1@1x.png'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress, saveScoring } from '../services/user'
import { useHistory } from 'react-router-dom'
import leftArrow from '../assets/images/leftArrow.svg'
export const Solve1_1 = () => {
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [textArea, setTextArea] = useState('')
  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    var stateNew = { ...state }
    stateNew.user.progress.course.exercise = {
      ...stateNew.user.progress.course.exercise,
      textArea,
      assessmentPercent:
        state?.user?.progress?.course?.exercise?.assessmentPercent > 11
          ? state?.user?.progress?.course?.exercise?.assessmentPercent
          : 11,
    }
    if (
      state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent === undefined &&
      state?.user?.progress?.course?.exercise?.assessmentPercent === undefined
    ) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_solve`,
          assessment: 11,
        }),
      )
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (textArea?.length >= 1) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }

      return history.push('/solve1.2')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.exercise?.textArea) {
      setTextArea(state?.user?.progress?.course?.exercise?.textArea)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold nav_title '>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent _regular'>11%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 1</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Problem Definition</h1>
              <p className='ex_solve_para '>
                First, it is critical that you clearly define the problem. Don’t make assumptions.
                One of the biggest mistakes that companies and individuals make is not clearly
                defining the real problem they are trying to solve.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='action_div'>
                <h3 className='exerciseReadheading uppercase'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Define the problem you are trying to solve. Keep it short and simple. You only get
                  150 characters.
                </p>
                <textarea
                  placeholder='Type here.'
                  id='inputText'
                  name='inputText'
                  value={textArea}
                  maxLength={150}
                  style={{
                    background: textArea?.length > 150 ? '#ffb3b3' : '#ffffff',
                    fontSize: '14px',
                  }}
                  onChange={e => setTextArea(e.target.value.trimStart())}
                  className='user_text_area'
                />
                <span className=' text-right text-xs ml-auto mt-2 ' style={{ color: 'red' }}>
                  {textArea?.length}/150
                </span>
                <div className='relative'>
                  <p className='message '>
                    <span className='_bold '>Example: </span>
                    <span className='exerciseExample'>
                      The problem with charging electric cars is that most city grids are powered by
                      dirty fuels such as coal.
                    </span>
                  </p>
                </div>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/solve1.0')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button
                    type='submit'
                    // disabled={
                    //   textArea?.length < 1 || textArea?.length > 150 || textArea?.length === undefined
                    //     ? true
                    //     : false
                    // }
                    onClick={saveData}>
                    <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
