import React from 'react'
import FBlogo from '../assets/images/group-2322@1x.png'
import { useHistory } from 'react-router-dom'
export const Landing = () => {
  const history = useHistory()
  if (window.location.pathname === '/') {
    setTimeout(() => {
      history.replace('/admin_signin')
    }, 2000)
  }

  return (
    <div
      className='create_account_bg fixed overflow-auto top-0 right-0 left-0 bottom-0'
      style={{ display: window.location.pathname !== '/' ? 'none' : 'block' }}>
      <div className='flex justify-center relative top-28'>
        <img src={FBlogo} className='' alt='logo' />
      </div>
      <p className='text-white align-middle text-center text-2xl leading-10 mt-32 tracking-widest antialiased font-sans font-bold'>
        <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='18' viewBox='0 0 111.039 9.59'>
          <g transform='translate(55.52 4.795)'>
            <g transform='translate(-55.52 -4.795)'>
              <path
                className='a logoW'
                d='M17.5,16.9h6.542v1.749H19.46v1.96h4.492v1.749H19.46v3.8H17.5Z'
                transform='translate(-17.5 -16.748)'
              />
              <path
                className='a logoW'
                d='M54.8,16.9h1.99v5.487a2.171,2.171,0,1,0,4.341,0V16.9h2.02v5.547c0,2.291-1.357,3.859-4.191,3.859S54.8,24.739,54.8,22.447Z'
                transform='translate(-43.554 -16.748)'
              />
              <path
                className='a logoW'
                d='M99.6,16.9h1.96v7.537h3.919v1.749H99.6Z'
                transform='translate(-74.848 -16.748)'
              />
              <path
                className='a logoW'
                d='M134.7,16.9h1.96v7.537h3.919v1.749H134.7Z'
                transform='translate(-99.365 -16.748)'
              />
              <path
                className='a logoW'
                d='M169.9,16.9h4.854a2.386,2.386,0,0,1,2.653,2.352,2.05,2.05,0,0,1-1.6,2.11,2.234,2.234,0,0,1,1.779,2.261,2.575,2.575,0,0,1-2.683,2.5h-5Zm4.432,3.708a1.014,1.014,0,1,0,0-2.02H171.86v2.02Zm.06,3.859c.754,0,1.206-.392,1.206-1.055s-.422-1.085-1.206-1.085H171.86v2.141Z'
                transform='translate(-123.953 -16.748)'
              />
              <path
                className='a logoW'
                d='M214.907,22.87H213.46v3.286H211.5V16.9h4.341a2.882,2.882,0,0,1,3.105,2.985,2.591,2.591,0,0,1-2.02,2.744l2.08,3.527h-2.261Zm.633-4.221h-2.08v2.5h2.08a1.264,1.264,0,0,0,1.387-1.236,1.31,1.31,0,0,0-1.387-1.3Z'
                transform='translate(-153.011 -16.748)'
              />
              <path
                className='a logoW'
                d='M252.1,16.9h1.96v9.256H252.1Z'
                transform='translate(-181.37 -16.748)'
              />
              <path
                className='a logoW'
                d='M275.8,16.9h3.648c2.894,0,4.914,1.839,4.914,4.643s-2.02,4.613-4.914,4.613H275.8Zm3.648,7.537a2.774,2.774,0,0,0,2.894-2.894,2.7,2.7,0,0,0-2.894-2.894H277.76v5.758Z'
                transform='translate(-197.925 -16.748)'
              />
              <path
                className='a logoW'
                d='M323.275,16.4a4.341,4.341,0,0,1,3.829,2.08l-1.658.874a2.623,2.623,0,0,0-2.2-1.206,2.877,2.877,0,0,0-2.894,3.045,2.9,2.9,0,0,0,2.894,3.045,3.136,3.136,0,0,0,1.96-.693V22.4h-2.442V20.677h4.4v3.558a5.125,5.125,0,0,1-3.95,1.749A4.7,4.7,0,0,1,318.3,21.19,4.754,4.754,0,0,1,323.275,16.4Z'
                transform='translate(-227.612 -16.394)'
              />
              <path
                className='a logoW'
                d='M364.1,16.9h6.542v1.749H366.06v1.96h4.492v1.749H366.06v2.11h4.583v1.749H364.1Z'
                transform='translate(-259.603 -16.748)'
              />
            </g>
          </g>
        </svg>
      </p>
      <div className='flex flex-row justify-center'>
        <div className='text-white align-middle text-center mt-4 tracking-normal antialiased font-normal leading-5 text-xl'>
          Business Fitness
        </div>
        <div className='text-xs leading-3 font-normal text-white relative top-4'>®</div>
      </div>
    </div>
  )
}
