import React, { useState, useEffect } from 'react'
import GlobalMenu from '../../components/userComponent/GlobalMenu'
import logo3 from '../../assets/images/path-15712@1x.png'
import logo4 from '../../assets/images/path-15743-1@1x.png'
import logo5 from '../../assets/images/path-15768@1x.png'
import logo7 from '../../assets/images/group-3439-10@1x.png'
import logo8 from '../../assets/images/Component 37 – 1.svg'
import backarrow from '../../assets/images/backrow.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../../reducer/types'
import check from '../../assets/images/Group 9485.svg'
import RightB from '../../assets/images/whitetick.svg'
import { saveProgress, saveScoring } from '../../services/user'
import { AddScore } from './Comm_Text'
export default function A_Critical_Thinking_1_3() {
  const { dispatch, state } = useApp()
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const [CheckValues, setCheckValues] = useState({
    value_1: 0,
    value_2: 1,
    value_3: 2,
    value_4: 3,
    value_5: 4,
  })
  const [initial, stateinital] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const CheckThree =
      initial.one === true
        ? CheckValues.value_1
        : initial.two
        ? CheckValues.value_2
        : initial.three
        ? CheckValues.value_3
        : initial.four
        ? CheckValues.value_4
        : initial.five
        ? CheckValues.value_5
        : alert('Answers can not be blank')

    let uri = '/course/course'
    var stateNew = { ...state }

    stateNew.user.progress.course.Assess = {
      ...stateNew.user.progress.course.Assess,
      CheckThree,
    }
    let res = await saveScoring(
      JSON.stringify({
        course: `assess_critical_thinking`,
        assessment: AddScore(state?.user?.progress?.course?.Assess, 3),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (initial.one || initial.two || initial.three || initial.four || initial.five == true) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      history.push('/assess_critical_thinking_4')
    }
  }
  useEffect(() => {
    switch (Object.values(CheckValues).indexOf(state?.user?.progress?.course?.Assess?.CheckThree)) {
      case 0:
        {
          stateinital({ ...initial, one: true })
        }
        break
      case 1:
        {
          stateinital({ ...initial, two: true })
        }
        break
      case 2:
        {
          stateinital({ ...initial, three: true })
        }
        break
      case 3:
        {
          stateinital({ ...initial, four: true })
        }
        break
      case 4: {
        stateinital({ ...initial, five: true })
      }

      default:
        break
    }
  }, [])

  return (
    <div className='bg assess_height'>
      <div className='h-16 bg_blue  flex flex-row justify-between fixed w-full top-0'>
        <div>
          {open && (
            <div>
              <GlobalMenu openMenu={() => toggleMenu(false)} />
            </div>
          )}

          <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
            {!open ? (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
                <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
                <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
              </div>
            ) : (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
              </div>
            )}
          </div>
        </div>
      </div>
      {!open && (
        <div className='w-screen mt-16 calc_height '>
          <div className='crical_thinking_splash2 ctBg bg-no-repeat pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center ' style={{ top: '-12px' }}>
              <div
                className='text-5xl text-center relative text-white _bold font-black '
                style={{ lineHeight: '48px' }}>
                <img src={logo8} alt='logo' className='assessImage' />
              </div>
            </div>
            <div className='relative ct_Content'>
              <h4 className='text-center text-sm leading-6 font-medium _bold uppercase assesHeading '>
                Critical Thinking
              </h4>
              <h4 className='text-center assessAssesment  mt-6 _bold  mb-14'>ASSESSMENT</h4>

              <h4 className='text-center assesFontBody pb-5' style={{ color: '#FFFFFF' }}>
                I can tell whether a source of information is good or bad.
              </h4>

              <div className='flex flex-row mx-7 justify-center items-center'>
                <div className='flex flex-col justify-center text-center'>
                  <div className='flex justify-center h-14 w-14   items-center '>
                    <img
                      src={initial.one ? RightB : check}
                      onClick={() =>
                        stateinital({
                          ...initial,
                          one: initial.one === false || true,
                          two: false,
                          three: false,
                          four: false,
                          five: false,
                        })
                      }
                    />
                  </div>
                  <div className='assessSelection font_9' style={{ marginTop: '-10px' }}>
                    Never
                  </div>
                </div>

                <div className='flex flex-col  justify-center text-center'>
                  <div className='flex justify-center h-14 w-14   items-center'>
                    <img
                      src={initial.two ? RightB : check}
                      onClick={() =>
                        stateinital({
                          ...initial,
                          two: initial.two === false || true,
                          one: false,
                          three: false,
                          four: false,
                          five: false,
                        })
                      }
                    />
                  </div>
                  <div className='assessSelection font_9' style={{ marginTop: '-10px' }}>
                    Seldom
                  </div>
                </div>

                <div className='flex flex-col  justify-center text-center'>
                  <div className='flex justify-center h-14 w-14   items-center'>
                    <img
                      src={initial.three ? RightB : check}
                      onClick={() =>
                        stateinital({
                          ...initial,
                          three: initial.three === false || true,
                          two: false,
                          one: false,
                          four: false,
                          five: false,
                        })
                      }
                    />
                  </div>
                  <div className='assessSelection font_9' style={{ marginTop: '-10px' }}>
                    Sometimes
                  </div>
                </div>

                <div className='flex flex-col  justify-center text-center'>
                  <div className='flex justify-center h-14 w-14   items-center'>
                    <img
                      className=''
                      src={initial.four ? RightB : check}
                      onClick={() =>
                        stateinital({
                          ...initial,
                          four: initial.four === false || true,
                          two: false,
                          three: false,
                          one: false,
                          five: false,
                        })
                      }
                    />
                  </div>
                  <div className='assessSelection font_9' style={{ marginTop: '-10px' }}>
                    Often
                  </div>
                </div>
                <div className='flex flex-col  justify-center text-center'>
                  <div className='flex justify-center h-14 w-14   items-center'>
                    <img
                      className=''
                      src={initial.five ? RightB : check}
                      onClick={() =>
                        stateinital({
                          ...initial,
                          five: initial.one === false || true,
                          two: false,
                          three: false,
                          four: false,
                          one: false,
                        })
                      }
                    />
                  </div>
                  <div className='assessSelection font_9' style={{ marginTop: '-10px' }}>
                    Always
                  </div>
                </div>
              </div>
            </div>
            {!open && (
              <div className='w-full h-[80px] mt-[30px] pb-[40px]  button_bottom  mx-auto assessBottom'>
                <button
                  className='relative  mb-5'
                  onClick={() => history.push('/assess_critical_thinking_2')}>
                  <img alt='logo' src={backarrow} className='h-10 w-10' />
                </button>
                <button className='relative  mb-5' onClick={handleSubmit}>
                  <img alt='logo' src={logo7} className='h-10 w-10' />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
