import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import ClientMenu from '../components/Client/ClientMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import useApp from '../context/useApp'
import { AppConstants } from '../appConstanst'
import Scalton from './common/Scalton'
const Cards = props => {
  return (
    <div className='flex flex-col gap-5 mt-3 cursor-pointer relative' onClick={props.click}>
      <div
        className='flex opacity-10'
        style={{ backgroundColor: ' var(--catalina-blue)', height: '64px' }}></div>
      <div className='min-h-full min-w-full overflow-hidden absolute top-3'>
        <div className='flex flex-grow justify-between pl-[27px] pr-[24px]'>
          <button
            className={'text-sm tracking-normal font-bold uppercase _bold'}
            style={{ color: 'var(--jacarta)', lineHeight: '14px' }}
            onClick={props.click}>
            {props.title}
          </button>
          <div
            className='text-5xl leading-[58px] _light'
            style={{ color: 'var(--jacarta)', lineHeight: '48px' }}>
            {props.number}
          </div>
        </div>
      </div>
    </div>
  )
}

const ClientDashboard = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [clintDashboardData, setClintDashboardData] = useState({})
  const { state } = useApp()
  const [liveUsers, setLiveUsers] = useState(0)
  const [pastWeekUsers, setPastWeekUsers] = useState(0)
  const [pastMonthUsers, setPastMonthUsers] = useState(0)
  const [aveFrequency, setAverFrequency] = useState(0)
  const [timePerVisit, setTimePerVisit] = useState(0)
  const [clientScoringData, setClientScoringData] = useState(0)
  useEffect(() => {
    var isMemoryLeak = true

    const CallingClientData = async () => {
      await fetch(`${AppConstants.BASE_URL}/admin/dashboard`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${state.token}` },
      })
        .then(res => res.json())
        .then(data => {
          const Wholedata = data
          setClintDashboardData(Wholedata)
          setLiveUsers(Wholedata.data?.google_analytics.rows[0].metricValues[0].value)
          setPastWeekUsers(Wholedata.data?.google_analytics.rows[0].metricValues[1].value)
          setPastMonthUsers(Wholedata.data?.google_analytics.rows[0].metricValues[2].value)
          setAverFrequency(Wholedata.data?.google_analytics.rows[0].metricValues[5].value)
          setTimePerVisit(Wholedata.data?.google_analytics.rows[0].metricValues[4].value)
          setClientScoringData(Wholedata.data?.client_scoring_data)
        })
    }
    if (isMemoryLeak) {
      CallingClientData()
    }

    return () => {
      isMemoryLeak = false
    }
  }, [])
  const mDate = state?.user.created_at
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const memberYear = new Date(mDate).getFullYear()
  const m = new Date(mDate)
  const memberMonth = month[m.getMonth()]
  const memberDate = new Date(mDate).getDate()
  const navigateToSignin = _ => {
    history.push('/logout')
    localStorage.clear()
  }

  localStorage.setItem('totalUser', clintDashboardData.total_users_count)
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto overflow-x-hidden'>
      {/* {open && (
        <div>
          <ClientMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div> */}
      {/* {!open ? ( */}
      <>
        <h2 className='text-center _medium mt-11 text-2xl normal' style={{ color: '#3C0774' }}>
          Client Dashboard
        </h2>
        <p
          className='text-center mt-11 text-sm px-2 _medium leading-[18px]'
          style={{ color: 'var(--windsor)' }}>
          ({state.user.name})
        </p>
        <p
          className='text-center _bold not-italic mt-[3px] font normal leading-[13px] '
          style={{ color: 'var(--windsor)' }}>
          Admin Since {memberMonth} {memberDate}, {memberYear}
        </p>

        <div
          className='text-center mt-14 mb-8 text-2xl normal _medium leading-[29px]'
          style={{ color: 'var(--windsor)' }}>
          Group Code
        </div>
        {clintDashboardData?.data?.client_g_code?.length > 0 ? (
          <div
            className='flex flex-col gap-5 mt-3 cursor-pointer relative'
            // onClick={() => history.push('/client-dashboard')}
          >
            <div
              className='flex opacity-10'
              style={{ backgroundColor: ' var(--catalina-blue)', height: '64px' }}></div>
            <div className='min-h-full min-w-full overflow-hidden absolute top-3'>
              <div className='flex flex-grow justify-between pl-[27px] pr-[24px]'>
                <button
                  className={'text-sm leading-[18px] _bold'}
                  style={{ color: 'var(--jacarta)', lineHeight: '14px' }}
                  // onClick={() => history.push('/client-dashboard')}
                >
                  Code
                </button>
                <div
                  className='text-5xl leading-[58px] _light ml-2'
                  style={{ color: 'var(--jacarta)', lineHeight: '48px' }}>
                  {`${clintDashboardData.data?.client_g_code[0].g_code}`}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Scalton />
        )}

        <div
          className='text-center my-7 text-2xl _medium leading-[29px]'
          style={{ color: 'var(--windsor)' }}>
          User Info
        </div>

        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title=' Total Users'
          number={clintDashboardData.data?.no_of_users_in_a_client}
        />
        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title='ACTIVE %'
          number={`${Number.parseFloat(
            clintDashboardData.data?.no_of_users_in_a_client > 0
              ? 100 / clintDashboardData.data?.no_of_users_in_a_client
              : 0,
          ).toFixed(2)}%`}
        />

        <div
          className='text-center my-6 text-2xl _medium not-italic font-medium'
          style={{ color: 'var(--windsor)' }}>
          Activity
        </div>

        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title='PAST 24 HOURS'
          number={liveUsers}
        />

        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title='past week'
          id='displayUsers'
          number={pastWeekUsers}
        />

        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title='past month'
          number={pastMonthUsers}
        />

        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title='Ave frequency'
          number={aveFrequency}
        />

        <Cards
          // click={() => {
          //   history.push('/client-dashboard')
          // }}
          title='Ave time per visit'
          number={Math.floor(timePerVisit / 60) + 'M'}
        />

        <Cards
          // click={() => {
          //   history.push('/admin-dashboard')
          // }}
          title='AVE BUSINESS FITNESS'
          number={
            Math.round(clientScoringData?.fbf_score_per)
              ? Math.round(clientScoringData?.fbf_score_per)
              : 0
          }
        />

        <Cards
          // click={() => {
          //   history.push('/admin-dashboard')
          // }}
          title='STARTS'
          number={clientScoringData?.total_start ? clientScoringData?.total_start : 0}
        />

        <Cards
          // click={() => {
          //   history.push('/admin-dashboard')
          // }}
          title='COMPLETIONS'
          number={clientScoringData?.completion ? clientScoringData?.completion : 0}
        />

        <Cards
          // click={() => {
          //   history.push('/admin-dashboard')
          // }}
          title='% OF COMPLETIONS'
          number={clientScoringData?.completion_per ? clientScoringData?.completion_per : 0}
        />

        <Cards
          // click={() => {
          //   history.push('/admin-dashboard')
          // }}
          title='AVE KNOWLEDGE GAIN'
          number={clientScoringData?.avg_kg ? clientScoringData?.avg_kg : 0}
        />

        <Cards
          // click={() => {
          //   history.push('/admin-dashboard')
          // }}
          title='AVE CONFIDENCE GAIN'
          number={clientScoringData?.avg_cf ? clientScoringData?.avg_cf : 0}
        />

        <div className='mt-20 mb-[75px] text-center'>
          <button
            className='h-[25px] rounded-[20px] font leading-[13px] _semiBold w-[123px]'
            style={{ backgroundColor: '#3C0774', color: 'white' }}
            onClick={navigateToSignin}>
            LOG OUT
          </button>
        </div>
      </>
      {/* ) : (
        ''
      )} */}
    </div>
  )
}

export default ClientDashboard
