import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per1 from '../assets/images/personal2.jpg'
import per2 from '../assets/images/personal22.svg'
import per3 from '../assets/images/personal4.jpg'
import per4 from '../assets/images/personal5.jpg'
import per5 from '../assets/images/personal6.jpg'
import per6 from '../assets/images/personal7.jpg'
import per7 from '../assets/images/personal8.jpg'
import per8 from '../assets/images/personal9.jpg'
// import cog_flex from '../assets/images/Persuasion1.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { UpdateAssessments } from './Asses/Comm_Text'
export const PersonalBranding1_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.personalBranding?.assesment[0]
      ? state?.user?.progress?.course?.personalBranding?.assesment[0]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }

    if (!stateNew?.user?.progress?.course['personalBranding']) {
      stateNew.user.progress.course['personalBranding'] = {
        ...stateNew.user.progress.course.personalBranding,
        assesment: [
          Number(localStorage.getItem('Pbarvalue16')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue16')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course.personalBranding,
        0,
        Number(localStorage.getItem('Pbarvalue16')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue16')), percent1),
      )
    }
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        assessment: state?.user?.progress?.course?.personalBranding?.assesment[0],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue16', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/personalbranding1.2')
    } else {
      history.push('/personalbranding1.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between  fixed z-10 top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className=' pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-full mt-16 flex flex-col  '>
          <div className='personal  pl-5 pr-5 flex flex-col items-center  justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange grid place-items-center'>
                <div
                  className='text-5xl text-center  text-white font-black _black'
                  style={{ lineHeight: '48px' }}>
                  1
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                INSPIRE
              </h4>
              <h4 className='text-[26px]  text-center  leading-6 _medium text-white pb-4 '>
                Personal Branding 1
              </h4>
              <p
                className='text-center font11 z-0 _regular text-white _margin_px'
                style={{ lineHeight: '21px', opacity: '.8' }}>
                Discover what personal branding is and its role in helping you stand out and inspire
                others.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white opacity-80'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px]'>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>

            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>PERSONAL BRANDING 1</h4>
            <p className='_regular text-2xl work_out mb-4 text-center  _margin_px'>
              What is Personal Branding?
            </p>
            <div className='_regular work_out1 text-base mb-5'>
              <p className='self'>
                Put simply, branding is what you believe to be true about a person, place, or thing.
                You might say it’s your reputation. Or, as Jeff Bezos said, “Your brand is what
                people say about you when you’re not in the room.”
              </p>

              <p className='self'>
                Personal branding is exactly what it sounds like—branding that is focused on you.
                Who are you? What do you stand for? And, why do you matter? These are all questions
                that each one of us is asking about everyone else around us all the time. It’s
                critical to take control of your personal branding process because if you leave it
                up to the world, they almost always get it wrong.
              </p>
            </div>
            <img src={per1} className='w-full mb-6' alt='logo' />
            <p className='_regular text-2xl work_out  text-center mb-4 _margin_px'>
              The Personal
              <br /> Branding Big 4
            </p>
            <div className='_regular work_out1 text-base mb-5'>
              <p className='self'>
                There are four key questions each one of us asks whenever we engage with anyone. We
                are much more aware and focused when we meet someone new, but we are still
                constantly assessing those around us, even when we know them well.
              </p>

              <p className='self'>
                Review the following infographic. Make sure you understand and remain aware of
                the big four questions we use to determine the hierarchy and value of those around
                us.
              </p>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={per2} style={{ width: '100%' }} alt='img not found'></img>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-[40px] '>
              <p className='_regular work_out text-2xl text-center bold mb-7 _margin_px'>
                The History Of <br /> Personal Branding
              </p>
              <div className='_regular work_out1 text-base mb-5 '>
                <p className='self'>
                  Branding and personal branding are as old as humanity itself. From the beginning
                  of time, individuals, cities, and nations have tried to highlight their advantages
                  and certainly their achievements. They have tried to position themselves and
                  categorize themselves as the best place to do trade, the best place for finance,
                  or the best place for agriculture. And of course, individuals have always sought
                  to highlight their achievements by creating legends, stories, and building statues
                  and monuments.
                </p>

                <p className='self'>
                  Today is not much different. Countries around the world have created brands of who
                  they are and why they matter. Switzerland is known for chocolate and private
                  banking. China is known for producing some of the world’s best products at a low
                  cost. The Middle East is known for producing oil, and America’s Midwest is known
                  for producing wheat and corn. Not all brands are positive. If left up to the world
                  and the media, many locations and individuals can be negatively branded. Today,
                  Mexico struggles with a negative branding that includes violent gang problems, as
                  do other Central and South American countries, like Venezuela.
                </p>
              </div>
              <img src={per3} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='self'>
                  Individuals are just like countries or products. We are all trying to self-package
                  our successes, skills, and interests. And most of us work hard—even
                  unconsciously—building our own personal brands. We tell our stories on LinkedIn,
                  Instagram, or other platforms, and we tend to share only the very best about
                  ourselves. Put simply, we tend to share what we want the world to believe to be
                  true about us, rather than what may actually be true.
                </p>
              </div>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                Where Did “Personal Branding” Come From?
              </p>
              <div className='_regular work_out1 text-base mb-5 self'>
                Many believe that the renowned business management guru Tom Peters first coined the
                term “personal branding” in his 1997 article “The Brand Called You.” But actually,
                the idea of personal branding was first introduced by the personal success author
                Napoleon Hill in his 1937 book “Think and Grow Rich.” Ultimately, branding took
                center stage in 1980 with the breakaway book “Positioning: The Battle for Your Mind”
                by Al Ries and Jack Trout. These authors would go on to write the world’s most
                successful and powerful books on branding, including “Positioning” and “The 22
                Immutable Laws of Branding,” (Al and Laura Ries). Their ideas have become the
                foundation of not only branding but also how each of us makes purchasing decisions.
              </div>

              <img src={per4} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='self'>
                  Today, the internet has changed everything. Businesses and individuals are
                  constantly online, consciously and unconsciously, creating content that either
                  builds or erodes their brands. The market is more sensitive to branding than ever
                  before. The wrong statement, or even the right statement taken in the wrong way,
                  can quickly destroy companies and careers.
                </p>

                <p className='self'>
                  We will explore the process of personal branding in more depth later, but it’s
                  important to understand now that every successful brand has three key components
                  (“brand legs”) that keep it strong. Just like a stool, if any of the three legs
                  are weak, you put your brand in jeopardy.
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                The 3 Legs Of <br /> Your Brand Stool
              </p>
              <div className='_regular work_out1 text-base mb-5 min-h-[370px]'>
                <div className='flex flex-col gap-8 items-center'>
                  <div className='c'>1</div>
                  <h1 className='b'>Your Frame Brand</h1>
                  <div className='c'>2</div>
                  <h1 className='b'>Your Experience Brand</h1>
                  <div className='c'>3</div>
                  <h1 className='b'>Your Performance Brand</h1>
                </div>
              </div>
            </div>
          </div>
          <img src={per5} className='w-full mb-10' alt='logo' />
          <div className='_regular work_out1 text-base mb-7 w-full'>
            <div className='flex flex-col items-center gap-2 w-full'>
              <h1 className='_regular work_out text-2xl text-center  _margin_px font-black'>1.</h1>
              <p className='_regular work_out text-2xl text-center w-full _margin_px'>
                Your Frame Brand
              </p>
            </div>
            <p className='self'>
              First, let’s talk about your frame brand—it’s how people pitch or introduce you. Have
              you ever heard the term, “You’ve been framed?” It happens every day when someone
              introduces you because it sets the stage for whether or not someone should trust you.
              Today, introductions are more important than ever before, and it’s critical that you
              help those who introduce you get it right. We’ll explore how to do this a bit later.
            </p>
          </div>
          <img src={per6} className='w-full mb-10' alt='logo' />
          <div className='_regular work_out1 text-base mb-7 w-full'>
            <div className='flex flex-col items-center gap-2 w-full'>
              <h1 className='_regular work_out text-2xl text-center  _margin_px font-black'>2.</h1>
              <p className='_regular work_out text-2xl text-center w-full _margin_px'>
                Your Experience Brand
              </p>
            </div>
            <p className='self'>
              Second, is your experience brand. This is anywhere someone can experience your brand
              in any way. The customer experience really is the new battlefield. As a matter of
              fact, 73% of consumers cite customer experience as an important factor in their
              purchase decision. But what really is a customer experience? It’s lots of little
              pieces. A customer experience is what they see, hear, touch, taste, smell, and feel
              when they engage with your brand. The more senses involved, the deeper the experience
              is ingrained in our memories and the more emotional it becomes.
            </p>
          </div>
          <img src={per7} className='w-full mb-10' alt='logo' />
          <div className='_regular work_out1 text-base mb-7 w-full'>
            <div className='flex flex-col items-center gap-2 w-full'>
              <h1 className='_regular work_out text-2xl text-center  _margin_px font-black'>3.</h1>
              <p className='_regular work_out text-2xl text-center w-full _margin_px'>
                Your Performance Brand
              </p>
            </div>
            <p className='self'>
              Third, is your performance brand. This is how your customers or those around you judge
              your value. Warren Buffett said, “Price is what you pay. Value is what you get,” and
              he was right. How is your performance being judged, and how are you being rated on
              value? We’re going to talk a lot about performance because this is where the rubber
              hits the road. Stay tuned to learn how to exceed expectations.
            </p>
          </div>
          <div className='flex flex-col items-center _mt-58 mb-11'>
            <h4 className='_bold font work_out tracking-[2px]'>PERSONAL BRANDING 1</h4>
            <p className='_regular text-2xl work_out mb-8 text-center  _margin_px'>
              Personal Branding Summary
            </p>
            <p className='self ssk'>
              <span className='font-black _bold'>
                A brand is simply what you believe to be true about a person, place, or thing.&nbsp;
              </span>
              Today, we are busier than ever before, so we tend to judge the book by its cover
              because we just don’t have time to read the book. The people surrounding us are
              constantly making large and small judgments about who we are, what we do, and if we
              can be of value to them or not. We can’t leave it up to the world to create our story
              for us—we must take control of the process. This is personal branding.
            </p>
          </div>
          <img src={per8} className='w-full mb-10' alt='logo' />
          <div className='_regular work_out1 text-base mb-7 w-full'>
            <p className='self'>
              Personal branding is your ability to define who you are, what’s important to you, and
              why you matter. Your personal brand encapsulates everything about you. How you walk,
              talk, dress, and wear your hair, along with the car you drive, the neighborhood you
              live in, your city, and even your country can carry attributes that define your brand.
              It’s time to take control of your personal brand!
            </p>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
