import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import BR21 from '../assets/images/BR-21.jpg'
import BR13 from '../assets/images/BR-13.jpg'
import BR20 from '../assets/images/BR-20.jpg'
import BR19 from '../assets/images/BR-19.jpg'
import BR18 from '../assets/images/BR-18.jpg'
import BR17 from '../assets/images/BR-17.jpg'
import BR16 from '../assets/images/BR-16.jpg'
import BR15 from '../assets/images/BR-15.jpg'
import BR24 from '../assets/images/BR-24.jpg'
import BR14 from '../assets/images/BR-14.jpg'
import BR22 from '../assets/images/BR-22.jpg'
import BR23 from '../assets/images/BR-23.jpg'
import PB_Flow from '../assets/images/PB_Flow.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
export const PersonalBranding1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  let isMemoryLeak = useRef(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.personalBranding?.assesment[2]
      ? state?.user?.progress?.course?.personalBranding?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.personalBranding,
      2,
      Number(localStorage.getItem('Pbarvalue18')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue18')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_inspire`,
        assessment: AddCourses(state?.user?.progress?.course?.personalBranding?.assesment, 3),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }
  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue18', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/personalbranding1.6')
    } else {
      history.push('/personalbranding1.5')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='pt-5 percentage text-white pl-10 pr-5'>{percent1 > 97 ? 100 : percent1}%</h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='personal pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                INSPIRE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Personal Branding 3
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Learn how to harness the power of personal branding to help you lead and inspire
                others.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px]'>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>PERSONAL BRANDING 3</h4>
            <p className='_regular text-2xl work_out  mb-5 text-center  _margin_px'>
              Building A Powerful Personal Brand
            </p>
            <div className='_regular work_out text-base mb-6 _margin_px'>
              <p>
                You should now have a clear understanding about what personal branding is and why
                you need it. Now let’s explore how you can build a powerful personal brand. As
                previously discussed, every job—and virtually every professional and personal
                situation you find yourself in—will give you an opportunity to apply your personal
                branding skills. Remember, the skills you are learning are just as applicable in
                your personal life as they are in your professional life.
              </p>
            </div>
            <img src={BR13} className='w-full mb-11' alt='logo' />
            <div className='flex items-center w-full '>
              <img src={PB_Flow} className='w-full mb-11' alt='flow' />
            </div>
          </div>
          <img src={BR21} className='w-full mb-11' alt='logo' />

          <div className='flex flex-col items-center  '>
            <p className=' work_out _bold text-2xl text-center mt-7'>1.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Defining The Problem You Solve
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                To be of value, you must solve a problem. Whether you’re trying to get a new job,
                grow in your existing job, or start a new business, the spoils always go to the
                person or company that is able to best articulate and sell their problem/solution
                statement to the market, not the company or individual with the best solution. It’s
                important for your personal brand that you make sure to clearly articulate the
                problem you solve.
              </p>
              <br />
              <p>
                For example, if the problem is keeping online networks secure, then your personal
                brand solution would be, “I’m a network security expert.” As simple as that may
                sound, many of us are unprepared to quickly and clearly articulate the problem we
                solve.
              </p>
            </div>
            <img src={BR20} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>2.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 '>
              Defining Your <br /> Personal Category
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                Your category is your “what”—the what you do. To make sense of the world around us,
                we categorize everything from cars, to countries, to schools, to phones, to people.
                Why? It makes our choices easier. If I need a network security expert, I don’t need
                to talk to everyone in the computer industry—just to the people in the “network
                security” category. Your “what” (or your category) in your personal brand is
                critical—you need to make sure that people understand what you do. The best way to
                determine your category is to try to determine how someone could find you if they
                had to Google you. If we don’t know the exact product name, we Google by category.
              </p>
              <br />
              <p>
                For example, if I was concerned about someone hacking my network, I would probably
                Google “network security expert.” If you work in network security, your category is
                “network security.” Expert is your attribute. More on that next.
              </p>
            </div>
            <img src={BR19} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>3.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Defining Your Category’s Attribute
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                Once you know what someone does, you then want to know where they are in the pack.
                For example, if you know that someone does network security, now you want to know if
                they are any good at it. All of us want to buy the best we can afford, and we buy
                everything according to the attributes that are most important to us. For example,
                if luxury is most important to me, I might buy a Mercedes. If safety is most
                important to me, I might choose a Volvo. And if fuel efficiency is most important to
                me, I might choose a Honda. Once you have articulated your category, or what you do,
                it’s critical that you also articulate the attribute that is most important in that
                category. Every category may have different attributes, so always make sure to look
                at the category from the perspective of the person across from you—your customer,
                client, or even boss.
              </p>
              <br />
              <p>
                So, back to our network security <br /> example. If you’re a “network security
                expert,” the word “expert” helps position your personal brand at the top of the
                network security category.
              </p>
            </div>
            <img src={BR18} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>4.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Your Brand Positioning Statement
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                Your personal brand positioning statement is the combination of your category and
                category attribute. It’s the one-liner that people will always attach to your name
                when they talk about you or introduce you. Don’t leave it up to others. Make sure
                that you give them the one line—your brand positioning statement—that matters most.
              </p>
              <br />
              <p>
                For example, if one of your friends was talking to a colleague about network
                security, they might say, “You should talk to my friend Tom; he’s a network security
                expert.”
              </p>
            </div>
            <img src={BR17} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>5.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Understanding Category Beliefs
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                What does this really mean? What it means is that all of us have beliefs that
                already exist about almost everything and everyone around us, and we attach
                attributes to those beliefs. And of course, that’s true about network security
                experts. If you work in IT, many people might believe that you’re quiet or even a
                bit introverted. They are also likely to believe that you’re smart. Understanding
                what beliefs already exist about your category can help you build a powerful
                personal brand.
              </p>
              <br />

              <p>
                For example, since people might believe that IT experts are introverted, you can
                work on being more outgoing, talkative, and expressive. This may help you grow in
                your career or get more business because customers or clients feel they can
                communicate better with you.
              </p>
            </div>
            <img src={BR16} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>6.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Developing A Trust Statement
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                Everything in life comes down to trust. Once we trust another person or ourselves in
                making a choice, we tend to take action. Put simply, we need to give people a reason
                to believe that we are the best at what we do. If I say that I am a network security
                expert, why should someone believe me? Anyone can and does say anything today,
                especially online. Trust statements can be several things, including testimonials,
                awards, certificates, or designations and certifications. Your trust statement
                should always follow your positioning statement.
              </p>
              <br />
              <p>
                For example, if you say, “I am a network security expert,” what should follow is the
                reason to believe that you are an expert—your trust statement. “I’m a Microsoft
                certified Azure security expert,” would be a good example of driving home a reason
                to believe.
              </p>
            </div>
            <img src={BR24} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>7.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Using The <br /> Right Name
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                We don’t get to choose our name when we’re born, but we can always change it when we
                grow up. Names, like categories, have attributes, and not all of them are positive.
                As a matter of fact, many studies support the idea that complex or hard to pronounce
                names are less trustworthy. It may not be right, or even fair, but if we want to
                win, we need to brand for success.
              </p>
              <br />
              <p>
                When it comes to naming ourselves, our businesses, or our products, we need to
                remember that simpler is better. Short names (two syllables ) are most memorable.
                Make sure your name is easy to remember, easy to spell, and easy to share, and
                you’ll be branding for success. You don’t need to formally change your name, just
                consider using a simple nickname that helps you stand out.
              </p>
              <br />
              <p>
                Celebrities and movie stars have been doing this forever. Ralph Lifshitz understood
                the stigma that came with his name and built a fashion empire by changing it to
                Ralph Lauren. Gordon Matthew Thomas Sumner was far too long to put on a ticket, so
                he simply changed his name to Sting. Stefani Joanne Angelina Germanotta made it big
                by choosing a simple name, Lady Gaga.
              </p>
            </div>
            <img src={BR15} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>8.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Developing Your Difference
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                Once someone knows and understands what you do and your attributes, they want to
                understand how you’re different from your competitors. In a crowded world, standing
                out has become more important than ever before. But you want to make sure you do it
                right. You want to make sure that your difference is valued.
              </p>
              <br />
              <p>
                Your personal brand differences can include how you dress, speak, act, and even the
                car you drive or where you live. What can also differentiate your personal brand is
                where you went to school, where you work, and your accomplishments. Look for areas
                where you can be unique—truly one of a kind. And if you can’t be unique, then make
                sure that you’re different.
              </p>
              <br />
              <span>
                Differences only matter when they can be recognized. Make sure to create contrast—a
                noticeable difference—between you and your competitors.
              </span>
              <span>
                {' '}For example, before-and-afters are one of the best ways to showcase contrast and
                difference. If you’re a network security expert with Microsoft certifications, you
                might share a story about how before you started your job, the network lacked
                security with multiple annual breaches, but ever since you’ve been there, there have
                been no breaches. This not only provides contrast and showcases your difference but
                also builds trust.
              </span>
            </div>
            <img src={BR14} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>9.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The 3 Legs of Your Personal Brand
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                Earlier, we explored building your frame brand, experience brand, and your
                performance brand ideas. This is another area where you bring together multiple
                pieces of your brand.
              </p>
              <br />
              <p> Let’s do a deeper dive into these three critical areas. </p>
              <br />
              <span className='_bold'>1. Frame Brand</span>
              <br />
              <br />
              <p>
                Remember, your frame brand is how other people introduce you. Make sure you give
                them your brand positioning statement and a couple of trust ideas. As noted before,
                “You should call my good friend Tom; he’s a network security expert with multiple
                Microsoft certifications.” This is a great introduction—it drives home your category
                (network security), your attribute (expert), and trust ideas (Microsoft
                certifications).
              </p>
              <br />
              <span className='_bold'>2. Experience Brand</span>
              <br />
              <br />
              <p>
                Your experience brand is what happens next. If your friend made an introduction and
                a potential new customer calls you, how will they experience your brand? Did you
                answer the phone promptly? If not, did you have a professional voicemail? Did you
                return their call promptly? Did you speak professionally and set up the next steps?
                Did they visit your website or your social media? All of these touch points are your
                experience brand and either add value or reduce the value of your personal brand.
                Make sure you get them right.
              </p>
              <br />
              <span className='_bold'>3. Performance Brand </span>
              <br />
              <br />
              <p>
                Your performance brand is where the rubber hits the road. Either your client,
                colleague, or boss will have an expected experience, an unexpected negative
                experience, or an unexpected positive experience. This is called “the expectation
                gap,” first attributed to Steve Brazell in his book “Clear! The Simple Guide to
                Keeping Your Business Alive and Kicking” in 2011. When we give customers or
                colleagues what they paid for, nothing really happens. There was an expectation, and
                it was met. If we give less than what someone was expecting, they feel they didn’t
                get value, and we create brand enemies. Only when we give more, when we exceed
                expectations, do we create brand fans. If you want to win today, you must exceed
                expectations every time. Make sure you understand what the other party is expecting
                first. If you don’t know what someone is expecting, you can’t exceed those
                expectations.
              </p>
            </div>
            <img src={BR22} className='w-full mb-7' alt='logo' />
            <p className=' work_out _bold text-2xl text-center '>10.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Developing Your Personal Brand Pitch
            </p>
            <div className='_regular work_out text-base  _margin_px mb-7'>
              <p>
                This is the summation of your personal brand. Winning or losing all comes down to
                your ability to sell yourself to others. This is the essence of personal branding.
                Your pitch not only includes what you say but also how you say it, your body
                language, the way you wear your hair, your clothes, your watch, your shoes, the way
                you shake hands, whether or not you showed up on time, and every other aspect of an
                interaction that creates perceptions in the mind of the person across from you.
              </p>
              <br />

              <span>
                Your personal brand pitch must include your category, your category attribute, and
                trust ideas—and it needs to be short and powerful.
              </span>
              <span>
                {' '}For example, “Hi, I’m Tom. One of the biggest problems in the world today is
                cyberattacks. I’m a network security expert with multiple Microsoft security
                certifications. I’ve helped reduce cyberattacks for my clients by over 90%.” That’s
                a powerful personal pitch! Simple and to the point—it covers the problem, solution,
                category, category attribute, and reasons to believe.
              </span>
            </div>
            <img src={BR23} className='w-full mb-6' alt='logo' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-10'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                PERSONAL BRANDING 3
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Personal Branding Summary And Final Wrap-Up
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  Unfortunately, personal branding is rarely given much conscious thought, let alone
                  strategy.
                  <span className='_bold'>
                    {' '}
                    Yet, personal branding is at the center of everything we do, who we are, and how
                    everyone around us perceives our value.{' '}
                  </span>
                  The sooner you understand that you are a brand and that you must carefully curate
                  your brand’s attributes and then clearly articulate your pitch, the more
                  successful you’ll be.
                </p>
                <br />
                <p>
                  The world is constantly changing. We must adapt, change, and evolve our brand to
                  continue to stay relevant. Personal branding is a lifelong journey. Always stay in
                  beta, and keep embracing change and new opportunities—then, clearly and succinctly
                  articulate who you are and why you matter to the world.
                </p>
                <br />
                <p>
                  How you build and manage your personal brand makes the difference between being
                  successful or languishing in your career. Each one of us is constantly being
                  assessed and judged by those around us. They may or may not have all the facts,
                  but they will make judgments based on perceptions. Their perceptions of what they
                  believe to be true about us determine whether or not they choose us. Personal
                  branding is the art and science of taking control of this process and making sure
                  we do everything in our power to drive home the right perceptions and attributes
                  that matter most.
                </p>
              </div>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
