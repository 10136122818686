import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import eq10 from '../assets/images/EQ-10.jpg'
import eq9 from '../assets/images/EQ-9.jpg'
import eq11 from '../assets/images/EQ-11.jpg'
import eq12 from '../assets/images/EQ 12.jpg'
import eq14 from '../assets/images/EQ-14.jpg'
import eq13 from '../assets/images/EQ-13.jpg'
import eq15 from '../assets/images/EQ-15.jpg'
import eq16 from '../assets/images/EQ-16.jpg'
import eq_flow3 from '../assets/images/colaborate3.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { UpdateAssessments } from './Asses/Comm_Text'
export const EmotionalIntelligence3_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.collabrate?.assesment[2]
      ? state?.user?.progress?.course?.collabrate?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.collabrate,
      2,
      Number(localStorage.getItem('Pbarvalue6')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue6')), percent1),
    )
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        assessment:
          state?.user?.progress?.course?.collabrate?.assesment[0] +
          state?.user?.progress?.course?.collabrate?.assesment[1] +
          state?.user?.progress?.course?.collabrate?.assesment[2],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue6', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 96) {
      history.push('/emotional_intelligence3.2')
    } else {
      history.push('/emotional_intelligence3.1')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='emotional_intelligence_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                COLLABORATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Emotional Intelligence 3
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover how to use emotional intelligence in your everyday home and work life as
                well as a proven process you can use to collaborate more successfully.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center'>
            <p className='text-center getFitSmallHeader text1 uppercase mt-[48px] tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='getFitMidHeading work_out mb-3.5 tracking-[2.8px]'>
              EMOTIONAL INTELLIGENCE 3
            </h4>
            <p className='getFitHeadline work_out text-center mb-[27px] mt-[45px]'>
              Emotional Intelligence At Home And Work
            </p>
            <p className='getFitBodySection work_out  mb-5 _margin_px'>
              You should now have a clear understanding about what emotional intelligence is and why
              you need it. Now let’s explore how you will use it in your life every day. <br />
              <br />
              As previously discussed, every job—and nearly every professional and personal
              situation you find yourself in—will give you an opportunity to apply your emotional
              intelligence skills. Remember, the skills you’re learning are just as applicable in
              your personal life as they are in your professional life.
            </p>
            <img src={eq10} className='w-full mb-6' alt='logo' />
            <p className=' work_out getFitBodySection  _margin_px mb-6'>
              Next is an infographic that you can use as a template to ensure you’re always using
              the right strategy when it comes to harnessing the power of emotional intelligence.
            </p>
          </div>

          <div className='flex items-center  '>
            <img src={eq_flow3} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-12'>
              <p className='work_out getFitHeadline text-center mb-6 _margin_px '>
                Understanding The 4 Pillars Of EQ
              </p>
              <p className='getFitBodySection work_out   _margin_px mb-5'>
                Emotional intelligence is your ability to perceive emotions, facilitate thought
                using emotions, understand emotions, and manage emotions. Let’s explore each one of
                these concepts deeper so you can have a better understanding of how you can apply
                emotional intelligence.
              </p>
              <img src={eq9} className='w-full' alt='logo' />

              <p className=' work_out _bold text-2xl text-center mt-8'>1.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Perceiving Emotions
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Perceiving emotions includes both the emotions of those around you and your own.
                Each one of us must be able to identify deceptive, dishonest emotions as well as
                sincere, truthful, and authentic emotions. We must remember that emotions may be
                displayed differently depending on culture and even language. We must be able to
                perceive emotional content in the world around us, including the arts, music, vocal
                cues and tone of voice, facial expressions, body language, and general behavior.
                It’s also important for us to be able to identify our own emotions, feelings, and
                thoughts as well as express them accurately and at the right time and place.
              </p>
              <img src={eq11} className='w-full' alt='logo' />
              <p className=' work_out _bold text-2xl text-center mt-8'>2.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Facilitating Thought Using Emotion
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Our emotions play a huge role in our ability to think clearly and select and define
                problems accurately. Our mood can dramatically affect our cognitive abilities as
                well as our perceptions. If we are aware of our mood, we can leverage our mood to
                generate different cognitive perspectives. When we understand our current emotional
                state, it becomes easier to prioritize our thinking and direct our focus. Our
                emotions are powerful, and if we increase our emotional awareness, we can use it as
                a way to relate to the experiences and emotions of others as well as gain insights
                and make better judgments.
              </p>
              <img src={eq12} className='w-full ' alt='logo' />
              <p className=' work_out _bold text-2xl text-center mt-8'>3.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Understanding Emotions
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Once we have identified an emotion, we have become aware that it exists and must
                attempt to understand that emotion. Understanding emotions helps us comprehend how
                another person might feel, not only now but also in the future or under a specific
                condition. With practice, we are better able to understand emotional transitions,
                such as from dissatisfaction to happiness. We are also able to better recognize
                situations that are likely to elicit specific emotions—these are often called
                triggers—where a specific word, situation, or emotion may spark a specific emotion.
                <br />
                <br />
                It’s important to differentiate between moods and emotions. A mood is defined as “a
                temporary state of mind or feeling.” An emotion is defined as “a natural instinctive
                state of mind deriving from one’s circumstances, mood, or relationships with
                others.” Put simply, emotions are short-lived feelings that come from a known cause,
                while moods are feelings that are longer lasting than emotions and have no clear
                starting point of formation. Emotions can range from happy to ecstatic to sad or
                even prideful, while moods are either positive or negative. The most important
                aspect of understanding emotions is understanding the consequences of those emotions
                in both personal and professional situations.
              </p>
              <img src={eq13} className='w-full' alt='logo' />
              <p className=' work_out _bold text-2xl text-center mt-8'>4.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Managing Emotions
              </p>
              <p className='_regular work_out text-base  _margin_px mb-12'>
                Managing emotions consists of two key elements:
                <strong className='getFitBold16px'>
                  {' '}
                  1) effectively managing your own emotions to achieve a desired outcome and 2)
                  effectively managing someone else’s emotions to achieve a desired outcome.{''}
                </strong>{' '}
                It’s crucial to understand that you want to manage emotions toward a desired
                outcome, so always begin with the end in mind. What is the desired outcome you want
                to achieve? Once you’ve clearly defined the desired outcome, you need to evaluate
                strategies to maintain, reduce, or intensify your own or someone else’s emotional
                response. During any engagement, make sure to monitor emotional reactions to
                determine if they’re helping you to achieve your desired outcome. Always engage with
                emotions if they’re helpful to achieving your desired outcome, and disengage if not.
                No one likes to be uncomfortable, but remember to stay open to unpleasant feelings,
                negativity, or uncomfortable moments, as they may be critical to gain insights into
                how someone actually feels.
              </p>
              <p className=' work_out _regular text-2xl text-center pb-3.5 mb-4 _margin_px'>
                Signs Of High EQ
              </p>
              <div className='_regular work_out text-base  _margin_px mb-12 eq_list'>
                <ul style={{ listStyle: 'disc' }}>
                  <li className=''>Keeping cool under pressure</li>
                  <li>Taking personal responsibility</li>
                  <li>
                    Listening intently and trying to understand the other person’s point of view
                  </li>
                  <li>Seeking to understand others’ emotions </li>
                  <li>Maintaining healthy friendships and professional relationships</li>
                  <li>Communicating in a positive way</li>
                  <li>Managing one’s own stress well </li>
                  <li>Forgiving easily</li>
                  <li>Feeling understood </li>
                  <li>Having high self-confidence and self-esteem</li>
                </ul>
              </div>
              <img src={eq14} className='w-full' alt='logo' />
              <p className=' work_out _regular text-2xl text-center pb-3.5 mb-4 _margin_px mt-12'>
                Signs Of Low EQ
              </p>
              <div className='_regular work_out text-base eq_list _margin_px mb-10'>
                <ul style={{ listStyle: 'disc' }}>
                  <li>Experiencing out of proportion emotional outbursts</li>
                  <li>Blaming others for personal mistakes </li>
                  <li>Refusing to listen to or understand another person’s point of view </li>
                  <li>Accusing others of being overly sensitive</li>
                  <li>Having difficulty maintaining friendships and professional relationships</li>
                  <li>Being argumentative</li>
                  <li> Becoming stressed easily </li>
                  <li>Holding grudges</li>
                  <li>Feeling misunderstood </li>
                  <li>Getting offended easily</li>
                </ul>
              </div>
              <img src={eq15} className='w-full' alt='logo' />
              <p className=' work_out _regular text-2xl text-center mb-4 _margin_px mt-7'>
                EQ: Close Encounters
              </p>
              <div className='eq_list'>
                <ul
                  className='_regular work_out text-base   _margin_px mb-12'
                  style={{ listStyle: 'disc' }}>
                  <li className='mb-4'>
                    A colleague approaches you. He is very upset and accuses you of giving a project
                    you promised to him to someone else. What do you do?
                  </li>
                  <li className='my-4'>
                    Your boss passes you over for a promotion. What do you do?
                  </li>
                  <li className='my-4'>
                    A client continues to demand work that is clearly not in the contract. What do
                    you do?
                  </li>
                  <li className='my-4'>
                    Your team is being downsized. You need to break the bad news to three people who
                    are not only your colleagues but who have also become your friends. What do you
                    do?
                  </li>
                  <li className='my-4'>
                    You’re stuck at home during a pandemic and everyone’s nerves are on edge. You
                    need some quiet time to get some work done. How do you discuss this with your
                    family?
                  </li>
                  <li>
                    Someone just cut you off in traffic and almost caused an accident. You pull up
                    next to them at the next stoplight. Do you respond? If so, how?
                  </li>
                </ul>
              </div>
              <img src={eq16} className='w-full' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <h4 className='_bold fcont work_out text-center tracking-[2px] text-[10px]'>
                EMOTIONAL INTELLIGENCE 3
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Emotional Intelligence Summary And Final Wrap-Up
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Almost every situation you encounter in your personal and professional life will
                provide an opportunity to use emotional intelligence. Your level of emotional
                intelligence often determines the outcome. Always begin with the end in mind. What
                is your desired outcome? Be aware of your emotions, be aware of others’ emotions,
                and manage both to be successful. <br />
                <br />
                Next to critical thinking, emotional intelligence (EQ) is the most in-demand skill
                in the world today—and the skill that you may end up using the most. Today, high EQ
                is rated as more important to hiring managers than high IQ. Being able to understand
                and manage your own emotions and the emotions of others will ultimately determine
                your level of personal and professional success. <br />
                <br />
                In a world that continues to embrace more technology, more AI, and more machine
                learning, our humanity is becoming more important than ever before.
                <br />
                <br /> Technology will continue to struggle to replicate what makes us human—our
                emotions and our capacity to feel, understand, and empathize. For humans, EQ is the
                future.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
