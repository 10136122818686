import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo from '../assets/images/group-4550-1@1x.png'
import logo1 from '../assets/images/group-4550-2@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo6 from '../assets/images/path-15712@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import { useHistory } from 'react-router-dom'
const AssessCard = props => {
  return (
    <div className='flex flex-row pt-2 pb-2 mt-3 ' style={props.style} onClick={props.onClick}>
      <div
        className=' flex flex-col justify-around items-center    responsive-card'
        style={{ paddingLeft: '18px', paddingRight: '15px', width: '20%' }}>
        <img className='h-[33.2px] w-[27.14px]' alt='logo' src={logo1} />
        <h4
          className='_bold items-center leading-none '
          style={{ color: '#083481', lineHeight: '10px', fontSize: '10px' }}>
          10 min
        </h4>
      </div>
      <div style={{ width: '80%' }}>
        <div className=' text-sm mt-2 _bold' style={{ color: '#083481', lineHeight: '18px' }}>
          {props.title}
        </div>
        <div
          className=' _regular  text-left leading-[14px] mt-1 pr-3 '
          style={{ color: '#083481', fontSize: '11px', lineHeight: '14px' }}>
          {props.discription}
        </div>
      </div>
    </div>
  )
}
export default function Assess() {
  const activeStyle = { backgroundColor: '#f2e8ed', opacity: '100%', height: '80px' }
  const nonActiveStyle = { backgroundColor: '#FFFFFF', opacity: '80%', height: '80px' }
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  return (
    <div className='user_access_cover w-full min-h-screen overflow-y-scroll overflow-x-hidden'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-2 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo6} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      {!open && (
        <div className='flex flex-col'>
          <div className='flex justify-center mt-10'>
            <img style={{ height: '66.41px', width: '54.27px' }} src={logo} alt='logo' />
          </div>
          <h4
            className='text-center text-2xl leading-6 _bold mt-3 mb-5'
            style={{ color: '#FFFFFF' }}>
            ASSESS
          </h4>

          <AssessCard
            style={activeStyle}
            title={'CRITICAL THINKING'}
            iconStyle={{ width: '80px' }}
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_m'}
            discription={
              'Assess your ability to solve problems harnessing the power of critical thinking.'
            }
            onClick={() => history.push('/assess_critical_thinking_0')}
          />

          <AssessCard
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_m'}
            iconStyle={{ width: '80px' }}
            style={activeStyle}
            title={'EMOTIONAL INTELLIGENCE'}
            discription={' Assess your ability to collaborate with those around you using your EQ.'}
            onClick={() => history.push('/assess_eq_0')}
          />

          <AssessCard
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_mw '}
            style={activeStyle}
            title={'CREATIVE THINKING'}
            iconStyle={{ width: '72px' }}
            discription={
              'Assess your ability to create out-of-the-box ideas using creative thinking.'
            }
            onClick={() => history.push('/assess_creative_thinking_0')}
          />
          <h4
            className='text-center text-lg _bold mt-9 '
            style={{ color: '#FFFFFF', lineHeight: '22px' }}>
            COMING SOON
          </h4>
          <h4
            className='pl-12 pr-12 text-center mt-4 mb-7 text-sm _regular '
            style={{ color: '#FFFFFF', lineHeight: '18px' }}>
            More of the world’s most in-demand Business Fitness assessments will be available
            shortly.
          </h4>

          <AssessCard
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_m'}
            style={nonActiveStyle}
            title={'COGNITIVE FLEXIBILITY '}
            iconStyle={{ width: '80px' }}
            discription={'Assess your ability to adapt to change using your cognitive flexibility.'}
          />
          <AssessCard
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_m'}
            style={nonActiveStyle}
            title={'PERSUASION'}
            iconStyle={{ width: '80px' }}
            discription={
              'Assess your ability to communicate your ideas effectively using the power of persuasion.'
            }
          />
          <AssessCard
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_mw'}
            style={nonActiveStyle}
            title={'PERSONAL BRANDING'}
            iconStyle={{ width: '72px' }}
            discription={
              'Assess your ability to inspire those around you using the power of personal branding.'
            }
          />
          <AssessCard
            iconClass={'flex flex-col items-center justify-between gap-[5px] mx-4 gallexy_m'}
            style={nonActiveStyle}
            title={'HYPER-FOCUS'}
            iconStyle={{ width: '80px' }}
            discription={'Assess your ability to achieve more positive outcomes using hyper-focus.'}
          />
          <div
            className='flex justify-center text-xl _medium text-color mb-10'
            style={{ marginTop: '167px' }}
            onClick={() => history.push('/user_dashboard')}>
            <div
              className=' mt-1  rounded-full py-2 pl-2 '
              style={{ background: '#FFFFFF', height: '39px', width: '39px' }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='#3c0774'
                width='22'
                height='22'
                viewBox='0 0 576 512'>
                <path d='M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z' />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
