import React, { useState } from 'react'
import AdminMenu from '../components/AdminComponent/AdminMenu'

import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo6 from '../assets/images/path-15710-10@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'

export default function UserAccountEdit(props) {
  const [open, toggleMenu] = useState(false)

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      <h4
        className='text-center  mt-10 text-2xl leading-6 not-italic _medium'
        style={{ color: 'var(--windsor)' }}>
        Account
      </h4>
      <div className='px-5'>
        <p className='_medium text-sm text-center mt-11 text-txtwin' style={{ lineHeight: '14px' }}>
          (Name Goes Here)
        </p>
        <p className='_bold text-sz text-center mt-1 text-txtwin'>Member Since (DATE)</p>
        <p className='_medium text-lg text-center mt-10 text-txtwin' style={{ lineHeight: '18px' }}>
          Personal Information
        </p>
        <div className='mt-8'>
          <label className='not-italic inline-block  _medium text-sz leading-l10 text-jac'>
            Update First Name
          </label>
          <input
            type='text'
            className='bg-transparent w-full input-border-1 outline-none border-b border-solid border-jac'
            placeholder=''
          />
        </div>
        <div className='mt-8'>
          <label className='not-italic inline-block  _medium text-sz leading-l10 text-jac'>
            Update Last Name
          </label>
          <input
            type='text'
            className='bg-transparent w-full input-border-1 outline-none border-b border-solid border-jac'
            placeholder=''
          />
        </div>

        <div className='mt-8'>
          <label className='not-italic inline-block  _medium text-sz leading-l10 text-jac'>
            Update Email (You must log-in using this email address.)
          </label>
          <input
            type='text'
            className='bg-transparent w-full input-border-1 outline-none border-b border-solid border-jac'
            placeholder=''
          />
        </div>
        <div className='mt-8'>
          <label className='not-italic inline-block  _medium text-sz leading-l10 text-jac'>
            Update Password: Enter OLD Password
          </label>
          <input
            type='text'
            className='bg-transparent w-full input-border-1 outline-none border-b border-solid border-jac'
            placeholder=''
          />
        </div>
        <div className='mt-8'>
          <label className='not-italic inline-block  _medium text-sz leading-l10 text-jac'>
            Enter NEW Password
          </label>
          <input
            type='text'
            className='bg-transparent w-full input-border-1 outline-none border-b border-solid border-jac'
            placeholder=''
          />
        </div>
        <div className='mt-8'>
          <label className='not-italic inline-block  _medium text-sz leading-l10 text-jac'>
            Confirm NEW Password
          </label>
          <input
            type='text'
            className='bg-transparent w-full input-border-1 outline-none border-b border-solid border-jac'
            placeholder=''
          />
        </div>
        {!open && (
          <div className='px-8  mt-32 mb-10 flex justify-between relative'>
            <button className=''>
              <img src={logo1} className='h-10 w-10' alt='logo' />
              <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
            </button>
            <button>
              <img src={logo3} className='h-10 w-10' alt='logo' />
              <img src={logo6} className='absolute h-3.5 ml-2.5 bottom-3' alt='logo' />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
