import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per1 from '../assets/images/hf2.png'
import per2 from '../assets/images/hf3.svg'
import per3 from '../assets/images/hf4.png'
import per4 from '../assets/images/hf5.png'
import per5 from '../assets/images/hf6.png'
import per6 from '../assets/images/hf7.png'
import per7 from '../assets/images/hf8.png'
import per8 from '../assets/images/hf9.png'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
export const Hyper1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  let isMemoryLeak = useRef(true)
  const { state, dispatch } = useApp()
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.hyper?.assesment[1]
      ? state?.user?.progress?.course?.hyper?.assesment[1]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }

    UpdateAssessments(
      stateNew.user.progress.course.hyper,
      1,
      Number(localStorage.getItem('Pbarvalue20')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue20')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_achieve`,
        assessment: AddCourses(state?.user?.progress?.course?.hyper?.assesment, 2),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue20', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/hyper_focus1.4')
    } else {
      return history.push('/hyper_focus1.3')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between  fixed z-10 top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className=' pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-full mt-16 flex flex-col'>
          <div className='personal1 w-full flex flex-col  items-center '>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange grid place-items-center'>
                <div
                  className='text-5xl text-center  text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='mt-auto flex flex-col  sskss justify-between'>
              <div>
                <h4
                  className='text-center text-xs  pb-2 _bold text-white lspacing'
                  style={{ letterSpacing: '6px' }}>
                  ACHIEVE
                </h4>
                <h4 className='text-[26px]  text-center  leading-6 _medium text-white pb-4 '>
                  Hyper-Focus 2
                </h4>
                <p
                  className='text-center font11 z-0 _regular text-white _margin_px'
                  style={{ lineHeight: '21px', opacity: '.8' }}>
                  Explore why you need hyper-focus and when to use it to achieve more with higher
                  quality outcomes.
                </p>
              </div>
              <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white opacity-80'>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>

          <div className='flex flex-col items-center mt-[48px] '>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>Hyper-Focus 2</h4>
            <p className='_regular text-2xl work_out mb-4 text-center  _margin_px'>
              Why Do You Need Hyper-Focus?
            </p>
            <div className='_regular work_out1 text-base mb-5'>
              <p className='self'>
                Focus and productivity are two of the most in-demand skills in the world today. But
                in a busy world, focus has become more challenging. Each one of us, on average, is
                being contacted via email, text, or phone about once every four minutes. Each time
                we become distracted, it takes almost 23 minutes for us to get back into a state of
                hyper-focus.
              </p>
              <p className='self'>
                After the COVID-19 pandemic, nearly 77% of work became remote—over three quarters of
                the workforce was working from home. In the United States, productivity decreased by
                7.2%, yet the length of the average workday increased by almost 40%. In short, it
                was taking a lot longer for people to accomplish less work. People were really
                struggling to stay focused and productive while working from home.
              </p>
            </div>
            <img src={per1} className='w-full mb-6' alt='logo' />
            <div className='_regular work_out1 text-base mb-5'>
              <p
                style={{
                  alignSelf: 'baseline',
                }}>
                So why the large drop in productivity and the substantial increase in the average
                workday? Are there more distractions in a home environment? Absolutely. And the more
                distractions, the more focus is required. Yet most of us had never had to work in
                such a distraction-rich environment, and we were highly unprepared to be productive.
              </p>

              <p className='self'>
                Many industries, especially in the technology sector, will not return to a
                traditional office environment—and certainly not full-time. And, if more of us are
                working from home, the need for focus and hyper-focus will be greater than ever
                before.
              </p>
              <p className='self'>
                The following infographic will help you better understand if hyper-focus is a
                strength or a stretch for you.
              </p>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={per2} className=' mb-5' style={{ width: '100%' }} alt='img not found'></img>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-4 w-full'>
              <p className='_regular work_out text-2xl text-center bold mb-7 _margin_px'>
                The 5 Signs That Indicate You Lack Focus
              </p>
              <div className='_regular work_out1 text-base mb-5 gap-4'>
                <p className='self'>
                  Lack of focus can hold us back in both our personal and professional lives by
                  reducing our ability to be productive and effective. Not all of us are able to
                  recognize that we may have a lack of focus, yet we experience the effects all the
                  same. Below, we explore in more detail five important signs that you might be
                  struggling to focus based on the last infographic.
                </p>
              </div>
              <img src={per3} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold  _margin_px'>
                  <strong>1. </strong>
                  <br />
                  Struggle To Prioritize Tasks
                </p>
                <p className='self'>
                  If you struggle to prioritize the tasks that matter most during the day, you lack
                  focus. Hyper-focus is at the center of prioritization. We simply can’t determine
                  what to work on first if we don’t have a clear idea about big picture priorities.
                  Remember, hyper-focus involves understanding what we’re also going to say “no” to
                  so that we can focus on where to say “yes.” Prioritization is critical to
                  productivity and effectiveness, and success is rooted in our ability to prioritize
                  what we do and how we spend our time. Put simply, successful prioritization
                  requires hyper-focus.
                </p>
              </div>

              <img src={per4} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong>2. </strong>
                  <br />
                  Struggle To Complete Tasks On Time
                </p>
                <p className='self'>
                  Once you’ve determined what to work on, do you struggle to finish tasks on time?
                  Completing tasks on time requires both prioritization and organization—and both
                  require hyper-focus. If you feel overwhelmed and struggle to get organized and
                  stay organized throughout the day, you may be experiencing a lack of focus.
                </p>
                <p className='self'>
                  Sometimes we all feel like we have just too much on our plates to get done in a
                  specific amount of time. This can also be a symptom of lacking hyper-focus. We
                  need to make sure we’re able to not only prioritize but also determine that what
                  we say “no” to ensures we are focusing on what matters and what we will be able to
                  realistically accomplish in a specific amount of time.
                </p>
              </div>
              <img src={per5} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong>3. </strong>
                  <br />
                  Struggle To Make Decisions
                </p>

                <p className='self'>
                  Do you struggle to make decisions? Whether large or small, all decisions are
                  rooted in critical thinking—and critical thinking requires hyper-focus. When we
                  lack the ability to focus, we’re unable to acquire the necessary data and then
                  critically think about that data to find solutions. Hyper-focus helps us clearly
                  define problems and challenges as well as opportunities so that we can make more
                  effective decisions faster. Being decisive is a highly valued trait and one of the
                  most sought after skills for leaders, but you can’t make good decisions without
                  hyper-focus.
                </p>
              </div>
              <img src={per6} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong>4. </strong>
                  <br />
                  Make Simple <br /> Mistakes
                </p>

                <p className='self'>
                  Do you find yourself making careless or simple mistakes? This is also a symptom of
                  lack of focus and the much misunderstood work strategy known as multitasking, more
                  on that next. Suffering from making simple mistakes is often due to trying to
                  complete a task too quickly or trying to complete multiple tasks at the same time.
                  Sometimes it’s as simple as starting to think about something else while working
                  on a task. All of these situations can result in making simple and careless
                  mistakes. These types of mistakes show that we’re not paying attention to detail,
                  and they can dramatically hurt our personal brand. The only way to ensure that we
                  don’t make simple and careless mistakes is to stay focused on the task at hand. We
                  need to be 100% invested in what we’re doing to ensure it’s done right.
                </p>
              </div>
              <img src={per7} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong>5. </strong>
                  <br />
                  Multitask <br /> Often
                </p>

                <p className='self'>
                  Ah, multitasking. People think multitasking is a value, an asset, and a strength.
                  They think that if you’re a good multitasker, you’re able to get more done and be
                  more effective. But they couldn’t be more wrong. A recent brain science study
                  found that multitasking may actually be bad for the brain. When bombarded with
                  several streams of electronic information, we’re not as able to pay attention,
                  control memory, or smoothly switch from one task to another (cognitive
                  flexibility) as those who focus on completing one task at a time (monotasking).
                </p>
                <p className='self'>
                  Researchers also found that multitasking affects critical thinking and that
                  multitaskers were worse at organizing their thoughts and filtering out irrelevant
                  information. In short, the study found that multitasking reduces efficiency and
                  performance. Once you understand focus and hyper-focus, it’s not surprising that
                  multitasking reduces your ability to be effective. Each one of us should learn to
                  focus on one task at a time and then move quickly between tasks rather than trying
                  to focus on multiple tasks at the same time.
                </p>
              </div>
              <img src={per8} className='w-full mb-10' alt='logo' />
              <div className='text-[26px]  _regular work_out text-2xl  text-center bold'>
                <h3
                  className='text-center text-xs _bold mb-1'
                  style={{ letterSpacing: '2px', fontSize: '10px' }}>
                  HYPER-FOCUS 2
                </h3>
                Hyper-Focus <br /> Summary
              </div>
              <div className='_regular work_out1 text-base mt-[37px] mb-5'>
                <p className='self'>
                  It should now be clear why you need hyper-focus and how the lack of it can
                  dramatically affect your performance both at home and at work. Focus is rarely
                  thought of as a skill, but is vital to our personal and professional success. And
                  as every other skill, focus can be exercised and thus become stronger. Focus is
                  our ability to tune out distractions and stay engaged with a specific task.
                  Hyper-focus includes our ability to think clearly about not only what we’re going
                  to do, but what we are also not going to do to further reduce distractions and
                  stay engaged with what matters most.
                </p>
              </div>
            </div>
          </div>

          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
