import React from 'react'
import { useHistory } from 'react-router-dom'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'

export default function PrivacyPolicy() {
  const history = useHistory()

  return (
    <div className='mt-10'>
      <div>
        <h4
          className='text-center text-2xl leading-[29px] _medium mt-[2px]'
          style={{ color: '#3C0774' }}>
          Terms of Service and <br /> Privacy Policy
        </h4>
        <h4 className='text-center font _medium mt-3' style={{ color: '#43296E' }}>
          Last Update: January 2022
        </h4>
      </div>
      <div className='mx-8 mt-10'>
        <h4 className='leading-[14px] text-xs mt-5  _bold ' style={{ color: '#2B1F5C' }}>
          TERMS OF SERVICE
        </h4>
        <h4 className=' font  _regular leading-[14px] mt-3' style={{ color: '#43296E' }}>
          Please read these terms of service (“terms”, “terms of service”) carefully before using
          the Fullbridge website or app (the “service”) operated by Fullbridge (“us”, ‘we”, “our”).
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Conditions of Use
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          We will provide their services to you, which are subject to the conditions stated below in
          this document. Every time you visit this website, use its services or make a purchase, you
          accept the following conditions. This is why we urge you to read them carefully.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Privacy Policy
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Before you continue using our website we advise you to read our privacy policy below
          regarding our user data collection. It will help you better understand our practices.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold' style={{ color: '#2B1F5C' }}>
          Copyright
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Content published on this website (digital downloads, images, texts, graphics, logos) is
          the property of Fullbridge and/or its content creators (unless otherwise specified) and
          protected by international copyright laws. The entire compilation of the content found on
          this website is the exclusive property of Fullbridge, with copyright authorship for this
          compilation by Fullbridge.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Communications
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          The entire communication with us is electronic. Every time you send us an email or visit
          our website, you are going to be communicating with us. You hereby consent to receive
          communications from us. If you subscribe to the news on our website, you are going to
          receive regular emails from us. We will continue to communicate with you by posting news
          and notices on our website and by sending you emails. You also agree that all notices,
          disclosures, agreements and other communications we provide to you electronically meet the
          legal requirements that such communications be in writing.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Applicable Law
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          By visiting this website, you agree that the laws of the State of New York, without regard
          to principles of conflict laws, will govern these terms of service, or any dispute of any
          sort that might come between Fullbridge and you, or its business partners and associates.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Disputes
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Any dispute related in any way to your visit to this website or to products you purchase
          from us shall be arbitrated by state or federal court in New York and you consent to
          exclusive jurisdiction and venue of such courts.
        </h4>

        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Comments, Reviews, and Emails
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Visitors may post content as long as it is not obscene, illegal, defamatory, threatening,
          infringing of intellectual property rights, invasive of privacy or injurious in any other
          way to third parties. Content has to be free of software viruses, political campaign, and
          commercial solicitation.
          <br />
          <br />
          We reserve all rights (but not the obligation) to remove and/or edit such content. When
          you post your content, you grant Fullbridge non-exclusive, royalty-free and irrevocable
          right to use, reproduce, publish, modify such content throughout the world in any media.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          License and Site Access
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          We grant you a limited license to access and make personal use of this website. You are
          not allowed to download or modify it. This may be done only with written consent from us
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          User Account
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          If you are an owner of an account on this website, you are solely responsible for
          maintaining the confidentiality of your private user details (username and password). You
          are responsible for all activities that occur under your account or password.
          <br />
          <br />
          We reserve all rights to terminate accounts, edit or remove content and cancel orders in
          their sole discretion.
        </h4>
        <h4 className='leading-[14px] text-xs mt-6 mb-3  _bold ' style={{ color: '#2B1F5C' }}>
          PRIVACY POLICY
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          This privacy policy ("policy") will help you understand how [name] ("us", "we", "our")
          uses and protects the data you provide to us when you visit and use Fullbridge ("website",
          "service").
          <br />
          <br />
          We reserve the right to change this policy at any given time, of which you will be
          promptly updated. If you want to make sure that you are up to date with the latest
          changes, we advise you to frequently visit this page.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          What User Data We Collect
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          When you visit the website, we may collect the following data:
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              our IP address.
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              Your contact information and email address
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              Other information such as interests and preferences.
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              Data profile regarding your online behavior on our website.
            </h4>
          </div>
        </h4>

        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Why We Collect Your Data
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          We are collecting your data for several reasons:
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              To better understand your needs.
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              To improve our services and products.
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              To send you promotional emails containing the information we think you will find
              interesting.
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              To contact you to fill out surveys and participate in other types of market research .
            </h4>
          </div>
          <div className='flex flex-row'>
            .
            <h4 className=' font  _regular leading-[14px] pl-5' style={{ color: '#43296E' }}>
              To customize our website according to your online behavior and personal preferences.
            </h4>
          </div>
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Safeguarding and Securing the Data
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Fullbridge is committed to securing your data and keeping it confidential. Fullbridge has
          done all in its power to prevent data theft, unauthorized access, and disclosure by
          implementing the latest technologies and software, which help us safeguard all the
          information we collect online.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Our Cookie Policy
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Once you agree to allow our website to use cookies, you also agree to use the data it
          collects regarding your online behavior (analyze web traffic, web pages you spend the most
          time on, and websites you visit).
          <br />
          <br />
          The data we collect by using cookies is used to customize our website to your needs. After
          we use the data for statistical analysis, the data is completely removed from our systems.
          Please note that cookies don't allow us to gain control of your computer in any way. They
          are strictly used to monitor which pages you find useful and which you do not so that we
          can provide a better experience for you.
          <br />
          <br />
          If you want to disable cookies, you can do it by accessing the settings of your internet
          browser.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold' style={{ color: '#2B1F5C' }}>
          Links to Other Websites
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          Our website may contain links that lead to other websites. If you click on these links
          Fullbridge is not held responsible for your data and privacy protection. Visiting those
          websites is not governed by this privacy policy agreement. Make sure to read the privacy
          policy documentation of the website you go to from our website.
        </h4>
        <h4 className='leading-[14px] font mt-4  _bold ' style={{ color: '#2B1F5C' }}>
          Restricting the Collection of your Personal Data
        </h4>
        <h4 className=' font  _regular leading-[14px]' style={{ color: '#43296E' }}>
          At some point, you might wish to restrict the use and collection of your personal data.
          You can achieve this by doing the following:
          <br />
          <br />
          When you are filling the forms on the website, make sure to check if there is a box which
          you can leave unchecked, if you don't want to disclose your personal information.
          <br />
          <br />
          If you have already agreed to share your information with us, feel free to contact us via
          email and we will be more than happy to change this for you.
          <br />
          <br />
          Fullbridge will not lease, sell or distribute your personal information to any third
          parties, unless we have your permission. We might do so if the law forces us. Your
          personal information will be used when we need to send you promotional materials if you
          agree to this privacy policy.
        </h4>
      </div>
      <div
        className='flex justify-center text-xl _medium text-color relative mt-5 mb-5'
        onClick={() => {
          history.push('/corporate_signup')
        }}>
        <img src={logo1} className='h-[39px] w-[39px]' alt='logo' />
        <img src={logo2} className='absolute h-4 bottom-3' alt='logo' />
      </div>
    </div>
  )
}
