import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per1 from '../assets/images/hhf2.svg'
import per2 from '../assets/images/hhf3.png'
import per3 from '../assets/images/hhf4.png'
import per4 from '../assets/images/hhf5.png'
import per5 from '../assets/images/hhf6.png'
import per6 from '../assets/images/hhf7.png'
import per7 from '../assets/images/hhf8.png'
import per9 from '../assets/images/hhf10.png'
import per10 from '../assets/images/hhf11.png'
import per11 from '../assets/images/hhf12.png'
import per8 from '../assets/images/hhf9.png'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
export const Hyper1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.hyper?.assesment[2]
      ? state?.user?.progress?.course?.hyper?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }

    UpdateAssessments(
      stateNew.user.progress.course.hyper,
      2,
      Number(localStorage.getItem('Pbarvalue21')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue21')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_achieve`,
        assessment: AddCourses(state?.user?.progress?.course?.hyper?.assesment, 3),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue21', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/hyper_focus1.6')
    } else {
      return history.push('/hyper_focus1.5')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between  fixed z-10 top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='percentage pt-5 text-white pl-10 pr-5'>{percent1 > 97 ? 100 : percent1}%</h4>
      </div>

      {!open && (
        <div className='w-full mt-16 flex flex-col'>
          <div className='personal1 w-full flex flex-col  items-center '>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange grid place-items-center'>
                <div
                  className='text-5xl text-center  text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='mt-auto flex flex-col  sskss justify-between'>
              <div>
                <h4
                  className='text-center text-xs  pb-2 _bold text-white lspacing'
                  style={{ letterSpacing: '7px' }}>
                  ACHIEVE
                </h4>
                <h4 className='text-[26px]  text-center  leading-6 _medium text-white pb-4 '>
                  Hyper-Focus 3
                </h4>
                <p
                  className='text-center font11 z-0 _regular text-white _margin_px'
                  style={{ lineHeight: '21px', opacity: '.8' }}>
                  Learn how to make hyper-focus a critical part of your day to prioritize and
                  produce at a higher level.
                </p>
              </div>
              <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white opacity-80'>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>

          <div className='flex flex-col items-center mt-[48px]'>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>Hyper-Focus 3</h4>
            <p className='_regular text-2xl work_out mt-7 mb-4 text-center '>
              Applying Hyper-Focus <br /> Every Day
            </p>
            <div className='_regular work_out1 text-base mb-5'>
              <p className='self'>
                Hyper-focus is a skill you should use every single day in both your personal and
                professional life. The more you strengthen your hyper-focus skill, the more
                effective, efficient, and productive you will become. The good news is that there is
                a substantial amount of science that supports increasing your ability to
                hyper-focus. In this section, we will explore the ideas and strategies that will
                help you strengthen your hyper-focus skill and maximize your success.
              </p>
              <p className='self'>
                Below is an infographic that you can use as a template to ensure you’re using the
                latest and best practices to increase your hyper-focus.
              </p>
            </div>
            <img src={per1} className='w-full mb-6' alt='logo' />
            <div className='_regular work_out1 text-base mb-5 mt-5'>
              <p className='_regular text-2xl work_out text-center '>
                The 9 Steps To Better <br /> Hyper-Focus
              </p>

              <p
                style={{
                  alignSelf: 'baseline',
                }}>
                It’s important to remember that hyper-focus is not the end goal—it’s simply the tool
                that we use to reach our end goals. Being productive, efficient, and effective by
                focusing on the things that matter most at the right time will dramatically increase
                our self-esteem, self-worth, and our overall well-being.
              </p>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={per2} className=' mb-5' style={{ width: '100%' }} alt='img not found'></img>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-4 w-full'>
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold  _margin_px'>
                  <strong className='_bold'>1.</strong>
                  <br />
                  Set Clear Daily <br />
                  Objectives
                </p>
                <p className='self'>
                  Each of us only has 24 hours every day. We need to make sure we allocate our time
                  properly to ensure we maximize our energy. Put simply, we need to do first things
                  first. Start your day by identifying your most important task (MIT). This is the
                  single most important task of your day, and you want to get this done first. Once
                  you’ve accomplished your MIT, the rest of the day tends to run smoothly and be
                  highly productive—so focus on getting your MIT done first.
                </p>
              </div>
              <img src={per3} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold  _margin_px'>
                  <strong className='_bold'>2.</strong>
                  <br />
                  Prioritize
                  <br /> Tasks
                </p>
                <p className='self'>
                  Once you’ve set clear daily objectives, you need to make sure you prioritize the
                  rest of your tasks. Prioritization strategies can vary, but a good rule of thumb
                  is to focus on and prioritize the tasks that will make the greatest impact by
                  being completed. Try to detach from emotions so that you don’t get caught in the
                  trap of prioritizing tasks based on what makes you feel good rather than what is
                  truly important. Make sure to prioritize your tasks every morning and throughout
                  the day so you can focus on what matters most.
                </p>
              </div>

              <img src={per4} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong className='_bold'>3.</strong>
                  <br />
                  Set Achievable <br />
                  Deadlines
                </p>
                <p className='self'>
                  Self-imposed deadlines are rarely effective unless you’re already highly
                  disciplined. When it comes to setting achievable deadlines, make sure to be
                  realistic—unrealistic deadlines create stress, reduce productivity, and are prone
                  to errors. The most effective deadlines are those you set with your group,
                  including your boss, team, or client, so there is more at stake than just a
                  personal goal. Set clear and achievable deadlines and then harness hyper-focus to
                  dramatically increase productivity.
                </p>
              </div>
              <img src={per5} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong className='_bold'>4.</strong>
                  <br />
                  Work in 90 <br /> Minute Cycles
                </p>

                <p className='self'>
                  Recent studies show that we tend to naturally work in cycles of 90 minutes. During
                  this time span, we can engage, focus, and be highly productive. It’s important to
                  take a break every 90 minutes and lower your brain frequency activity to give your
                  brain a rest. The optimum break time is about 20 minutes. When you take a break,
                  make sure to stand up, move around, go for a walk, play the guitar, or do
                  something that you enjoy and that reduces stress. After the break, you’ll be
                  recharged and ready to reengage; you’ll increase your brain frequency again. Get
                  hyper-focused for 90 minutes of work followed by a 20-minute break. Repeat the
                  cycle throughout the day.
                </p>
              </div>
              <img src={per6} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong className='_bold'>5.</strong> <br />
                  Train Your Brain To <br /> Hyper-Focus
                </p>
                <p className='self'>
                  Hyper-focus is a skill, and just like every other skill, it requires training. It
                  may not be easy for you to do deep work for 90 minutes straight followed by a
                  20-minute break. If this is the case, start by setting a timer and doing deep work
                  for 20 to 30 minutes followed by a 5-minute break. Then, increase that time to 60
                  minutes followed by a 10-minute break. Finally, push yourself into the 90-minute
                  cycle for maximum productivity. Hyper-focus requires training and discipline.
                  Don’t get frustrated if you find it challenging in the beginning. Keep pushing
                  yourself forward; train your brain to hyper-focus for extremely powerful results.
                </p>
              </div>
              <img src={per7} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong className='_bold'>6.</strong> <br />
                  Turn Off <br />
                  Distractions
                </p>

                <p className='self'>
                  Brain science studies show that even small distractions change brain wavelengths
                  and can dramatically reduce focus. It’s important that when you begin a 90-minute
                  deep work cycle, you turn off distractions. Turn off your email and text
                  notifications—or even turn off your phone. When you’re focused on a task, stopping
                  even briefly to answer an email, respond to a text, or take an unscheduled or
                  unimportant call will break your deep work hyper-focus cycle and lead to lower
                  productivity, increased errors, and often, less effective outcomes. If you want to
                  be productive and hyper-focused, turn off distractions.
                </p>
              </div>
              <img src={per8} className='w-full mb-10' alt='logo' />
              <div className='_regular work_out1 text-base mb-5'>
                <p className='_regular work_out text-2xl  text-center bold'>
                  <strong className='_bold'>7.</strong> <br />
                  Declutter Your <br /> Space
                </p>

                <p className='self'>
                  Researchers have discovered that having too many objects in our fields of view has
                  a measurable impact on our productivity. A messy desk or cluttered office causes
                  your brain to lose power that is necessary to hyper-focus. At the end of every
                  day, spend a few moments decluttering your desk, computer desktop, and office to
                  ensure you can start the next day ready to focus.
                </p>
              </div>
            </div>
          </div>
          <img src={per9} className='w-full mb-10' alt='logo' />
          <div className='_regular work_out1 text-base mb-5'>
            <p className='_regular work_out text-2xl  text-center bold'>
              <strong className='_bold'>8.</strong>
              <br />
              Monotask — Focus On <br />
              One Task At A Time
            </p>

            <p className='self'>
              We’ve already discussed multitasking and how it destroys focus, increases mistakes,
              and reduces productivity and effectiveness. Monotasking is the polar opposite of
              multitasking. Monotasking is focusing on one task at a time until it is complete. In
              our hyper-busy world, it’s easy to believe that if we multitask we can be more
              effective, but study after study shows the opposite. Once you’ve prioritized your day,
              jump in and monotask. By doing this, you’ll soon discover what new brain science is
              showing—an increase in productivity and effectiveness through hyper-focusing on one
              thing at a time.
            </p>
          </div>
          <img src={per10} className='w-full mb-10' alt='logo' />
          <div className='_regular work_out1 text-base mb-5'>
            <p className='_regular work_out text-2xl  text-center bold'>
              <strong className='_bold'>9.</strong>
              <br />
              Fuel Up and <br />
              Exercise
            </p>
            <p className='self'>
              We all know that eating healthy foods and exercising is smart. But, what many of us
              don’t know is that eating healthy and exercising makes us smarter. New studies show
              that those of us who work out and eat healthy have greater volume in the parts of the
              brain that control memory and thinking. Working out also boosts memory, increases
              learning potential, and substantially improves the ability to increase attention and
              focus. Don’t use the excuse that you don’t have time to eat healthy or work out. When
              you prioritize your day, make sure to set aside time for exercise and healthy meals.
              What’s good for getting your body in shape is also good for getting your brain in
              shape to increase hyper-focus.
            </p>
          </div>
          <img src={per11} className='w-full mb-10' alt='logo' />
          <div className='mb-6'>
            <h4 className='text-[26px]  _regular work_out text-2xl  text-center bold '>
              <span
                className='text-center getFitSmallHeader mb-3 '
                style={{ letterSpacing: '2px' }}>
                HYPER-FOCUS 3
              </span>
              <br />
              Hyper-Focus <br /> Summary And Final <br />
              Wrap Up
            </h4>
          </div>
          <div className='_regular work_out1 text-base mb-5'>
            <p className='self'>
              Being productive and effective are rooted in hyper-focus. Put simply, increasing your
              ability to focus on what matters most will dramatically increase your success.
              Hyper-focus is a skill that when practiced will become more effective and when ignored
              will reduce your ability to get things done right and on time. Although you may not
              think about hyper-focus very often, it’s the engine behind harnessing your brainpower.
              Once you have mastered the 90-minute deep work flow strategy, you will see a
              substantial increase in your ability to think clearly, prioritize, and be effective in
              both your personal and professional life.
            </p>
          </div>

          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
