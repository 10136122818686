import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import mail from '../assets/images/mail.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
export const Create_tool1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [inputField_3, setInputField_3] = useState({
    firstField: '',
    secondField: '',
    thirdField: '',
    fourthField: '',
    fifthField: '',
    sixField: '',
    sevenField: '',
    eightField: '',
  })

  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    if (state?.user?.progress?.course?.create_tool?.assessmentPercent < 43) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 43,
        }),
      )
    }
    var stateNew = { ...state }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      inputField_3,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 43
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 43,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (
      inputField_3?.firstField.length >= 1 &&
      inputField_3?.secondField.length >= 1 &&
      inputField_3?.thirdField.length >= 1 &&
      inputField_3?.fourthField.length >= 1 &&
      inputField_3?.fifthField.length >= 1 &&
      inputField_3?.sixField.length >= 1 &&
      inputField_3?.sevenField.length >= 1 &&
      inputField_3?.eightField.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      return history.push('/create_tool1.4')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.create_tool?.inputField_3) {
      setInputField_3(state?.user?.progress?.course?.create_tool?.inputField_3)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top ml-2.5'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3
                className='exercise_solve_1_3_heading _bold nav_title'
                style={{ marginLeft: '5%' }}>
                CREATE
              </h3>
              <strong>
                <h1 className='percent _regular nav_percent'>43%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 3</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Generate Ideas</h1>
              <p className='ex_solve_para '>
                Thomas Edison discovered that generating lots of ideas was his key to success. He
                suggests developing between 40-50 ideas at a minimum. Why? Because most often, your
                last 10 ideas will be the best.
              </p>
              <p className='ex_solve_para '>
                We are going to use this section to jumpstart ideas and guide you through the
                creative process. Remember, rarely do you need to come up with a completely new
                idea, rather you can use part of an idea you’ve already had or make incremental
                changes. Follow the SCAMPER steps below and add any new ideas.Try and generate 40-50
                total ideas.
              </p>
              <p className='ex_solve_para '>
                You can use the boxes below to log your ideas, or use your notebook, whiteboard or
                whatever works best for you during your creative process.
              </p>
              <p className='ex_solve_para'>
                On the next page, we’ll use some key criteria to narrow down our ideas to the top
                10.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={saveData}>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Can you SUBSTITUTE something with the current idea to solve the problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.firstField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      firstField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Substitute graphene or gallium nitride for silicon in solar panels.
                  </span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  Can you COMBINE something with the current idea to solve the problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.secondField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      secondField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    We can coat existing solar panels with dust and water repellants to increase
                    efficiency.
                  </span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Can you ADAPT the current idea to solve the problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.thirdField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      thirdField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message  ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    We can adapt mirrors to reflect sunlight onto the panels to increase output.
                  </span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Can you MODIFY the current idea to solve the problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.fourthField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      fourthField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message  ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    We can modify the shape of solar panel arrays to maximize output.
                  </span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Can you put the current idea to ANOTHER USE?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.fifthField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      fifthField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>We could use solar panels on electric vehicles.</span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Can you ELIMINATE something to solve the current problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.sixField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      sixField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    We could eliminate the large size of panels and use many small panels in more
                    efficient locations.
                  </span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  Can you REARRANGE something to solve the current problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.sevenField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      sevenField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    We could develop motors that could rearrange how the panels face the sun
                    throughout the day.
                  </span>
                </p>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  What completely new ideas could solve the current problem?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField_3.eightField}
                  onChange={e =>
                    setInputField_3({
                      ...inputField_3,
                      eightField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full'
                  style={{ height: '130px' }}
                />
                <p className='message' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    We could develop panels that harness humidity from the air and convert the water
                    to steam.
                  </span>
                </p>
              </div>
              <div
                className='w-full flex flex-col items-center gap-6 mb-6 mt-5'
                style={{ marginTop: '80px' }}>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/create_tool1.2')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit'>
                    <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <div>
                  <p className='ex_solve_para mb-3'>Email these ideas.</p>
                  <button
                    type='submit'
                    onClick={() => {
                      localStorage.setItem('createMailIdeas', JSON.stringify(inputField_3))
                      return history.push('/createMailIdeas')
                    }}>
                    <img src={mail} alt='image no present' className='w-[40px] h-[40px]' />
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
