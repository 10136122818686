import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import Next_image from '../assets/images/group-3437-1@1x.png'
import rightTick from '../assets/images/right.svg'
import WhiteRight from '../assets/images/whitetick.svg'
import White from '../assets/images/whitemenu icon.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress, saveScoring } from '../services/user'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
export default function ExerciseSolvess() {
  const { dispatch, state } = useApp()

  const [initial, stateinital] = useState({
    one: false,
    two: false,
    three: false,
  })
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const [textInput1_6, setTextInput1_6] = useState({
    objective:
      state?.user?.progress?.course?.solve?.Exercise?.textInput?.objective?.length !== 0
        ? state?.user?.progress?.course?.solve?.Exercise?.textInput?.objective
        : '',
    stakeholder:
      state?.user?.progress?.course?.solve?.Exercise?.textInput?.stakeholder?.length !== 0
        ? state?.user?.progress?.course?.solve?.Exercise?.textInput?.stakeholder
        : '',
    another:
      state?.user?.progress?.course?.solve?.Exercise?.textInput?.another?.length !== 0
        ? state?.user?.progress?.course?.solve?.Exercise?.textInput?.another
        : '',
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const textSend =
      initial.one === true
        ? textInput1_6.objective
        : initial.two
        ? textInput1_6.stakeholder
        : initial.three
        ? textInput1_6.another
        : alert('Select any one idea')
    let uri = '/course/course'
    var stateNew = { ...state }

    stateNew.user.progress.course.solve.Exercise = {
      ...stateNew.user.progress.course.solve.Exercise,
      textSend,
      assessmentPercent:
        state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent > 67
          ? state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent
          : 67,
    }
    if (state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent < 67) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_solve`,
          assessment: 67,
        }),
      )
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
    return textSend ? history.push('exercise_solve_1.7') : null
  }
  useEffect(() => {
    switch (
      Object.values(textInput1_6).indexOf(state?.user?.progress?.course?.solve?.Exercise?.textSend)
    ) {
      case 0:
        {
          stateinital({ ...initial, one: true })
        }
        break
      case 1:
        {
          stateinital({ ...initial, two: true })
        }
        break
      case 2: {
        stateinital({ ...initial, three: true })
      }

      default:
        break
    }
  }, [])

  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading nav_title'>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent'>67%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='step'>STEP 6</h3>
              <h1 className='leading-none mt-2 mainHeading '>Select Solution</h1>
              <p className='ex_solve_para '>
                Although you have noted three potential solutions, now is the time to select the one
                you believe has the greatest chance of success. Please select only one from your
                solutions below.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <h3 className='step '>action</h3>
                <p className='ex_solve_para exerciseSemibold mb-[15px] _semiBold'>Solution 1</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from Tool - SOLVE 1.4 box 1.'
                  disabled
                  value={textInput1_6.objective}
                  style={{
                    background: textInput1_6?.objective?.length <= 150 ? '#ffffff' : '#ffb3b3',
                    backgroundColor: '#fff',
                  }}
                  onChange={e => setTextInput1_6({ ...textInput1_6, objective: e.target.value })}
                  className='user_text_area'
                />
                <div className='w-full flex items-center justify-end mt-[13px]'>
                  <img
                    src={initial.one ? rightTick : WhiteRight}
                    onClick={() =>
                      stateinital({
                        ...initial,
                        one: initial.one === false ? true : false,
                        two: false,
                        three: false,
                      })
                    }
                    alt='Image not found'
                  />
                </div>
              </div>
              <div className='action_div'>
                <p className='ex_solve_para exerciseSemibold mb-[15px] _semiBold'>Solution 2</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from Tool - SOLVE 1.4 box 2.'
                  className='user_text_area w-full'
                  value={textInput1_6.stakeholder}
                  disabled
                  style={{
                    background: textInput1_6?.stakeholder?.length <= 150 ? '#ffffff' : '#ffb3b3',
                    backgroundColor: '#fff',
                  }}
                  onChange={e => setTextInput1_6({ ...textInput1_6, stakeholder: e.target.value })}
                />
                <div className='w-full flex items-center justify-end mt-[13px]'>
                  <div className='whiteRightTick '>
                    <img
                      src={initial.two ? rightTick : WhiteRight}
                      alt='Image not found'
                      onClick={() =>
                        stateinital({
                          ...initial,
                          two: initial.two === false ? true : false,
                          one: false,
                          three: false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='action_div mt-5'>
                <p className='ex_solve_para exerciseSemibold mb-[15px] _semiBold'>Solution 3</p>
                <textarea
                  name='new_text_area'
                  placeholder='Text from Tool - SOLVE 1.4 box 3.'
                  className='user_text_area w-full'
                  value={textInput1_6.another}
                  disabled
                  style={{
                    background: textInput1_6?.another?.length <= 150 ? '#ffffff' : '#ffb3b3',
                    backgroundColor: '#fff',
                  }}
                  onChange={e => setTextInput1_6({ ...textInput1_6, another: e.target.value })}
                />
                <div className='w-full flex items-center justify-end mt-[13px]'>
                  <div className='whiteRightTick '>
                    <img
                      src={initial.three ? rightTick : WhiteRight}
                      alt='Image not found'
                      onClick={() =>
                        stateinital({
                          ...initial,
                          three: initial.three === false ? true : false,
                          one: false,
                          two: false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-8'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/exercise_solve_1.5')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button type='submit'>
                    <img
                      disabled={
                        textInput1_6?.objective?.length === 0 ||
                        textInput1_6?.objective?.length > 150 ||
                        textInput1_6?.stakeholder?.length === 0 ||
                        textInput1_6?.stakeholder?.length > 150 ||
                        textInput1_6?.another?.length === 0 ||
                        textInput1_6?.another?.length > 150
                          ? true
                          : false
                      }
                      src={Next_image}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
