import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import leftArrow from '../assets/images/leftArrow.svg'
export const Solve1_2 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textBox, setTextBox] = useState({
    firstInputText: '',
    secondInputText: '',
    thirdInputText: '',
  })

  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    var stateNew = { ...state }
    stateNew.user.progress.course.exercise = {
      ...stateNew.user.progress.course.exercise,
      textBox,
      assessmentPercent:
        state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent > 22
          ? state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent
          : 22,
    }
    if (
      state?.user?.progress?.course?.exercise?.assessmentPercent <= 22 &&
      state?.state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent === undefined
    ) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_solve`,
          assessment: 22,
        }),
      )
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)

    if (
      textBox?.firstInputText?.length >= 1 &&
      textBox?.secondInputText?.length >= 1 &&
      textBox?.thirdInputText?.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }

      return history.push('/exercise_solve_1.3')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.exercise?.textBox) {
      setTextBox(state?.user?.progress?.course?.exercise?.textBox)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold nav_title'> SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent _regular'>22%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 2</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Gather Supporting Data</h1>
              <p className='ex_solve_para'>
                Every strong argument is supported by data. Gather the three strongest data sources
                and add them below. Make sure you also include the source of the information. Your
                data must support your Problem Definition.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  First data source that supports your Problem Definition argument.
                </p>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textBox.firstInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, firstInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px' }}
                />
                <p className='message'>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    {' '}
                    90% of grid power on the East Coast is provided by Coal. (Add your source and
                    hyperlink here).
                  </span>
                </p>
              </div>
              <div className='action_div' style={{ marginTop: '-12px' }}>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Second data source that supports your Problem Definition argument.
                </p>
                <textarea
                  id=' secondInputText'
                  name=' secondInputText'
                  placeholder='Type here.'
                  value={textBox.secondInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, secondInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px' }}
                />
              </div>
              <div className='action_div'>
                <p className='ex_solve_para mb-[15px] _semiBold'>
                  Third data source that supports your Problem Definition argument.
                </p>
                <textarea
                  id=' thirdInputText'
                  name=' thirdInputText'
                  placeholder='Type here.'
                  value={textBox.thirdInputText}
                  onChange={e =>
                    setTextBox({ ...textBox, thirdInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  style={{ fontSize: '14px' }}
                />
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/solve1.1')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button
                    type='submit'
                    // disabled={
                    //   textArea?.length < 1 || textArea?.length > 150 || textArea?.length === undefined
                    //     ? true
                    //     : false
                    // }
                    onClick={saveData}>
                    <img src={nextArrow} alt='image no present' className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
