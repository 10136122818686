
export const AppConstants = process.env.REACT_APP_INSTANCE === 'staging' ?
  {
    BASE_URL: 'https://whale-app-72djc.ondigitalocean.app',
    MEASUREMENT_ID:"G-YGYCNRGEDY"
  } :
  process.env.REACT_APP_INSTANCE === 'prod' ?
    {
      BASE_URL: 'https://hammerhead-app-2-aib7s.ondigitalocean.app',
          MEASUREMENT_ID:"G-QYQ2FMG9QC"

    } :
    {
      BASE_URL: 'http://localhost:3001',
          MEASUREMENT_ID:"G-YGYCNRGEDY"

    }