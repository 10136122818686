import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import solvelogo from '../assets/images/solve.svg'
import nextArrow from '../assets/images/whiteArrow.svg'
import { useHistory } from 'react-router-dom'
export const CreateTool1_0 = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)

  return (
    <div className='fixed top-0 bottom-0 right-0 left-0 '>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={
          'cursor-pointer pt-1 pl-2 pb-2.5 fixed w-full  ' + (open ? 'none' : '_solve1_bg')
        }
        style={{ pointerEvents: 'none' }}
        onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-5 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={menu} alt='logo' />
          </div>
        ) : (
          <div className='mt-5 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9 ' src={whiteCross} alt='logo' />
          </div>
        )}
      </div>
      <div className='creative_thinking_splash  '>
        <div
          className=' flex flex-col items-center mt-14 h-screen'
          style={{ background: 'rgba(45, 27, 75, .2)' }}>
          <div className='h-16 w-16 rounded-full mt-10 mb-5 '>
            <img src={solvelogo} logo='logo' className='object-cover' alt='background' />
          </div>
          <h4 className=' _bold text-xs _font_color_white '>E X E R C I S E</h4>
          <h4 className=' _medium text-2xl _font_color_white pb-3 pt-1.5'>Create</h4>
          <p
            className=' _regular font11 _font_color_white px-10 text-center mb-10'
            style={{ lineHeight: '2.0', letterSpacing: '1px' }}>
            Creativity is not about being artistic. Creativity is about thinking outside the box to
            find unique solutions. The good news is that there is a lot of science behind generating
            creative ideas and this tool will walk you through a proven process to get better
            creative results.
          </p>
          {!open && (
            <div className='fixed bottom-0 text-center' style={{ marginBottom: '-13px' }}>
              <button className=' rounded-full ' onClick={() => history.push('/create_tool1.1')}>
                <img src={nextArrow} logo='logo' />
              </button>
              <h4 className=' _regular font  _font_color_white pb-3 pt-1.5 mb-1'>
                ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
              </h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
