import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo10 from '../assets/images/path-15747-10@1x.png'
import logo11 from '../assets/images/path-15748-10@1x.png'
import logo12 from '../assets/images/path-15709-10@1x.png'
import logo13 from '../assets/images/group-4466-1@1x.png'
import { userList } from '../services/user'
import useApp from '../context/useApp'
export default function ViewUsers() {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  // May Be this  state Variable do not need
  const [pageNo, setPageNo] = useState(1)
  const [DataPoint1, setDataPoint1] = useState(0)
  const [DataPoint2, setDataPoint2] = useState(10)
  const body = {
    limit: 10,
    pageno: pageNo,
  }
  const [data, setdata] = useState([])

  const { state } = useApp()
  // This Slice function is used for sliceing Whole Data in 10form
  // const SliceData = data.slice(DataPoint1, DataPoint2)
  // To get total number of page
  const TotalPageFuction = size => {
    const totalPage = size / 10
    const TotalpageReturn = size % 10
    if (TotalpageReturn > 0 && TotalpageReturn < 10) {
      return Math.floor(totalPage) + 1
    } else {
      return totalPage
    }
  }
  const PageNO = TotalPageFuction(Number(localStorage.getItem('totalUser')))

  useEffect(() => {
    const viewusersList = async () => {
      const res = await userList(JSON.stringify(body), state?.token)

      setdata(res.data.users)
    }

    viewusersList()
  }, [pageNo])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto '>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      <h4
        className='text-center _medium mt-10 text-2xl not-italic font-medium'
        style={{ color: 'var(--windsor)' }}>
        View User
      </h4>

      <div className='pt-12 px-9'>
        {!open && (
          <div className=' flex flex-row justify-between'>
            <div
              className=' text-sm leading-3 relative left-1 _regular'
              style={{ color: 'var(--jacarta)' }}>
              User Name
            </div>
            <div
              className='relative left-3 text-sm leading-3 _regular'
              style={{ color: 'var(--jacarta)' }}>
              Status
            </div>
          </div>
        )}
        <div className='mt-4'>
          {data?.map((value, i) => {
            return (
              <div className=' flex flex-row justify-between p-1' key={i}>
                <div>
                  <h4
                    key={i}
                    className=' text-sm font-bold _bold not-italic dottedText'
                    style={{ color: 'var(--jacarta)' }}>
                    {value?.username}
                  </h4>
                </div>
                <div>
                  <h4
                    key={i}
                    className='text-center text-sm  font-bold _bold not-italic uppercase'
                    style={{
                      color: value?.subscriber ? '#4daa86' : '#9a9a9a',
                    }}>
                    {value?.subscriber ? 'S' : 'U'}
                  </h4>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='absolute inset-x-0 h-16'>
        <div className='flex flex-row justify-center mt-5' style={{ display: open ? "none" : "" }}>
          <button
            className='mr-5'
            onClick={() => {
              setDataPoint2(
                DataPoint2 % 10 != 0
                  ? Math.floor(DataPoint2 / 10) * 10
                  : pageNo === 1
                  ? 10
                  : DataPoint2 - 10,
              )
              setDataPoint1(pageNo === 1 ? 0 : DataPoint1 - 10)
              return pageNo > 1 ? setPageNo(pageNo - 1) : setPageNo(1)
            }}>
            &lt;
          </button>
          <h4 className='text-center text-xs mt-2 font-semibold ' style={{ color: '#2B1F5C' }}>
            {pageNo} / {PageNO}
          </h4>
          <button
            className='ml-5'
            onClick={() => {
              setDataPoint1(
                DataPoint1 + 10 < data.length ? DataPoint1 + 10 : DataPoint1 - (DataPoint1 % 10),
              )
              setDataPoint2(DataPoint2 + 10 < data.length ? DataPoint2 + 10 : data.length)

              return pageNo < PageNO ? setPageNo(pageNo + 1) : setPageNo(PageNO)
            }}>
            &gt;
          </button>
        </div>
      </div>

      {!open && (
        <div className='px-16 flex justify-between mt-32 mb-12 relative'>
          <button onClick={() => history.push('/admin-edit-users')}>
            <img src={logo10} className='h-10 w-10' alt='logo' />
            <img src={logo11} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>
          <button onClick={() => history.push('/admin-email_user')}>
            <img src={logo12} className='h-10 w-10' alt='logo' />
            <img
              src={logo13}
              className='absolute h-9 w-9'
              style={{ bottom: '2px', marginLeft: '2px' }}
              alt='logo'
            />
          </button>
        </div>
      )}
    </div>
  )
}
