import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import leftArrow from '../assets/images/leftArrow.svg'
import logo5 from '../assets/images/path-15768@1x.png'
export const Create_tool1_2 = () => {
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [inputField, setInputField] = useState({
    firstInputText: '',
    secondInputText: '',
  })
  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    if (state?.user?.progress?.course?.create_tool?.assessmentPercent < 29) {
      await saveScoring(
        JSON.stringify({
          course: `exercise_create`,
          assessment: 29,
        }),
      )
    }
    var stateNew = { ...state }
    stateNew.user.progress.course.create_tool = {
      ...stateNew.user.progress.course.create_tool,
      inputField,
      assessmentPercent:
        state?.user?.progress?.course?.create_tool?.assessmentPercent > 29
          ? state?.user?.progress?.course?.create_tool?.assessmentPercent
          : 29,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (inputField?.firstInputText.length >= 1 && inputField?.secondInputText.length >= 1) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }
      return history.push('/create_tool1.3')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.create_tool?.inputField) {
      setInputField(state?.user?.progress?.course?.create_tool?.inputField)
    }
  }, [])
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top ml-2.5'>
            <div className='mt-3 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold nav_title_create'>CREATE</h3>
              <strong>
                <h1 className='percent _regular nav_percent'>29%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 2</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Challenge Assumptions</h1>
              <p className='ex_solve_para '>
                Remember, creativity is not about being artistic. Start with the belief that you ARE
                creative. Next, challenge existing assumptions so you can, as Elon Musk says, “Think
                for yourself using First Principles.” A First Principle is defined as “fundamental
                concepts or assumptions on which a theory, system or method is based.” In other
                words, stop looking at what others have done in the past or are doing now, and
                create your own path.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  Note the current assumptions that are being made about your problem.
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField.firstInputText}
                  onChange={e =>
                    setInputField({ ...inputField, firstInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />

                <div className='relative mt-3.5'>
                  <p className='message'>
                    <span className='_bold'>Example: </span>
                    <span className='italic'>
                      Solar panel material pricing will stay the same, and solar panel technology
                      won’t evolve.
                    </span>
                  </p>
                </div>

                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  How can you challenge the above assumptions? What could be changed?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={inputField.secondInputText}
                  onChange={e =>
                    setInputField({ ...inputField, secondInputText: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                  //   style={{
                  //     background: textArea?.length > 150 ? '#ffb3b3' : '#ffffff',
                  //     fontSize: '14px',
                  //   }}
                />

                <div className='relative mt-3.5'>
                  <p className='message'>
                    <span className='_bold'>Example: </span>
                    <span className='italic'>
                      New material sources and/or coatings could reduce costs and increase
                      efficiencies.
                    </span>
                  </p>
                </div>
              </div>

              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/create_tool1.1')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit'>
                    <img
                      onClick={saveData}
                      src={nextArrow}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
