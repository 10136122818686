import React from 'react'

export const InputBox = props => {
  return (
    <div className='px-9 mt-8'>
      <label
        htmlFor={props.htmlFor}
        className='not-italic font-medium inline-block font-style _medium'
        style={{ color: 'var(--jacarta)', fontSize: '10px', lineHeight: '10px' }}>
        {props.label}
      </label>
      <input
        {...props}
        id={props.id}
        type={props.type}
        style={{ borderBottom: '1px solid var(--windsor)' }}
        required
        autoComplete='off'
        className='bg-transparent w-full input-border-1 outline-none'
      />
    </div>
  )
}
