import React, { useState } from 'react'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import { useHistory } from 'react-router-dom'
export const AddSuccess = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-hidden'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      <div className='pt-32 relative'>
        <h4
          className='text-center _medium text-2xl not-italic font-medium'
          style={{ color: 'var(--windsor)' }}>
          Success!
        </h4>

        <h4
          className='text-center _regular text-sm not-italic '
          style={{ color: 'var(--windsor)' }}>
          Client has been added.
        </h4>
      </div>

      <div className='absolute bottom-8' style={{ marginLeft: '47%' }}>
        <div className=' flex justify-center relative'>
          <button className='' onClick={() => history.push('/admin-client')}>
            <img src={logo1} className='h-10 w-10' alt='logo' />
            <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>
        </div>
      </div>
    </div>
  )
}
