import React from 'react'

import home from '../assets/images/home icon.svg'

import { useHistory } from 'react-router-dom'
export const EmailSuccess = () => {
  const history = useHistory()
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      <h4
        className='text-center _medium text-2xl not-italic font-medium mt-52'
        style={{ color: 'var(--windsor)' }}>
        Success!
      </h4>

      <h4
        className='text-center _regular text-sm not-italic mx-12 mt-4 '
        style={{ color: 'var(--windsor)' }}>
        A link has been sent to the email you provided.
      </h4>
      <div
        className='flex justify-center mt-14'
        onClick={() => {
          history.push('/')
        }}>
        <img src={home} alt='home' />
      </div>
    </div>
  )
}
