import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo6 from '../assets/images/path-15710-10@1x.png'
import { InputBox } from './reusable_code/ReusableComp'
import { addClientData } from '../services/user'
const AddClient = () => {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState()
  const [groupCode, setGroupCode] = useState()
  const [userCount, setUserCount] = useState()
  const handleSubmit = async e => {
    e.preventDefault()
    if (
      !email ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    ) {
      alert('Invalid Email')
    } else if (!password) {
      alert('Please Fill Password')
    } else if (password.length < 8) {
      alert('Password should not be less than 8 characters')
    } else if (
      !/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(password)
    ) {
      alert(
        'The password must be at least 8 characters long contain a special character, a number, an uppercase letter, and a lower case letter',
      )
    } else if (!groupCode || groupCode.length > 7 || groupCode.length < 7) {
      alert('Corporate Serial Number can not be more or less then 7 digit')
    } else {
      let res = await addClientData(
        JSON.stringify({
          name: `${firstName} ${lastName}`,
          username: email.toLowerCase(),
          password: btoa(`${password}`),
          g_code: groupCode,
          user_count: userCount,
        }),
      )
      let data = res.ok ? await res.text() : null

      if (data) {
        data = JSON.parse(data)
        if (data.success) {
          history.replace('/add-success')
        } else {
          alert(data.message)
        }
      } else {
        const error = await res.json()
        alert(error.message)
      }
    }
  }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>
      <h4
        className='text-center _medium mt-10 text-2xl not-italic font-medium'
        style={{ color: 'var(--windsor)' }}>
        Add
      </h4>
      <form onSubmit={handleSubmit}>
        <InputBox
          htmlFor='#first_name'
          label='Client First Name'
          id='firstName'
          type='text'
          autoComplete='off'
          onChange={e => {
            setFirstName(e.target.value.trimStart())
          }}
        />
        <InputBox
          htmlFor='#last_name'
          label='Client Last Name'
          id='lastName'
          type='text'
          autoComplete='off'
          onChange={e => {
            setLastName(e.target.value.trimStart())
          }}
        />
        <InputBox
          htmlFor='#email'
          label='Client Email Address'
          id='email'
          type='email'
          autoComplete='off'
          onChange={e => {
            setEmail(e.target.value.toLowerCase().trimStart())
          }}
        />
        <InputBox
          htmlFor='#password'
          label='Client Password'
          id='password'
          type='password'
          autoComplete='off'
          onChange={e => {
            setPassword(e.target.value.trimStart())
          }}
        />
        <InputBox
          htmlFor='#gCode'
          label='Client Corporate Serial Number'
          id='gCode'
          autoComplete='off'
          type='text'
          maxLength={7}
          onChange={e => {
            setGroupCode(e.target.value.trimStart())
          }}
        />

        <InputBox
          htmlFor='#userCount'
          label='Client User Count'
          id='userCount'
          type='number'
          onChange={e => {
            setUserCount(e.target.value.trimStart())
          }}
        />

        {!open && (
          <div className='px-16  mt-32 mb-10 flex justify-between relative'>
            <button className='' onClick={() => history.push('/admin-client')}>
              <img src={logo1} className='h-10 w-10' alt='logo' />
              <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
            </button>

            <button
              type='submit'
              disabled={
                groupCode?.length === 0 || groupCode?.length > 7 || groupCode?.length < 0
                  ? true
                  : false
              }>
              <img src={logo3} className='h-10 w-10' alt='logo' />
              <img src={logo6} className='absolute h-3.5 ml-2.5 bottom-3' alt='logo' />
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default AddClient
