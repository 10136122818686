import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import cf8_1 from '../assets/images/CF-8-1.jpg'
import cf8 from '../assets/images/CF-8.jpg'
import cf9 from '../assets/images/CF-9.jpg'
import cf10 from '../assets/images/CF-10.jpg'
import cf11 from '../assets/images/CF-11.jpg'
import cog_flex from '../assets/images/Cognitive2.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { UpdateAssessments } from './Asses/Comm_Text'
export const CogFlex1_3 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  // scroll logic
  let isMemoryLeak = useRef(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.adapt?.assesment[1]
      ? state?.user?.progress?.course?.adapt?.assesment[1]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.adapt,
      1,
      Number(localStorage.getItem('Pbarvalue8')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue8')), percent1),
    )
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_adapt`,
        assessment:
          state?.user?.progress?.course?.adapt?.assesment[0] +
          state?.user?.progress?.course?.adapt?.assesment[1],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }
  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue8', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/cog_flex_1.4')
    } else {
      history.push('/cog_flex_1.3')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5  percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='cog_flex_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                ADAPT
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Cognitive Flexibility 2
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover why you need cognitive flexibility when to use it to successfully adapt to
                new situations in your personal and professional life.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>
          <div className='flex flex-col items-center _mt-58 '>
            <p className='text-center mb-1 text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 tracking-[2.8px]'>
              COGNITIVE FLEXIBILITY 2
            </h4>

            <p className='_regular text-2xl work_out  text-center mb-7 _margin_px'>
              Why Do I Need Cognitive Flexibility?
            </p>
            <p className='_regular work_out text-base mb-5 _margin_px'>
              Today, the pace of change and the flood of data demand mentally agile and flexible
              team members. In a complex world, we are constantly solving puzzles to find the best
              or right solutions—cognitive flexibility helps us bridge the gap between existing
              knowledge and new knowledge to successfully adapt to change.
            </p>
            <img src={cf8_1} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-base _margin_px mb-8'>
              Explore the infographic to better understand when to use cognitive flexibility and to
              discover the key questions to ask yourself to avoid staying stuck.
            </p>
          </div>
          <div className='flex items-center  '>
            <img src={cog_flex} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                Cognitive Flexibility Is Critical To Your Success
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Let’s explore situations in which cognitive flexibility can be crucial to success.
              </p>
              <p className='_bold work_out text-base  _margin_px mb-6'>Changing Subject Matter</p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Every day we are faced with a constant array of subjects. For example, in school,
                one hour you might be working on math, and the next hour you’re working on English.
                Or, at work, you might need to be focused on a financial issue one moment and a
                human resource issue the next. Your ability to transition smoothly, efficiently, and
                effectively between one subject and the next is determined by your level of
                cognitive flexibility.
              </p>
              <img src={cf8} className='w-full ' alt='logo' />
              <p className='_bold work_out text-base  _margin_px mb-6 _mt-34'>
                Abandoning Unsuccessful Ideas
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Changing our minds can be difficult, especially if we’ve invested a lot of time,
                energy, or money into an idea. It’s crucial that once we recognize that an idea may
                be unsuccessful, we have the ability to change our minds and our course of action as
                soon as possible. Our ability to let go of an unsuccessful concept is dependent on
                our level of cognitive flexibility.
              </p>
              <p className='_bold work_out text-base  _margin_px mb-6'>
                Changing Incorrect Thinking
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Changing incorrect thinking might sound similar to abandoning unsuccessful ideas,
                but the difference is rooted in fact. Once we determine that we have information
                that is incorrect or wrong, it’s not always easy to adapt to the correct
                information. Our ability to change our minds and adopt correct information is
                determined by our level of cognitive flexibility.
              </p>
              <img src={cf9} className='w-full ' alt='logo' />
              <p className='_bold work_out text-base  _margin_px mb-6 _mt-34'>Adapting To Change</p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Change is the new normal. Everything is changing faster than ever before. The way we
                work is changing, the way we communicate is changing, and even the way we learn is
                changing—and continues to change at an unprecedented pace. Our ability to shift our
                thinking and adapt to change is determined by our level of cognitive flexibility.
              </p>
              <img src={cf10} className='w-full ' alt='logo' />
              <p className='_bold work_out text-base  _margin_px mb-6 _mt-34'>
                Applying Abstract Thinking
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Applying abstract ideas or concepts can be challenging, but being able to connect
                the dots or tie similar ideas and concepts together, or in some cases unrelated
                ideas and concepts, is an important part of cognitive flexibility—and even our
                survival. For example, basic math is an abstract concept that must be applied to any
                successful investment strategy.
              </p>
              <img src={cf11} className='w-full ' alt='logo' />
            </div>
          </div>
          <div className='flex flex-col items-center  '>
            <div className='mt-14'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                COGNITIVE FLEXIBILITY 2
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Cognitive Flexibility Summary
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Developing your cognitive flexibility muscles is critical to your overall success.
                The pace of change is increasing and technology is widening the gap of change, both
                require cognitive flexibility to think about old problems in new ways and to connect
                the dots between the information we already have and the new information that we
                obtain. Our ability to adapt to change hinges on our cognitive flexibility.
                <br />
                <br /> Cognitive flexibility will give you a true competitive advantage in both your
                personal and professional life.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
