import React, { useEffect, useState, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15712@1x.png'
import logo4 from '../assets/images/path-15743-1@1x.png'
import logocross from '../assets/images/path-15768@1x.png'
import img2 from '../assets/images/Group 9640.svg'
import cliffLogo from '../assets/images/cliff@1x.png'
// import frame2_img from '../assets/images/CT2.svg'
import { UpdateAssessments } from './Asses/Comm_Text'
import menu from '../assets/images/white dash.svg'
import lightbulb from '../assets/images/lightbulb2@1x.png'
import eye from '../assets/images/eye@1x.png'
import across_stone from '../assets/images/across-stones@1x.png'
import bear from '../assets/images/bear-billboard@1x.png'
import bridge from '../assets/images/bridge2@1x.png'
import logotick from '../assets/images/path-15710-10@1x.png'
import logocircle from '../assets/images/path-15709-10@1x.png'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { APP_USER_AUTH_SUCCESS } from '../reducer/types'

export default function CriticalThinking21(props) {
  const { dispatch, state } = useApp()
  let isMemoryLeak = useRef(true)
  const [open, toggleMenu] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.criticalthinking?.assesment[1]
      ? state?.user?.progress?.course?.criticalthinking?.assesment[1]
      : 0,
  )

  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const history = useHistory()

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    var stateNew = {
      ...state,
    }
    UpdateAssessments(
      stateNew.user.progress.course.criticalthinking,
      1,
      Number(localStorage.getItem('Pbarvalue2')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue2')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_solve`,
        assessment:
          state?.user?.progress?.course?.criticalthinking?.assesment[0] +
          state?.user?.progress?.course?.criticalthinking?.assesment[1],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue2', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/critical_thinking2.2')
    } else {
      history.push('/critical_thinking2.1')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange upperr flex flex-row justify-between fixed w-full top-0'>
        <div>
          {open && (
            <div>
              <GlobalMenu openMenu={() => toggleMenu(false)} />
            </div>
          )}

          <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
            {!open ? (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={menu} alt='logo' />
                
              </div>
            ) : (
              <div className='mt-3 ml-3'>
                <img className=' w-9 h-9' src={logo3} alt='logo' />
                <img className='h-3 left-6  top-6 absolute' src={logocross} alt='logo' />
              </div>
            )}
          </div>
        </div>
        <h4 className='percentage text-center text-xl pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>
      {!open && (
        <div className='w-screen mt-16'>
          <div className='crical_thinking_splash  bg-no-repeat pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-5'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center  getFitTopName  pb-2 pt-10 _bold '
                style={{ color: '#ffffff', letterSpacing: '6px' }}>
                SOLVE
              </h4>
              <h4 className='text-center  getFitTopHeading mb-4 '>Critical Thinking 2</h4>
              <h4 className='text-center  mb-10 topPara pb-20 w-[87%] mx-auto'>
                Discover why you need critical thinking, when to use it and how it compliments other
                skills to solve problems more effectively.
              </h4>
            </div>
            <p
              className='text-center mb-5 font-normal tracking-normal leading-6 _regular'
              style={{ color: '#ffffff', fontSize: '10px' }}>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='mt-12'>
            <p
              className=' text-center mb-1 getFitSmallHeader  uppercase '
              style={{ letterSpacing: '2px' }}>
              GET FIT
            </p>
            <p
              className='text-center  mb-7 getFitMidHeading uppercase'
              style={{ lineHeight: '14px', letterSpacing: '2.80px' }}>
              Critical Thinking 2
            </p>
            <p className='text-center  mb-4 getFitHeadline  pl-10 pr-10 '>
              Why Do I Need <br />
              Critical Thinking?
            </p>
          </div>
          <h4 className='text-left getFitBodySection ml-10 mr-10 _regular'>
            Critical thinking is the single most in-demand skill in the world today, and arguably
            the most difficult skill to find. In a fast changing, data-filled world, critical
            thinking is your key to assessing your surroundings and making smart, reasoned and sound
            decisions.
          </h4>

          <div className='mt-7'>
            <img alt='logo' src={cliffLogo} className='w-full' />
          </div>
          <p className='text-left  getFitBodySection ml-10 mr-10 mt-6 '>
            The following infographic will help you better understand why and when you need to use
            critical thinking most.
          </p>

          <div className='py-10'>
            <img className='w-full' alt='logo' src={img2} />
          </div>
          <div className='px-7'>
            <div className='mt-2 text-center getFitHeadline'>
              Critical Thinking Is Used In Every Industry
            </div>
            <p className='mt-5 ml-2 getFitBodySection mb-5'>
              Why do we really need critical thinking today? Employers want and need employees who
              can be trusted to make the right decision after correctly evaluating any given
              situation. Because critical thinking is used in every industry across every position,
              you must showcase how critical thinking is a strength if you want to win your dream
              job or advance in your career.
            </p>
          </div>

          <img className='mt-3 imgFullScreen' alt='logo' src={lightbulb} />
          <div className='px-7'>
            <p className='mt-5 ml-1 getFitBodySection'>
              You will use critical thinking in every aspect of your professional career. Below are
              just a few examples of additional high-demand skills that depend on good critical
              thinking to be effective.
            </p>
          </div>
          <div className='px-7'>
            <div className='mt-8 text-center getFitHeadline'>
              Compliment Other <br />
              Vital Skills
            </div>
          </div>
          <div className='px-7'>
            <div className='text-base font-bold mt-5 text-left  text1 mb-5 _bold'>
              Communication
            </div>
            <p className='getFitBodySection'>
              Good communication has been and always will be a critical skill in every environment.
              Good communicators are typically good critical thinkers. (Don’t confuse good talkers
              or speakers with good communicators.)
            </p>
            <p className='getFitBodySection mt-5'>
              Good communication boils down to your ability to quickly and clearly communicate a
              problem and viable solution. Good communication is at the heart of your team
              understanding what action needs to be taken and when—and critical thinking is at the
              heart of understanding what the right action should be.
            </p>
          </div>

          <img className='mt-3 imgFullScreen' alt='logo' src={eye} />

          <div className='px-7'>
            <div className='text-base font-bold my-5 text-left text1 _bold'>
              Data Analysis And Monetization
            </div>
            <p className='getFitBodySection'>
              Data is doubling every two years. Most companies seldom need more data; they typically
              struggle to make sense of the data they already have. Information is power, and data
              has become the new oil—data is now the most valuable commodity on the planet. But in
              order to monetize data, you have to discover what the data means and how you can use
              it to make better decisions or implement solutions.
            </p>
            <p className='getFitBodySection mt-7'>
              As discussed previously, at the heart of critical thinking is our ability to ask the
              right questions. Making sense of data starts with asking the right questions. Only
              after asking the right questions can we determine if we have the right data or need to
              gather additional data.
            </p>
          </div>

          <img className='mt-3 imgFullScreen' alt='logo' src={across_stone} />
          <div className='px-7'>
            <div className=' text-base font-bold my-5 text-left text1 _bold'>
              Problem Solving And Innovation
            </div>
            <p className='getFitBodySection'>
              Every business exists to solve a problem. You were hired, or will be hired, to solve a
              problem at the business. In order to solve a problem, you must be able to ask the
              right questions, collect the right data, and then sort and filter what matters most to
              make the right decisions.
            </p>
            <p className='getFitBodySection mt-7'>
              Every great invention and innovation has started with a question. For example, the
              invention of the mobile phone started with the question of, “Why do I need to be at a
              place to make a phone call?” and then, “What if I could make a phone call from
              anywhere?” If you want to be a good problem solver and innovator, you must become
              better at critical thinking.
            </p>
          </div>

          <div className='px-7'>
            <div className='text-base font-bold my-5 text-left text1 _bold'>
              Marketing And Sales
            </div>
            <p className='getFitBodySection'>
              Every great marketer or salesperson is a good critical thinker. Marketing is your
              ability to grab someone’s attention and then quickly deliver an impactful idea that
              moves them to action. Sales is your ability to articulate a solution to someone that
              motivates them to buy that solution. Both marketing and sales require a deep
              understanding of your prospective buyer, their needs, and their pain points. Only by
              using critical thinking can you start with asking the right questions to better
              understand these needs and pain points, then gather data, sift and sort, and provide
              the right solutions.
            </p>
          </div>

          <img className='mt-6 imgFullScreen' alt='logo' src={bear} />

          <div className='px-7'>
            <div className='text-base font-bold my-5 text-left text1 _bold'>
              Identifying False Information
            </div>
            <p className='getFitBodySection'>
              Nearly half of all information on the internet is fake. Click farms in China
              (warehouses full of low-paid workers with phones and tablets running algorithms to
              drive up likes and/or post reviews on products) create a false sense of trust when
              buying products or services. Critical thinking helps us ask the right questions, find
              the right data, ask ourselves who benefits most from stories or reviews, and sift and
              sort through information to make decisions based on fact, not fiction.
            </p>
          </div>
          <div className='px-7 mt-14'>
            <div
              className='text-center leading-3 _bold  text1 font-bold'
              style={{ letterSpacing: '2px', fontSize: '10px' }}>
              CRITICAL THINKING 2
            </div>
            <div className='mt-2 getFitHeadline text-center' style={{ color: '#43296e' }}>
              Critical Thinking Summary
            </div>
          </div>
          <div className='px-7 mt-5'>
            <p className='getFitBodySection'>
              Critical thinking is the single most in-demand skill in the world today. Put simply,
              critical thinking is at the heart of problem solving, innovation, data analysis, good
              communication, business analysis, marketing and sales, and most of the other top
              in-demand business skills today.
            </p>
          </div>

          <img className='mt-5 imgFullScreen' alt='logo' src={bridge} />

          <div className='px-7 mt-5'>
            <p className='getFitBodySection'>
              Critical thinking is also at the core of navigating our new data-filled world
              successfully. We are faced with more fake information, misinformation, and
              disinformation than ever before. Critical thinking helps you ask the right questions
              so you can better discover the truth. If you want your dream job or to advance quickly
              in your career, you must become better at critical thinking.
            </p>
          </div>

          {!open && (
            <div className='w-full h-[80px] mt-[30px] pb-[40px] grid place-items-center'>
              <button onClick={navigateNext} className='relative '>
                <img alt='logo' src={logocircle} className='h-10 w-10' />
                <img alt='logo' src={logotick} className='absolute h-3.5 ml-2.5 bottom-3 top-1/3' />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
