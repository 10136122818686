import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import per11 from '../assets/images/PER-11.jpg'
import per12 from '../assets/images/PER-12.jpg'
import per13 from '../assets/images/PER-13.jpg'
import per14 from '../assets/images/PER-14.jpg'
import per15 from '../assets/images/PER-15.jpg'
import per16 from '../assets/images/PER-16.jpg'
import per17 from '../assets/images/PER-17.jpg'
import per19 from '../assets/images/PER-19.jpg'
import per20 from '../assets/images/PER-20.jpg'
import persuasion from '../assets/images/Persuasion3.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export const Persuasion1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.communicate?.assesment[2]
      ? state?.user?.progress?.course?.communicate?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.communicate,
      2,
      Number(localStorage.getItem('Pbarvalue12')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue12')), percent1),
    )
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        assessment: AddCourses(state?.user?.progress?.course?.communicate?.assesment, 3),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue12', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/persuasion1.6')
    } else {
      history.push('/persuasion1.5')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl percentage pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='per_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                COMMUNICATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Persuasion 3
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover how to use persuasion every day at home and at work as well as a proven
                process you can use to become more persuasive.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px]'>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 tracking-[2.8px]'>PERSUASION 3</h4>
            <p className='_regular text-2xl work_out  mb-7 text-center  _margin_px'>
              Applying Persuasion At Home And Work
            </p>
            <div className='_regular work_out text-base mb-5 _margin_px'>
              <p>
                It should be clear by now that you can use persuasion in some way almost every day
                in both your personal and professional life. You’ve also learned that your success
                may depend on your ability to be persuasive.
              </p>
            </div>
            <img src={per11} className='w-full mb-6' alt='logo' />
            <div className=' work_out _regular text-base _margin_px mb-8'>
              <p>
                Fortunately, science provides deep insights into human behavior and can help us
                learn to be more persuasive. World-renowned psychologist Robert Cialdini is known as
                the grandfather of persuasion and has written several bestselling books on the
                subject. Cialdini lays out what he considers to be the 6 Laws of Persuasion that can
                dramatically increase your ability to win arguments and influence others to your
                point of view. His insights have influenced our ideas below.
              </p>
              <br />
              <p>
                Study this infographic. Be sure to refer to it often to hone your persuasion skills.
              </p>
            </div>
          </div>

          <div className='flex items-center' style={{ marginLeft: '-27px' }}>
            <img src={persuasion} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px'>
                The 6 Laws Of Persuasion
              </p>

              <div className='_regular work_out text-base  _margin_px mb-6'>
                <p>
                  Whether we’re negotiating with people at work, while shopping, or even at home, we
                  all negotiate for mutually beneficial results. The more we understand how to use
                  influence and persuasion, the more successful we’ll be at selling our ideas,
                  building relationships, and finding win-win solutions.
                </p>
                <br />
                <p>
                  Put in simple terms, persuasion is our ability to influence thoughts and actions
                  through specific strategies. Each one of us is faced with hundreds of decisions
                  every day, and the six laws of persuasion provide shortcuts to making choices.
                </p>
                <br />
                <p>
                  Whether we’re trying to make a sale or persuade others for better outcomes on a
                  project, the six laws of persuasion will help us be more successful.
                </p>
              </div>
              <img src={per12} className='w-full mb-6 ' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <p className=' work_out _bold text-2xl text-center mt-7'>1.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The Law Of Reciprocity
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                The law of reciprocity is based on the idea that, in general, when we receive, we
                feel obligated to repay. We want to balance the scales. This includes simple favors
                that may or may not have monetary value and can also include concessions.
              </p>
              <br />
              <p>
                If you begin a negotiation with the end in mind and extrapolate several acceptable
                outcomes, one of your strategies might be to include a concession. This means that
                if you asked for something large, you can retreat to something smaller—which may
                have been your goal all along.
              </p>
              <br />
              <p>
                For example, if a child wants $20 from their father to go to the movies, they might
                start by asking for $50, which will certainly meet a negative result. But if the
                child retreats to asking for $20, the father may view this as a concession and,
                thus, acquiesce to the request.
              </p>
              <br />
              <p>
                Furthermore, a study revealed that when a waiter included a few extra chocolates
                with the bill, tips increased substantially—far beyond the value of the extra
                chocolates. Thus, even small gifts can result in much larger returns.
              </p>
              <br />
              <p>
                Always remember that the law of reciprocity means that when you give, the other
                person may feel obligated to return the favor—but it also means that when you choose
                to receive, you may also feel obligated to reciprocate.
              </p>
            </div>
            <img src={per13} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>2.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The Law Of Commitment And Consistency
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                The law of commitment and consistency works because, as a society, we believe that
                being consistent is a positive trait, whereas inconsistency is viewed as a negative
                trait.
              </p>
              <br />
              <p>
                When we make a commitment to a decision, position, or even a cause, we tend to be
                consistent in our actions to support that commitment.
              </p>
              <br />
              <p>
                Not all commitments are equal. The more time, money, or effort that’s required when
                making a commitment, the more likely we are to keep it. Verbal commitments, simply
                telling someone we will do something, are the weakest. Written commitments are
                stronger, as they can be referred back to and may be used against us in the future.
                Physical commitments are the strongest, as they require us to make the most effort.
              </p>
              <br />
              <p>
                If you want to be more persuasive, try to get small commitments early in your
                conversation and negotiation. Small commitments often lead to large commitments.
              </p>
              <br />
              <p>
                But remember, if someone has made a previous commitment, getting them to change
                their mind and support your argument may be difficult.
              </p>
              <br />
              <p>
                During your research, try to discover any previous commitments that the other party
                has already made. This will help you understand what they are most likely to do—and
                what they are most likely to consistently do.
              </p>
            </div>
            <img src={per14} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>3.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The Law Of Liking
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                The law of liking is extremely powerful. Put simply, it means that we are more
                influenced by the people we know and like. It’s the reason most salespeople work
                hard at trying to discover your passions and interests; they’re looking to find
                commonalities.
              </p>
              <br />
              <p>
                Studies show that the more we have in common with someone, the more likely we can
                persuade them to our point of view.
              </p>
              <br />
              <p>
                Again, do your research. Find out what the other person’s interests and passions
                are, and try to find areas of commonality.
              </p>
              <br />
              <p>
                Mirroring body language, facial expressions, and voice tonality can increase
                likability, which can increase trust. Always give people a reason to like you. Find
                areas of common interest. By doing these things, you’ll be more persuasive.
              </p>
            </div>
            <img src={per15} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>4.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The Law Of Scarcity
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                Humans are most motivated by fear of loss. In other words, we tend to fear losing
                what we already have more than we anticipate gaining something.
              </p>
              <br />
              <p>
                The law of scarcity states that when something is in short supply and in high
                demand, it is more valuable. It’s the reason why “sales” are effective. “Hurry! Only
                three seats left at this price!” “Act now! Just two rooms left at this price.” These
                scarcity strategies work because we believe if we don’t purchase now, it may cost us
                more later.
              </p>
              <br />
              <p>
                The law of scarcity is a very powerful persuasion tactic that can be used during
                negotiations to move someone to action or persuade them to your point of view. For
                example, “This offer is only on the table until 5 p.m.” or “We’re going to take this
                offer to your competitor tomorrow morning.” Both of these statements play on fear of
                loss—on fear of scarcity. Rarely is an item truly scarce. So while you may use this
                strategy to be more persuasive, be aware of when others may be using it against you.
              </p>
              <br />
              <p>
                <span className='_bold'>NOTE:</span> Diamonds are not rare, they are ubiquitous. De
                Beers simply controls the market to keep the price high—supply and demand.
              </p>
            </div>
            <img src={per19} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>5.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The Law Of Authority
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                The law of authority states that we tend to believe and trust people in positions of
                authority and power—or those we perceive to be experts.
              </p>
              <br />
              <p>
                One brain study revealed that when a question was asked in regard to a specific
                category, if an expert was present in the room, the participants not only deferred
                to the expert’s opinion but actually turned off their brains.
              </p>
              <br />
              <p>
                Authority figures can include experts, celebrities, and even companies or
                institutions in positions of power. For example, if two out of three dentists
                recommend a certain toothpaste, who are we to challenge them? Or, if the Center for
                Disease Control makes recommendations in regard to COVID-19, we tend to believe and
                trust the recommendations because we tend to believe what government organizations
                say.
              </p>
              <br />
              <p>
                The law of authority is important because in order for us to gain trust, we need to
                position ourselves as authorities. If we are not authorities or experts on a certain
                subject, then we must establish our credentials by linking our argument to respected
                sources that will drive credibility.
              </p>
            </div>
            <img src={per20} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>6.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              The Law Of Social Proof
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                There is power in numbers. To reduce risk, we often look at what others are doing in
                our particular situation.
              </p>
              <br />
              <p>
                For example, we often look at testimonials, rankings, or reviews in order to
                determine whether or not to purchase a product or service. We assume that if there
                are thousands of four-star reviews, the product must be good.
              </p>
              <br />
              <p>
                Many brands use celebrities to market their products. This is because we believe
                that if it’s good enough for a celebrity (who has enough money to choose any product
                they want), it’s probably good enough for us. Using celebrities can also be applied
                to the tactic of using the law of authority. One of the most powerful ways to use
                the law of social proof is to show the other side what people or companies like them
                are doing in the same situation. For example, you might think, “If other companies
                like mine are having their IT departments move to the cloud, this makes me think
                it’s a good idea, so maybe my IT department should move to the cloud as well.”
              </p>
              <br />
              <p>
                As humans, it’s important for us to fit in if we want to be accepted. Social proof
                is a powerful strategy to persuade others to be compliant.
              </p>
            </div>
            <img src={per16} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Persuasion Scenarios
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>Opportunities you may encounter that will require you to use persuasion:</p>
              <ul className='flex flex-col gap-3 mt-6'>
                <li>
                  <span className='_bold'>• </span> Asking for a raise or promotion{' '}
                </li>
                <li>
                  <span className='_bold'>• </span> Requesting something at work, including new
                  equipment, support, or even time off
                </li>
                <li>
                  <span className='_bold'>• </span> Pitching a new idea or strategy
                </li>
                <li>
                  <span className='_bold'>• </span> Negotiating any kind of agreement, deal or sale
                  with a vendor, supplier, or client
                </li>
                <li>
                  <span className='_bold'>• </span>Convincing friends or colleagues to try a new
                  location for lunch or dinner
                </li>
                <li>
                  <span className='_bold'>• </span>
                  Making an argument for a vacation destination with your family .
                </li>
              </ul>
              <p className='mt-5'>
                One unexpected outcome of learning about persuasion, is that you can quickly
                recognize when someone might be trying to sell or manipulate you, and you can expose
                the other side’s tactics or counter with the appropriate strategy.
              </p>
            </div>
            <img src={per17} className='w-full ' alt='logo' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-10'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>PERSUASION 3</h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Persuasion <br /> Summary And Final Wrap-Up
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  Persuasion will harness the power of every skill you will learn with Fullbridge.
                  No matter how smart or talented you are, you must be able to sell your ideas and
                  persuade others to your point of view. This is the single most important skill
                  that every business leader must learn to master.
                </p>
                <br />
                <p>
                  You will use critical thinking every day as you gather data. You will use
                  cognitive flexibility to connect the dots and creative thinking to find new
                  solutions. You will use hyper-focus to be more efficient and productive. Emotional
                  intelligence will dramatically increase your success with those around you, and
                  your personal brand and reputation will follow you everywhere.
                </p>
                <br />
                <p>
                  But it all boils down to your ability to pitch your ideas and use the strategies
                  and tactics you’ve learned in this section to become powerfully persuasive.
                </p>
              </div>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
