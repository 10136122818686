import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import Next_image from '../assets/images/group-3437-1@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import { useHistory } from 'react-router-dom'
import leftArrow from '../assets/images/leftArrow.svg'
import useApp from '../context/useApp'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export default function ExerciseSolves() {
  const { dispatch, state } = useApp()
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const [textInput, setTextInput] = useState({
    objective: '',
    stakeholder: '',
    another: '',
  })
  useEffect(() => {
    if (state?.user?.progress?.course?.solve?.Exercise?.textInput?.objective) {
      setTextInput({
        ...textInput,
        objective: !state?.user?.progress?.course?.solve?.Exercise?.textInput.objective
          ? ''
          : state?.user?.progress?.course?.solve?.Exercise?.textInput.objective,
        stakeholder: !state?.user?.progress?.course?.solve?.Exercise?.textInput.stakeholder
          ? ''
          : state?.user?.progress?.course?.solve?.Exercise?.textInput.stakeholder,
        another: !state?.user?.progress?.course?.solve?.Exercise?.textInput.another
          ? ''
          : state?.user?.progress?.course?.solve?.Exercise?.textInput.another,
      })
    }
  }, [])

  const handleSubmit = async event => {
    let uri = '/course/course'
    event.preventDefault()
    var stateNew = { ...state }

    stateNew.user.progress.course.solve.Exercise = {
      ...stateNew.user.progress.course.solve.Exercise,
      textInput,
      assessmentPercent:
        state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent > 44
          ? state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent
          : 44,
    }
    if (state?.user?.progress?.course?.solve?.Exercise?.assessmentPercent < 44) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_solve`,
          assessment: 44,
        }),
      )
    }
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (
      textInput?.another?.length >= 1 &&
      textInput?.objective?.length >= 1 &&
      textInput?.stakeholder?.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
        return history.push('/exercise_solve_1.5')
      }
    }
    alert('Answer can not be blank')
  }

  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _semiBold nav_title'>SOLVE</h3>
              <strong className='nav_percent'>
                <h1 className='percent'>44%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>{' '}
      {/* Top Navbar option End */}
      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='step'>STEP 4</h3>
              <h1 className='leading-none mt-2 mainHeading w-full' style={{ fontSize: '23px' }}>
                Three Potential Solutions
              </h1>
              <p className='ex_solve_para'>
                When looking for solutions, science tells us that we should try and generate up to
                30 or more ideas. Often the best ideas are the ones we generate last. Then we need
                to narrow down our ideas to the top two or three and make sure they are data
                supported.
              </p>
            </div>
            <form className='solve_form_1_3' onSubmit={handleSubmit}>
              <div className='action_div'>
                <h3 className='step'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  First potential solution. Keep it short and clear with 150 characters or less.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  value={textInput.objective}
                  maxLength={150}
                  onChange={e =>
                    setTextInput({ ...textInput, objective: e.target.value.trimStart() })
                  }
                  className='user_text_area'
                />
                <span className='text-right text-xs ml-auto mt-2 ' style={{ color: 'red' }}>
                  {textInput.objective.length}/150
                </span>
                <p className='message'>
                  <span className='_semiBold'>Example: </span>
                  <span className='italic'>
                    Nuclear energy is a clean and sustainable energy source when compared to coal.
                    It is more reliable than wind and solar.
                  </span>
                </p>
              </div>
              <div className='action_div'>
                <h3 className='step'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  Second potential solution. Keep it short and clear with 150 characters or less.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area w-full'
                  maxLength={150}
                  value={textInput.stakeholder}
                  onChange={e =>
                    setTextInput({ ...textInput, stakeholder: e.target.value.trimStart() })
                  }
                />
                <span className='text-right text-xs ml-auto mt-2 ' style={{ color: 'red' }}>
                  {textInput.stakeholder.length}/150
                </span>
              </div>
              <div className='action_div'>
                <h3 className='step'>action</h3>
                <p className='ex_solve_para mb-[15px]'>
                  Third potential solution. Keep it short and clear with 150 characters or less.
                </p>
                <textarea
                  name='new_text_area'
                  placeholder='Type here.'
                  className='user_text_area w-full'
                  maxLength={150}
                  value={textInput.another}
                  style={{
                    background: textInput?.another?.length <= 150 ? '#ffffff' : '#ffb3b3',
                  }}
                  onChange={e =>
                    setTextInput({ ...textInput, another: e.target.value.trimStart() })
                  }
                />
                <span className='text-right text-xs ml-auto mt-2 ' style={{ color: 'red' }}>
                  {textInput.another.length}/150
                </span>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/exercise_solve_1.3')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>
                  <button type='submit'>
                    <img
                      src={Next_image}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
