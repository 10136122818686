import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15743-1@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logocircle from '../assets/images/group-3437-1@1x.png'
import logohead from '../assets/images/group-4480-4@1x.png'
import logowhite from '../assets/images/path-15712@1x.png'
import { useHistory } from 'react-router-dom'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'

export default function CriticalThinking32() {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  return (
    // <div className=' fixed top-0 bottom-0 right-0 left-0 overflow-auto mt-5 p-5'>
    <div className='fixed top-0 bottom-0 right-0 left-0 overflow-auto bg_grey'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-4 ml-5'>
            <img className=' w-9 h-9' alt='logo' src={menu} />
            
          </div>
        ) : (
          <div className='mt-4 ml-5'>
            <img className=' w-9 h-9' alt='logo' src={whiteCross} />
            
          </div>
        )}
      </div>
      <div className='flex justify-center mt-11'>
        <img className='h-16 w-16' alt='logo' src={logohead} />
      </div>
      <h4
        className='text-center text-sm _bold mt-4 error_msg_color'
        style={{ letterSpacing: '2.8px', fontSize: '14px' }}>
        SOLVE
      </h4>
      <div className='px-5'>
        <div className='text-center getFitHeadline leading-7 ml-2 mt-7 '>
          What You Can Do With Your New Skill
        </div>
        <div className='flex justify-center mt-8'>
          <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
            <div
              className=' leading-7  my-1 text-center whitespace-nowrap text-lg _bold w-8 mt-2'
              style={{ color: '#ffffff' }}>
              1
            </div>
          </div>
        </div>
        <p className='mt-4 leading-6  text-center _semiBold text-base px-5 work_out'>
          Solve problems using a proven logical process.
        </p>
        <div className='flex justify-center mt-8'>
          <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
            <div
              className='tracking-normal leading-7 my-1 text-center whitespace-nowrap text-lg _bold w-8 mt-2'
              style={{ color: '#ffffff' }}>
              2
            </div>
          </div>
        </div>
        <p className='mt-4 leading-6 ml-1 text-center _semiBold text-base px-10 work_out'>
          Make better decisions by removing bias and emotion.
        </p>
        <div className='flex justify-center mt-8'>
          <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
            <div
              className='tracking-normal leading-7  my-1 text-center whitespace-nowrap text-lg _bold w-8 mt-2'
              style={{ color: '#ffffff' }}>
              3
            </div>
          </div>
        </div>
        <p className='mt-4 leading-6 ml-1 text-center _semiBold text-base px-14 work_out'>
          Better discern between valuable and less valuable information.
        </p>
      </div>
      <div
        className='mt-24 mb-10 flex justify-center relative'
        onClick={() => history.push('/critical_thinking3.3')}>
        <div>
          <img className=' w-10 h-10' alt='logo' src={logocircle} />
        </div>
      </div>
    </div>
  )
}
