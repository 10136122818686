import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo6 from '../assets/images/path-15710-10@1x.png'
import { InputBox } from './reusable_code/ReusableComp'
import { signup } from '../services/user'

const AddUsers = () => {
  const history = useHistory()
  const [btnState, setbtnState] = useState(false)
  const [open, toggleMenu] = useState(false)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState('')
  const [password, setPass] = useState()
  const [conPass, setConPass] = useState()
  const [check, setCheck] = useState(false)
  const [responseState, setResponsestate] = useState(false)
  const handleSubmit = async e => {
    // perform all neccassary validations
    if (
      !email ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    ) {
      alert('Invalid email')
    } else if (!password || !conPass || password !== conPass) {
      alert("Passwords don't match")
    } else {
      let res = await signup(
        JSON.stringify({
          name: `${firstName} ${lastName}`,
          username: email,
          password: btoa(`${password}`),
          subscriber: check,
        }),
      )
      let data = res.ok ? await res.text() : null
      if (res.ok) {
        setResponsestate(true)
      }
      let timeout
      if (data) {
        data = JSON.parse(data)
        if (data.success) {
          if (data?.data?.user?.is_admin) {
            alert('User added')
            timeout = setTimeout(() => {
              // history.push('/admin-edit-users')
            }, 3000)
            clearTimeout(timeout)
            // history.push('/admin-edit-users')
          } else {
            timeout = setTimeout(() => {
              // history.push('/admin-edit-users')
            }, 3000)
            clearTimeout(timeout)
            // history.push('/admin-edit-users')
          }
        }
      } else {
        const error = await res.json()
        alert(error.message)
      }
    }
  }
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      <h4
        className='text-center _medium mt-10 text-2xl not-italic font-medium'
        style={{ color: 'var(--windsor)' }}>
        Add User
      </h4>

      <InputBox
        htmlFor='#first_name'
        label='User First Name'
        id='firstName'
        type='text'
        onChange={e => {
          setFirstName(e.target.value)
        }}
      />
      <InputBox
        htmlFor='#last_name'
        label='User Last Name'
        id='lastName'
        type='text'
        onChange={e => {
          setLastName(e.target.value)
        }}
      />
      <InputBox
        htmlFor='#email'
        label='User Email Address'
        id='email'
        type='email'
        onChange={e => {
          setEmail(e.target.value)
        }}
      />

      <InputBox
        htmlFor='#code'
        label='Password'
        id='code'
        type='password'
        onChange={e => {
          setPass(e.target.value)
        }}
      />
      <InputBox
        htmlFor='#code'
        label='Confirm Password'
        id='code'
        type='password'
        onChange={e => {
          setConPass(e.target.value)
        }}
      />

      <div className='px-9 flex' style={{ marginTop: '52px' }}>
        <input
          id='checkbox'
          type='checkbox'
          className=' mt-4 mb-8 h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setCheck(check === false ? true : false)}
        />
        <div
          className=' text-xs mt-6 not-italic font-medium inline-block _medium ml-3'
          style={{ color: ' var(--windsor)' }}>
          Subscriber
        </div>
      </div>

      {!open && (
        <div className='px-16  mt-32 mb-10 flex justify-between relative'>
          <button className='' onClick={() => history.push('/admin-edit-users')}>
            <img src={logo1} className='h-10 w-10' alt='logo' />
            <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>

          <button onClick={() => handleSubmit()}>
            <img src={logo3} className='h-10 w-10' alt='logo' />
            <img src={logo6} className='absolute h-3.5 ml-2.5 bottom-3' alt='logo' />
          </button>
        </div>
      )}
      <div
        className='popUp'
        style={{
          display: responseState === true && btnState === false ? 'block' : 'none',
        }}>
        <div className='PopUpWrapper'>
          <h5 className='font-semibold text-green-500 p-2 text-center'>User is added.</h5>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full
          '
            onClick={() => {
              setbtnState(true)
              history.push('/admin-edit-users')
            }}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddUsers
