import { Link, useHistory } from 'react-router-dom'
import { APP_USER_LOGOUT } from '../../reducer/types'
import useApp from '../../context/useApp'
export default function AdminMenu(props) {
  const { openMenu } = props
  const { dispatch } = useApp()
  const history = useHistory()
  const Logout = () => {
    dispatch({ type: APP_USER_LOGOUT })
    history.push('/')
  }
  return (
    <div className='w-full menu-content h-full fixed top-0 bottom-0 right-0 left-0 _bg__windsor fixPopupView admin_signup_in text-center'>
      <div className=' _regular text-2xl'>
        <p className='pb-3'>
          {/* <Link to='/dashboard' className='text-white'>
            Dashboard
          </Link> */}
          <Link to='/admin-dashboard' className='text-white text-2xl' onClick={openMenu}>
            Dashboard
          </Link>
        </p>

        <p className='pb-3'>
          <button onClick={Logout} className='text-white text-2xl'>
            Log Out
          </button>
        </p>
      </div>
    </div>
  )
}
