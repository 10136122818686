import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import ProgressCard from '../components/userComponent/ProgressCard'
import logo2 from '../assets/images/backrow.svg'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import logo8 from '../assets/images/path-15712@1x.png'
import logo6 from '../assets/images/Group 5368.svg'
import communicateLogo from '../assets/images/group-4484@1x.png'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'

export default function UserCommunicateWorkout(props) {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state } = useApp()

  const data = [
    {
      name: 'PERSUASION 1',
      description:
        'Discover what persuasion is and its role in successful and effective communication.',
      progress: state?.user?.progress?.course?.communicate?.assesment[0]
        ? state?.user?.progress?.course?.communicate?.assesment[0]
        : 0,
      time: '10 min',
      logo: communicateLogo,
      route: '/persuasion1.1',
    },
    {
      name: 'PERSUASION 2',
      description:
        'Discover why you need persuasion and when to use it to drive more successful communication.',
      progress: state?.user?.progress?.course?.communicate?.assesment[1]
        ? state?.user?.progress?.course?.communicate?.assesment[1]
        : 0,
      time: '10 min',
      logo: communicateLogo,
      route:
        state?.user?.progress?.course?.communicate?.assesment[0] === 100 ? '/persuasion1.3' : null,
    },
    {
      name: 'PERSUASION 3',
      description:
        'Discover how to use persuasion and a proven process you can use to become more persuasive.',
      progress: state?.user?.progress?.course?.communicate?.assesment[2]
        ? state?.user?.progress?.course?.communicate?.assesment[2]
        : 0,
      time: '10 min',
      logo: communicateLogo,
      route:
        state?.user?.progress?.course?.communicate?.assesment[0] &&
        state?.user?.progress?.course?.communicate?.assesment[1] === 100
          ? '/persuasion1.5'
          : null,
    },
  ]

  return (
    <div className='user_get-fit fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo3} />
            <img className='top-5 left-5 h-1   absolute' alt='logo' src={logo4} />
            <img className=' h-1 top-7 left-5 absolute' alt='logo' src={logo4} />
            <img className='top-9 left-5 h-1 absolute' alt='logo' src={logo4} />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo8} />
            <img className='h-3 left-6  top-6 absolute' alt='logo' src={logo5} />
          </div>
        )}
      </div>
      {!open && (
        <div>
          <div className='flex justify-center mt-10'>
            <img className='h-13 w-12' alt='logo' src={logo6} />
          </div>
          <h4 className='text-center text-2xl _medium pb-1 _font_color_white'>Communicate</h4>
          <h4
            className='text-center font mb-6 spaceing _bold _font_color_white '
            style={{ letterSpacing: '2.5px' }}>
            GET FIT
          </h4>
          {data?.map((item, index) => {
            return (
              <ProgressCard
                cardContainerOpacity={index === 0 ? '100%' : item?.route !== null ? '100%' : '80%'}
                leftLogo={item?.logo}
                key={index}
                time={item.time}
                name={item?.name}
                route={item?.route}
                description={item?.description}
                progress={item?.progress}
                timeColor={'#E28D3D'}
                cardContainerColor={'#ffffff'}
                nameColor={'#E28D3D'}
                descriptionColor={'#E28D3D'}
                progressContainerColor={'#F6DBC9'}
                progressColor={'#E28D3D'}
                progressTextColor={'#E28D3D'}
              />
            )
          })}
          <div className='mt-80 mb-10 flex justify-center relative'>
            <div onClick={() => history.push('/get_fit')}>
              <div className='flex justify-center items-center h-10 w-10'>
                <img className='absolute' alt='logo' src={logo2} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
