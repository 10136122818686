import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import eq1 from '../assets/images/EQ-1.jpg'
import eq2 from '../assets/images/EQ-2.jpg'
import eq3 from '../assets/images/EQ-3.jpg'
import eq4 from '../assets/images/EQ-4.jpg'
import img4 from '../assets/images/img4.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { UpdateAssessments } from './Asses/Comm_Text'

export const EmotionalIntelligence = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.collabrate?.assesment[0]
      ? state?.user?.progress?.course?.collabrate?.assesment[0]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }

    if (!stateNew.user.progress.course['collabrate']) {
      stateNew.user.progress.course['collabrate'] = {
        ...stateNew.user.progress.course.collabrate,
        assesment: [
          Number(localStorage.getItem('Pbarvalue4')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue4')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course.collabrate,
        0,
        Number(localStorage.getItem('Pbarvalue4')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue4')), percent1),
      )
    }

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        assessment: state?.user?.progress?.course?.collabrate?.assesment[0],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue4', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      return history.push('/emotional_intelligence1.2')
    } else {
      history.push('/emotional_intelligence1.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-full mt-16 flex flex-col'>
          <div className='emotional_intelligence_splash justify-between w-full flex flex-col  items-center'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange grid place-items-center'>
                <div
                  className='text-5xl text-center  text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  1
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center getFitTopName mt-12 pb-2 '
                style={{ letterSpacing: '7px' }}>
                COLLABORATE
              </h4>
              <h4 className='text-center getFitTopHeading pb-4'>Emotional Intelligence 1</h4>
              <p className='text-center topPara _margin_px' style={{ lineHeight: '21px' }}>
                Discover what emotional intelligence is and its role in effective and successful
                collaboration.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-10 '>
            <h1 className=' text-center getFitSmallHeader tracking-[2px]'>GET FIT</h1>
            <h4 className='getFitMidHeading mb-5 tracking-[2.8px]'>EMOTIONAL INTELLIGENCE 1</h4>
            <p className='getFitHeadline work_out text-center mb-7'>
              What Is Emotional Intelligence?
            </p>
            <p className='getFitBodySection mb-5 _margin_px'>
              Emotional intelligence (EQ) is your ability to be aware of and express your emotions
              in relationships empathetically and judiciously. In short, it’s how you engage with
              the people around you. Good EQ requires you to be able to first identify and manage
              your own emotions and then be able to identify and manage or engage with the emotions
              of others.
            </p>
            <img src={eq1} className='w-full mb-6' alt='logo' />
            <p className=' work_out getFitBodySection _margin_px mb-6'>
              Next, let’s explore The Emotional Intelligence Flow infographic to ensure you’re ready
              to engage.
            </p>
          </div>

          <div className='flex items-center  '>
            <img src={img4} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-12'>
              <p className='getFitHeadline text-center mb-7  '>
                Emotional Intelligence <br /> Is A Relatively New Term
              </p>
              <p className='getFitBodySection  _margin_px mb-5'>
                The term “emotional intelligence” was first coined in 1990 by Peter Salovey, Ph.D.,
                president of Yale and professor of psychology, and John D. Mayer, Ph.D., personality
                psychologist. They described emotional intelligence as “a form of social
                intelligence that involves the ability to monitor one’s own and others’ feelings and
                emotions, to discriminate among them, and to use this information to guide one’s
                thinking and actions.” The duo initiated research programs with the intent of
                developing valid measures of EQ and gaining better understanding of its significance
                in personal and professional relationships—and ultimately, overall success in life.
              </p>
              <img src={eq2} className='w-full mb-6' alt='logo' />
              <p className='getFitHeadline text-center mb-5  '>Four Key Characteristics</p>
              <p className='getFitBodySection  _margin_px mb-5'>
                Trained as a psychologist at Harvard, Daniel Goleman learned of Salovey and Mayer’s
                work during the 1990s, which ultimately led to his book “Emotional Intelligence.”
                Goleman and others at the time were driving the debate about whether or not
                cognitive intelligence leads to success or if emotional intelligence plays a greater
                role. In his book, Goleman argued that, indeed, it is not cognitive intelligence
                that guarantees business success—it is emotional intelligence. He described highly
                emotionally intelligent people as exhibiting four critical characteristics. He
                concluded that individuals with high EQ were good at:
              </p>
            </div>
          </div>

          <div className='flex justify-center mb-5'>
            <div className='rounded-full cursor-pointer flex mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold _bold w-8'
                style={{ color: '#ffffff' }}>
                1
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center  mb-6 text1 _margin_px'>
            Understanding their own emotions (self-awareness).
          </h4>

          <div className='flex justify-center  mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                2
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center text1  _bold _margin_px'>
            Managing their emotions (self-management).
          </h4>

          <div className='flex justify-center  mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                3
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center text1  _bold _margin_px'>
            Being empathetic to the emotions of others (social awareness).
          </h4>

          <div className='flex justify-center mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                4
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center  text1 _margin_px '>
            Handling other people’s emotions (social skills).
          </h4>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <p className='getFitBodySection  _margin_px mb-5'>
                Two clear sides of emotional intelligence emerged:
                <span className='_bold'>
                  &nbsp;1) self-awareness and management and 2) social awareness and management.
                </span>
                <br />
                <br />
                Accordingly, only by mastering your ability to be aware of, understand, and manage
                your own emotions, combined with your ability to be aware of, understand, and manage
                your engagement with others and their emotions, could you attain high EQ and, thus,
                success.
              </p>
              <img src={eq3} className='w-full mb-6' alt='logo' />
              <p className='getFitHeadline text-center mb-5  '>The Latest On EQ</p>
              <p className='getFitBodySection  _margin_px mb-5'>
                In 2016, John D. Mayer, David R. Caruso, and Peter Salovey published and updated a
                revised model of the four pillars of emotional intelligence. Although similar to the
                original four, there are some important differences in the new version based on the
                latest discoveries in research. The revised model is:
              </p>
            </div>
          </div>

          <div className='flex justify-center mb-5'>
            <div className='rounded-full cursor-pointer flex mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                1
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center  mb-6 text1 _margin_px'>Perceiving emotions</h4>

          <div className='flex justify-center  mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                2
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center text1  _bold _margin_px'>
            Facilitating thought using emotion
          </h4>

          <div className='flex justify-center  mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                3
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center text1  _bold _margin_px'>
            Understanding emotions
          </h4>

          <div className='flex justify-center mb-5'>
            <div className=' rounded-full cursor-pointer flex  mt-2 h-10 w-10 justify-center avatarBg'>
              <div
                className='leading-7  my-1 text-center text-lg _bold font-bold w-8'
                style={{ color: '#ffffff' }}>
                4
              </div>
            </div>
          </div>

          <h4 className='getFitBold16px text-center  text1 _margin_px '>Managing emotions</h4>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <p className='getFitBodySection  _margin_px mb-5'>
                At their core, both models are nearly identical.
                <span className='_bold'>
                  {' '}
                  The main difference is facilitating thought using emotions.{' '}
                </span>
                As we explore other skills, including critical thinking and creative thinking, our
                personal awareness of our emotional state becomes even more important. Understanding
                our own emotions or the emotions of others can dramatically affect our perception of
                the situation, our bias, and even whether we choose to accept or reject certain
                data.
              </p>
              <img src={eq4} className='w-full mb-6' alt='logo' />
              <p className='getFitHeadline text-center mb-5  '>Using Your Head And Your Heart</p>
              <p className='getFitBodySection  _margin_px mb-12'>
                David Caruso wrote, “It is very important to understand that emotional intelligence
                is not the opposite of intelligence, it is not the triumph of heart over head—it is
                a unique intersection of both.” <br />
                <br />
                As we explore emotional intelligence, it’s important to understand that it is about
                finding balance—not allowing our intellect or emotions to control any given
                situation, thought pattern, or analysis. As we all strive to make better choices, we
                need to use every tool at our disposal. Emotional intelligence becomes yet another
                tool to help ensure we get things right. Just as importantly, high EQ also ensures
                the process is dynamic, enjoyable, positive, and uplifting for each person or team
                member who engages with us along the way.
              </p>
              <h4 className='getFitSmallHeader text-center tracking-[2px]'>
                EMOTIONAL INTELLIGENCE 1
              </h4>
              <p className='getFitHeadline text-center mb-8 '>Emotional Intelligence Summary</p>
              <p className='getFitBodySection  _margin_px mb-5'>
                Emotional intelligence (EQ) is your ability to be aware of and express your emotions
                in relationships empathetically and judiciously. In short, it’s how you engage with
                the people around you. High EQ is also a critical part of the analysis and
                decision-making process, helping you find a balance between your heart and your
                head.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
