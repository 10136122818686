import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import logo5 from '../assets/images/path-15768@1x.png'
import dumble from '../assets/images/group-4546-1@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import Next_image from '../assets/images/group-3437-1@1x.png'
import { useHistory } from 'react-router-dom'
import White from '../assets/images/whitemenu icon.svg'
import { saveScoring } from '../services/user'
import { saveProgress } from '../services/user'
import useApp from '../context/useApp'
import leftArrow from '../assets/images/leftArrow.svg'
export function CollabrateTool1_4() {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const handleSubmit = async event => {
    event.preventDefault()
    let uri = '/course/course'
    var stateNew = { ...state }
    if (state?.user?.progress?.course?.eq_tool?.assessmentPercent < 67) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_communicate`,
          assessment: 67,
        }),
      )
    }
    stateNew.user.progress.course.eq_tool = {
      ...stateNew.user.progress.course.eq_tool,
      assessmentPercent:
        state?.user?.progress?.course?.eq_tool?.assessmentPercent > 67
          ? state?.user?.progress?.course?.eq_tool?.assessmentPercent
          : 67,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    history.push('/collabrate_tool1.5')
  }
  return (
    <div className='w-full fixed top-0 left-0 right-0 bottom-0 bg-white'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading '>COLLABORATE</h3>
              <strong>
                <h1 className='percent'>67%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      {/* Top Navbar option End */}

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='step'>STEP 4</h3>
              <h1 className='leading-none mt-2 mainHeading '>The 4 EQ Pillars</h1>
              <p className='ex_solve_para'>
                Remember, collaboration is a two-way street—you will be communicating with your team
                and they will be communicating with you. Here is where you will need to exercise all
                of your EQ muscles to ensure you get the desired successful outcome.
              </p>
            </div>
            <h3 className='exerciseReadheading' style={{ margin: '-15px 0px -35px 0px' }}>
              action
            </h3>
            <p className='ex_solve_para'>
              When someone is communicating with you via email, text, phone or in person, ask
              yourself the following questions:
            </p>
            <div className='solve_form_1_3'>
              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>1.</h1>
                </strong>
                <p className='ex_solve_para mb-[15px] _semiBold' style={{ marginTop: '0px' }}>
                  Am I aware of my own emotions? Am I aware of the other person’s emotions?
                </p>
              </div>
              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>2.</h1>
                </strong>
                <p className='ex_solve_para mb-[15px] _semiBold' style={{ marginTop: '0px' }}>
                  What do my emotions mean? Can I truly define how I’m feeling? What about the other
                  party’s emotions, can I define how they are feeling?
                </p>
              </div>
              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>3.</h1>
                </strong>
                <p className='ex_solve_para mb-[15px] _semiBold' style={{ marginTop: '0px' }}>
                  How will my emotions affect how I think or feel about the information? How will
                  their emotions affect their view about the information?
                </p>
              </div>

              <div className='action_div'>
                <strong>
                  <h1 className='leading-none  mainHeading'>4.</h1>
                </strong>
                <p className='ex_solve_para mb-[15px] _semiBold' style={{ marginTop: '0px' }}>
                  Am I managing my emotions? Am I managing the other party’s emotions?
                </p>
              </div>

              <div className='relative mt-3.5'>
                <p className='message italic'>
                  <span className='_semiBold'>Example:</span> <br /> <br />
                  <p>
                    {' '}
                    1) I’m feeling agitated. 2) My agitation can best be defined as frustration. 3)
                    My frustration might make me feel that people are trying to please me with quick
                    information rather than the right information. 4) If I don’t manage my
                    frustration, they might give me quick information and we will not reach a
                    successful outcome.
                  </p>
                </p>
                <p className='message  italic'>
                  1) The other party is being unusually short in their communication. 2) I think
                  they are feeling intimidated and/or threatened. 3) They might try and get me a
                  quick result rather than the right result. 4) I need to make sure I’m managing my
                  frustration as well as listening and communicating effectively to manage their
                  emotions.
                </p>
              </div>
              <div className='w-full flex flex-col items-center gap-6 mb-8'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/collabrate_tool1.3')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit'>
                    <img
                      src={Next_image}
                      alt='image no present'
                      onClick={handleSubmit}
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
