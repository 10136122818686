import Collabrate from '../assets/images/col.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress, saveScoring } from '../services/user'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export const EmotionalIntelligence3_5 = () => {
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.collabrate.cg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        cg: state?.user?.progress?.course?.collabrate.cg,
        kg: state?.user?.progress?.course?.collabrate.kg,
        assessment:
          state?.user?.progress?.course?.collabrate?.assesment[0] +
          state?.user?.progress?.course?.collabrate.assesment[1] +
          state?.user?.progress?.course?.collabrate.assesment[2],
        complete: true,
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }

    return history.push('/user_dashboard')
  }

  const handleClickTwo = async () => {
    var stateNew = { ...state }
    stateNew.user.progress.course.collabrate.cg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        cg: state?.user?.progress?.course?.collabrate.cg,
        kg: state?.user?.progress?.course?.collabrate.kg,
        assessment:
          state?.user?.progress?.course?.collabrate?.assesment[0] +
          state?.user?.progress?.course?.collabrate.assesment[1] +
          state?.user?.progress?.course?.collabrate.assesment[2],
        complete: true,
      }),
    )

    return history.push('/user_dashboard')
  }

  return (
    <CommonConfirmation
      logo={Collabrate}
      title={'COLLABORATE'}
      trainingTitle={'EMOTIONAL INTELLIGENCE'}
      question={'Do you feel more confident to use emotional intelligence every day?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
