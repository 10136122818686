import React, { useEffect, useState } from 'react'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import logo7 from '../assets/images/path-15712@1x.png'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import useApp from '../context/useApp'
import { useHistory } from 'react-router-dom'
import { InputBox } from '../adminPages/reusable_code/ReusableComp'
import { sendEmailIndividual } from '../services/user'
const CollMilSend = () => {
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [email, setEmail] = useState('')
  const [emailData, setEmailData] = useState('')
  const [subject, setSub] = useState('')
  const mailSend = async event => {
    event.preventDefault()
    let body = {
      email: email,
      subject: subject,
      text: emailData,
      key: 'collabrate',
    }
    body = JSON.stringify(body)
    const data = await sendEmailIndividual(body)
    if (data?.status === 200) {
      let confirmBox = window.confirm('Mail sent successfully')
      if (confirmBox) {
        history.push('/collabrate_tool1.6')
      }
    } else {
      alert('Something went wrong, Please check all values are correctly filled')
    }
  }
  useEffect(() => {
    setEmailData(JSON.parse(localStorage.getItem('collMail')))
  }, [])

  return (
    <div className='_group-bg1 fixed top-0 bottom-0 right-0 left-0 overflow-x-hidden'>
      {open && (
        <div className='w-full flex justify-between z-20'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-01 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top absolute'>
            <div className='mt-03 ml-3 w-10 ' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                alt='Not Load'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt ' style={{ marginRight: '12%' }}>
              <h3 className='exercise_solve_1_3_heading'>COLLABORATE</h3>
            </div>
          </div>
        ) : (
          <div className='mt-03 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>
      <div
        className='flex flex-col items-center w-full'
        style={{ position: 'absolute', zIndex: '-1' }}>
        <div style={{ width: '100%', marginTop: '60px', marginBottom: '40px' }}>
          <form className='relative' style={{ marginBottom: '40px' }}>
            <InputBox
              htmlFor='#To'
              label='To'
              id='To'
              type='text'
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            <InputBox
              htmlFor='#subject'
              label='Subject'
              id='firstName'
              type='text'
              value={subject}
              onChange={event => {
                setSub(event.target.value)
              }}
            />
            <div className='px-9 mt-08'>
              <div>
                <label
                  className='not-italic font-medium inline-block font-style _medium font-extrabold'
                  style={{ color: 'var(--jacarta)', fontSize: '10px', lineHeight: '10px' }}>
                  Email Body
                </label>
                <div>
                  <div className='mb-3'>
                    <p className='_bold'> EQ Check-In</p> Mastering Emotional Intelligence (EQ)
                    means regularly checking in on what we are learning from an experience and how
                    we could continue to improve. Take a moment to reflect on how you’re doing and
                    what you could do better.
                  </div>

                  <div className='flex flex-col justify-start gap-1'>
                    <section>
                      <strong>
                        How have I been managing my emotions well during this project?
                      </strong>
                      <div className='mt-0 list-none my-2'>{emailData?.fifth?.firstField}</div>
                    </section>
                    <section>
                      <strong>What could I do better?</strong>
                      <div className='mt-0 list-none my-2'>{emailData?.fifth?.secondField}</div>
                    </section>
                    <section>
                      <strong>
                        How have I been managing my team’s emotions well during this project?
                      </strong>
                      <div className='mt-0 list-none my-2'>{emailData?.fifth?.thirdField}</div>
                    </section>
                    <section>
                      <strong>What could I do better?</strong>
                      <div className='mt-0 list-none my-2'>{emailData?.fifth?.fourthField}</div>
                    </section>
                  </div>
                </div>
              </div>
            </div>

            <button
              className='absolute left-9 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-06 '
              onClick={mailSend}>
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default CollMilSend
