import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_LOGOUT } from '../reducer/types'
const Logout = () => {
  const history = useHistory()
  const [open] = useState(false)
  const { dispatch } = useApp()
  const Logout = () => {
    dispatch({ type: APP_USER_LOGOUT })
    history.replace('/')
  }

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      <div className='px-14 pt-28 logout'>
        <h4
          className=' text-lg mt-14 font-bold mb-3  tracking-normal  text-center px-9'
          style={{ color: 'var(--jacarta)' }}>
          Are you sure you want to log out?
        </h4>
      </div>
      {!open && (
        <div className='px-16 flex justify-between top-4 relative logout_p'>
          <button className='Lbtn' onClick={() => history.push('/user_dashboard')}>
            <span>NO</span>
          </button>
          <button className='Ybtn' onClick={Logout}>
            <span className='_bold tracking-[2px] indent-0.5'>YES</span>

            {/* <img src={Yes} className='h-10 w-10' alt='logo'  /> */}
          </button>
        </div>
      )}
    </div>
  )
}

export default Logout
