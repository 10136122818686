import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminMenu from '../components/AdminComponent/AdminMenu'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo1 from '../assets/images/path-15747-10@1x.png'
import logo2 from '../assets/images/path-15748-10@1x.png'
import logo5 from '../assets/images/path-15765@1x.png'
import logo6 from '../assets/images/group-4466-1@1x.png'
import { AppConstants } from '../appConstanst'

export default function EmailUsers(props) {
  const history = useHistory()
  const [open, toggleMenu] = useState(false)
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [subscribedUsers, setSubscribedUsers] = useState(false)
  const [allUsers, setAllUsers] = useState(false)
  const [unsubscribedUsers, setUnsubscribedUsers] = useState(false)
  const [responseState, setResponsestate] = useState(false)
  const [btnState, setbtnState] = useState(false)
  const token =
    localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).token
      ? JSON.parse(localStorage.getItem('auth')).token
      : null
  const sendEmail = body =>
    fetch(`${AppConstants.BASE_URL}/admin/sendmail`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body,
    })
  const sendEmailTousers = async () => {
    let res = await sendEmail(
      JSON.stringify({
        all_users: allUsers,
        subscribed_users: subscribedUsers,
        unsubscribed_users: unsubscribedUsers,
        subject: subject,
        text: message,
        html: message,
      }),
    )

    if (res.ok) {
      setResponsestate(true)
    }
  }
  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <AdminMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}
      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='top-5 left-5 h-1   absolute' src={logo4} alt='logo' />
            <img className=' h-1 top-7 left-5 absolute' src={logo4} alt='logo' />
            <img className='top-9 left-5 h-1 absolute' src={logo4} alt='logo' />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' src={logo3} alt='logo' />
            <img className='h-3 left-6  top-6 absolute' src={logo5} alt='logo' />
          </div>
        )}
      </div>

      <h4
        className='text-center leading-6 mt-10 text-2xl not-italic _medium'
        style={{ color: 'var(--windsor)' }}>
        Email Users
      </h4>

      <div className='px-9 flex' style={{ marginTop: '52px' }}>
        <input
          id='checkbox'
          type='checkbox'
          disabled={subscribedUsers === true || unsubscribedUsers === true ? true : false}
          className=' mt-4  mb-8 h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setAllUsers(allUsers === false ? true : false)}
        />
        <div
          className=' mt-7 not-italic _medium inline-block  ml-3'
          style={{ color: ' var(--windsor)', fontSize: '10px', lineHeight: '10px' }}>
          All Users
        </div>
      </div>

      <div className='px-9 flex'>
        <input
          id='checkbox'
          type='checkbox'
          disabled={allUsers === true || unsubscribedUsers === true ? true : false}
          className=' mt-4  mb-8  h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setSubscribedUsers(subscribedUsers === false ? true : false)}
        />
        <div
          className=' mt-7 not-italic _medium inline-block  ml-3'
          style={{ color: ' var(--windsor)', fontSize: '10px', lineHeight: '10px' }}>
          Subscribed Users
        </div>
      </div>

      <div className='px-9 flex'>
        <input
          disabled={allUsers === true || subscribedUsers === true ? true : false}
          id='checkbox'
          type='checkbox'
          className=' mt-4 mb-8   h-8 w-8 cursor-pointer'
          style={{ border: '1px solid var(jacarta)', backgroundColor: 'var(--gallery)' }}
          onClick={() => setUnsubscribedUsers(unsubscribedUsers === false ? true : false)}
        />
        <div
          className=' mt-7 not-italic _medium inline-block  ml-3'
          style={{ color: ' var(--windsor)', fontSize: '10px', lineHeight: '10px' }}>
          Un Subscribed Users
        </div>
      </div>
      <label
        className=' mt-2 not-italic _medium inline-block px-9'
        style={{ color: ' var(--windsor)', fontSize: '10px', lineHeight: '10px' }}>
        Subject
      </label>
      <div className='px-9'>
        <input
          type='text'
          className='
          input
        form-control
        block
        w-full
        outline-none
        h-8
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
         border border-solid border-blue_1
        bg-white bg-clip-padding 
        '
          id='subject'
          onChange={e => {
            setSubject(e.target.value)
          }}
        />
      </div>
      <label
        className=' mt-7 not-italic _medium inline-block px-9'
        style={{ color: ' var(--windsor)', fontSize: '10px', lineHeight: '10px' }}>
        Message
      </label>
      <div className='px-9'>
        <textarea
          className='
          input
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        outline-none
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-blue_1'
          id='msg'
          rows='10'
          onChange={e => {
            setMessage(e.target.value)
          }}
        />
      </div>
      {!open && (
        <div className='px-16 flex justify-between mt-16 mb-11 relative'>
          <button className='' onClick={() => history.push('/admin-edit-users')}>
            <img src={logo1} className='h-10 w-10' alt='logo' />
            <img src={logo2} className='absolute h-4 ml-3.5 bottom-3' alt='logo' />
          </button>
          <button
            onClick={sendEmailTousers}
            disabled={
              message.length < 1 ||
              (allUsers === false && unsubscribedUsers === false && subscribedUsers === false)
                ? true
                : false
            }>
            <img src={logo3} className='h-10 w-10' alt='logo' />
            <img
              src={logo6}
              className='absolute h-9 w-9'
              style={{ bottom: '2px', marginLeft: '2px' }}
              alt='logo'
            />
          </button>
        </div>
      )}
      <div
        className='popUp'
        style={{
          display: responseState === true && btnState === false ? 'block' : 'none',
        }}>
        <div className='PopUpWrapper'>
          <h5 className='font-semibold text-green-500 p-2 text-center'>Email sent successfully</h5>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full
          '
            onClick={() => {
              setbtnState(true)
              history.push('/admin-edit-users')
            }}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
