import { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import Collabrate from '../assets/images/col.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export const EmotionalIntelligence3_4 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.collabrate.kg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        kg: stateNew?.user?.progress?.course?.collabrate.kg,
       
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }

    return history.push('/emotional_intelligence3.5')
  }

  const handleClickTwo = async () => {
    var stateNew = { ...state }
    stateNew.user.progress.course.collabrate.kg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        kg: stateNew?.user?.progress?.course?.collabrate.kg,
        
      }),
    )

    return history.push('/emotional_intelligence3.5')
  }

  return (
    <CommonConfirmation
      logo={Collabrate}
      title={'COLLABORATE'}
      trainingTitle={'EMOTIONAL INTELLIGENCE'}
      question={'Did you learn new information about emotional intelligence?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
