import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { login } from '../services/user'
import UserLoginHeader from '../components/userComponent/UserLoginHeader'

const initialState = {
  username: { value: '', isError: false, msg: '' },
  password: { value: '', isError: false, msg: '' },
  res: { isError: false, msg: '' },
}

export default function UserSignin() {
  const { dispatch } = useApp()
  const [form, setForm] = useState(initialState)
  const [isPasswordEnter, setIsPasswordEnter] = useState('')
  const history = useHistory()

  const signin = async _ => {
    if (form.username.value === '' || form.password.value === '') {
      setForm({
        ...form,
        ...(form?.username?.value === ''
          ? { username: { ...form.username, isError: true, msg: 'This field is required.' } }
          : { username: { ...form.username, isError: false, msg: '' } }),
        ...(form?.pass?.value === ''
          ? { password: { ...form.password, isError: true, msg: 'This field is required.' } }
          : { password: { ...form.password, isError: false, msg: '' } }),
        res: {
          ...form.res,
          isError: true,
          msg: 'Please fill all the required fields.',
        },
      })
      return
    }
    if (!form.username.isError && !form.password.isError) {
      let res = await login(
        JSON.stringify({
          username: form.username.value,
          password: btoa(`${form.password.value}`),
          is_admin: true,
        }),
      )

      let data = res.ok ? await res.text() : null
      if (data) {
        data = JSON.parse(data)
        if (data.success) {
          dispatch({ type: APP_USER_AUTH_SUCCESS, value: data.data })
          if (data?.data?.user?.is_admin) {
            history.replace('/admin-dashboard')
          } else {
            history.replace('/user_dashboard')
          }
        }
      } else {
        const error = await res.json()
        alert(error.message)
        setIsPasswordEnter('')
      }
    }
    setForm(initialState)
  }

  return (
    <div className='admin_signup_in fixed overflow-auto top-0 right-0 left-0 bottom-0'>
      <UserLoginHeader />

      <div className='mt-14 mx-8'>
        {!isPasswordEnter ? (
          <div className='flex items-end justify-between'>
            <h4 className='text-white _regular text-2xl'>Sign in</h4>
            <span className='text-white _semiBold font-small'>
              New user?{' '}
              <button
                className='text-white _semiBold _font_color_white ml-0'
                onClick={() => {
                  history.replace('/corporate_signup')
                }}>
                Create an account
              </button>
            </span>
          </div>
        ) : (
          <div className='flex'>
            <h4 className='text-white _regular text-xl'>Enter your password</h4>
          </div>
        )}

        {!isPasswordEnter ? (
          <div>
            <label
              htmlFor='#email'
              className='text-white mt-5 inline-block _medium font-medium'
              style={{ fontSize: '10px', lineHeight: '14px' }}>
              Email address
            </label>
            <input
              id='email'
              className='bg-transparent border-b w-full text-white mt-2 mb-8'
              value={form.username.value}
              onBlur={e =>
                e.target.value.trim() === ''
                  ? setForm({
                      ...form,
                      username: {
                        ...form.username,
                        isError: true,
                        msg: 'This field is required.',
                      },
                    })
                  : setForm({ ...form, username: { ...form.username, isError: false, msg: '' } })
              }
              onChange={e =>
                setForm({ ...form, username: { ...form.username, value: e.target.value } })
              }
            />
          </div>
        ) : (
          <div>
            <label
              htmlFor='#password'
              className='text-white  mt-5 inline-block _medium'
              style={{ fontSize: '10px', lineHeight: '14px' }}>
              Password
            </label>
            <input
              id='password'
              type='password'
              className='bg-transparent border-b w-full text-white mt-2 mb-4'
              value={form.password.value}
              onBlur={e =>
                e.target.value.trim() === ''
                  ? setForm({
                      ...form,
                      password: {
                        ...form.password,
                        isError: true,
                        msg: 'This field is required.',
                      },
                    })
                  : setForm({ ...form, password: { ...form.password, isError: false, msg: '' } })
              }
              onChange={e =>
                setForm({ ...form, password: { ...form.password, value: e.target.value } })
              }
            />
          </div>
        )}

        <div className={`${isPasswordEnter ? 'flex items-end justify-between' : 'flex'}`}>
          {isPasswordEnter && (
            <button
              disabled={!form.username.value}
              className=' py-1 _semiBold _font_color_white divide-transparent '
              style={{ fontSize: '10px', lineHeight: '14px' }}>
              Forgot Password
            </button>
          )}
          <div className='flex-grow'></div>

          <button
            disabled={!form.username.value}
            className='bg-white rounded-full px-1.5 h-8 w-8 font-small _semiBold _font_color_windsor divide-transparent outline-none'
            onClick={() => {
              if (isPasswordEnter) {
                signin()
              } else {
                setIsPasswordEnter('enterPassword')
              }
            }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15pt'
              fill='#2B1F5C'
              viewBox='0 0 448 512'>
              <path d='M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z' />
            </svg>
          </button>

          {/* <button
            disabled={!form.username.value}
            className='bg-white rounded-full px-6 py-1 _semiBold _font_color_windsor divide-transparent'
            onClick={() => {
              if (isPasswordEnter) {
                signin()
              } else {
                setIsPasswordEnter('enterPassword')
              }
            }}>
            Continue
          </button> */}
        </div>
      </div>
    </div>
  )
}
