import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import ct1 from '../assets/images/CT-1.jpg'
import ct2 from '../assets/images/CT-2.jpg'
import ct3 from '../assets/images/CT-3.jpg'
import ct4 from '../assets/images/CT-4.jpg'
import CT_Flow from '../assets/images/CT_Flow.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { UpdateAssessments } from './Asses/Comm_Text'
export const Creative_Thinking1_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  let isMemoryLeak = useRef(true)
  const { state, dispatch } = useApp()
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.creative?.assesment[0]
      ? state?.user?.progress?.course?.creative?.assesment[0]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }

    if (!stateNew.user.progress.course['creative']) {
      stateNew.user.progress.course['creative'] = {
        ...stateNew.user.progress.course.creative,
        assesment: [
          Number(localStorage.getItem('Pbarvalue13')) > 95 || percent1 === 100
            ? 100
            : Math.max(Number(localStorage.getItem('Pbarvalue13')), percent1),
        ],
        kg: false,
        cg: false,
      }
    } else {
      UpdateAssessments(
        stateNew.user.progress.course.creative,
        0,
        Number(localStorage.getItem('Pbarvalue13')) > 95 || percent1 === 100
          ? 100
          : Math.max(Number(localStorage.getItem('Pbarvalue13')), percent1),
      )
    }

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_create`,
        assessment: state?.user?.progress?.course?.creative?.assesment[0],
      }),
    )

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }
  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue13', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/creative_thinking1.2')
    } else {
      history.push('/creative_thinking1.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='creative_thinking_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  1
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                CREATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Creative Thinking 1
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover what creative thinking is and its role in creating new solutions.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px] '>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='getFitMidHeading lineH2_8 work_out mb-5'>CREATIVE THINKING 1</h4>
            <p className='_regular text-2xl work_out  text-center mb-7 _margin_px'>
              What Is Creative Thinking?
            </p>
            <div className='_regular work_out text-base mb-5 _margin_px'>
              <p>
                Creative thinking is your ability to look at problems from a new or fresh
                perspective that suggests unorthodox solutions. What does that really mean? It means
                you need to think outside the box. You need to think unconventionally.
              </p>
              <br />
              <p>
                Study the following infographic to better understand the difference between critical
                thinking and creative thinking.
              </p>
            </div>
          </div>

          <div className='flex items-center  '>
            <img src={CT_Flow} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Creative thinking is often confused with artistic talent. While many artists may
                certainly be creative, whether you are artistic or not has very little correlation
                with your ability to think creatively. Put simply, creativity is defined as the
                ability to transcend traditional ideas, rules, patterns, and relationships, and to
                create meaningful new ideas, forms, methods, or interpretations.
              </p>
              <img src={ct1} className='w-full mb-10' alt='logo' />
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Creative thinking is as old as humankind itself. From the very beginning of time,
                humans have been working to solve key life problems: where to find food, shelter,
                and safety. But the actual term “creativity” struggled for centuries to be born. The
                ancient Greeks were first to argue about the difference between creation and art.
                Plato asked, “Will we say, of a painter, that he makes something?” and answered,
                “Certainly not, he merely imitates.” In ancient Greece, the idea of creativity
                implied freedom of action, whereas art was the making of things, according to rules.
                In short, the artist was a discoverer and recreator of surrounding nature, while a
                creator was an inventor of something new. This is an important differentiation.
              </p>
              <img src={ct2} className='w-full mb-10' alt='logo' />
              <p className='_regular work_out text-base  _margin_px mb-5'>
                This idea takes us to the heart of creative thinking—the creation of something new.
                Let’s make sure we define what we mean by new. The term “new” can be defined as not
                existing before; introduced, or discovered recently or already existing but seen,
                experienced, or acquired for the first time.
              </p>
              <img src={ct3} className='w-full mb-10' alt='logo' />
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                So What Is <br /> Really New?
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  It’s important to understand that creative thinking doesn’t need to result in
                  something completely new to the world—it may just result in something new to you,
                  your team, your company, or your market. Many of the world’s greatest inventions
                  were simply updates or iterations of existing technology. Apple, the world’s most
                  valuable company, is rarely first to invent a new product or even product
                  category. Apple was not the first to develop the mobile phone, the tablet, the
                  laptop, or even the smartwatch. The company simply used creative thinking to
                  iterate on existing ideas to bring new products to market.
                </p>
                <br />
                <p>
                  While creative thinking may seem to come easily to some, it’s a skill that we can
                  all learn, strengthen, and use to solve problems.
                </p>
              </div>
              <img src={ct4} className='w-full mb-7' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                CREATIVE THINKING 1
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Creative Thinking Summary
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Creative thinking is your ability to look at problems from a new or fresh
                perspective that suggests unorthodox solutions. Creative thinking results in the
                creation of something new, such as an idea, method, service, or product.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
