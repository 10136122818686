import React, { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import dumble from '../assets/images/group-4546-1@1x.png'
import nextArrow from '../assets/images/next arrow.svg'
import { useHistory } from 'react-router-dom'
import leftArrow from '../assets/images/leftArrow.svg'
import { saveProgress, saveScoring } from '../services/user'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import useApp from '../context/useApp'
import logo7 from '../assets/images/path-15712@1x.png'
import White from '../assets/images/whitemenu icon.svg'
import logo5 from '../assets/images/path-15768@1x.png'
import mail from '../assets/images/mail.svg'
export const Collabrate_tool1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const { state, dispatch } = useApp()
  const history = useHistory()
  const [textInput_5, setTextInput_5] = useState({
    firstField: '',
    secondField: '',
    thirdField: '',
    fourthField: '',
  })
  let uri = '/course/course'
  const saveData = async event => {
    event.preventDefault()
    var stateNew = { ...state }
    if (state?.user?.progress?.course?.eq_tool?.assessmentPercent < 83) {
      let res = await saveScoring(
        JSON.stringify({
          course: `exercise_communicate`,
          assessment: 83,
        }),
      )
    }
    stateNew.user.progress.course.eq_tool = {
      ...stateNew.user.progress.course.eq_tool,
      textInput_5,
      assessmentPercent:
        state?.user?.course?.progress?.eq_tool?.assessmentPercent > 83
          ? state?.user?.progress?.course?.eq_tool?.assessmentPercent
          : 83,
    }

    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (
      textInput_5?.firstField?.length >= 1 &&
      textInput_5?.secondField?.length >= 1 &&
      textInput_5?.thirdField?.length >= 1 &&
      textInput_5?.fourthField?.length >= 1
    ) {
      if (data?.ok) {
        dispatch({
          type: APP_USER_AUTH_SUCCESS,
          value: stateNew,
        })
      }

      return history.push('/collabrate_tool1.6')
    }
    alert('Answer can not be blank')
  }

  useEffect(() => {
    if (state?.user?.progress?.course?.eq_tool?.textInput_5) {
      setTextInput_5(state?.user?.progress?.course?.eq_tool?.textInput_5)
    }
  }, [])

  return (
    <div className='bg-white fixed top-0 bottom-0 right-0 left-0 overflow-auto  _group-bg1'>
      {open && (
        <div className='w-full flex justify-between'>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div
        className={'cursor-pointer absolute mt-1 '}
        style={{
          backgroundColor: !open ? '#ededed' : 'transparent',
          position: !open ? 'fixed' : 'none',
          pointerEvents: 'none',
        }}
        onClick={_ => toggleMenu(!open)}>
        {/* Top Navbar Option  */}
        {!open ? (
          <div className='exercise_solve_1_3_top'>
            <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
              <img
                className=' w-9 h-9 z-20'
                src={White}
                style={{ position: !open ? 'none' : 'fixed' }}
              />
            </div>
            <div className='nav_respresnt'>
              <h3 className='exercise_solve_1_3_heading _bold'>COLLABORATE</h3>
              <strong>
                <h1 className='percent _regular'>83%</h1>
              </strong>
            </div>
          </div>
        ) : (
          <div className='mt-3 ml-3 w-10' style={{ pointerEvents: 'all' }}>
            <img className=' w-9 h-9' src={logo7} />
            <img className='h-3 left-6  top-6 absolute' src={logo5} />
          </div>
        )}
      </div>

      {!open /*  */ && (
        <div className='Exercise_solve_1_3 _regular'>
          <div className='exercise_solve_1_3_bottom'>
            <div className='exercise_solve_1_3_bottom_top_portion'>
              <div className='w-[70px] h-[70px] bg-[#D9182D] grid place-items-center rounded-full mt-5 mb-4 '>
                <img src={dumble} alt='image is not load' className='w-[35px] h-[25px] '></img>
              </div>
              <h3 className='exerciseReadheading'>STEP 5</h3>
              <h1 className='leading-none mt-2 mainHeading _medium '>Apply The 4 EQ</h1>
              <p className='ex_solve_para '>
                Now is your opportunity to apply the 4 Pillars of EQ to the Collaboration Outcome.
                Remember, take a moment to reflect on each question and try to put yourself in your
                team’s shoes to better understand challenges.
              </p>
            </div>
            <form className='solve_form_1_3'>
              <div className='action_div'>
                <h3 className='exerciseReadheading'>action</h3>
                <p className='ex_solve_para mb-[15px] '>
                  What feelings are surrounding the achievement of this objective?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput_5.firstField}
                  onChange={e =>
                    setTextInput_5({
                      ...textInput_5,
                      firstField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area w-full textArea1'
                  style={{ height: '110px' }}
                />
                <p className='message  ' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Overwhelm and the lack-of-faith that it can be successfully achieved in time.
                  </span>
                </p>
              </div>

              <div className='action_div '>
                <p className='ex_solve_para mb-[15px]'>
                  How does this make you and your team feel?
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput_5.secondField}
                  onChange={e =>
                    setTextInput_5({
                      ...textInput_5,
                      secondField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area textArea1'
                  style={{ fontSize: '14px', height: '110px' }}
                />
                <p className='message' style={{ marginBottom: '40px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>Anxious and frustrated.</span>
                </p>
              </div>
              <div className='action_div'>
                <p className='ex_solve_para mb-[15px] '>
                  What impact could these emotions have on achieving the objective?
                </p>
                <textarea
                  id=' firstInputText'
                  name=' firstInputText'
                  placeholder='Type here.'
                  value={textInput_5.thirdField}
                  onChange={e =>
                    setTextInput_5({ ...textInput_5, thirdField: e.target.value.trimStart() })
                  }
                  className='user_text_area textArea1'
                  style={{ fontSize: '14px', height: '110px' }}
                />
                <p className='message' style={{ marginBottom: '20px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    These emotions could affect progress and innovation.
                  </span>
                </p>
              </div>

              <div className='action_div '>
                <p className='ex_solve_para mb-[15px] '>
                  What can you do to solve these challenges.
                </p>
                <textarea
                  id='firstInputText'
                  name='firstInputText'
                  placeholder='Type here.'
                  value={textInput_5.fourthField}
                  onChange={e =>
                    setTextInput_5({
                      ...textInput_5,
                      fourthField: e.target.value.trimStart(),
                    })
                  }
                  className='user_text_area textArea4'
                  style={{ fontSize: '14px', height: '250px' }}
                />

                <p className='message' style={{ marginBottom: '48px' }}>
                  <span className='_bold'>Example: </span>
                  <span className='italic'>
                    Have honest and open discussions around the time frame. Find methods to reduce
                    overwhelm by using the Solve Tool. Find new ways to motivate the team using the
                    Inspire Tool.
                  </span>
                </p>
              </div>

              {/* <p className='ex_solve_para'>Email the 4 EQ.</p>
              <div className='w-full flex flex-col items-center gap-14 mb-16 mt-5'>
                <button type='submit'>
                  <img src={mail} alt='image no present' className='w-[40px] h-[40px]'></img>
                </button>
              </div> */}

              <div className='w-full flex flex-col items-center gap-6 mb-6 mt-5'>
                <div className='flex w-[90%] items-center justify-between'>
                  <button onClick={() => history.push('/collabrate_tool1.4')}>
                    <img src={leftArrow} alt='Back Arrow'></img>
                  </button>

                  <button type='submit'>
                    <img
                      onClick={saveData}
                      src={nextArrow}
                      alt='image no present'
                      className='w-[40px] h-[40px]'></img>
                  </button>
                </div>
                <div>
                  <p className='ex_solve_para mb-3'>Email the 4 EQ.</p>
                  <button
                    type='submit'
                    onClick={() => {
                      localStorage.setItem('collaborate22', JSON.stringify(textInput_5))
                      return history.push('/collaborate2')
                    }}>
                    <img src={mail} alt='image no present' className='w-[40px] h-[40px]' />
                  </button>
                </div>

                <p className='text-[#43296E] leading-none text-[11px]'>
                  ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
