import Communicate from '../assets/images/Communicate.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { CommonConfirmation } from './CommonComponent/CommonConfirmation'
export const Persuasion1_8 = () => {
  const history = useHistory()
  const { dispatch, state } = useApp()

  const handleClick = async () => {
    let uri = '/course/course'
    var stateNew = { ...state }
    stateNew.user.progress.course.communicate.kg = true
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        kg: state?.user?.progress?.course?.communicate.kg,
        
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }

    return history.push('/persuasion1.9')
  }

  const handleClickTwo = async () => {
    var stateNew = { ...state }
    stateNew.user.progress.course.communicate.kg = false
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_communicate`,
        kg: state?.user?.progress?.course?.communicate.kg,
        
      }),
    )

    return history.push('/persuasion1.9')
  }

  return (
    <CommonConfirmation
      logo={Communicate}
      title={'COMMUNICATE'}
      trainingTitle={'PERSUASION'}
      question={'Did you learn new information about persuasion?'}
      handleClick={handleClick}
      handleClickTwo={handleClickTwo}
    />
  )
}
