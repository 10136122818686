import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import eq5 from '../assets/images/EQ-5.jpg'
import eq6 from '../assets/images/EQ-6.jpg'
import eq7 from '../assets/images/EQ-7.jpg'
import eq8 from '../assets/images/EQ-8.jpg'
import img5 from '../assets/images/Group9641.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { UpdateAssessments } from './Asses/Comm_Text'
export const EmotionalIntelligence2_1 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.collabrate?.assesment[1]
      ? state?.user?.progress?.course?.collabrate?.assesment[1]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.collabrate,
      1,
      Number(localStorage.getItem('Pbarvalue5')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue5')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_collaborate`,
        assessment:
          state?.user?.progress?.course?.collabrate?.assesment[0] +
          state?.user?.progress?.course?.collabrate?.assesment[1],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue5', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/emotional_intelligence2.2')
    } else {
      history.push('/emotional_intelligence2.1')
    }
  }

  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='emotional_intelligence_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  2
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                COLLABORATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Emotional Intelligence 2
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover why you need emotional intelligence and when to use it for more effective
                collaboration.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px] '>
            <p className='text-center mb-1 text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='getFitMidHeading work_out mb-3.5 tracking-[2.8px]'>
              EMOTIONAL INTELLIGENCE 2
            </h4>

            <p className='getFitHeadline work_out text-center mb-7'>
              Why Do I Need <br /> Emotional Intelligence?
            </p>
            <p className='getFitBodySection work_out  mb-5 _margin_px'>
              Ninety percent of top performers have high EQ. Seventy-one percent of hiring managers
              said EQ is more important than IQ. And, high EQ people make 20-30% more, on average,
              per year than low EQ people. Put simply, your level of EQ can dramatically affect your
              level of success no matter what you do now or choose to do in the future.
            </p>
            <img src={eq5} className='w-full mb-6' alt='logo' />
            <p className=' work_out getFitBodySection _margin_px mb-6'>
              Next to critical thinking, emotional intelligence is the most in-demand skill in the
              world today. No matter how smart, organized, or productive you are, without emotional
              intelligence, every job is more difficult. <br />
              <br />
              In a world more connected by technology than ever before, we have become less
              connected as humans. Yet, at our core, each of us craves human connection. Social
              media has become a cheap substitute for engagement—we have begun to accept likes and
              followers over real relationships. <br />
              <br />
              Unfortunately, social media fails to create the deep relationships of trust required
              to be successful in today’s world of business. <br />
              <br />
              The following infographic will help you better understand when to use emotional
              intelligence to build and manage relationships for success.
            </p>
          </div>

          <div className='flex items-center  '>
            <img src={img5} className='w-full mb-12' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            {/* <div className='mt-12'> */}
            <p className=' work_out getFitHeadline text-center mb-6 _margin_px '>
              Applying EQ At Home And At Work
            </p>
            <p className='getFitBodySection work_out  _margin_px mb-5'>
              Let’s explore additional situations where emotional intelligence can be crucial to
              success.
            </p>
            <img src={eq6} className='w-full mb-6' alt='logo' />

            <div className='work_out getFitBodySection  _margin_px mb-5'>
              <p className='getFitBold16px work_out mb-5'>Adapting To Change </p>
              Our ability to understand and manage our own emotions as well as our ability to
              understand and engage with the emotions of those around us during turbulent times of
              change is critical. Change can be unsettling and, thus, highly emotional. How we deal
              with our emotions during change can dramatically affect the outcome. <br />
              <br />
              <p className='getFitBold16px work_out  mb-5'>Navigating Social Complexities</p>
              From home to school to work, each one of us is faced with navigating our social
              networks. Each social network is filled with different personality types and levels of
              emotion. Emotional intelligence not only helps us better understand our networks but
              also manage our relationships within those networks.
              <br />
              <br />
              <p className=' work_out getFitBold16px mb-5'>Managing Stress </p>
              In our fast-changing world, stress has become a constant. How we manage stress both in
              our personal and professional lives dramatically affects outcomes. Emotional
              intelligence plays a key role in identifying and managing stress points to reduce
              stress now and in the future. Our ability to understand and cope with stress is
              fundamental to both productivity and successful performance.
            </div>
            <img src={eq7} className='w-full mb-6' alt='logo' />
            <div className='_regular work_out text-base  _margin_px mb-5'>
              <p className=' work_out getFitBold16px my-5'>Leading Others</p>
              Every great leader has high emotional intelligence. Your ability to capture the minds
              and hearts of those around you and inspire them hinges on your ability to understand
              their emotional drivers. Some studies suggest that high EQ is the single most
              important skill for successfully leading a group.
              <br />
              <br />
              <p className=' work_out getFitBold16px my-5'>Physical And Mental Health </p>
              Recent studies have tied our emotional intelligence, specifically our ability to
              understand our own emotions and manage them, to both our physical and mental
              well-being. Low EQ, or a lack of our ability to understand and regulate our emotions,
              is a key driver of stress, resulting in increased anxiety and depression and increased
              risk of diabetes, heart problems, and other physical ailments.
            </div>
            <img src={eq8} className='w-full mb-6' alt='logo' />
            {/* </div> */}
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-9'>
              <h4 className='getFitSmallHeader work_out text-center tracking-[2px]'>
                EMOTIONAL INTELLIGENCE 2
              </h4>
              <p className='getFitHeadline work_out  text-center mb-8 '>
                Emotional Intelligence Summary
              </p>
              <p className='work_out getFitBodySection _margin_px mb-5'>
                Emotional intelligence plays a critical role in every facet of your life. It is the
                cornerstone of understanding yourself and others, and it determines your ability to
                engage in a healthy and productive way. Highly successful people have high EQ; they
                are genuinely happier, healthier, wealthier, and more respected and liked by their
                peers. If you’re still wondering why you need emotional intelligence, please reread
                this section and study the infographic.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
