import { useState, useEffect } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import cf12 from '../assets/images/CF-12.jpg'
import cf13 from '../assets/images/CF-13.jpg'
import cf14 from '../assets/images/CF-14.jpg'
import cf15 from '../assets/images/CF-15.jpg'
import cf16 from '../assets/images/CF-16.jpg'
import cf17 from '../assets/images/CF-17.jpg'
import cf18 from '../assets/images/CF-20.jpg'
import cf19 from '../assets/images/CF-19.jpg'
import cog_flex from '../assets/images/Cognitive3.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
import { UpdateAssessments } from './Asses/Comm_Text'
export const CogFlex1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.adapt?.assesment[2]
      ? state?.user?.progress?.course?.adapt?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }

  async function updateProgress() {
    let uri = '/course/course'
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.adapt,
      2,
      Number(localStorage.getItem('Pbarvalue9')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue9')), percent1),
    )
    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_adapt`,
        assessment:
          state?.user?.progress?.course?.adapt?.assesment[0] +
          state?.user?.progress?.course?.adapt?.assesment[1] +
          state?.user?.progress?.course?.adapt?.assesment[2],
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue9', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/cog_flex_1.6')
    } else {
      history.push('/cog_flex_1.5')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className='_regular text-2xl pt-5 percentage text-white pl-10 pr-5'>
          {percent1 > 96 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='cog_flex_splash  pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                ADAPT
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Cognitive Flexibility 3
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover how you will use cognitive flexibility and harness a proven process to
                adapt to change more quickly and successfully.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center _mt-63 '>
            <p className=' text-center mb-1 text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out tracking-[2.8px]'>COGNITIVE FLEXIBILITY 3</h4>
            <p className='_regular text-2xl work_out mt-[45px] mb-7 text-center  _margin_px'>
              Cognitive Flexibility Applied
            </p>
            <p className='_regular work_out text-base mb-5 _margin_px'>
              Every day in both our professional and personal lives, we have the opportunity to use
              cognitive flexibility. As discussed, change is the new norm, and our survival is based
              on our ability to adapt to change. Developing our cognitive flexibility skills is not
              only critical to our survival but also to our professional success.
            </p>
            <img src={cf12} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-base _margin_px mb-8'>
              Below is an infographic that you can use as a template to engage your awareness of
              cognitive flexibility and ensure that you are exercising your cognitive flexibility
              muscles.
            </p>
          </div>

          <div className='flex items-center  '>
            <img src={cog_flex} className='w-full' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='_mt-54 '>
              <p className='_regular work_out text-2xl text-center mb-7 '>
                Build A True <br /> Competitive Advantage
              </p>

              <p className='_regular work_out text-base  _margin_px mb-6'>
                Throughout history, humans have been highly successful at adapting to change. While
                technology can dramatically affect our lives in a positive way, we are,
                unfortunately, also shifting our thinking and our cognitive processes to computers.
                We’re letting algorithms make decisions for us. And while cognitive flexibility is
                more important than ever before, technology often leads us to cognitive complacency.
                We also tend to be highly resistant to change and will often refuse to adopt new
                innovations that run counter to our existing knowledge. In short, as humans, it’s
                easy for us to become stuck in our ways and become incredibly biased.
              </p>
              <img src={cf13} className='w-full mb-8' alt='logo' />

              <p className='_regular work_out text-base  _margin_px mb-6'>
                Studies show that having a higher level of cognitive flexibility gives us a
                substantial competitive advantage, including being able to acquire and assimilate
                new information, solve problems more creatively, quickly adjust responses to
                changing conditions, and incorporate positive automatic behaviors. The benefits of
                an increased level of cognitive flexibility allow us to outperform others socially,
                academically, politically, and professionally.
              </p>
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-8'>
              <p className='_regular work_out text-2xl text-center mb-7 _margin_px '>
                Increasing Your Cognitive Flexibility
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                The good news is that there are several things we can do in the real world to
                increase our cognitive flexibility, thus increasing our success.
              </p>
              <img src={cf14} className='w-full' alt='logo' />

              <p className=' work_out _bold text-2xl text-center mt-8'>1.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Break Out Of Your Rut
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                It’s easy to get caught in a rut or comfortable routine. Our routines bring us a
                sense of predictability and even comfort, but those same routines can dramatically
                deteriorate our cognitive flexibility. It’s time to break out of your rut! It
                doesn’t have to be dramatic, it just has to be different so that it forces you to
                think in a new way. For example, do you drive to work or drive home the same way
                every day? Do you always use Google Maps? If so, take a different road, create your
                own route—flex your cognitive muscles. Do you always work out at the same time every
                day? Do you eat the same foods? If so, change up your routine. Try a different work
                out time or even a completely different exercise program. Change your diet. Try a
                new kind of food or a different restaurant. Anything outside of your normal behavior
                will force you to think differently and thus increase your cognitive flexibility.
              </p>
              <img src={cf15} className='w-full' alt='logo' />
              <p className=' work_out _bold text-2xl text-center mt-8'>2.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Learn Or Experience Something New
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                One of the best ways to break out of your rut is to learn or experience something
                new. Combining mental and physical learning can dramatically increase your cognitive
                flexibility. Science shows that learning to play the guitar and sing requires a
                substantial amount of cognitive flexibility, as you not only have to physically play
                the instrument but also remember lyrics and sing at the same time. Things like
                dancing, martial arts, boxing, and even painting require both your mind and your
                body to be engaged. <br />
                <br />
                Remember, you don’t have to make dramatic changes, you just need to do things
                differently. Try learning a new language, traveling to a different country, or even
                just exploring a different part of your own city. Every time we learn or experience
                something new, it strengthens our cognitive flexibility.
              </p>
              <img src={cf16} className='w-full ' alt='logo' />
              <p className=' work_out _bold text-2xl text-center mt-8'>3.</p>
              <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
                Meet New People Or Join New Groups
              </p>
              <p className='_regular work_out text-base  _margin_px mb-6'>
                Study after study supports the idea that we like people who are like us. We like
                people who speak our language and share our culture, values, and even habits.
                <br />
                <br /> As a matter of fact, one study shows that the more time we spend with the
                same people, the more we start to think in the same way. While being around the same
                people and doing the same things or even eating the same food provides comfort, it
                dampens our cognitive flexibility.
                <br />
                <br />
                It’s vital that we find opportunities to spend time with different people. Meeting
                new people challenges our thinking, our views, and our ideas. By being around new
                people, we not only increase and improve our cognitive flexibility but also start to
                exhibit less cognitive bias, and ultimately, we will gain a stronger capacity for
                reasoning and fairness. In short, meet new people, exchange ideas and information,
                engage in robust conversation, and challenge your own ideas and views. This will
                increase your ability to adapt to change and succeed in the new world.
              </p>
              <img src={cf17} className='w-full' alt='logo' />
              <p className=' work_out _regular text-2xl text-center mt-9 mb-6 _margin_px'>
                Signs Of High Cognitive Flexibility
              </p>
              <ul className='_regular work_out text-base _margin_px m-list ml-4'>
                <li>Easily shifts between subjects</li>
                <li>Successfully applies abstract thinking to real-world problems</li>
                <li>Easily handles transitions</li>
                <li>Smoothly shifts between tasks</li>
                <li>Effectively applies subject knowledge to different subjects</li>
              </ul>

              <p className=' work_out _regular text-2xl text-center  mt-9 mb-6 _margin_px  '>
                Signs Of Low Cognitive Flexibility
              </p>

              <ul className='_regular work_out text-base _margin_px m-list ml-4'>
                <li>Struggles to adapt to change</li>{' '}
                <li> Has difficulty letting go of an idea or concept </li>
                <li>Struggles to apply knowledge across diverse subjects </li>
                <li> Finds shifting between tasks to be challenging </li>
                <li> Unable to easily shift between subjects</li>
              </ul>

              <img src={cf18} className='w-full  mt-6' alt='logo' />
              <p className=' work_out _regular text-2xl text-center mt-10 _margin_px mb-5'>
                Cognitive Flexibility Scenarios
              </p>

              <p className='_regular work_out text-base  _margin_px mb-7'>
                <li className='my-4'>
                  A consultant from a different country has new ideas to solve problems at your
                  company. Are you open to change, or are you set in your ways?
                </li>
                <li className='my-4'>
                  Your company just merged with a competitor. Will you be able to adapt
                  successfully? How?
                </li>
                <li className='my-4'>
                  A coworker just showed you new information that proves your idea may not work.
                  What do you do?
                </li>
                <li className='my-4'>
                  You have been working on an important project. Your boss wants you to stop
                  immediately and work on a different project. How do you adjust?
                </li>
              </p>
              <img src={cf19} className='w-full' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-10'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                COGNITIVE FLEXIBILITY 3
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Cognitive Flexibility Summary And Final Wrap-Up
              </p>
              <p className='_regular work_out text-base  _margin_px mb-5'>
                Cognitive flexibility is often overlooked as a critical skill. Hopefully, you’ve
                discovered that cognitive flexibility is vital to your ability to adapt successfully
                to change. <br />
                <br />
                Because our cognitive flexibility diminishes over time, it’s important to continue
                mental exercises to ensure our cognitive skills remain sharp. Remember to break out
                of your rut. Try new things, explore new places, meet new people, and entertain new
                ideas.
                <br />
                <br /> Stay open to change, and always try to connect the dots between the knowledge
                you already have and the new knowledge that you acquire so that you create a network
                of dynamic thinking that will improve your ability to innovate and problem solve.
              </p>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
