import { useState, useEffect, useRef } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import menu from '../assets/images/white dash.svg'
import whiteCross from '../assets/images/white cross.svg'
import ct12 from '../assets/images/CT-12.jpg'
import ct13 from '../assets/images/CT-13.jpg'
import ct14 from '../assets/images/CT-14.jpg'
import ct15 from '../assets/images/CT-15.jpg'
import ct16 from '../assets/images/CT-16.jpg'
import ct17 from '../assets/images/CT-17.jpg'
import ct18 from '../assets/images/CT-18.jpg'
import ct19 from '../assets/images/CT-19.jpg'
import ct20 from '../assets/images/CT-20.jpg'
import CT3 from '../assets/images/nnn.svg'
import rightArrow from '../assets/images/right.svg'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import { saveProgress } from '../services/user'
import { saveScoring } from '../services/Savescore'

import { AddCourses, UpdateAssessments } from './Asses/Comm_Text'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'
export const Creative_Thinking1_5 = () => {
  const [open, toggleMenu] = useState(false)
  const history = useHistory()
  const { state, dispatch } = useApp()
  let isMemoryLeak = useRef(true)
  // scroll logic
  const [scrollPosition, setScrollPosition] = useState(0)
  const [percent1, setPercent1] = useState(
    state?.user?.progress?.course?.creative?.assesment[2]
      ? state?.user?.progress?.course?.creative?.assesment[2]
      : 0,
  )
  const [checkPercent, setCheckPercent] = useState()
  const [data, setData] = useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position, scrollPosition)
    handleScroll1()
  }

  let uri = '/course/course'
  function handleScroll1() {
    if (data) {
      clearTimeout(data)
    }
    setData(
      setTimeout(() => {
        setData(null)
        if (checkPercent >= 0) {
          updateProgress()
        }
      }, 50),
    )
  }
  async function updateProgress() {
    var stateNew = { ...state }
    UpdateAssessments(
      stateNew.user.progress.course.creative,
      2,
      Number(localStorage.getItem('Pbarvalue15')) > 95 || percent1 === 100
        ? 100
        : Math.max(Number(localStorage.getItem('Pbarvalue15')), percent1),
    )

    let res = await saveScoring(
      JSON.stringify({
        course: `getfit_create`,
        assessment: AddCourses(state?.user?.progress?.course?.creative?.assesment, 3),
      }),
    )
    let data = await saveProgress(uri, JSON.stringify(stateNew.user.progress.course), state?.token)
    if (data?.ok) {
      dispatch({
        type: APP_USER_AUTH_SUCCESS,
        value: stateNew,
      })
    }
  }

  useEffect(() => {
    if (isMemoryLeak.current) {
      window.addEventListener('scroll', handleScroll)
    }
    let percent = Math.round(
      ((document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    let checkpercent1 = percent - percent1
    setCheckPercent(checkpercent1)
    if (percent > percent1) {
      localStorage.setItem('Pbarvalue15', percent > 95 ? 100 : percent)
    }
    if (checkPercent > 0) setPercent1(percent)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition])

  const navigateNext = () => {
    if (percent1 > 95) {
      history.push('/creative_thinking1.6')
    } else {
      history.push('/creative_thinking1.5')
    }
  }
  return (
    <div className='bg'>
      <div className='h-16 bg_orange flex flex-row justify-between z-10 fixed top-0 w-full'>
        {open && (
          <div>
            <GlobalMenu openMenu={() => toggleMenu(false)} />
          </div>
        )}

        <div
          className={'cursor-pointer mt-1 ml-2 ' + (open ? 'absolute ' : 'none')}
          onClick={_ => toggleMenu(!open)}>
          {!open ? (
            <div className='ml-3' style={{ marginTop: '13px' }}>
              <img className=' w-9 h-9' src={menu} alt='logo' />
            </div>
          ) : (
            <div className='mt-5 ml-3'>
              <img className=' w-9 h-9' src={whiteCross} alt='logo' />
            </div>
          )}
        </div>
        <h4 className=' percentage pt-5 text-white pl-10 pr-5'>
          {percent1 > 97 ? 100 : percent1}%
        </h4>
      </div>

      {!open && (
        <div className='w-screen mt-16'>
          <div className='creative_thinking_splash pl-5 pr-5 flex flex-col justify-between'>
            <div className='flex justify-center relative top-7'>
              <div className='h-16 w-16 rounded-full bg_orange'>
                <div
                  className='text-5xl text-center relative top-2 text-white _black font-black '
                  style={{ lineHeight: '48px' }}>
                  3
                </div>
              </div>
            </div>
            <div className='' style={{ marginBottom: '391px' }}>
              <h4
                className='text-center text-xs mt-12 pb-2 _bold text-white lspacing'
                style={{ letterSpacing: '7px' }}>
                CREATE
              </h4>
              <h4 className='text-center text-2xl leading-6 _medium text-white pb-4'>
                Creative Thinking 3
              </h4>
              <p
                className='text-center font11  _regular text-white _margin_px'
                style={{ lineHeight: '21px' }}>
                Discover how you will use creative thinking every day at home and at work and a
                proven process you can use to jump-start successful brainstorming sessions.
              </p>
            </div>
            <p className='text-center mb-5 font-normal tracking-normal leading-6 _regular font text-white'>
              ©2022-2023 FULLBRIDGE. ALL RIGHTS RESERVED.
            </p>
          </div>

          <div className='flex flex-col items-center mt-[48px]'>
            <p className='_bold text-center mb-1 font-bold text1 uppercase getFitSmallHeader tracking-[2px]'>
              GET FIT
            </p>
            <h4 className='_bold text-sm work_out mb-5 lineH2_8'>CREATIVE THINKING 3</h4>
            <p className='_regular text-2xl work_out  mb-5 text-center  _margin_px'>
              Applying Creative Thinking At Home And Work
            </p>
            <div className='_regular work_out text-base mb-4 _margin_px'>
              <p>
                By now, you should have a clear understanding of what creative thinking is, why you
                need to use it, and what other skills can help you be a more effective creative
                thinker. Now let’s explore how you will use it every day in real-world situations.
                As previously discussed, every job and almost every professional and personal
                situation you find yourself in will give you an opportunity to apply creative
                thinking to develop new ideas and find solutions. Remember, all of these skills are
                just as applicable in your personal life as they are in your professional life.
              </p>
            </div>
            <img src={ct12} className='w-full mb-8' alt='logo' />
            <p className='_regular text-2xl work_out  mb-10 text-center  _margin_px'>
              What Made Edison <br />
              So Creative?
            </p>
            <div className=' work_out _regular text-base _margin_px mb-16'>
              <p>
                To best understand how to use creative thinking every day, let’s look at a
                real-world creative thinker who was highly successful. By almost all standards,
                Thomas Edison was one of the modern world’s most prolific creative thinkers. Edison
                was granted 1,093 patents for his inventions, which included the lightbulb,
                typewriter, electric panel, phonograph, motion picture camera, and alkaline storage
                battery. After his death in 1931, he left over 3,500 notebooks filled with his ideas
                from countless brainstorming sessions. Keep in mind that Thomas Edison had almost no
                formal education, yet he was able to achieve an unrivaled inventive record. His
                notebooks give us deep insight into his creative thinking process. While there are
                countless strategies for a successful creative thinking process, let’s focus on
                keeping it simple and making it memorable and effective.
              </p>
              <p className='mt-4'>
                Review the following infographic and learn the process behind thinking creatively.
              </p>
            </div>
          </div>

          <div className='w-full flex items-center'>
            <img src={CT3} className='w-screen object-cover' alt='flow' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-14 '>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Five Key Steps In The Edison Process
              </p>
              <img src={ct14} className='w-full mb-6 ' alt='logo' />
            </div>
          </div>

          <div className='flex flex-col items-center  '>
            <p className=' work_out _bold text-2xl text-center mt-7'>1.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Quantity Over Quality
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                Thomas Edison famously said, “Genius is 1% inspiration and 99% perspiration.” This
                sounds counterintuitive, but Edison believed that creativity was simply hard work.
                He believed the best way to come up with one good idea was to come up with thousands
                of ideas. Edison noted that it required over 50,000 experiments to invent a
                successful alkaline storage battery and over 9,000 experiments to perfect the
                lightbulb. He often set creative thinking idea quotas for himself and his employees,
                thus forcing everyone to exhaust potential creative solutions. It’s easy to come up
                with three to five ideas as potential solutions, but coming up with 30 to 50, let
                alone 9,000 becomes much more challenging—truly requiring deep creative thinking.
                It’s also important to note that during any brainstorming session, the early ideas
                will be the most common, and the latter ideas (often the last third) will be the
                ones that take true creative thinking and will ultimately be the most unique and
                complex.
              </p>
              <br />
              <p>
                The best way to ensure a productive creative thinking session is to set an idea
                quota for yourself and every team member. Make it challenging, somewhere between 40
                and 50 ideas per person. Remember, the best ideas will typically be found in the
                last third of your list—after you’ve exhausted the typical.
              </p>
            </div>
            <p className=' work_out _regular text-2xl text-center pb-4 _margin_px '>Inspiration</p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              Put your creative thinking to the test. Look around your office or the room you’re
              sitting in right now and choose an object, any object will do. The more mundane, the
              better. Write down 40 ways you can use that object beyond what it was designed to do.
              For example, your cell phone can also be used as a paperweight or a coaster for your
              coffee cup (not highly recommended). Now just 38 more to go. :)
            </p>
            <img src={ct13} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>2.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 '>
              Challenge Assumptions
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                It’s rumored that before hiring a research assistant, Thomas Edison always invited
                each candidate over for a bowl of soup. If the person seasoned the soup before
                tasting it, Edison believed that the candidate had preconceived assumptions, so he
                wouldn’t hire them.
              </p>
              <br />
              <p>
                Edison was passionate about constantly challenging assumptions. He often said that
                his lack of formal education gave him a competitive advantage. He made far fewer
                assumptions than his highly educated peers and was able to approach solving problems
                without the bias that comes with believing you already know all the answers. Many of
                his most successful inventions flew in the face of the known science of the day, and
                Edison would often try solutions that other inventors believed were impossible. He
                once even clamped his teeth onto a phonograph horn to use it as a hearing aid,
                feeling the sound vibrate through his jaw—amazingly, the same idea is used today in
                Jawbone Bluetooth headphones.
              </p>
            </div>
            <img src={ct15} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>3.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Idea Appropriation
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                Thomas Edison firmly believed that nothing was ever wasted in the creative thinking
                process. He said, “I haven’t failed. I’ve just found 10,000 ways that won’t work.”
                Edison learned that during the creative thinking process, ideas that were being
                generated to solve a specific problem could often be used to solve a completely
                different problem. For example, Edison spent years working to develop an undersea
                telegraph cable that was ultimately unsuccessful, but his work led to solving the
                challenges of the telephone transmitter that soon became the industry standard.
              </p>
              <br />
              <p>
                Put simply, an idea that may not be able to solve the problem you’re currently
                focused on might just be the perfect solution for a different problem.
              </p>
            </div>
            <p className=' work_out _regular text-2xl text-center pb-4 _margin_px '>Inspiration</p>
            <p className='_regular work_out text-base  _margin_px mb-6'>
              Purchase a physical notebook and keep it with you wherever you go. Use it exclusively
              for idea generation, notes, and sketches of your ideas. Read through it often—you’ll
              be surprised by how you can appropriate your ideas to new problems.
            </p>
            <img src={ct16} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>4.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Incremental Improvements
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                When we look at the world today, especially the technology sector, very rarely do we
                see big new inventions coming to market. Most often, we see incremental improvements
                to technologies we already know and love. The first iPhone was revolutionary, but
                the lastest iterations have been just that, incremental improvements to the
                technology.
              </p>
              <br />
              <p>
                Albert Einstein was another highly successful creative thinker. He believed that
                every idea had the potential to improve or modify something that already existed.
                Apple didn’t invent the cell phone—they just made it better. And, contrary to
                popular belief, Thomas Edison didn’t invent the lightbulb—he just made it better and
                then brought it to the masses. Most of Edison’s inventions led to other
                inventions—he was constantly looking for ways to connect inventions or incrementally
                improve his own inventions, and even the inventions of others.
              </p>
              <br />
              <p>
                Alex Osborn is best known for developing a formal method for brainstorming. In 1953,
                he wrote a book about it called “Applied Imagination: Principles and Procedures of
                Creative Problem Solving.” Osborn was one of the first to write about creative
                thinking and creative problem solving (a.k.a. CPS) and the first to develop the
                acronym SCAMPER to help jump-start creative thinking and problem solving.
              </p>
            </div>
            <p className=' work_out _regular text-2xl text-center pb-4 _margin_px '>
              SCAMPER Method
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                Here’s a checklist you can use for more successful creative thinking and
                brainstorming. Simply isolate the subject (your product, idea, solution, service,
                etc.) you want to think about. Then, ask the SCAMPER questions:
              </p>
              <br />
              <p className='font-black _bold'>S = SUBSTITUTE </p>
              <p>Can you substitute something?</p>
              <br />
              <p className='font-black _bold'>C = COMBINE</p>
              <p>Can you combine your subject with something else?</p>
              <br />
              <p className='font-black _bold'>A = ADAPT </p>
              <p>Can you adapt something to your subject? </p>
              <br />
              <p className='font-black _bold'>M = MODIFY</p>
              <p>Can you modify and change it or magnify and add something to it?</p>
              <br />
              <p className='font-black _bold'>P = PUT TO OTHER USES </p>
              <p>Can you put it to some other use?</p>
              <br />
              <p className='font-black _bold'>E = ELIMINATE </p>
              <p>Can you eliminate something from it? </p>
              <br />
              <p className='font-black _bold'>R = REARRANGE </p>
              <p>Can you rearrange it or reverse it?</p>
            </div>
            <img src={ct17} className='w-full mb-6' alt='logo' />
            <p className=' work_out _bold text-2xl text-center mt-8'>5.</p>
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Be Creative
            </p>
            <div className='_regular work_out text-base  _margin_px mb-8'>
              <p>
                Remember, being creative or a creative thinker has nothing to do with being
                artistic. Starting today, choose to BE CREATIVE! Use your idea notebook to
                brainstorm, and keep your ideas where they are easily accessible to you. Volunteer
                for brainstorming sessions, set a quota for yourself or your team, and exhaust
                creative thinking and ideas. Most importantly, remove your own labels, bias, and
                limitations. As a human, you’re already creative—exercise your creative thinking
                muscles.
              </p>
            </div>
            <img src={ct18} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Creative Thinking Examples
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <p>
                There are countless examples of real-world creative thinking. Below are a few
                examples and several scenarios to jump-start your thinking:
              </p>
              <br />
              <p>
                <span className='_bold'>Example: </span> An engineer needed to place a large piece
                of equipment into an excavated area. Typically, this would require a heavy crane and
                thousands of dollars of extra budget. One day, while watching a show on the Inuit
                tribes and how they built their igloos with blocks of snow, he had an idea. He
                trucked in blocks of ice and placed them in the excavated area. Next, he simply
                pushed the equipment onto the ice. When the ice melted, the equipment settled
                perfectly into place.
              </p>
              <br />
              <p>
                <span className='_bold'>Example: </span> Steve Jobs was apparently frustrated that
                it was difficult to pick up the early prototypes of the iPad. The only way to pick
                it up was to slide it to the edge of the table. This forced the creative team to
                find a solution, which was beveling the edges of the iPad so that it could easily be
                picked up from a flat surface.
              </p>
              <br />
              <p>
                <span className='_bold'>Example: </span> Earlier at Apple, Steve Jobs mandated that
                it couldn’t take more than three clicks for a user to find the song they wanted on
                the new iPod. This forced the creative team to find a very unique solution, and the
                click wheel on the front of the iPod was born.
              </p>
            </div>
            <img src={ct19} className='w-full mb-6' alt='logo' />
            <p className=' work_out _regular text-2xl text-center pb-4 mb-5 _margin_px'>
              Creative Thinking Scenarios
            </p>
            <div className='_regular work_out text-base  _margin_px mb-6'>
              <ul className='ml-3 flex flex-col gap-3'>
                <li>
                  <span className='_bold'>•</span> You’re in your car on a long road trip. One of
                  the windows won’t roll all the way up, and all the noise is frustrating you. How
                  can you solve this problem?
                </li>
                <li>
                  <span className='_bold'>•</span> A global pandemic has forced you to work from
                  home. Your wife and three kids are also home. How can you keep everyone engaged,
                  happy, and healthy?
                </li>
                <li>
                  <span className='_bold'>•</span> Your company has an older product that is no
                  longer in demand by the market. Your boss has asked you to come up with ideas
                  about how to either liquidate the product or find other potential uses for the
                  technology. How can you solve this problem?
                </li>
              </ul>
            </div>
            <img src={ct20} className='w-full ' alt='logo' />
          </div>

          <div className='flex flex-col items-center  '>
            <div className='mt-10'>
              <h4 className='_bold font work_out text-center tracking-[2px]'>
                CREATIVE THINKING 3
              </h4>
              <p className='_regular work_out text-2xl text-center mb-8 _margin_px'>
                Creative Thinking Summary And Final Wrap-Up
              </p>
              <div className='_regular work_out text-base  _margin_px mb-5'>
                <p>
                  Your opportunities to use creative thinking in the real world are endless. As a
                  matter of fact, you’re probably already using creative thinking every single day.
                  Remember, some of the best solutions are simply incremental advancements on
                  existing ideas. Use the SCAMPER method to help iterate and drive new creative
                  thinking around your ideas, solutions, products, or services.
                </p>
                <br />
                <p>
                  Creative Thinking is your ability to look at problems from a new or fresh
                  perspective that suggests unorthodox solutions. What does that really mean? It
                  means you need to think outside the box. You need to think unconventionally. It’s
                  important to remember that your ability to think creatively has nothing to do with
                  being artistic.
                </p>
              </div>
            </div>
          </div>
          <div
            className='mt-14 w-full h-[80px] grid place-items-center'
            style={{ paddingBottom: '70px' }}>
            <button onClick={navigateNext}>
              <img src={rightArrow} className='w-full ' alt='logo' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
