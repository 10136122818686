import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import LoginHeader1 from '../components/LoginHeader1'
import { userSignup } from '../services/user'
import White from '../assets/images/whiteArrow.svg'
import { APP_USER_AUTH_SUCCESS } from '../reducer/types'

export default function UserSignIn() {
  const { dispatch } = useApp()

  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPass] = useState()
  const [conPass, setConPass] = useState()
  const [gCode, setGCode] = useState()
  // direct option

  const handleSubmit = async e => {
    // perform all neccassary validations

    e.preventDefault()
    if (
      !email ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    ) {
      alert('Invalid email')
    } else if (
      !password ||
      !/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(password)
    ) {
      alert(
        'The password must be at least 8 characters long contain a special character, a number, an uppercase letter, and a lower case letter ',
      )
    } else if (password.length < 8) {
      alert('Password should not be less than 8 characters')
    } else if (!password || !conPass || password !== conPass) {
      alert("Passwords don't match")
    } else if (!gCode || gCode.length > 7 || gCode.length < 7) {
      alert('Corporate Serial Number can not be more or less then 7 digit')
    } else {
      let res = await userSignup(
        JSON.stringify({
          name: `${firstName.trim()} ${lastName.trim()}`,
          username: email.toLowerCase(),
          password: btoa(`${password}`),
          g_code: gCode,
        }),
      )
      let data = res.ok ? await res.text() : null

      if (data) {
        data = JSON.parse(data)
        if (data.success) {
          dispatch({ type: APP_USER_AUTH_SUCCESS, value: data.data })
          if (data?.data?.user?.is_admin) {
            history.replace('/admin-dashboard')
          } else {
            history.replace('/user_dashboard')
          }
        } else {
          alert(data.data)
        }
      } else {
        const error = await res.json()
        alert(error.message)
      }
    }
  }

  const navigateToSignin = _ => {
    history.replace('/admin_signin')
  }

  return (
    <div className='admin_signup_in fixed overflow-auto top-0 right-0 left-0 bottom-0'>
      <LoginHeader1 />

      <div className='mx-9'>
        <div className='flex justify-between items-center mt-10 mb-5'>
          <span
            className='text-white text-2xl leading-[29px] _regular'
            style={{ lineHeight: '13pt' }}>
            Create Account
          </span>
          {/* <button
            className='bg-white rounded-full px-1.5 h-8 w-8 font-small _semiBold _font_color_windsor divide-transparent outline-none'
            onClick={navigateToSignin}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15pt'
              fill='#2B1F5C'
              viewBox='0 0 448 512'>
              <path d='M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z' />
            </svg>
          </button> */}
        </div>

        {/* <h4 className='text-white _regular text-2xl'>Or, Create Account</h4> */}
        <form onSubmit={handleSubmit}>
          <label htmlFor='#f_name' className='text-white leading-[13px] inline-block font _medium'>
            First Name
          </label>
          <input
            id='fname'
            required
            onChange={e => {
              setFirstName(e.target.value.replace(/\s+/g, ' ').trim())
            }}
            className='bg-transparent border-b w-full text-white px-2 py-1 mb-8 outline-none'
          />
          <label htmlFor='#l_name' className='text-white mt-0 inline-block font _medium'>
            Last Name
          </label>
          <input
            required
            id='lname'
            onChange={e => {
              setLastName(e.target.value.replace(/\s+/g, ' ').trim())
            }}
            className='bg-transparent border-b w-full text-white px-2 py-1 mb-8 outline-none'
          />
          <label htmlFor='#Email' className='text-white leading-[13px] inline-block font _medium'>
            Email address
          </label>
          <input
            required
            id='email'
            onChange={e => {
              setEmail(e.target.value.toLowerCase())
            }}
            className='bg-transparent border-b w-full text-white px-2 py-1 mb-8 outline-none'
          />
          <label htmlFor='#pass' className='text-white leading-[13px] inline-block font _medium'>
            Password
          </label>
          <input
            required
            id='password'
            type='password'
            onChange={e => {
              setPass(e.target.value)
            }}
            className='bg-transparent border-b w-full text-white px-2 py-1 mb-8 outline-none'
          />
          <label htmlFor='#conPass' className='text-white font leading-[13px] inline-block _medium'>
            Confirm Password
          </label>
          <input
            required
            id='conpass'
            type='password'
            onChange={e => {
              setConPass(e.target.value)
            }}
            className='bg-transparent border-b w-full text-white mt-2 mb-8 outline-none'
          />

          <label htmlFor='#gcode' className='text-white font inline-block _medium'>
            Corporate Code
          </label>
          <input
            required
            id='g_code'
            type='text'
            value={gCode}
            maxLength={7}
            onChange={e => {
              setGCode(e.target.value)
            }}
            className='bg-transparent border-b w-full text-white mt-2 mb-4 outline-none'
          />
          <div className='flex  justify-around items-center mt-6 '>
            <Link to={'/privacy_policy'}>
              <p className='text-white font ' style={{ maxWidth: '90%', lineHeight: '1.2' }}>
                By clicking forward I agree to the Fullbridge Terms of Service
              </p>
            </Link>
            <button
              type='submit'
              disabled={
                gCode?.length === 0 ||
                gCode?.length > 7 ||
                gCode?.length < 0 ||
                firstName.trim().length < 1 ||
                lastName.trim().length < 1
                  ? true
                  : false
              }
              className=' h-[39px] w-[39px] '>
              <img src={White} alt='next button is Loading'></img>
            </button>
          </div>
        </form>
        <div className='mt-[60px] mb-20'>
          <p className='text-white _regular text-2xl'>Already a Member?</p>
          <p className='text-white _regular text-sm mt-4' onClick={navigateToSignin}>
            Click here to Login.
          </p>
        </div>
      </div>
    </div>
  )
}
