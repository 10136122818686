import React, { useState } from 'react'
import GlobalMenu from '../components/userComponent/GlobalMenu'
import ProgressCard from '../components/userComponent/ProgressCard'
import collaborateLogo from '../assets/images/group-4507@1x.png'
import logo3 from '../assets/images/path-15709-10@1x.png'
import logo2 from '../assets/images/backrow.svg'
import logo4 from '../assets/images/path-15738-10@1x.png'
import logo5 from '../assets/images/path-15768@1x.png'
import logo8 from '../assets/images/path-15712@1x.png'
import { useHistory } from 'react-router-dom'
import useApp from '../context/useApp'
import Collabrate from '../assets/images/Collbrate_i.svg'
export default function UserSolveWorkout() {
  const [open, toggleMenu] = useState(false)
  const { state } = useApp()
  const history = useHistory()

  const data = [
    {
      name: 'EMOTIONAL INTELLIGENCE 1',
      description:
        'Discover what emotional intelligence is and its role in effective and successful collaboration.',
      progress: state?.user?.progress?.course?.collabrate?.assesment[0]
        ? state?.user?.progress?.course?.collabrate?.assesment[0]
        : 0,
      time: '10 min',
      route: '/emotional_intelligence1.1',

      logo: collaborateLogo,
    },
    {
      name: 'EMOTIONAL INTELLIGENCE 2',
      description:
        'Discover why you need emotional intelligence and when to use it for more effective collaboration.',
      progress: state?.user?.progress?.course?.collabrate?.assesment[1]
        ? state?.user?.progress?.course?.collabrate?.assesment[1]
        : 0,
      time: '10 min',

      route:
        state?.user?.progress?.course?.collabrate?.assesment[0] === 100
          ? '/emotional_intelligence2.1'
          : null,
      logo: collaborateLogo,
    },
    {
      name: 'EMOTIONAL INTELLIGENCE 3',
      description:
        'Discover how to use EQ and a proven process you can use to collaborate more successfully.',
      progress: state?.user?.progress?.course?.collabrate?.assesment[2]
        ? state?.user?.progress?.course?.collabrate?.assesment[2]
        : 0,
      time: '10 min',
      route:
        state?.user?.progress?.course?.collabrate?.assesment[0] &&
        state?.user?.progress?.course?.collabrate?.assesment[1] === 100
          ? '/emotional_intelligence3.1'
          : null,
      logo: collaborateLogo,
    },
  ]
  return (
    <div className='user_solve_cover fixed top-0 bottom-0 right-0 left-0 overflow-auto'>
      {open && (
        <div>
          <GlobalMenu openMenu={() => toggleMenu(false)} />
        </div>
      )}

      <div className={'cursor-pointer absolute mt-1 ml-2'} onClick={_ => toggleMenu(!open)}>
        {!open ? (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo3} />
            <img className='top-5 left-5 h-1   absolute' alt='logo' src={logo4} />
            <img className=' h-1 top-7 left-5 absolute' alt='logo' src={logo4} />
            <img className='top-9 left-5 h-1 absolute' alt='logo' src={logo4} />
          </div>
        ) : (
          <div className='mt-3 ml-3'>
            <img className=' w-9 h-9' alt='logo' src={logo8} />
            <img className='h-3 left-6  top-6 absolute' alt='logo' src={logo5} />
          </div>
        )}
      </div>
      {!open && (
        <div>
          <div className='flex justify-center mt-10'>
            <img className='h-16 w-12' alt='logo' src={Collabrate} />
          </div>
          <h4 className='text-center text-2xl   pb-2 _medium' style={{ color: '#ffffff' }}>
            Collaborate
          </h4>
          <h4
            className='text-center mb-10  spaceing '
            style={{ color: '#ffffff', fontSize: '10px' }}>
            GET FIT
          </h4>
          {data?.map((item, index) => {
            return (
              <ProgressCard
                cardContainerOpacity={index === 0 ? '90%' : item?.route !== null ? '90%' : '50%'}
                leftLogo={item?.logo}
                key={index}
                time={item.time}
                name={item?.name}
                route={item?.route}
                description={item?.description}
                progress={item?.progress}
                timeColor={'#E28D3D'}
                cardContainerColor={'#ffffff'}
                nameColor={'#E28D3D'}
                descriptionColor={'#E28D3D'}
                progressContainerColor={'#F6DBC9'}
                progressColor={'#E28D3D'}
                progressTextColor={'#E28D3D'}
              />
            )
          })}

          <div className='  mt-80 mb-10 flex justify-center relative'>
            <div onClick={() => history.push('/get_fit')}>
              <div className='flex justify-center items-center h-10 w-10'>
                <img className='absolute ' alt='logo' src={logo2} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
